/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from '../../Context/UserContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { socialKey } from '../../Service/key';

const GoogleBtn = styled.div`
  button {
    width: 100%;

    margin-top: 5px;
    padding: 20px;

    /* background-color: #ccc; */
    /* color: #ccc; */
    border: 1px solid #ccc !important;
  }
`;

function Google({ checkSocial }) {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const googleLoginBtn = useRef(null);
  // const [token, setToken] = useState("");

  useEffect(() => {
    if (googleLoginBtn.current.id === 'customBtn') googleSDK();
  }, []);

  //SDK 초기 설정 및 내 API초기화
  const googleSDK = () => {
    window.googleSDKLoaded = () => {
      // console.log(window.gapi);
      window.gapi.load('auth2', () => {
        const auth2 = window.gapi.auth2.init({
          client_id: socialKey.google,
          scope: 'profile email',
        });
        //버튼 클릭시 사용자 정보 불러오기

        auth2.attachClickHandler(
          googleLoginBtn.current,
          {},
          (googleUser) => {
            const profile = googleUser.getBasicProfile();
            // console.log(profile);
            // console.log(googleUser.getAuthResponse());
            // console.log(`Token || ${googleUser.getAuthResponse().id_token}`);
            // setToken(googleUser.getAuthResponse().id_token);
            // console.log(`ID: ${profile.getId()}`);
            // console.log(`Name: ${profile.getName()}`);
            // console.log(`Image URL: ${profile.getImageUrl()}`);
            // console.log(`Email: ${profile.getEmail()}`);

            const access_token = googleUser.getAuthResponse().id_token;
            const payload = {
              access_token: access_token,
              channel: 'G',
            };

            socialDispatch({
              type: 'CHECK_SOCIAL_LOADING',
              access_token: access_token,
              channel: 'G',
            });

            checkSocial(payload);
            popupSetState({
              ...popupState,
              login: false,
              singup: false,
            });
          },
          (error) => {
            console.log(JSON.stringify(error, undefined, 2));
            // alert(JSON.stringify(error, undefined, 2));
          },
        );
      });
    };
    //구글 SDK 불러오기
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  };

  const { t } = useTranslation();

  return (
    <GoogleBtn id="gSignInWrapper">
      <span className="label" />
      <button
        ref={googleLoginBtn}
        id="customBtn"
        className="customGPlusSignIn google"
      >
        <span className="icon"></span>
        <span className="buttonText">{t('continue-google')}</span>
      </button>
    </GoogleBtn>
  );
}
export default Google;
