import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const Wrapper = styled.div`
  max-width: 1280px;
  width: ${(props) => (props.width ? props.width : "90%")};
  margin: 0 auto;
  padding: 0;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "0px"};

  /* 
  ${Media.tablet`
  `}

  ${Media.mobile`
  `} */
`;
const SubHeaderText = styled.h2`
  /*padding-left: ${(props) =>
    props.paddingLeft ? props.paddingLeft : "0px"};*/
  color: ${(props) => (props.color ? props.color : "#fff")};
  font-size: 1.6rem;
  text-align: ${(props) => props.textAlign};
  span {
    font-size: 1rem;
    display: block;
    margin-top: 0.4rem;
  }
`;

const SubHeader = ({
  title,
  subtitle,
  color,
  paddingLeft,
  textAlign,
  width,
  marginBottom,
}) => {
  return (
    <Wrapper marginBottom={marginBottom} width={width}>
      <SubHeaderText
        className="eng"
        color={color}
        paddingLeft={paddingLeft}
        textAlign={textAlign}
      >
        {title}
        {/* <span>{subtitle}</span> */}
      </SubHeaderText>
    </Wrapper>
  );
};

export default SubHeader;
