import React, { useState } from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SelectSignUp from "../Layout/SelectSignUp";
import AgreementPersonal from "../Layout/AgreementPersonal";
import AgreementBusiness from "../Layout/AgreementBusiness";
import PersonalSignUp from "../Layout/PersonalSignUp";
import BusinessSignUp from "../Layout/BusinessSignUp";
import CompleteSignUp from "../Layout/CompleteSignUp";

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.63);
`;

const PopUpBlock = styled.div`
  /* padding: 80px 30px; */
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 991;

  overflow-y: auto;

  width: 500px;
  height: 800px;
  min-height: 85vh;

  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;

  ${Media.tablet`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100vh;
  max-height: 100vh;
  transform: none;
  `}

  ${Media.mobile`
  
  `}
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 999;

  cursor: pointer;
`;

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Media.tablet`

  `}

  ${Media.mobile`

  `}
`;
const SignUp = ({ setOpenSignUp }) => {
  const [popUpPage, setPopUpPage] = useState(1);

  const pageComponent = {
    1: <SelectSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    2: <AgreementPersonal popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    3: <PersonalSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    4: <CompleteSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,

    5: <AgreementBusiness popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    6: <BusinessSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
  };

  const closePopUp = () => {
    setOpenSignUp(false);
  };

  return (
    <>
      <PopUpBlock>
        <SignUpWrapper>{pageComponent[popUpPage]}</SignUpWrapper>
        <CloseBtn onClick={closePopUp}>
          <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <defs>
              <path
                id="icon-close_svg__a"
                d="M0 1.5L1.5 0 8 6.5 14.5 0 16 1.5 9.5 8l6.5 6.5-1.5 1.5L8 9.5 1.5 16 0 14.5 6.5 8z"
              />
            </defs>
            <use xlinkHref="#icon-close_svg__a" transform="translate(4 4)" />
          </svg>
        </CloseBtn>
      </PopUpBlock>
      <Dimmed onClick={closePopUp} />
    </>
  );
};

export default SignUp;
