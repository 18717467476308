/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";
import SubHeader from "../Layout/SubHeader";
import Slider from "react-slick";
// import { EventButton } from "../../Styles/ButtonStyle";
import EventLogin from "./EventLogin";
import EventSignUp from "./EventSignUp";
import { useTranslation } from "react-i18next";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import Meta from "../../Utils/Meta";
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal';

const EventBlock = styled.div`
  position: relative;
  padding: 135px 0 80px;
  width: 100%;
  min-height: 100vh;

  background-color: #0065c3;
  background-image: url("./Img/bg_pattern3.jpg");
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  br {
    display: none;
  }
  ${Media.mobile`
  a {
    padding: 90px 0;
  }
  
  br{
    display: block;
  }
  
  `}
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;

  h2 {
    color: #fff;
    font-size: 2rem;
    margin: 20px 0 40px;
    font-weight: bold;
  }
  h3 {
    margin-top: 20px;
    color: #fff;
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: bold;
    margin-bottom: 6px;
    display: inline-block;
  }
  ul li {
    color: #fff;
    margin-bottom: 30px;
  }

  h4 {
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: normal;
  }
  ${Media.tablet`

  `}

  ${Media.mobile`
  h2 {
    font-size: 1.4rem;
    line-height: 1.4;
    word-break: keep-all;
  }
  h3{
    font-size: 1.2rem;
  }
  
  ul li {
    line-height: 1.4;
   
  }
  
  ul li p {
    /* background-color: #fff; */
    /* padding-right: 37%; */
    /* word-break: keep-all; */
  }
  `}
`;

const DetailView = styled.div`
  display: inline-block;
  position: relative;
  top: -5px;
  color: #fff;
  padding: 4px 16px;
  border: solid 1px #fff;
  font-size: 0.875rem;
  font-weight: normal;
  margin-left: 10px;
  cursor: pointer;

  ${Media.mobile`
    display: none;
  `}
`;

const DetailViewMobile = styled.div`
  display: none;

  ${Media.mobile`
    display: block;
    text-align: center;
    border: 1px solid #fff;
    margin-top: 20px;
    height: 50px;
    line-height: 50px;
    padding: 0 0;
    cursor: pointer;
  `}
`;

const SliderWrapper = styled.div`
  margin-top: 20px;
`;

const Goods = styled.div`
  width: 40%;
  padding-top: 100%;
  margin: 5px;
  /* margin-bottom: 15px; */
  /* background-color: #f4f4f4; */
  border-radius: 5px;

  background-image: url(${(props) => props.image || null});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  box-shadow: -3px -3px 3px 3px 3px rgba(0, 0, 0, 0.3);

  ${Media.desktop`


  `}

  ${Media.tablet`


  `}
  
  ${Media.mobile`


  `}
`;

const HowToDetail = styled.div`
  &::before,
  &::after {
    content: "";
    display: block;
    width: 1px;
    height: 50px;
    background-color: #fff;
    margin: 10px auto;
  }
  div {
    color: #fff;
    font-size: 1rem;
    line-height: 1.4;
  }

  .howto-detail {
    text-align: center;
  }

  h6 {
    font-size: 1rem;
    line-height: 1.6;
  }

  li {
    width: 30%;
    max-width: 300px;
    padding: 0 30px;
  }
  img {
    width: 100%;
  }
  span {
    display: block;
    color: #fff;
    margin-top: 10px;
    line-height: 1.4;
    font-size: 1rem;
  }
  ${Media.tablet`
    
  `}

  ${Media.mobile`
    &::before, &::after{
      display: none;
    }
    .howto-detail{
      text-align: left;
      br{
        display: none;
      }
    }
    ul{
      display: block;
      text-align: left;
    }
    li{
      width: 100%;
      max-width: 100%;
      padding: 0 0;
      margin-bottom: 30px;
      
      &::after{
        content: '';
        display: block;
        clear: both;
      }
    }
    img{
      max-width: 40%;
      width: 40%;
      vertical-align: middle;
      float: left;
    }
    span{
      align-content: center;
      display: inline-block;
      margin-top: 5%;
      margin-left: 20px;
      float: left;
      vertical-align: middle;
    }
  `}
`;

const HowTo = styled.div`
  padding: 70px 0 0;
  ul {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    text-align: center;
  }
  li {
    width: 30%;
    max-width: 300px;
    padding: 0 30px;
  }
  img {
    width: 100%;
  }
  span {
    display: block;
    color: #fff;
    margin-top: 10px;
    line-height: 1.4;
    font-size: 1rem;
  }

  ${Media.tablet`
    
  `}

  ${Media.mobile`
    ul{
      display: block;
      text-align: left;
    }
    li{
      width: 100%;
      max-width: 100%;
      padding: 0 0;
      margin-bottom: 30px;
      padding-left: 0;

      &::after{
        content: '';
        display: block;
        clear: both;
      }
    }
    img{
      max-width: 40%;
      width: 40%;
      vertical-align: middle;
      float: left;
    }
    span{
      align-content: center;
      display: inline-block;
      margin-top: 8%;
      margin-left: 20px;
      float: left;
      vertical-align: middle;
    }
  `}
`;

const ButtonWrapper = styled.div``;

const GiveAway = styled.div`
  background-color: #0065c3;
  text-align: left;
  padding: 70px 0;

  h2 {
    color: #fff;
    margin-bottom: 10px;
  }
  h6 {
    color: #fff;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    margin-bottom: 50px;
  }
  ul {
    display: flex;
    justify-content: space-between;
  }
  li {
    width: 24%;
    text-align: left;
  }
  img {
    width: 100%;
    vertical-align: bottom;
  }
  span {
    display: inline-block;
    margin: 10px 0;
    color: #fff;
  }
  ${Media.tablet`
    padding: 40px 0;
    ul{
      flex-wrap: wrap;
    }
    li{
      width: 49%;
      margin-bottom: 1%;
    }
  `}

  ${Media.mobile`
    p {
      font-size: 0.9rem;
    }
  `}
`;
const SnsAd = styled.div`
  background-color: #fff;
  text-align: center;
  padding: 70px 0;

  .hash-description::before,
  .hash-description::after {
    content: "";
    display: block;
    width: 1px;
    height: 50px;
    background-color: #000;
    margin: 10px auto;
  }

  .hash-description span {
    text-align: center;
  }

  h2 {
    color: #000;
    margin-bottom: 10px;
  }

  p {
    word-break: keep-all;
    line-height: 1.4;
    margin-bottom: 50px;
  }

  ul {
    margin: 30px auto 50px;
    text-align: left;
    display: flex;
    justify-content: space-between;
  }
  li {
    padding-left: 20px;
    border-left: solid 3px #000;
    width: 32%;
    box-sizing: border-box;
    line-height: 1.4;
    color: #000 !important;
  }
  h3 {
    color: #000;
    margin-bottom: 10px;
  }

  h6 {
    font-weight: bold;
    margin-bottom: 10px;
  }

  span div {
    margin-top: 10px;
  }

  p.notice {
    font-size: 0.875rem;
    color: #333;
  }
  ${Media.tablet`
    padding: 40px 0;
    ul{
      width: 100%;
    }
  `}

  ${Media.mobile`
    p {
    padding: 0 0px;
    }
    ul{
      display: block;
    }
    li{
      width: 100%;
      margin-bottom: 20px;
    }
    
    .hash-description::before, .hash-description::after{
      display: none;
    }
  
    .hash-description span {
      text-align: left;
      line-height: 1.4;
    }
    .bonus-notice {
      text-align: center;
      word-break: keep-all;
    }
    p.notice {
      text-align: left;
    }
  `}
`;

const EventDetail = styled.div`
  margin: 50px 0;
  font-size: 0.875rem;
  line-height: 1.7;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px 30px 10px;
  border: solid 1px rgba(255, 255, 255, 0.5);

  div {
    width: 100%;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: bold;

    &::before {
      content: "";
      display: inline-block;
      margin-bottom: 8px;
      margin-right: 5px;
      width: 20px;
      height: 1px;
      background-color: #fff;
      vertical-align: middle;
    }
  }

  p {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  table {
    border-collapse: collapse;
    margin-bottom: 40px;
    font-size: 1rem;
    width: 96%;
  }
  tr {
  }
  th {
    border: solid 1px rgba(255, 255, 255, 0.5);
    padding: 8px 10px;
  }
  td {
    border: solid 1px rgba(255, 255, 255, 0.5);
    padding: 8px 10px;
  }
  ul li {
    margin-bottom: 15px;
  }
  li {
    font-size: 0.875rem;
    opacity: 0.6;
  }

  ${Media.tablet`
    padding: 0 0 0;
    border: 0;
    
    display: block;
    div{
      width: 100%;
    }
    
    h4{
      font-size: 1rem;
      &::before{
        display: none;
      }
    }
    
    table{
      margin-left: 0;
      width: 100%;
      font-size: 0.875rem;
    }
    
    th, td{
      padding: 5px 5px;
    }
    
    p{
      margin-left: 0;
    }
    
    li{
      margin-left: 0;
    }

  `}

  ${Media.mobile`


  `}
`;

const GoodsInfoBlock = styled.div`
  background-color: #0065c3;
  padding: 70px 0;
  text-align: center;
  h2 {
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    color: #fff;
    line-height: 1.4;
    margin-bottom: 50px;
  }
  h4 {
    font-size: 1.4rem;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  h5 {
    font-size: 1.2rem;
  }

  ul {
    margin: 0 0 80px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateY(-40px);

    li {
      width: 32%;
      margin-right: 1%;
      margin-bottom: 1%;
    }

    span {
      display: inline-block;
      margin: 10px 0;
      color: #fff;
    }

    img {
      width: 100%;
    }
  }

  ${Media.tablet`
  padding: 40px 0;
  ul{
    margin: 0 0 10px;
  }

`}
  ${Media.mobile`
  .goods-5 li {
    width: 49% !important;
  }


`}
`;

const HeritageImg = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

const GoodsTitleImg = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  h3 {
    margin-left: 10px;
    transform: translateY(-100%);
    color: #000;
    font-size: 2.6rem;
    width: calc(100% - 10px);
    text-align: left;
  }
`;

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="nextArrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="prevArrow"
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

const EventButton = styled.button`
  position: static;
  width: auto;
  min-width: 50%;
  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  display: none;
  `}
`;

const EventButton2 = styled.button`
  position: static;
  width: auto;
  min-width: 50%;
  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }

  ${Media.tablet`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
  `}

  ${Media.mobile`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 700;
    padding: 20px;
  `}
`;

const Popup = styled.div`
  width: 100%;
  height: 50%;
`;


const data = [
  { id: "goods01", name: "hoodie", image: "/Img/goods2/hoodie.jpg" },
  { id: "goods02", name: "t-shirt", image: "/Img/goods2/tshirt60.jpg" },
  { id: "goods03", name: "beach-slipper", image: "/Img/goods2/slippers02.jpg" },
  { id: "goods04", name: "socks", image: "/Img/goods/fashion08.jpg" },
  { id: "goods05", name: "coppel", image: "/Img/goods/camping01.jpg" },
  { id: "goods06", name: "folding-box", image: "/Img/goods2/foldingbox02.jpg" },
  { id: "goods07", name: "relax-chair", image: "/Img/goods/camping04-bk.jpg" },
  { id: "goods08", name: "tarp", image: "/Img/goods/camping05.jpg" },
  { id: "goods09", name: "beam-proojector", image: "/Img/goods2/beam03.jpg" },
];

const Event = ({ match }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [open, setOpen] = useState(true);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    // console.log(match.url);
    if (match.url === "/newtropepsipartner")
      localStorage.setItem("partner", true);
  }, []);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#fff",
      page: "event",
    });
  }, []);

  const { is_login, is_reset, userInfo } = state;

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    centerMode: true,
    centerPadding: "60px",
    dotsClass: "his-slider-dots",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    // beforeChange: function (currentSlide, nextSlide) {
    //   console.log("before change", currentSlide, nextSlide);
    // },
    // afterChange: function (currentSlide) {
    //   console.log("after change", currentSlide);
    // },
  };

  // const [openLogIn, setOpenLogIn] = useState(false);
  // const [openSignUp, setOpenSignUp] = useState(false);

  const openLogInPopUp = () =>
    popupSetState({
      ...popupState,
      login: true,
      event_login: true,
    });

  const snsAd = useRef();
  const goSNSAd = () => {
    // console.log(snsAd.current);
    // console.log(snsAd.current.scrollHeight);
    window.scroll({
      top: snsAd.current.offsetTop - 80,
      left: 0,
      behavior: "smooth",
    });
  };

  const btn = useRef();

  const onIntersect = ([entry], observer) => {
    if (entry.isIntersecting) {
      observer.unobserve(entry.target);
      btn.current.style.position = "static";
      observer.observe(entry.target);
    } else {
      btn.current.style.position = "fixed";
    }
  };

  const [target, setTarget] = useState(null);

  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, { threshold: 0.5 });
      observer.observe(target);
    }
    return () => {
      return observer && observer.disconnect();
    };
  }, [target]);
  // console.log(target);

  const { t } = useTranslation();

  const metaData = {
    title: "K하우스오브펩시 :: 이벤트",
    description:
      "진영 X 요한과 함께한 펩시 한정판 선물 이벤트 응모 페이지입니다.",
    canonical: "",
    keyword:
      "K하우스오브펩시,하우스오브펩시,한국펩시콜라,펩시콜라,pepsi,khouseofpepsi,pepsi-korea,pepsicola,pesi cola, 진영 요한과 함께한 펩시 한정판 선물 이벤트, 펩시헤리티지, 펩시 브랜드 소개, 펩시 브랜드",
  };

  const alertEvent = () => {
    alert('진영X요한과 함께하는 펩시 한정판\n선물 이벤트는 2020년 12월 31일을 마지막으로 종료되었습니다.\n\n2021년 2월부터 더욱 다채로운 혜택으로 새롭게 시작되는 펩시 이벤트를 기대해주세요!\n\n소망하시는 일이 모두 이루어지는 2021년,\n펩시가 항상 곁에서 응원하겠습니다.\n새해 복 많이 받으세요 :)');
  }

  return (
    <>
      <Meta data={metaData} />
      <EventBlock>
        <SubHeader title="Event" subtitle="이벤트" color="#fff" />
        <Wrapper>
          <h2>{t("event:campaign-title")}</h2>
          <ul>
            <li>
              <h3>{t("event:event-title-1")}</h3>
              <p>{t("event:event-title-1-2")}</p>
            </li>
            <li>
              <h3>{t("event:event-title-2")}</h3>
              <DetailView onClick={goSNSAd}>{t("event:see-more")}</DetailView>
              <p>{t("event:event-title-2-2")}</p>
              <DetailViewMobile onClick={goSNSAd}>
                {t("event:see-more")}
              </DetailViewMobile>
            </li>
          </ul>

          <EventButton2 bgColor="#000" onClick={alertEvent} ref={btn}>
              {t("event:event-btn")}
          </EventButton2>

          {/*{is_login ? (
            <Link to="/quiz">
              <EventButton bgColor="#000">{t("event:event-btn")}</EventButton>
            </Link>
          ) : (
            <EventButton bgColor="#000" onClick={openLogInPopUp}>
              {t("event:event-btn")}
            </EventButton>
          )}*/}
        </Wrapper>
        <SliderWrapper className="eventSlider">
          <Slider {...settings}>
            {data.map((goods) => (
              <Goods key={goods.id} image={goods.image} />
            ))}
          </Slider>
        </SliderWrapper>
        <Wrapper>
          {/* <EventDetail>
            {t("event-description")
              .split("\n")
              .map((line) => (
                <div>{line}</div>
              ))}
          </EventDetail> */}

          <HowTo>
            <h2 className="eng">{t("event:howto-title")}</h2>
            <ul>
              <li>
                <img src="/Img/event-step1.png" alt="" />
                <span>
                  {t("event:howto-1")
                    .split("\n")
                    .map((line) => (
                      <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
                    ))}
                </span>
              </li>
              <li>
                <img src="/Img/event-step2.png" alt="" />
                <span>
                  {t("event:howto-2")
                    .split("\n")
                    .map((line) => (
                      <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
                    ))}
                </span>
              </li>
              <li>
                <img src="/Img/event-step3.png" alt="" />
                <span>
                  {t("event:howto-3")
                    .split("\n")
                    .map((line) => (
                      <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
                    ))}
                </span>
              </li>
            </ul>
          </HowTo>
          <HowToDetail>
            <div className="howto-detail">
              <h6>{t("event:event-notice-2-1")}</h6>
              <h6>{t("event:event-notice-2-2")}</h6>
              <h6>
                {t("event:event-notice-2-3")
                  .split("\n")
                  .map((line) => (
                    <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
                  ))}
              </h6>
            </div>
          </HowToDetail>
          <EventDetail>
            <div>
              <h4>{t("event:event-period")}</h4>
              <table>
                <tbody>
                  <tr>
                    <th>{t("event:event-1st-period")}</th>
                    <td>{t("event:event-1st-period-date")}</td>
                  </tr>
                  <tr>
                    <th>{t("event:event-2st-period")}</th>
                    <td>{t("event:event-2st-period-date")}</td>
                  </tr>
                  <tr>
                    <th>{t("event:event-3st-period")}</th>
                    <td>{t("event:event-3st-period-date")}</td>
                  </tr>
                  <tr>
                    <th>{t("event:event-4st-period")}</th>
                    <td>{t("event:event-4st-period-date")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <h4>{t("event:announcement")}</h4>
              <p>{t("event:announcement-send")}</p>
              <div>
                {t("event:event-limit")
                  .split("\n")
                  .map((line) => (
                    <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
                  ))}
              </div>

              <ul>
                <li>{t("event:event-notice-1")}</li>
                <li>{t("event:event-notice-2")}</li>
                <li>{t("event:event-notice-3")}</li>
              </ul>
            </div>
          </EventDetail>
        </Wrapper>
      </EventBlock>
      <GiveAway>
        <Wrapper>
          <h2 className="eng">GIVEAWAY</h2>
          <h6>
            {t("event:give-away-notice")
              .split("\n")
              .map((line) => (
                <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
              ))}
          </h6>
          <p>
            {t("event:give-away-notice2")
              .split("\n")
              .map((line) => (
                <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
              ))}
          </p>
          <ul>
            <li>
              <img src="/Img/goods/giveaway01.jpg" alt="" />
              <span>Stickers (OPP)</span>
            </li>
            <li>
              <img src="/Img/goods/giveaway02.jpg" alt="" />
              <span>Stickers (Epoxy)</span>
            </li>
            <li>
              <img src="/Img/goods/giveaway03.jpg" alt="" />
              <span>Mobile Phone Stickers</span>
            </li>
            <li>
              <img src="/Img/goods/giveaway04.jpg" alt="" />
              <span>Post Cards</span>
            </li>
          </ul>
        </Wrapper>
      </GiveAway>
      <SnsAd ref={snsAd}>
        <Wrapper>
          <h2 className="eng">{t("event:bonus-notice-title")}</h2>
          <p>{t("event:bonus-notice-title-top")}</p>

          <div className="hash-description">
            <span>
              {t("event:bonus-notice-title-list")
                .split("\n")
                .map((line) => (
                  <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
                ))}
            </span>
          </div>

          <HeritageImg>
            <img src="./Img/heritage.jpg" alt="" />
          </HeritageImg>
          <ul>
            <li>
              <h6>{t("event:bonus-notice-1")}</h6>
              <span>
                {t("event:bonus-notice-1-description")
                  .split("\n")
                  .map((line) => (
                    <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
                  ))}
              </span>
            </li>
            <li>
              <h6>{t("event:bonus-notice-2")}</h6>
              <span>
                {t("event:bonus-notice-2-description")
                  .split("\n")
                  .map((line) => (
                    <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
                  ))}
              </span>
            </li>
            <li>
              <h6>{t("event:bonus-notice-3")}</h6>
              <span>
                {t("event:bonus-notice-3-description")
                  .split("\n")
                  .map((line) => (
                    <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
                  ))}
              </span>
            </li>
          </ul>
          <p className="notice">
            {t("event:bonus-notice-4")
              .split("\n")
              .map((line) => (
                <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
              ))}
          </p>
        </Wrapper>
      </SnsAd>
      <GoodsInfoBlock>
        <Wrapper>
          <h2 className="eng">{t("event:goods-title")}</h2>
          <p>{t("event:goods-notice")}</p>

          <GoodsTitleImg>
            <img src="/Img/goods-title01.jpg" alt="" />
            <h3 className="eng">Camping</h3>
          </GoodsTitleImg>
          <ul className="goods-5">
            <li>
              <img src="/Img/goods/camping01.jpg" alt="" />
              <span>Portable Dishes</span>
            </li>
            <li>
              <img src="/Img/goods/camping02.jpg" alt="" />
              <span>Folding Box (Dark Grey)</span>
            </li>
            <li>
              <img src="/Img/goods/camping03.jpg" alt="" />
              <span>Blanket</span>
            </li>
            <li>
              <img src="/Img/goods/camping04-bk.jpg" alt="" />
              <span>Relax Chair</span>
            </li>
            <li>
              <img src="/Img/goods/camping05.jpg" alt="" />
              <span>Tarp</span>
            </li>
          </ul>

          <GoodsTitleImg>
            <img src="/Img/goods-title02.jpg" alt="" />
            <h3 className="eng">Fashion</h3>
          </GoodsTitleImg>
          <ul className="goods-5">
            <li>
              <img src="/Img/goods/fashion01.jpg" alt="" />
              <span>T-shirt 40's</span>
            </li>
            <li>
              <img src="/Img/goods/fashion02.jpg" alt="" />
              <span>T-shirt 50's</span>
            </li>
            <li>
              <img src="/Img/goods/fashion03.jpg" alt="" />
              <span>T-shirt 60's</span>
            </li>
            <li>
              <img src="/Img/goods/fashion04.jpg" alt="" />
              <span>T-shirt 80's</span>
            </li>
            <li>
              <img src="/Img/goods/fashion05.jpg" alt="" />
              <span>T-shirt 90's</span>
            </li>
            <li>
              <img src="/Img/goods/fashion06.jpg" alt="" />
              <span>Hoodie (White)</span>
            </li>
            <li>
              <img src="/Img/goods/fashion07.jpg" alt="" />
              <span>Beach Slipper (White)</span>
            </li>
            <li>
              <img src="/Img/goods/fashion08.jpg" alt="" />
              <span>Socks (White)</span>
            </li>
            <li>
              <img src="/Img/goods/fashion09.jpg" alt="" />
              <span>Masks set (10 masks)</span>
            </li>
            <li>
              <img src="/Img/goods/fashion10.jpg" alt="" />
              <span>Mask set (Cases)</span>
            </li>
          </ul>

          <GoodsTitleImg>
            <img src="/Img/goods-title03.jpg" alt="" />
            <h3 className="eng">Solo Play</h3>
          </GoodsTitleImg>
          <ul className="goods-3">
            <li>
              <img src="/Img/goods/soloplay09.jpg" alt="" />
              <span>Puzzle</span>
            </li>
            <li>
              <img src="/Img/goods/soloplay10.jpg" alt="" />
              <span>Smart Beam Projector</span>
            </li>
            <li>
              <img src="/Img/goods/soloplay11.jpg" alt="" />
              <span>Garland</span>
            </li>
          </ul>

          <div className="goods-info-small">
            <p>{t("event:not-for-sale")}</p>
            <p>{t("event:it-may-different")}</p>
          </div>

          <EventButton2 bgColor="#000" onClick={alertEvent} ref={btn}>
              {t("event:event-btn")}
          </EventButton2>

          {/*{is_login ? (
            <Link to="/quiz">
              <EventButton2 bgColor="#000" ref={btn}>
                {t("event:event-btn")}
              </EventButton2>
            </Link>
          ) : (
            <EventButton2 bgColor="#000" onClick={openLogInPopUp} ref={btn}>
              {t("event:event-btn")}
            </EventButton2>
          )}*/}
        </Wrapper>
      </GoodsInfoBlock>
      <ButtonWrapper ref={setTarget} />
      <Popup>
          <Modal
              open={open}
              onClose={onCloseModal}
              classNames = {{
                modal: 'eventModal',
                closeButton: 'eventCloseBtn'
              }}
          >
            <h2>2020년 이벤트 종료 안내</h2>
            <p>
              진영X요한과 함께하는 펩시 한정판 선물 이벤트는<br/>
              2020년 12월 31일을 마지막으로 종료되었습니다.<br/>
              <br/>2021년, 더욱 다채로운 혜택으로 진행되는<br/>펩시 이벤트를 기대해주세요!
            </p>
          </Modal>
      </Popup>
      <Footer />
      {/*
      {openLogIn && (
        <EventLogin
          setOpenLogIn={setOpenLogIn}
          openLogIn={openLogIn}
          setOpenSignUp={setOpenSignUp}
        />
      )}
      {openSignUp && <EventSignUp setOpenSignUp={setOpenSignUp} />} */}
    </>
  );
};

export default Event;
