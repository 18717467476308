/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SubHeader from "../Layout/SubHeader";
import Meta from "../../Utils/Meta";
import Footer2 from "./Footer2";
import Footer from "./Footer";
import HistorySlider2 from "../Layout/HistorySlider2";
import HistoryMobile from "../Layout/HistoryMobile"


const HistoryBlock = styled.div`
  padding: 126px 0 0 0;
  background: url("/Img/bg_pattern2.jpg") center center fixed;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${Media.mobile`
    padding: 75px 0 0;
    box-sizing: border-box;
    height: 100vh;
  `}

  .historyDesktop {
    ${Media.mobile`
      display: none;
    `}
  }
  .historyMobile {
    display: none;
    ${Media.mobile`
      display: block;
    `}
  }
`;

const HistoryHeader = styled.div`
  width: 80%;
  ${Media.mobile`
    width: 86%;
  `}
  margin: 0 auto;
  
  h2 {
    color: #fff;
    width: 90%;
    font-size: 36px;
  }
`

// const Wrapper = styled.div`
//   width: 90%;
//   max-width: 1280px;
//   margin: 0 auto;
// `;

const metaData = {
  title: "K하우스오브펩시 :: 히스토리",
  description: "진영 X 요한과 함께한 펩시 한정판 선물 이벤트 응모 페이지입니다.",
  canonical: "",
  keyword: "K하우스오브펩시,하우스오브펩시,한국펩시콜라,펩시콜라,pepsi,khouseofpepsi,pepsi-korea,pepsicola,pesi cola, 진영 요한과 함께한 펩시 한정판 선물 이벤트, 펩시헤리티지, 펩시 브랜드 소개, 펩시 브랜드"
};

const History = ({ match }) => {
  // console.log(match);
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#FFF",
      page: "history",
      pepsi: true,
      gatorade: false,
    });
    return () => {
      popupSetState({
        ...popupState,
        menu_button: "#000",
        page: "",
      });
    };
  }, []);

  return (
    <HistoryBlock>
      <Meta data={metaData} />
      <HistoryHeader>
        <h2 className="eng">History</h2>
      </HistoryHeader>
      <HistorySlider2 className="historyDesktop" />
      <HistoryMobile className="historyMobile" />
      <Footer2 />
    </HistoryBlock>
  );
};

export default History;
