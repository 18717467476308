export const instagram = [
  {
    img: "/Asset/sns/regular-01.jpg",
    externalLink:
        "https://www.instagram.com/p/CeQi5dDLj0J/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/regular-02.jpg",
    externalLink:
        "https://www.instagram.com/p/Ce0knK3FsAC/",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/sns/zero-01.jpg",
    externalLink:
        "https://www.instagram.com/p/CeihxrJNdoI/",
    isVideo: true,
    brandId: 2,
  },

  {
    img: "/Asset/sns/zero-02.jpg",
    externalLink:
        "https://www.instagram.com/p/Ceik21hrCFo/",
    isVideo: true,
    brandId: 2,
  },
  {
    img: "/Asset/sns/zero-03.jpg",
    externalLink:
        "https://www.instagram.com/p/CeimbgPjDQ5/",
    isVideo: true,
    brandId: 2,
  },
  {
    img: "/Asset/sns/zero-04.jpg",
    externalLink:
        "https://www.instagram.com/p/CelJRYHJxBl/",
    isVideo: true,
    brandId: 2,
  },
  {
    img: "/Asset/sns/zero-05.jpg",
    externalLink:
        "https://www.instagram.com/p/CfgXjIfriGW/",
    isVideo: true,
    brandId: 2,
  },

  {
    img: "/Asset/sns/bluenblack-01.jpg",
    externalLink:
        "https://www.instagram.com/p/Ce3Rw__rTZf/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-02.jpg",
    externalLink:
        "https://www.instagram.com/p/CfBeA_HrSZ_/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-03.jpg",
    externalLink:
        "https://www.instagram.com/p/CfECzcOLylT/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-04.jpg",
    externalLink:
        "https://www.instagram.com/p/CfLxLJMLDPb/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-05.jpg",
    externalLink:
        "https://www.instagram.com/p/CfJMYQbrt92/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-06.jpg",
    externalLink:
        "https://www.instagram.com/p/CfOV-KqrsR4/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-07.jpg",
    externalLink:
        "https://www.instagram.com/p/CfQ6w7brBbB/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-08.jpg",
    externalLink:
        "https://www.instagram.com/p/CfTflPCriyf/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-09.jpg",
    externalLink:
        "https://www.instagram.com/p/CfV9gigrTbv/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-10.jpg",
    externalLink:
        "https://www.instagram.com/p/CfdywoQrcf5/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-11.jpg",
    externalLink:
        "https://www.instagram.com/p/CflhIHTrS1m/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-12.jpg",
    externalLink:
        "https://www.instagram.com/p/CfloMqpr4eI/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-13.jpg",
    externalLink:
        "https://www.instagram.com/p/Cfv0Tj_r8W7/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-14.jpg",
    externalLink:
        "https://www.instagram.com/p/CgjUC4yDAJs/",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-15.jpg",
    externalLink:
        "https://www.instagram.com/p/CgeKhy1twFN/",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/sns/bluenblack-16.jpg",
    externalLink:
        "https://www.instagram.com/p/CftBx4lrefD/",
    isVideo: true,
    brandId: 1,
  },

  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/259036694_473475141108858_8237582688200286026_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWkMxjtFclM/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/259571692_274357507833228_5106469028374787788_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWklXK6PRox/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/258881161_1762302010827154_8347166012343795067_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWlDhpIhwUU/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/258885225_297853072069839_3899908018018792339_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWfXwSUFcQm/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/258851996_228682299352292_193901602015236896_n.jpeg",
    externalLink:
      "https://www.instagram.com/p/CWXz_Y3hUVb/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/257435036_655689592481517_3785022237688051839_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWXkn8PPm-l/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/258130091_417893126497802_2625311340358010569_n.jpeg",
    externalLink: "https://www.instagram.com/p/CWXs7e3BsRW/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/243167845_876848379624706_7272524521576043905_n.jpg",
    externalLink:
      "https://www.instagram.com/reel/CUSfL9mBsR9/?utm_medium=copy_link",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/1.png?w=500",
    externalLink:
      "https://www.instagram.com/tv/CTYdNMSjLdm/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/234387538_115186027521284_3660929450086144858_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CSWe-6Yhl1J/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/235705531_243246887654294_812147641783327125_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CSgbJSdFlOD/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/213623993_403010784431940_3838458085816282213_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CROe4GOF8-d/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/217873096_325558092551587_6540185748834059984_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CRVU1TWhuTU/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/jiIMDrMguBU-maxresdefault.jpg",
    externalLink:
      "https://www.instagram.com/tv/CSHBupEH1tZ/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 2,
  },
  {
    img: "/Img/sns-thumb/sns-yohan.png",
    externalLink:
      "https://www.instagram.com/p/CFPD4-HgtFA/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-jinyoung.png",
    externalLink:
      "https://www.instagram.com/p/CFPDUVIgK6n/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-jinyoung-yohan.png",
    externalLink:
      "https://www.instagram.com/p/CFPEdcUA6pK/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-yohan-clip.png",
    externalLink:
      "https://www.instagram.com/p/CFhFITfgY2R/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-jinyoung-clip.png",
    externalLink:
      "https://www.instagram.com/p/CFhEabng0Im/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-daniel-clip.png",
    externalLink:
      "https://www.instagram.com/p/CFJ5LylA1hP/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-ibelieve.png",
    externalLink:
      "https://www.instagram.com/p/CFb1KEjgrlK/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-jinyoung-yohan-record.png",
    externalLink:
      "https://www.instagram.com/p/CFtTxRkgCLE/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-ibelieve-mv.png",
    externalLink:
      "https://www.instagram.com/tv/CFjcfiYALKI/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-aframe-cafe.png",
    externalLink:
      "https://www.instagram.com/p/CV7vUovhH3i/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
  {
    img: "/Img/sns-thumb/sns-cyper.png",
    externalLink:
      "https://www.instagram.com/p/CSWe42YBZQX/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 3,
  },
];

export const others = [
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/E5yP4SNVcAEDIRa.jpeg",
    externalLink:
      "https://twitter.com/OfficialMonstaX/status/1413162590675423235?s=20 ",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/E51Jk1IVkAIUKRa.jpeg",
    externalLink:
      "https://twitter.com/OfficialMonstaX/status/1413366771260608512?s=20 ",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/E51V57-VIAEM5oX.jpeg",
    externalLink:
      "https://twitter.com/OfficialMonstaX/status/1413380329277071361?s=20 ",
    isVideo: false,
    brandId: 2,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/huDRr07sm_k-maxresdefault.jpg",
    externalLink: "https://www.vlive.tv/post/0-24690362",
    isVideo: true,
    brandId: 2,
  },
];

export const youtube = [
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/213623993_403010784431940_3838458085816282213_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CROe4GOF8-d/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/217873096_325558092551587_6540185748834059984_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CRVU1TWhuTU/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/huDRr07sm_k-maxresdefault.jpg",
    externalLink: "https://www.vlive.tv/post/0-24690362",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/257435036_655689592481517_3785022237688051839_n.jpg",
    externalLink:
      "https://www.instagram.com/p/CWXkn8PPm-l/?utm_source=ig_web_copy_link",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/258130091_417893126497802_2625311340358010569_n.jpeg",
    externalLink: "https://www.instagram.com/p/CWXs7e3BsRW/",
    isVideo: false,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/243167845_876848379624706_7272524521576043905_n.jpg",
    externalLink:
      "https://www.instagram.com/reel/CUSfL9mBsR9/?utm_medium=copy_link",
    isVideo: true,
    brandId: 1,
  },
  {
    img: "/Asset/01FYHKGNXB9KE7DFG04B31NRK7/1.png?w=500",
    externalLink:
      "https://www.instagram.com/tv/CTYdNMSjLdm/?utm_source=ig_web_copy_link",
    isVideo: true,
    brandId: 1,
  },
];
