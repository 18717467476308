/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  statusReset,
} from "../../Context/UserContext";
import styled from "styled-components";
import SubHeader from "../Layout/SubHeader";
import Media from "../../Styles/MediaQuery";
import PersonalButtons from "../Layout/PersonalButtons";
import BusinessButtons from "../Layout/BusinessButtons";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { resources } from "../../config/lang/i18n";

const SelectSignUpBlock = styled.div`
  /* padding: 70px 30px; */
`;
const SelectSignUpBtn = styled.ul`
  margin-bottom: 2rem;
  display: flex;
  li {
    width: 50%;
    padding: 30px 15px;
    margin-right: 0.5rem;
    text-align: center;
    svg {
      display: block;
      width: 30%;
      margin: 0 auto;
    }
    h4 {
      margin-top: 10px;
    }
  }
`;
const PersonalBtn = styled.li`
  border: ${(props) => (props.active ? "4px solid #004a98" : "3px solid #ddd")};
  color: ${(props) => (props.active ? "#004a98" : "#999")};
  cursor: pointer;

  svg {
    fill: none;
    stroke: ${(props) => (props.active ? "#004a98" : "#999")};
    stroke-width: 42;
    stroke-miterlimit: 10;
  }
`;

const CompanyBtn = styled.li`
  border: ${(props) => (props.active ? "4px solid #004a98" : "3px solid #ddd")};
  color: ${(props) => (props.active ? "#004a98" : "#999")};
  cursor: pointer;
  .st0 {
    fill: none;
  }
  .st1 {
    fill: ${(props) => (props.active ? "#004a98" : "#999")};
  }
`;

const SelectSignUp = ({ checkSocial, popUpPage, setPopUpPage }) => {
  const [isPersonal, setIsPersonal] = useState(true);

  const selectPersonal = () => {
    setIsPersonal(true);
  };
  const selectCompany = () => {
    setIsPersonal(false);
  };

  const state = useUsersState();
  const dispatch = useUsersDispatch();

  useEffect(() => {
    return () => {
      // console.log("closelogin");
      statusReset(dispatch);
    };
  }, []);

  const { t, changeLanguage } = useTranslation();

  return (
    <SelectSignUpBlock>
      <SubHeader
        title="JOIN"
        subtitle="회원가입"
        color="#000"
        textAlign="center"
        marginBottom="30px"
      />
      <SelectSignUpBtn>
        <PersonalBtn onClick={selectPersonal} active={isPersonal}>
          <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
            <circle className="st0" cx="257.4" cy="191.2" r="105.7" />
            <path
              className="st0"
              d="M57.3,496.9c0-110.5,89.6-200,200-200s200,89.6,200,200"
            />
          </svg>
          <h4>{t("personal-member")}</h4>
        </PersonalBtn>
        <CompanyBtn onClick={selectCompany} active={!isPersonal}>
          <svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">
            <rect x="150" y="392" className="st0" width="92" height="90" />
            <path
              className="st0"
              d="M90,482h30V362h152v120h30V30H90V482z M181,209.5c-33.9-6.8-61-36.7-61-73.5c0-41.6,34.7-76,76-76
	c41.6,0,76,34.7,76,76c0,36.9-27.1,66.7-61,73.5 M120,302h152v30H120V302z M120,362h152v30H120V362z"
            />
            <path
              className="st0"
              d="M196,181c24.9,0,46-20.6,46-45c0-24.9-21.1-46-46-46s-46,21.1-46,46C150,160.4,171.1,181,196,181z"
            />
            <path
              className="st0"
              d="M332,482h90V211h-90V482z M362,302h30v30h-30V302z M362,362h30v30h-30V362z M362,422h30v30h-30V422z"
            />
            <path
              className="st1"
              d="M452,181H332V0H60v166v15v30v271v30h392V181z M242,482h-92v-90h92V482z M302,482h-30V362H120v120H90V30h212V482
	z M422,482h-90V211h90V482z"
            />
            <path
              className="st1"
              d="M211,209.5c33.9-6.8,61-36.7,61-73.5c0-41.3-34.4-76-76-76c-41.3,0-76,34.4-76,76c0,36.9,27.1,66.7,61,73.5
	 M196,90c24.9,0,46,21.1,46,46c0,24.4-21.1,45-46,45s-46-20.6-46-45C150,111.1,171.1,90,196,90z"
            />
            <rect x="120" y="242.2" className="st1" width="152" height="30" />
            <rect x="120" y="302.2" className="st1" width="152" height="30" />
            <rect x="362" y="302" className="st1" width="30" height="30" />
            <rect x="362" y="362" className="st1" width="30" height="30" />
            <rect x="362" y="422" className="st1" width="30" height="30" />
          </svg>
          <h4>{t("business-member")}</h4>
        </CompanyBtn>
      </SelectSignUpBtn>
      {isPersonal ? (
        <>
          <PersonalButtons
            popUpPage={popUpPage}
            setPopUpPage={setPopUpPage}
            checkSocial={checkSocial}
          />
        </>
      ) : (
        <>
          <BusinessButtons
            popUpPage={popUpPage}
            setPopUpPage={setPopUpPage}
            checkSocial={checkSocial}
          />
        </>
      )}
    </SelectSignUpBlock>
  );
};

export default SelectSignUp;
