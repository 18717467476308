const ZERO_SUGAR_2022 = "01FYTM8FH0E90DEA492YXA7K8J";
const TASTE_OF_KOREA_2021 = "01FYTMANK2ZPFSSNJJSJCDWJJF";
const ZERO_SUGAR_2021 = "01FYTNMDAHFS0RCQ0N5JKEE1ES";
const PEPSI_HERITAGE = "01FYTP7W1154GDX1E4GYWWN2KG";
const PEPSI_CULTURE = "01FYTPAZEG4M19MGT050JTZ331";

const getUrl = (parentId) => `/Asset/${parentId}/`;

export const lookbookData = [
  {
    id: 1,
    title: "2023 pepsi x ive",
    path: "2023-pepsi-ive",
    imgs: [
      { src: `/Img/lookbook-ive-2023/lookbook-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-ive-2023/ive-01.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-02.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-03.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-04.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-05.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-06.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-07.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-08.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-09.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-10.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-11.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-12.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-13.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-14.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-15.jpg`, span: 6 },
      { src: `/Img/lookbook-ive-2023/ive-16.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-17.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-18.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-19.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-20.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-21.jpg`, span: 3 },
      { src: `/Img/lookbook-ive-2023/ive-22.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-23.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-24.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-25.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-26.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-27.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-28.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-29.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-30.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-31.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-32.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-33.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-34.jpg`, span: 6 },
      { src: `/Img/lookbook-ive-2023/ive-35.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-36.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-37.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-38.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-39.jpg`, span: 2 },
      { src: `/Img/lookbook-ive-2023/ive-40.jpg`, span: 2 },
    ],
  },
  {
    id: 2,
    title: "2023 pepsi x iu",
    path: "2023-pepsi-iu",
    imgs: [
      { src: `/Img/lookbook-pepsi-iu-2023/lookbook-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-iu-2023/pepsi-iu-01.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu-2023/pepsi-iu-02.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu-2023/pepsi-iu-03.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu-2023/pepsi-iu-04.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu-2023/pepsi-iu-05.jpg`, span: 2 },
    ],
  },
  {
    id: 3,
    title: "2022 BLUE&BLACK",
    path: "2022-blue-black",
    imgs: [
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-01.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-02.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-03.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-04.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-05.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-06.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-07.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-08.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-09.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-10.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-11.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-12.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-13.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-14.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-15.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-16.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-17.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-18.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-19.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-20.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-21.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-22.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-23.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-24.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-25.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-26.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-27.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-28.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-29.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-30.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-31.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-32.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-33.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-34.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-35.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-36.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-37.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-38.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-39.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-40.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-41.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-42.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-43.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-44.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-45.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-46.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-47.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-48.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-49.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-50.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-51.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-52.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-53.jpg`, span: 1 },
      { src: `/Img/lookbook-pepsi-blue-black/blue-black-54.jpg`, span: 1 },
    ],
  },
  {
    id: 4,
    title: "2022 pepsi x iu",
    path: "2022-pepsi-iu",
    imgs: [
      { src: `/Img/lookbook-pepsi-iu/lookbook-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-pepsi-iu/iu-01.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-02.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-03.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-04.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-05.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-06.jpg`, span: 2 },
      { src: `/Img/lookbook-pepsi-iu/iu-07.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu/iu-08.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu/iu-09.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu/iu-10.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu/iu-11.jpg`, span: 3 },
      { src: `/Img/lookbook-pepsi-iu/iu-12.jpg`, span: 3 },
    ],
  },
  {
    id: 5,
    title: "2022 pepsi zero sugar",
    path: "2022-pepsi-zero-sugar",
    imgs: [
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-01.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-02.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-03.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-04.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-05.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-06.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-07.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-08.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2022)}iu-09.jpg`, span: 2 },
    ],
  },
  {
    id: 6,
    title: "2021 taste of korea",
    path: "2021-taste-of-korea",
    imgs: [
      { src: `${getUrl(TASTE_OF_KOREA_2021)}lookbook-visimg.jpg`, span: 6 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-01.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-02.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-03.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-04.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-05.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-06.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-07.jpg`, span: 3 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-08.jpg`, span: 3 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-09.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-10.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-11.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-12.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-13.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-14.jpg`, span: 2 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-15.jpg`, span: 3 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-16.jpg`, span: 3 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-17.jpg`, span: 3 },
      { src: `${getUrl(TASTE_OF_KOREA_2021)}taste-18.jpg`, span: 3 },
    ],
  },
  {
    id: 7,
    title: "2021 pepsi zero sugar",
    path: "2021-pepsi-zero-sugar",
    imgs: [
      { src: `${getUrl(ZERO_SUGAR_2021)}lookbook-visimg.jpg`, span: 6 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-01.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-02.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-03.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-04.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-05.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-06.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-07.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-08.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-09.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-10.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-11.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-12.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-13.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-14.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-15.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-16.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-17.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-18.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-19.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-20.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-21.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-22.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-23.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-24.jpg`, span: 2 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-25.jpg`, span: 3 },
      { src: `${getUrl(ZERO_SUGAR_2021)}zero-26.jpg`, span: 3 },
    ],
  },
  {
    id: 8,
    title: "2020 pepsi heritage",
    path: "2020-pepsi-heritage",
    imgs: [
      { src: `${getUrl(PEPSI_HERITAGE)}lookbook-visimg.jpg`, span: 6 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-01.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-02.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-03.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-04.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-05.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-06.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-07.jpg`, span: 3 },
      { src: `${getUrl(PEPSI_HERITAGE)}heri-08.jpg`, span: 3 },
    ],
  },
  {
    id: 9,
    title: "2020 pepsi culture",
    path: "2020-pepsi-culture",
    imgs: [
      { src: `${getUrl(PEPSI_CULTURE)}lookbook-visimg.jpg`, span: 6 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-01.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-02.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-03.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-04.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-05.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-06.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-07.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-08.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-09.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-10.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-11.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-12.jpg`, span: 2 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-13.jpg`, span: 3 },
      { src: `${getUrl(PEPSI_CULTURE)}kpop-14.jpg`, span: 3 },
    ],
  },
];
