import moment from 'moment';
import React from 'react'
import styled from 'styled-components'
import Media from "../../Styles/MediaQuery";


const Wrapper = styled.div`
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
`

const Modal = styled.div`
    position: absolute;
    padding: 0;
    margin: 0;
    animation: none !important;
    top: 50%;
    left: 50%;
    text-align: center;
    background: white;
    transform: translate(-50%, -50%);
`

const BottomActionBar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px 5px 10px;
    span{
        font-size: 14px;
        color: #333;
        cursor: pointer;
        font-family: 'MontrealTS', 'noto sans kr', '맑은 고딕', 'Malgun Gothic', sans-serif !important;
    }
    .do-not-show-for-one-day{

    }
    .close{

    }
`

export const PopupWrapper = ({children, open, setOpen}) => {
    const onBackgroundClick = (e) => {
        e.stopPropagation();
        setOpen(false)
    }
    const doNotShowForOneDay = () => {
        localStorage.setItem('taste-of-korea-popup-hide-expire', moment().add(1, 'day'))
        setOpen(false)
    }
    if(!open){
        return null;
    }
    return (
        <Wrapper onClick={onBackgroundClick}>
            <Modal>
            {children}
            <BottomActionBar>
                <span className="do-not-show-for-one-day" onClick={ doNotShowForOneDay }>하루동안 보지 않기</span>
                <span className="close" onClick={ onBackgroundClick }>닫기</span>
            </BottomActionBar>
            </Modal>
        </Wrapper>
    )
}

