/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { findEmail } from "../../Service/UserApi";
import {
  ValidationInput,
  Alert,
  onFocus,
  onBlur,
} from "../../Styles/InputStyle";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";

const FindIDForm = styled.form`
  width: 100%;
  padding: 150px 0;
  color: rgb(45, 49, 56);

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }

  strong {
    display: block;
    margin-bottom: 30px;
  }
`;

const ErrorMsg = styled.div`
  color: rgb(1, 74, 153);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const FindID = ({ userEmail, setUserEmail, popUpPage, setPopUpPage }) => {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const response = await findEmail(data);
      setUserEmail(response.data[0]);
      // console.log("data", data.data[0]);
      onFindIDComplete();
    } catch (e) {
      let errorMsg = "";
      // console.log(e.response.request.responseText);
      errorMsg = JSON.parse(e.response.request.responseText);
      // console.log(errorMsg);

      let first_key = Object.keys(errorMsg)[0];
      let first_value = errorMsg[Object.keys(errorMsg)[0]];

      // console.log(first_key, first_value);

      if (first_key === "phonenumber")
        setErrorMsg(t("valid:cannot-find-number"));
      else if (first_value === "User Does Not Exist")
        setErrorMsg(t("valid:cannot-find-account"));
      else setErrorMsg(first_value);
    }

    reset();
  };

  const cancleHypen = (e) => {
    let phoneValue = getValues("phonenumber");
    var replaceNotInt = /[^0-9]/gi;

    if (phoneValue !== replaceNotInt)
      setValue("phonenumber", phoneValue.replace(replaceNotInt, ""));
  };

  const watchName = watch("name");
  const watchPhone = watch("phonenumber");

  const onFindIDComplete = () => {
    setPopUpPage(3);
  };

  const { t } = useTranslation();

  return (
    <>
      <FindIDForm onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("find-account")}</h3>
        <strong>{t("input-name-number")}</strong>
        <ValidationInput
          name="name"
          label="name"
          placeholder={t("name")}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          validation={{ required: true, minLength: 2 }}
        />
        {errors.name && <Alert>{t("valid:not-valid-username")}</Alert>}
        <ValidationInput
          name="phonenumber"
          label="phonenumber"
          placeholder={t("number")}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          onKeyUp={cancleHypen}
          validation={{
            required: true,
            minLength: 11,
            maxLength: 11,
          }}
        />
        {errors.phonenumber && (
          <Alert>{t("valid:not-valid-phone-number")}</Alert>
        )}
        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
        <br />
        <MainButton2 bgColor="#0065c3" disabled={!(watchName && watchPhone)}>
          {t("find-account")}
        </MainButton2>
      </FindIDForm>
    </>
  );
};

export default FindID;
