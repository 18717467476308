export const MVDataGatorade2 = [
  {
    id: 1,
    title: "2022 gatorade move like this",
    path: "2022-gatorade-move-like-this",
    videos: [
      {
        title: "[TEASER] 강다니엘(KANGDANIEL), 아이브 안유진(IVE ANYUJIN) - Move Like This (Feat. 김연아 (YUNA KIM))\n",
        url: "https://www.youtube.com/embed/eXHUrpV44gA",
      },
      {
        title: "[INTERVIEW] 김연아 X 강다니엘 X 안유진 (IVE)- 2022 GATORADE X STARSHIP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/VA3j_Vp8wHI",
      },
      {
        title: "[MV] 강다니엘(KANGDANIEL), 아이브 안유진(IVE ANYUJIN) - ‘Move Like This (Feat. 김연아 (YUNA KIM))’\n",
        url: "https://www.youtube.com/embed/EkiMQaWc5ZI",
      },
      {
        title: "[MAKING FILM] 김연아 X 강다니엘 X 안유진 (IVE)- Move Like This 녹음실\n",
        url: "https://www.youtube.com/embed/iXXdtD4TzlI",
      },
      {
        title: "[MAKING FILM] 김연아 X 강다니엘 X 안유진 (IVE)- ‘Move Like This’ MV\n",
        url: "https://www.youtube.com/embed/t9YRxvBxVVA",
      }
    ],
  },
];
