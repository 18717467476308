import axios from "axios";

const api = axios.create({
  baseURL: 'https://web.pep1.kr',
});

// const api = axios.create();

export const signUp = async (payload) => {
  const response = await api.post("/api/v1/auth/signup/", payload);

  const { refresh } = response.data;

  localStorage.setItem("refresh", refresh);
  loginSuccess(response);
  // console.log(response);
  return response;
};

export const socialSignUp = async (payload) => {
  const response = await api.post("/api/v1/auth/social/", payload);

  const { refresh } = response.data;

  localStorage.setItem("refresh", refresh);
  localStorage.setItem("social", true);
  loginSuccess(response);
  // console.log(response);
  return response;
};

export const login = async (payload) => {
  const response = await api.post("/api/v1/auth/login/", payload);

  const { refresh } = response.data;

  localStorage.setItem("refresh", refresh);
  loginSuccess(response);

  // console.log(axios.defaults.headers.common["Authorization"]);
  return response;
};

export const checkSocial = async (payload) => {
  const response = await api.post("/api/v1/auth/social/check/", payload);
  console.log(response.data);
  if (response.status === "204") console.log("가입되지 않은 유저입니다");
  const { refresh } = response.data;

  localStorage.setItem("refresh", refresh);
  loginSuccess(response);

  return response;
};

export const silentRefresh = async () => {
  // localStor+++++age.removeItem("acc");
  const payload = { refresh: localStorage.getItem("refresh") };
  const response = await api.post("/api/v1/auth/token/refresh/", payload);
  loginSuccess(response);

  return response;
};

export const loginSuccess = async (response) => {
  const { access } = response.data;

  // localStorage.setItem("access", access);
  axios.defaults.headers.common["Authorization"] = `${access}`;
  // console.log(access);
  // localStorage.setItem("acc", true);

  // await api
  //   .get("/user/self/", {
  //     headers: {
  //       Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
  //     },
  //   })
  //   .then((res) => {
  //     console.log(res);
  //     const { data } = res;
  //     const refreshUserState = {
  //       is_login: true,
  //       userInfo: data,
  //       status: {
  //         loading: false,
  //         success: true,
  //         error: null,
  //       },
  //     };
  //   })
  //   .catch((err) => console.error(err))
  //   .finally(() => console.log("user info complete"));
};

export const getUserInfo = async () => {
  // const access = localStorage.getItem("access");
  // console.log(access);
  const response = await api.get("/api/v1/user/self/", {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
    },
  });

  return response.data;
};

export const userUpdate = async (payload) => {
  const response = await api.patch("/api/v1/user/self/", payload, {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
    },
  });

  return response.data;
};

export const logout = async () => {
  const response = await api.post("/api/v1/auth/logout/");
  axios.defaults.headers.common["Authorization"] = null;
  // console.log(response);
  localStorage.removeItem("refresh");
  localStorage.removeItem("social");
  return response;
};

export const findEmail = async (payload) => {
  const response = await api.post("/api/v1/auth/email/", payload);

  return response;
};

export const findPassword = async (payload) => {
  const response = await api.post("/api/v1/auth/password/reset/", payload);

  return response;
};

export const passwordChange = async (payload) => {
  const response = await api.post("/api/v1/auth/password/change/", payload);

  return response;
};

export const unRegister = async (payload) => {
  // console.log(axios.defaults.headers.common["Authorization"]);
  const response = await api.post("/api/v1/auth/unregister/", payload, {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
    },
  });
  localStorage.removeItem("refresh");
  return response;
};

export const applyNonMembers = async (payload) => {
  const response = await api.post("/api/v1/events/codes/", payload, {
    headers: {
      Authorization: "",
    },
  });

  return response.data;
};

export const applyMembers = async (payload) => {
  const response = await api.post("/api/v1/events/codes/", payload, {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
      // Authorization: `Bearer ${access}`,
    },
  });

  return response.data;
};

export const getUserEvent = async () => {
  // console.log(access);
  const response = await api.get("/api/v1/user/self/events/", {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
    },
  });

  return response.data;
};

export const getUserEventDetail = async (event_uuid) => {
  // console.log(access);
  const response = await api.get(`/api/v1/user/self/events/${event_uuid}/`, {
    headers: {
      Authorization: `Bearer ${axios.defaults.headers.common["Authorization"]}`,
    },
  });

  return response.data;
};
