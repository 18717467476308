export const TVCDataGatorade = [
    {
        id: 1,
        title: "2022 gatorade move like this",
        path: "2022-gatorade-move-like-this",
        videos: [
            {
                title: "[COMING SOON] 김연아 (YUNA KIM) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/aDjZvmmp4E0",
            },
            {
                title: "[COMING SOON] 강다니엘 (KANGDANIEL) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/4hgKHa7vS2Q",
            },
            {
                title: "[COMING SOON] 아이브 안유진 (IVE AN YUJIN) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/FBRlssUt1tU",
            },
            /*{
                title: "IVE 아이브 'LOVE DIVE' MV\n",
                url: "https://www.youtube.com/embed/Y8JFxS1HlDo",
            },*/
            {
                title: "[MAKING FILM] 김연아 (YUNA KIM) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/bhGWFhFBABE",
            },
            {
                title: "[MAKING FILM] 안유진(IVE) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/QPSx8Xq9ePw",
            },
            {
                title: "[MAKING FILM] 강다니엘 (KANGDANIEL) - 2022 GATORADE X STARSHIP CAMPAIGN\n",
                url: "https://www.youtube.com/embed/XDqASPQN1Wc",
            },
            {
                title: "김연아 (YUNA KIM) ‘2022 GATORADE X STARSHIP CAMPAIGN’ (RUNNING VER.)\n",
                url: "https://www.youtube.com/embed/Y1yPXzcfu94",
            },
            {
                title: "김연아 (YUNA KIM) ‘2022 GATORADE X STARSHIP CAMPAIGN’ (GYM VER.)\n",
                url: "https://www.youtube.com/embed/bJqTGOSUcIw",
            },
            {
                title: "김연아 X 강다니엘 X 안유진 (IVE) - 2022 GATORADE X STARSHIP CAMPAIGN (15s VER.)\n",
                url: "https://www.youtube.com/embed/uy9gX7a5K0U",
            },
            {
                title: "김연아 X 강다니엘 X 안유진 (IVE) - 2022 GATORADE X STARSHIP CAMPAIGN (30s VER.)\n",
                url: "https://www.youtube.com/embed/V9reykHQLvg",
            }
        ],
    },
];