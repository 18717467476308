/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";

const showHeader = keyframes`
  from {
	bottom: -100%;
  }
  to {
    bottom: 0;
  }
`;

const Sentinel = styled.div`
  // DOM element for triggering the intersection observer event
  // you can use 'height' to determine when the event is triggered
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
`;

const HeaderBlock = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 890;
  background-color: rgba(255, 255, 255, 0);
  display: ${(props) => (props.show ? "none" : "block")};
  ${(props) =>
    props.bgHidden &&
    css`
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    `};
`;

const ScrollHeaderBlock = styled.div`
  display: block;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 890;
  background-color: #fff;
  animation-delay: 0s;
  animation-duration: 0.5s;
  box-shadow: ${(props) =>
    props.show ? "0 10px 30px rgba(0, 0, 0, 0.05)" : null};

  ${Media.mobile`
    display: ${(props) => (props.show ? "block" : "none")};
    color: black;
    top: unset;
    bottom: 0;
    background-color: ${(props) =>
    props.show ? "#fff" : "rgba(255, 255, 255, 0)"};
    ${(props) =>
      props.bgHidden &&
      css`
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;
      `}
    animation-name: ${(props) => (props.show ? showHeader : null)};
  `}
`;

const HeaderInner = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-content: center;
  z-index: 950;
  ${Media.mobile`
    padding: 15px 0;
  `}
`;

const Logo = styled.h1`
  margin: 0;
  display: flex;
  align-content: center;
  a {
    width: 30px;
    height: 30px;
    background-image: url("/Img/gato-logo-bk.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    margin-right: 5rem;
    ${Media.mobile`
      width: 25px;
      height: 25px;
    `}
  }
`;

const MobileLogoHidden = styled.div`
  margin: 0;
  display: flex;
  align-content: center;
  a {
    width: 30px;
    height: 30px;
    background-image: url("/Img/gato-logo-bk.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
  }
`;

const Menus = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    margin: 8px 0;
    justify-content: space-between;
    width: full;
    ${Media.mobile`
      font-size: 0.875rem;
      color: black;
    `}
  }
  li {
    font-weight: bold;
    margin: 0 28px;
    a {
      color: #111;
      ${Media.mobile`
        color: black;
      `}
    }
    ${Media.mobile`
      font-weight: regular;
      margin: 0 8px;
    `}
  }
`;
const Icons = styled.div`
  display: flex;
  cursor: pointer;
  div {
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    margin: 0 8px;
    ${Media.mobile`
      margin: 0 2px;
    `}
  }
  .menu {
    background-image: url("/Img/menu-d-bk.svg");
    ${Media.mobile`
      background-image: url("/Img/menu-d-bk.svg");
    `}
  }
  .barcode {
    background-image: url("/Img/barcode.svg");
    display: none;
    ${Media.mobile`
      display: block;
      /*For Maintenance temporary hiding*/
      display: none;
    `}
  }
  .login {
    background-image: url("/Img/login-bk.svg");
    ${Media.mobile`
      display: none;
    `}
  }
`

const HeaderContainerGatorade2 = ({openLogInPopUp}) => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [show, setShow] = useState(false);

  const openMainMenuSlider = () => {
    popupSetState({
      ...popupState,
      mainmenu: !popupState.mainmenu,
    });
  };



  const closePopUp = () => {
    popupSetState({
      ...popupState,
      login: false,
      signup: false,
      mainmenu: false,
      event_login: false,
      brand: false,
      gatorade: false,
    });
  };
  

  const onIntersect = async ([entry]) => {
    if (entry.isIntersecting) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const [target, setTarget] = useState(null);

  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, { threshold: 0.5 });
      observer.observe(target);
    }
    return () => {
      return observer && observer.disconnect();
    };
  }, [target]);

  return (
    <>
      <Sentinel ref={setTarget} />
          <HeaderBlock show={show}>
            <HeaderInner>
              <MobileLogoHidden onClick={closePopUp}>
                <Link to="/gatorade">Gatorade</Link>
              </MobileLogoHidden>
            </HeaderInner>
          </HeaderBlock>
          <ScrollHeaderBlock show={show} bgHidden={popupState.mainmenu}>
            <HeaderInner>
              <Menus>
                <ul className="eng">
                  <li onClick={closePopUp}>
                    <Link to="/">Back To Pepsi</Link>
                  </li>
                </ul>
              </Menus>
              <Logo>
                <Link to="/gatorade">Gatorade</Link>
              </Logo>
              <Icons>
                <Link to="/mypage">
                  <div className="login" />
                </Link>
                <Link to="/samsung_galaxy">
                  <div className="barcode" />
                </Link>
                <div onClick={openMainMenuSlider} className="menu" />
              </Icons>
            </HeaderInner>
          </ScrollHeaderBlock>
    </>
  );
};

export default HeaderContainerGatorade2;
