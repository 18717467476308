/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  unRegister,
} from "../../Context/UserContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

import DataLoadingStyle from "../../Styles/DataLoadingStyle";

const UnregisterBlock = styled.div`
  padding: 90px 0;
`;

const Wrapper = styled.div`
  padding: 80px 30px;
  max-width: 520px;
  margin: 0 auto;

  h2 {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 30px;
  }
  div{
    display: flex;
    justify-content: space-between;
  }
  a{
    width: 49%;
    button{
      width: 100%;
      background-color: #0065c3;
    }
  }
  button {
    width: 49%;
    padding: 20px;
    margin-bottom: 5px;
    border-radius: 0;
    background: #333;
    color: #fff;
    font-size: 1rem;
    border: 0;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  `}
`;

const ErrorMsg = styled.div`
  color: rgb(1, 74, 153);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const Unregister = () => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const fetchData = () => {
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
  };

  useEffect(() => {
    fetchData();
  }, [state.acc]);

  const cancleMembership = async () => {
    unRegister(dispatch);
    // try {
    //   const response = await unRegister();
    //   // console.log(response.status);
    //   if (response.status === 200)
    //     setCancelMemberState({
    //       ...cancelMemberState,
    //       loading: false,
    //       success: true,
    //       error: null,
    //     });
    // } catch (e) {
    //   let errorMsg = "";
    //   errorMsg = e.response.request.responseText;
    //   // console.log(errorMsg);
    //   setErrorMsg(errorMsg);
    // }
  };
  if (loading) return <DataLoadingStyle fetchData={fetchData} />;

  let errorMsg = "";
  if (error) {
    errorMsg = JSON.parse(error.response.request.responseText).detail;
    // console.log(errorMsg);
  }

  if (success && !is_login)
    return (
      <UnregisterBlock>
        <Wrapper>
          <h2>정상적으로 탈퇴처리가 되었습니다.</h2>
          <Link to="/">
            <button>메인으로</button>
          </Link>
        </Wrapper>
      </UnregisterBlock>
    );

  return (
    <UnregisterBlock>
      <Wrapper>
        <h2>정말로 회원을 탈퇴하시겠습니까?</h2>
        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}

        <div>
          <Link to="userinfo">
            <button>아니오</button>
          </Link>

          <button onClick={cancleMembership}>예</button>
        </div>
      </Wrapper>
    </UnregisterBlock>
  );
};

export default Unregister;
