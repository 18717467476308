import React from "react";
import { TiktokFeed } from "../../TiktokFeed";
import {
  PhoneImg,
  PhoneImgMobile,
  Heading,
  Wrapper,
  Description,
  Slogan,
  MobileLogo,
  TiktokFeedList,
  HorizontalBetween,
  MobileShowAgreementButton,
} from '../styles';
import { ChannelHeader } from './channel-header';
import { RightArrowIcon } from './right-arrow-icon';

const tiktokUrls = [
  "https://www.tiktok.com/@starship_ent/video/6940946974753492226",
  "https://www.tiktok.com/@starship_ent/video/6940931218347166977",
  "https://www.tiktok.com/@starship_ent/video/6940575625518386433",
  "https://www.tiktok.com/@starship_ent/video/6940560281412078850",
  "https://www.tiktok.com/@starship_ent/video/6940204135270681858",
  "https://www.tiktok.com/@ateez_official_/video/6942709872228175105",
  "https://www.tiktok.com/@ab6ix.official/video/6942774321240640770",
  "https://www.tiktok.com/@starship_ent/video/6942416087476653314",
  "https://www.tiktok.com/@creker_theboyz/video/6943104946858396930",
  "https://www.tiktok.com/@ab6ix.official/video/6945000883843288322",
  "https://www.tiktok.com/@starship_ent/video/6943529212909276418"
]

export const ZeroSugar = ({ setAgreementPopup }) => (
  <>
    <PhoneImg>
      <img src="/Img/zero/zero-phone.png" alt="phone" />
    </PhoneImg>
    <Wrapper>
      <MobileLogo>
        <img src="/Img/zero/mobile-logo@3x.png" alt="logo" />
      </MobileLogo>
      <Heading>
        <div>
          2021 <span>NEW</span> PEPSI ZERO SUGAR
          <br />
          ZERO: ATTITUDE CHALLENGE
        </div>
        <img src="/Img/zero/desktop-logo@3x.png" alt="logo" />
      </Heading>
      <Description>
        K-POP 아티스트와 함께한 ZERO:ATTITUDE CHALLENGE 영상을 만나보세요
      </Description>
      <MobileShowAgreementButton onClick={() => setAgreementPopup(true)}>
        약관보기 <RightArrowIcon />
      </MobileShowAgreementButton>
      <PhoneImgMobile>
        <img src="/Img/zero/zero-phone.png" alt="phone" />
      </PhoneImgMobile>
      <HorizontalBetween>
        <Slogan>ZERO: ATTITUDE CHALLENGE VIDEO</Slogan>
      </HorizontalBetween>
      <ChannelHeader logoUrl="/Img/zero/TikTok.png" title="TIKTOK" />
      <TiktokFeedList>
        {
          tiktokUrls.map((url, index) => <TiktokFeed url={url} key={`${url}-${index}`} />)
        }
      </TiktokFeedList>
      <ChannelHeader
        logoUrl="/Img/zero/Instagram.png"
        title="INSTAGRAM"
        marginTop="4.3vw"
      />
      <div className="elfsight-app-c33cc2f8-660b-44a4-9d22-26f27d7ae0b5"></div>
    </Wrapper>
  </>
)