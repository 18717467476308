/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { socialKey } from "../../Service/key";
import { useTranslation } from 'react-i18next';

const Naver = ({ children }) => {
  // console.log(naver_id_login);
  const [stateUrl, setStateUrl] = useState();
  const naverEl = useRef();

  const checkNaver = () => {
    // console.log("111");
    const { naver_id_login } = window;

    const loginNaver = new naver_id_login(socialKey.naver, 'https://web.pep1.kr/oAuth');

    // console.log(loginNaver);

    const state = loginNaver.getUniqState();

    // console.log(state);
    setStateUrl(state);
    loginNaver.setButton('white', 1, 40);
    loginNaver.setDomain('#{DOMAIN}#');
    loginNaver.setState(state);
    loginNaver.setPopup();
    loginNaver.init_naver_id_login();
  };

  useEffect(() => {
    // console.log(naverEl.current.id);
    if (naverEl.current.id === 'naver_id_login') checkNaver();
  }, [naverEl.current]);

  // console.log(naver_id_login);

  const { t } = useTranslation();

  return (
    <div id="naver_id_login" className="naver" ref={naverEl}>
      <a
        href={`https://nid.naver.com/oauth2.0/authorize?response_type=token&amp;client_id=${socialKey.naver}&amp;redirect_uri=https%3A%2F%2Fkhouseofpepsi.com%2FoAuth&amp;state=${stateUrl}`}
        id="naver_id_login_anchor"
        className="naver"
      >
        {t('continue-naver')}
      </a>
    </div>
  );
};

export default Naver;
