/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  applyMembers,
  getUsers,
} from "../../Context/UserContext";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useForm } from "react-hook-form";
import { Input } from "../../Styles/InputStyle";
import { EventButton } from "../../Styles/ButtonStyle";
import { goodsData } from "../../Constants/GoodsData";
import GoodsItem from "../Items/GoodsItem";
import ApplyComplete from "./ApplyComplete";
import { useTranslation, initReactI18next } from "react-i18next";

const ApplyUserInfoBlock = styled.div`
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    line-height: 1.2;
    margin-bottom: 40px;
  }

  textarea {
    width: 100%;
    height: 150px;
    border: solid 1px #aaa;
    box-sizing: border-box;
    padding: 20px;
    font-size: 0.875rem;
    color: #777;
    line-height: 1.5rem;
  }
  #all-agreement {
    display: none;
  }
  label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url("./Img/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }
  label {
    font-size: 0.875rem;
    a {
      text-decoration: underline;
      display: inline;
    }
  }
  #all-agreement:checked + label::before {
    background-color: #0065c3;
  }
`;

const ApplyUserForm = styled.div`
  width: 49%;

  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }

  ${Media.tablet`
    
  `}

  ${Media.mobile`
    width: 100%;
  `}
`;
const GoodsList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const UserInfoWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${Media.mobile`
    display: block;
  `}
`;

const AgreementWrap = styled.div`
  width: 49%;
  margin: 5px 0;
  ${Media.mobile`
    width: 100%;
  `}
`;

const AgreementBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;

  ${Media.mobile`
    width: 100%;
    padding: 0 0 20px;
  `}

  ${Media.mobile`
  `}
`;
const UserInfoBlock = styled.div`
  width: 49%;

  ${Media.tablet`
    width: 100%;
    
  `}

  ${Media.mobile`
  `}
`;
const AddressWrapper = styled.div`
  margin-bottom: 50px;
`;

const Terms = styled.textarea``;

const AgreeCheck = styled.div`
  margin: 20px 0;

  input {
    display: none;
  }
  label {
    font-size: 0.875rem;
  }
  label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url(./Img/check.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }

  input:checked + label::before {
    background-color: #0065c3;
  }
  ${Media.mobile`
    label {
      font-size: 0.8rem;
    }
  `}
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const ApplyEventUserInfo = ({
  applyEventInfo,
  setApplyEventInfo,
  setState,
  openState,
  match,
}) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, applyEventUser } = state;

  const goScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    goScrollTop();
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const watchAgree = watch("agreeevent");
  const watchAgreePolicy = watch("agreeeventPolicy");

  useEffect(() => {
    if (is_login && !userInfo) getUsers(dispatch);
  }, []);

  useEffect(() => {
    if (!is_login) return <div>로그인 상태가 아닙니다.</div>;
  }, [is_login]);

  const [applyCompletePage, setApplyCompletePage] = useState(false);
  const [agreeCheck, setAgreeCheck] = useState(false);

  const openApplyCompletePage = () => setApplyCompletePage(true);

  // event 갱신 event_uuid 수정
  const onSubmit = () => {
    let fullData = {
      event_uuid: "f8618201-082d-41f1-9ded-073f79301c19",
      first: applyEventInfo.first,
      second: applyEventInfo.second,
      third: applyEventInfo.third,
      exclusive: localStorage.getItem("partner")
        ? localStorage.getItem("partner")
        : false,
    };

    applyMembers(dispatch, fullData);
    openApplyCompletePage();
  };

  const { t, changeLanguage } = useTranslation();

  if (loading) return <DataLoadingStyle />;
  if (!userInfo) return <DataLoadingStyle />;

  return (
    <>
      {!applyCompletePage ? (
        <ApplyUserInfoBlock>
          <h3>{t("event:enter-information")}</h3>
          <h4>
            {t("event:result-will-send")
              .split("\n")
              .map((line) => (
                <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
              ))}
          </h4>
          <UserInfoWrap>
            <AgreementWrap>
              <AgreementBlock>
                <Terms defaultValue={t("policy:privacy-policy")} />
                <AgreeCheck>
                  <input
                    type="checkbox"
                    id="agree-event-privacy"
                    name="agreeevent"
                    ref={register({
                      required: true,
                    })}
                  />
                  <label htmlFor="agree-event-privacy">
                    {t("policy:agree-event-privacy")}
                  </label>
                </AgreeCheck>
                {errors.agreeevent && (
                  <Alert>{t("valid:not-valid-check")}</Alert>
                )}
                {/* {!agreeCheck && <Alert>{t("valid:not-valid-check")}</Alert>} */}
              </AgreementBlock>
              <AgreementBlock>
                <Terms defaultValue={t("policy:event-policy")} />
                <AgreeCheck>
                  <input
                    type="checkbox"
                    id="agree-event-policy"
                    name="agreeeventPolicy"
                    ref={register({
                      required: true,
                    })}
                  />
                  <label htmlFor="agree-event-policy">
                    {t("policy:agree-event-policy")}
                  </label>
                </AgreeCheck>
                {errors.agreeeventPolicy && (
                  <Alert>{t("valid:not-valid-check")}</Alert>
                )}
                {/* {!agreeCheck && <Alert>{t("valid:not-valid-check")}</Alert>} */}
              </AgreementBlock>
            </AgreementWrap>
            <ApplyUserForm>
              <Input
                name="email"
                label="email"
                placeholder={userInfo.email}
                value={userInfo.email}
                readOnly={true}
              />
              <Input
                name="username"
                label="username"
                placeholder={userInfo.name}
                value={userInfo.name}
                readOnly={true}
              />
              <Input
                name="phonenumber"
                label="phonenumber"
                placeholder={userInfo.phonenumber}
                value={userInfo.phonenumber}
                readOnly={true}
              />
              <AddressWrapper>
                <Input
                  className="input-postcode"
                  name="postcode"
                  label="postcode"
                  placeholder={userInfo.address.postcode}
                  value={userInfo.address.postcode}
                  readOnly={true}
                />
                <Input
                  name="address1"
                  label="address1"
                  placeholder={userInfo.address.land_lot_number_address}
                  value={userInfo.address.land_lot_number_address}
                  readOnly={true}
                />
                <Input
                  name="address2"
                  label="address2"
                  placeholder={userInfo.address.street_name_address}
                  value={userInfo.address.street_name_address}
                  readOnly={true}
                />
                <Input
                  name="address3"
                  label="address3"
                  placeholder={userInfo.address.details}
                  value={userInfo.address.details}
                  readOnly={true}
                />
              </AddressWrapper>
              <EventButton
                bgColor="#0065c3"
                disabled={!(watchAgree && watchAgreePolicy)}
                onClick={onSubmit}
              >
                {t("event:apply-event")}
              </EventButton>
            </ApplyUserForm>
          </UserInfoWrap>
        </ApplyUserInfoBlock>
      ) : (
        <ApplyComplete />
      )}
    </>
  );
};

export default ApplyEventUserInfo;
