import React from "react";
import { Link } from "react-router-dom";
import Swiper from "react-id-swiper";
import { MainBrandSlideContainer } from "../../Utils/styledComponents";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const SwiperSlide = styled.li`
  width: 20%;
  background-image: url(${(props) => props.mainImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translate3d(120px, 0px, 0px);

  ${Media.mobile`
    transform: translate3d(-80px, 0px, 0px);
  `}

  a {
    display: block;
  }

  img {
    display: block;
    height: 400px;

    ${Media.tablet`
     height: 350px;
    `}

    ${Media.mobile`
      height: 300px;
    `}
  }
`;

const Wrapper = styled.div`
  background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/brand-bg.png);
  ${Media.mobile`
    background-image: none;
  `}
`;

const MainBrandSlider = ({ params, data }) => {
  return (
    <Wrapper>
      <MainBrandSlideContainer>
        <Swiper {...params}>
          {data.map((item) => (
            <SwiperSlide
              tag={"li"}
              key={"item" + item.id}
              id={"item" + item.id}
            >
              <Link key={"key" + item.id} to={item.link}>
                <img src={item.canImg} alt="" />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </MainBrandSlideContainer>
    </Wrapper>
  );
};

export default MainBrandSlider;
