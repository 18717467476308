import React from "react";
import styled from "styled-components";
import { MainButton } from "../../Styles/ButtonStyle";

const FindPWCompleteBlock = styled.div`
  width: 100%;
  padding: 150px 30px 0 30px;

  color: rgb(45, 49, 56);

  h3 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
`;

const FindPWComplete = ({ popUpPage, setPopUpPage }) => {
  const onLogin = () => {
    setPopUpPage(1);
  };
  return (
    <FindPWCompleteBlock>
      <h3>비밀번호 발송 완료</h3>
      <strong>가입한 이메일을 확인해주세요.</strong>
      <br />
      <MainButton onClick={onLogin} bgColor="#0065c3">
        바로 로그인 하기
      </MainButton>
    </FindPWCompleteBlock>
  );
};

export default FindPWComplete;
