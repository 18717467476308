/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
// import { ModalButton } from "../../Styles/ButtonStyle";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.63);
`;

const PopUpBlock = styled.div`
  /* padding: 80px 30px; */
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 991;

  overflow-y: auto;

  width: 500px;
  height: 80vh;
  min-height: 50vh;

  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;

  ${Media.tablet`

  `}

  ${Media.mobile`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100vh;
  max-height: 100vh;
  transform: none;
  `}

  img {
    width: 100%;
  }

  h3 {
    padding: 20px;
  }
  em {
    padding: 0 20px 20px;
    display: block;
    line-height: 1.2;
  }

  strong {
    display: block;
    padding: 0 20px;
    margin-bottom: 10px;
  }
`;

const PopupWrapper = styled.div`
  /* overflow: scroll; */
  ${Media.tablet`

  `}
  ${Media.mobile`

  `}
`;

const PopupInner = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 30px;

  ${Media.tablet`

  `}

  ${Media.mobile`
  height: 120vh;
  `}
`;

const SelectMarginBox = styled.div`
  width: 100px;
  height: 120px;
  /* background: #f4f4f4; */
  ${Media.tablet`

`}

  ${Media.mobile`
  display: none;
`}
`;

const ModalButton = styled.button`
  /* width: auto;
  min-width: 50%; */
  /* position: fixed;
  left: 0;
  right: 0;
  bottom: 0; */
  position: static;
  width: 100%;

  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }
  ${Media.tablet`
  position: static;
  /* left: 0;
    right: 0;
    bottom: 0; */
    width: 100%;
    z-index: 700;
  `}

  ${Media.mobile`
  position: fixed;
  left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 700;
  `}
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 999;

  cursor: pointer;
`;

const SelectWrapper = styled.div`
  padding: 0 20px;

  input {
    /* background-color: red !important; */
    cursor: pointer;
  }
  input:focus {
    color: transparent;
  }
`;

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    width: "100%",
    padding: "0px",
    borderRadius: "0px",
    cursor: "pointer",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#0052CC"
        : isFocused
        ? "#9EB5E2"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : "#0052CC",
      cursor: isDisabled ? "pointer" : "pointer",
      padding: "13px",
      width: "100%",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#0052CC" : "#0052CC"),
      },
    };
  },
  input: (styles) => ({
    ...styles,
    height: "30px",
    cursor: "pointer",
    "&:focus": {
      color: "transparent",
    },
  }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};

const GoodsPopUp = ({
  width,
  height,
  uuid,
  id,
  name,
  color,
  description,
  option,
  image,
  setState,
  openState,
  applyEventInfo,
  setApplyEventInfo,
  rankState,
  setRankState,
  goodsDataState,
  setGoodsDataState,
  active,
  select,
  setClassSelect,
}) => {
  const [goodsOption, SetGoodsOption] = useState(null);
  const [goodsRank, SetGoodsRank] = useState(null);
  const [goodsIndex, SetGoodsIndex] = useState(null);
  const [errorMsg, SetErrorMsg] = useState(null);
  // option.map((opt) => console.log(...Object.keys(opt)));

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const selectOption = (newValue) => {
    // console.log(e.target.value);
    // SetGoodsOption(e.target.value);
    // console.log(newValue.value);
    SetGoodsOption(newValue.value);
  };

  const selectRank = (newValue) => {
    // console.log(e.target);
    // console.log(newValue.value);

    if (!option) SetGoodsOption(uuid);

    // if (e.target.value === "first") SetGoodsIndex(1);
    // else if (e.target.value === "second") SetGoodsIndex(2);
    // else if (e.target.value === "third") SetGoodsIndex(3);

    // SetGoodsRank(e.target.value);

    if (newValue.value === "first") SetGoodsIndex(1);
    else if (newValue.value === "second") SetGoodsIndex(2);
    else if (newValue.value === "third") SetGoodsIndex(3);

    SetGoodsRank(newValue.value);
  };

  const selectGoods = () => {
    // console.log(goodsOption);
    // console.log(goodsRank);

    if (!goodsOption && !goodsRank) SetErrorMsg(t("event:select-option-goods"));
    else if (!goodsOption) SetErrorMsg(t("event:select-option"));
    else if (!goodsRank) SetErrorMsg(t("event:select-goods"));
    else {
      setClassSelect("selected");
      setApplyEventInfo({ ...applyEventInfo, [goodsRank]: goodsOption });
      setGoodsDataState(
        goodsDataState.map((goods) =>
          goods.id === id
            ? { ...goods, active: true, select: goodsIndex }
            : goods
        )
      );
      setRankState(rankState.filter((rank) => rank.value !== goodsRank));
      closePopUp();
    }
  };
  // console.log(rankState);
  const closePopUp = () => setState(!openState);

  const { t } = useTranslation();
  // option: [
  //   { value: "10630a43-6dc8-4a12-8f4a-1fccd2ac44bf", label: "XL" },
  //   { value: "c11b7300-4e90-40e4-a30f-4e18f2f370ff", label: "XXL" },
  // ],

  // const Goptions = [
  //   { value: "10630a43-6dc8-4a12-8f4a-1fccd2ac44bf", label: "XL" },
  //   { value: "c11b7300-4e90-40e4-a30f-4e18f2f370ff", label: "XXL" },
  // ];

  return (
    <>
      <PopUpBlock>
        <PopupWrapper>
          <PopupInner>
            <img src={image} alt="" />
            <h3>{name}</h3>
            <em>{description}</em>
            {errorMsg && <strong>{errorMsg}</strong>}
            {option && (
              <>
                <strong>{t("event:goods-option")}</strong>
                {/* <select id="option-select" onChange={selectOption}>
                <option value="">{t("event:select-option")}</option>
                {option.map((opt) => (
                  <option
                    key={opt[Object.keys(opt)[0]]}
                    value={opt[Object.keys(opt)[0]]}
                  >
                    {Object.keys(opt)[0]}
                  </option>
                ))}
              </select> */}
                <SelectWrapper>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={{
                      value: "",
                      label: t("event:select-option"),
                    }}
                    name="color"
                    options={option}
                    styles={colourStyles}
                    onChange={selectOption}
                    blurInputOnSelect={true}
                    isSearchable={false}
                  />
                </SelectWrapper>
              </>
            )}
            <strong>{t("event:goods-priority")}</strong>
            {/* <select id="rank-select" onChange={selectRank}>
            <option value="">{t("event:select-goods-priority")}</option>
            {rankState.map((rank) => (
              <option
                key={Object.keys(rank)[0]}
                value={rank[Object.keys(rank)[0]]}
              >
                {t('event:select-goods-en')}{Object.keys(rank)[0]}{t('event:select-goods-kr')}
              </option>
            ))}
          </select> */}
            <SelectWrapper>
              <Select
                className="basic-single"
                classNamePrefix="select"
                defaultValue={{
                  value: "",
                  label: t("event:select-goods-priority"),
                }}
                name="color"
                options={rankState}
                styles={colourStyles}
                onChange={selectRank}
                blurInputOnSelect={true}
                isSearchable={false}
              />
            </SelectWrapper>
            <SelectMarginBox />
          </PopupInner>
        </PopupWrapper>
        <ModalButton onClick={selectGoods}>{t("event:complete")}</ModalButton>
        <CloseBtn onClick={closePopUp}>
          <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <defs>
              <path
                id="icon-close_svg__a"
                d="M0 1.5L1.5 0 8 6.5 14.5 0 16 1.5 9.5 8l6.5 6.5-1.5 1.5L8 9.5 1.5 16 0 14.5 6.5 8z"
              />
            </defs>
            <use xlinkHref="#icon-close_svg__a" transform="translate(4 4)" />
          </svg>
        </CloseBtn>
      </PopUpBlock>
      <Dimmed onClick={closePopUp} />
    </>
  );
};

export default GoodsPopUp;
