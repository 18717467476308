import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const VideoCardWrapper = styled.div`
  p {
    font-size: 0.875rem;
    font-weight: bold;
  }
  ${Media.mobile`
    p {
      font-size: 0.6875rem;
    }
  `}
`

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  width: 100%;
  height: 0;
  margin-bottom: 22px;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  p {
    position: absolute;
    bottom: 0;
    font-size: 0.875rem;
    font-weight: bold;
  }
  ${Media.mobile`
    margin-bottom: 10px;
  `}
`;

const VideoCard = ({ src, title }) => (
    <VideoCardWrapper>
        <VideoWrapper>
          <iframe
            src={src}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            title="video"
          />
        </VideoWrapper>
        <p>{title}</p>
    </VideoCardWrapper>
);

export default VideoCard;