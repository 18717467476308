import React from "react";
import { ChannelHeaderWrapper } from '../styles'


export const ChannelHeader = ({ logoUrl, title, marginTop }) => {
  return (
    <ChannelHeaderWrapper marginTop={marginTop}>
      <img src={logoUrl} alt="logo" />
      <span>{title}</span>
    </ChannelHeaderWrapper>
  );
};