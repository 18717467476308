import styled from 'styled-components';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import prize1 from './event-code-assets/prize1.svg';
import prize2 from './event-code-assets/prize2.svg';
import prize3 from './event-code-assets/prize3.svg';
import Media from '../../Styles/MediaQuery';
import { EventCodeAgreementPopup } from './EventCodeAgreementPopup';
import { Modal } from 'react-responsive-modal';
import {
  usePopupSetState,
  usePopupState,
  useUsersDispatch,
  useUsersState,
} from '../../Context/UserContext';

const Container = styled.div`
  margin-top: 23vw;
  background: #094f9b;
  height: 100vh;
  padding: 0 5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${Media.iphoneP`
    height: 83vh;
  `}
  ${Media.iphoneS`
    height: 75vh;
    margin-top: 27vw;
    padding: 0 7vw;
  `}
`;

const Popup = styled.div`
  width: 100%;
  height: 50%;
`;

const Title = styled.p`
  font-family: MontrealTS;
  text-align: center;
  font-style: italic;
  font-weight: normal;
  font-size: 8.8vw;
  line-height: 110%;
  /* or 31px */

  text-transform: uppercase;

  color: #ffffff;
`;
const KorTitle = styled.p`
  margin-top: 7.8vw;
  text-align: center;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 5.4vw;
  line-height: 140%;
  /* or 31px */

  display: flex;
  align-items: center;
  text-transform: capitalize;
  justify-content: center;

  color: #ffffff;
  margin-bottom: 21px;
`;
const AgreementButton = styled.button`
  background: #073f7c;
  padding: 9px 14px;
  width: initial;
  border: none;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-transform: capitalize;

  color: #ffffff;
`;
const EventDate = styled.div`
  margin-top: 5vw;
  text-align: center;
  background: #ffffff;
  border-radius: 100px;
  padding: 2vw;
  > b {
    font-weight: 700;
    margin-right: 20px;
  }
  font-family: Noto Sans KR;
  font-style: normal;
  font-size: 4vw;
  line-height: 160%;
  /* identical to box height, or 26px */

  color: #000000;
`;

const OutDesc = styled.p`
  margin-top: 3vw;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 3vw;
  line-height: 160%;
  /* identical to box height, or 21px */
  text-transform: capitalize;

  color: #ffffff;

  opacity: 0.8;
  margin-bottom: 2vw;
`;
const Prize = styled.img`
width 100%;
margin-bottom: 14px;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 46px;
  text-transform: capitalize;

  color: #ffffff;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  position: fixed;
  bottom: 0;

  color: #efefef;
  text-align: center;
  width: 100%;

  ${(props) =>
    !props.canProceed &&
    `
      background: #D6D6D6;  
      color: white;
  `}
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;
export const EventCodeMain = (props) => {
  const code = props.match.params.code;

  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    // console.log(match.url);
    if (props.url === '/newtropepsipartner')
      localStorage.setItem('partner', true);
  }, []);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: '#fff',
      page: 'event',
    });
  }, []);

  const [canProceed, setCanProceed] = useState(false);
  const domain = 'https://web.pep1.kr';
  // const domain = 'https://khouseofpepsi-.com';
  useEffect(() => {
    async function init() {
      localStorage.removeItem('option_id')
      localStorage.removeItem('product_id')
      try {
        const res = await Axios.get(
          `${domain}/api/v1/events/codes/${code}/?format=json`,
        );
        if (Array.isArray(res.data)) {
          setCanProceed(true);
        } else {
          setOpen(true);
          setCanProceed(false);
        }
      } catch (e) {
        // 종료 팝업
        setOpen(true);
        setCanProceed(false);
      }
    }
    init();
  }, []);
  const checkResult = async () => {
    try {
      const res = await Axios.get(
        `${domain}/api/v1/events/codes/${code}/?format=json`,
      );
      if (!Array.isArray(res.data)) {
        return;
      }
      localStorage.setItem('ITEMS_DATA', JSON.stringify(res.data));
      window.location.href = `/qr/exclusive/select/${code}`;
    } catch (e) {
      switch (e.response.status) {
        case 400:
          // 낙첨 코드
          window.location.href = '/qr/nexttime';
          break;
        case 403:
          // 이미 사용한 코드
          window.location.href = '/qr/nexttime';
          break;
        case 404:
          // 존재하지 않는 코드
          window.location.href = '/qr/nexttime';
          break;
        default:
          alert(`서버에러: ${e.response.status}`);
          break;
      }
    }
  };
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <EventCodeAgreementPopup opened={opened} setOpened={setOpened} />
      <Popup>
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{
            modal: 'eventModal',
            closeButton: 'eventCloseBtn',
          }}
        >
          <h2>PEPSI QR CODE Just for You</h2>
          <p>
            이미 사용이 완료된 QR코드입니다.<br/>
            다시 한 번 응모하고 싶으시면<br/>
            새로운 QR코드로 접속해주세요!
          </p>
        </Modal>
      </Popup>

      <Container>
        <Title>
          PEPSI QR CODE
          <br />
          Just for You
        </Title>
        <KorTitle>
          QR코드 스캔하고
          <br />한 번에 당첨 확인!
        </KorTitle>
        <AgreementButton onClick={() => setOpened(true)}>
          이벤트 약관 보기
        </AgreementButton>
        {/*<EventDate>
          <b>이벤트 기간</b> ~2021년 9월 30일까지
        </EventDate>*/}
        <OutDesc>이벤트 제품 소진시 이벤트가 조기종료될 수 있습니다.</OutDesc>

        {/*<CheckResultButton style={{marginBottom: 55, marginTop: 0}} onClick={checkResult}>지금바로당첨확인하기</CheckResultButton>*/}

        {/*<Prize src={prize1}/>
            <PrizeDesc>1등 상품. 갤럭시 워치 (50명)</PrizeDesc>

            <Prize src={prize2}/>
            <PrizeDesc>2등 상품. 갤럭시 버즈 프로 (100명)</PrizeDesc>

            <Prize src={prize3}/>
            <PrizeDesc>3등 상품. 펩시 굿즈 or 기프티콘 랜덤발송 <br/>(10,000명)</PrizeDesc>*/}

        {/*<CheckResultButton onClick={checkResult}>지금바로당첨확인하기</CheckResultButton>*/}
      </Container>
      <CheckResultButton
        type="button"
        canProceed={canProceed}
        onClick={canProceed && checkResult}
        bottom
      >
        지금 바로 당첨 확인하기
      </CheckResultButton>
    </div>
  );
};
