/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Media from "../Styles/MediaQuery";

const Wrapper = styled.div`
  /* padding: 80px 5%; */
`;

const PostcodeSearch = styled.div`
  height: 85vh;
`;

const initialState = {
  province: "",
  street_name_address: "",
  land_lot_number_address: "",
  details: "",
  postcode: "",
};

const DaumPostcode = ({
  setOpenAddress,
  address,
  setAddress,
  setAddress1,
  setAddress2,
  setPostcode,
}) => {
  const postcodeEl = useRef();

  let address1 = "";

  const handleComplete = async (data) => {
    let addr = ""; // 주소 변수
    let extraAddr = ""; // 참고항목 변수

    let extraAddress = "";
    let postcode = "";
    let address = "";
    //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
    if (data.userSelectedType === "R") {
      // 사용자가 도로명 주소를 선택했을 경우

      setAddress1(data.roadAddress);
    } else {
      // 사용자가 지번 주소를 선택했을 경우(J)

      setAddress1(data.jibunAddress);
    }

    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
    if (data.userSelectedType === "R") {
      // 법정동명이 있을 경우 추가한다. (법정리는 제외)
      // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
      if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
        extraAddr += data.bname;
      }
      // 건물명이 있고, 공동주택일 경우 추가한다.
      if (data.buildingName !== "" && data.apartment === "Y") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      if (extraAddr !== "") {
        extraAddr = " (" + extraAddr + ")";
      }
      // 조합된 참고항목을 해당 필드에 넣는다.
      extraAddress = extraAddr;
    } else {
      extraAddress = "";
    }

    // 우편번호와 주소 정보를 해당 필드에 넣는다.
    postcode = data.zonecode;
    address = addr;

    setAddress2(extraAddress);
    setPostcode(postcode);

    const PROVINCE_CHOICES = {
      강원: "GW",
      경기: "GG",
      충북: "CB",
      충남: "CN",
      서울: "SE",
      경북: "GB",
      경남: "GN",
      전북: "JB",
      전남: "JN",
      제주특별자치도: "JJ",
      부산: "BS",
      대구: "DG",
      인천: "IC",
      광주: "GJ",
      대전: "DJ",
      울산: "US",
      세종특별자치시: "SJ",
    };

    const selectProvince = (province) => {
      if (PROVINCE_CHOICES[province]) return PROVINCE_CHOICES[province];
      else return "UN";
    };

    setAddress({
      ...address,
      province: selectProvince(data.sido),
      land_lot_number_address: data.jibunAddress,
      street_name_address: data.roadAddress,
      extra_address: extraAddress,
      postcode: postcode,
    });

    if (postcode) setOpenAddress(false);
  };

  const searchPostcode = () => {
    const { daum } = window;

    // console.log(daum);

    new daum.Postcode({
      oncomplete: function (data) {
        // console.log(data);
        handleComplete(data);
      },
      width: "100%",
      height: "100%",
      maxSuggestItems: 5,
    }).embed(postcodeEl.current);
  };

  useEffect(() => {
    if (postcodeEl.current) {
      searchPostcode();
    }
  }, []);

  return (
    <Wrapper>
      <PostcodeSearch ref={postcodeEl} className="postcodeBlock" />
    </Wrapper>
  );
};

export default DaumPostcode;
