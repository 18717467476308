/* eslint-disable no-unused-vars */
import { Global } from "@emotion/core";
import React, { useEffect, useRef, useState } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BrandSliderContainer } from "../../Utils/styledComponents";
import { globalStyles } from "../../Utils/stylesConfig";
import Footer2 from "./Footer2";
import Media from "../../Styles/MediaQuery";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";

const BrandBlock = styled.div`
  padding-top: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  background-image: url(/Asset/01FYNPB8W3GKRDSNCGCA94S0N2/${(props) =>
    BrandImageData[props.currentIndex]
      ? BrandImageData[props.currentIndex].name
      : "zero"}-bg-a.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // background: rgba(0, 0, 0, 0.7);
  .slider {
    height: 100%;
  }
  h1 {
    position: absolute;
    font-size: 36px;
    left: 10%;
    margin: 46px auto 0;
    color: white;
    ${Media.mobile`
      margin-top: 0;
      left: 10%;
    `}
  }
`;

const SwiperItem = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100%;
    filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.15));
    margin-top: ${(props) => (props.isMain ? "0" : "44px")};
    opacity: ${(props) => (props.isMain ? "1" : "0.7")};
    ${Media.mobile`
      margin-top: 0;
      margin-bottom: ${(props) => (props.isMain ? "0" : "26px")};
    `}
  }
  em {
    display: ${(props) => (props.isMain ? "block" : "none")};
    color: white;
    padding-top: 22px;
    font-size: 22px;
    ${Media.mobile`
      padding-top: 12px;
      font-size: 14px;
    `}
  }
`;

const BrandImageData = [
  { id: 0, name: "zero", description: "zero sugar", link: "brand/1" },
  { id: 1, name: "regular", description: "fun! food! festa", link: "brand/2" },
  { id: 1, name: "retro", description: "retro pepsi", link: "brand/3" },
  { id: 0, name: "zero", description: "zero sugar", link: "brand/1" },
  { id: 1, name: "regular", description: "fun! food! festa", link: "brand/2" },
  { id: 1, name: "retro", description: "retro pepsi", link: "brand/3" },
];

const Brand2 = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const swiperParams = {
    slidesPerView: 2.8,
    spaceBetween: 26,
    centeredSlides: true,
    loop: true,
    breakpoints: {
      1024: {
        slidesPerView: 2.8,
        spaceBetween: 132,
      },
      980: {
        slidesPerView: 2.4,
        spaceBetween: 100,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      320: {
        slidesPerView: 1.2,
        spaceBetween: 18,
        loop: true,
      },
      0: {
        slidesPerView: 1.2,
        spaceBetween: 18,
        loop: true,
      },
    },
  };

  const swiperRef = useRef(null);

  const [currentIndex, updateCurrentIndex] = useState(0);

  useEffect(() => {
    popupSetState({
      ...popupState,
      pepsi: true,
      gatorade: false,
    });

    if (!swiperRef.current) return;
    swiperRef.current.swiper.on("slideChange", () =>
      updateCurrentIndex(swiperRef.current.swiper.realIndex)
    );
  }, []);

  return (
    <BrandBlock currentIndex={currentIndex}>
      <Global styles={globalStyles} />
      <h1 className="eng">brand</h1>
      <BrandSliderContainer className="slider">
        <Swiper {...swiperParams} ref={swiperRef}>
          {BrandImageData.map((item, index) => {
            const img = `/Asset/01FYNPB8W3GKRDSNCGCA94S0N2/${item.name}-a.png`;
            return (
              <SwiperItem
                key={item.name}
                isMain={item.name === BrandImageData[currentIndex].name}
              >
                <Link to={item.link}>
                  <img href="hi" src={img} alt={item.name} />
                </Link>
                <em className="eng">{item.description}</em>
              </SwiperItem>
            );
          })}
        </Swiper>
      </BrandSliderContainer>
      <Footer2 />
    </BrandBlock>
  );
};

export default Brand2;
