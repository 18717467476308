/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { MVData } from "../../Constants/MVData";

const SubMenuBlock = styled.div`
  width: 100%;
  padding: 10px;

  ${Media.tablet` 
`}

  ${Media.mobile`

`}
`;

const PrevPageBtn = styled.div`
  position: fixed;
  left: 0;
  top: 50%;
  z-index: 99;

  width: 90px;
  height: 90px;
  padding: 20px;
  background-color: #000;

  overflow: hidden;
  transition: width 1s ease-out;

  ${Media.mobile`
    width: 45px;
    height: 45px;
    transition: none;
    padding: 0;
  `}

  &:hover {
    width: 280px;
    transition: width 1s ease-out;

    ${Media.mobile`
      width: 45px;
      transition: none;
    `}

    h3 {
      opacity: 1;
      transition: opacity 1s ease-out;
    }

    svg {
    }
  }

  a {
    width: 100%;
    height: 100%;
  }

  h3 {
    position: absolute;
    top: calc(50% - 0.5rem);
    left: 85px;
    font-size: 1rem;
    color: #fff;
    width: 250px;
    opacity: 0;
    transition: opacity 1s ease-out;

    ${Media.mobile`
      display: none;
    `}
  }

  svg {
    position: absolute;
    top: calc(50% - 14px);
    left: 37px;

    ${Media.mobile`
      top: 14px;
      left: 17px;
      width: 10px;
      height: 16px;
    `}
  }
`;

const NextPageBtn = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 99;

  width: 90px;
  height: 90px;
  padding: 20px;
  background-color: #000;

  overflow: hidden;
  transition: width 1s ease-out;

  ${Media.mobile`
    width: 45px;
    height: 45px;
    transition: none;
    padding: 0;
  `}

  &:hover {
    width: 290px;
    transition: width 1s ease-out;

    ${Media.mobile`
      width: 45px;
      transition: none;
    `}

    h3 {
      opacity: 1;
      transition: opacity 1s ease-out;
    }

    svg {
    }
  }

  a {
    width: 100%;
    height: 100%;
    position: relative;
  }

  h3 {
    position: absolute;
    top: calc(50% - 0.5rem);
    right: 56px;
    font-size: 1rem;
    color: #fff;
    width: 250px;
    text-align: right;
    opacity: 0;
    transition: opacity 1s ease-out;

    ${Media.mobile`
      display: none;
    `}
  }

  svg {
    position: absolute;
    top: calc(50% - 14px);
    right: 16px;

    ${Media.mobile`
      top: 14px;
      right: 17px;
      width: 10px;
      height: 16px;
    `}
  }
`;

const MVSubMenu = () => {
  const [prevPage, setPrevPage] = useState(null);
  const [nextPage, setNextPage] = useState(null);

  const [prevTitle, setPrevTitle] = useState(null);
  const [nextTitle, setNextTitle] = useState(null);

  const match = useRouteMatch();
  const { detail } = match.params;

  // console.log(detail);

  const findPrevPage = (prevData) => {
    if (Array.isArray(prevData) && prevData.length === 0) setPrevPage(null);
    else {
      setPrevPage(prevData[0].path);
      setPrevTitle(prevData[0].title);
    }
  };

  const findNextPage = (nextData) => {
    if (Array.isArray(nextData) && nextData.length === 0) setNextPage(null);
    else {
      setNextPage(nextData[0].path);
      setNextTitle(nextData[0].title);
    }
  };

  const findPageLink = () => {
    const currentData = MVData.filter((item) =>
      detail ? item.path === detail : item.path === "pepsi-heritage"
    );

    const prevData = MVData.filter(
      (item) => item.id === currentData[0].id - 1
    );
    const nextData = MVData.filter(
      (item) => item.id === currentData[0].id + 1
    );

    findPrevPage(prevData);
    findNextPage(nextData);
  };

  useEffect(() => findPageLink(), [detail]);

  return (
    <SubMenuBlock>
      {prevPage && (
        <PrevPageBtn>
          <Link to={`/magazine/mv/${prevPage}`}>
            <svg
              width="13"
              height="27"
              viewBox="0 0 17 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.949 33L0 16.5L13.949 0L16.7539 3.31783L5.60976 16.5L16.7539 29.6822L13.949 33Z"
                fill="white"
              />
            </svg>
            <h3>{prevTitle}</h3>
          </Link>
        </PrevPageBtn>
      )}
      {nextPage && (
        <NextPageBtn>
          <Link to={`/magazine/mv/${nextPage}`}>
            <h3>{nextTitle}</h3>
            <svg
              width="13"
              height="27"
              viewBox="0 0 17 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.05098 -1.21946e-06L17 16.5L3.05097 33L0.246094 29.6822L11.3902 16.5L0.246096 3.31783L3.05098 -1.21946e-06Z"
                fill="white"
              />
            </svg>
          </Link>
        </NextPageBtn>
      )}
    </SubMenuBlock>
  );
};

export default MVSubMenu;
