/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
  statusReset,
  login,
  refresh,
} from "../../Context/UserContext";
import { loginSuccess } from "../../Service/UserApi";
import axios from "axios";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useForm } from "react-hook-form";
import { ValidationInput } from "../../Styles/InputStyle";
import SubHeader from "./SubHeader";
import SocialButtons2 from "./SocialButtons2";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { resources } from "../../config/lang/i18n";
import PopupLoadingStyle from "../../Styles/PopupLoadingStyle";

const LoginForm = styled.form`
  padding: 60px 30px 0 30px;

  color: rgb(45, 49, 56);
  text-align: center;
`;

const LoginBtn = styled.button`
  width: 100%;
  margin-top: 10px;
  padding: 20px 0;

  background-color: rgb(1, 74, 153);
  color: #fff;
  border: 0;

  ${Media.tablet`
  width: 100%;
`}

  ${Media.mobile`
  
`}
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const ErrorMsg = styled.div`
  color: rgb(1, 74, 153);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const LoginList = styled.ul`
  margin: 10px 0;
  padding: 10px 0;
  text-align: center;
  font-size: 0.875rem;

  li {
    padding: 0 10px;
    cursor: pointer;
    display: inline-block;
  }
`;
const SocialLoginWrapper = styled.div`
  padding: 0 30px;
`;

const SocialLoginBtn = styled.button``;

const SelectLogin = ({
  popUpPage,
  setPopUpPage,

  checkSocial,
}) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { loading, success, error } = state.status;

  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: "all",
  });

  const initializeUserInfo = async () => {
    const loggedInfo = localStorage.getItem("refresh");
    if (!loggedInfo) return;
    await refresh(dispatch);
  };

  const onSubmit = async (data) => {
    // console.log(data);
    await login(dispatch, data);
    initializeUserInfo();
  };

  const loginSuccess = () => {
    popupSetState({
      ...popupState,
      login: false,
    });
    reset();
  };
  // console.log(socialState);
  useEffect(() => {
    return () => {
      // console.log("closelogin");
      statusReset(dispatch);
    };
  }, []);

  useEffect(() => {
    if (state.is_login && state.status.success) loginSuccess();
  }, [state.status.success]);

  const watchEmail = watch("email");
  const watchPassword = watch("password");

  const onFocus = (e) => {
    e.target.style.border = "2px solid rgb(1, 74, 153)";
  };

  const onBlur = (e) => {
    e.target.style.border = "1px solid rgb(217, 219, 224)";
  };

  const onClickSignUp = () => {
    popupSetState({
      ...popupState,
      login: false,
      signup: true,
    });
  };

  const onClickFindID = () => {
    setPopUpPage(2);
  };

  const onClickFindPW = () => {
    setPopUpPage(4);
  };

  const { t, changeLanguage } = useTranslation();

  const [loadingPopup, setLoadingPopup] = useState(true);

  if (loading)
    return (
      loadingPopup && (
        <PopupLoadingStyle setLoadingPopup={setLoadingPopup} login={true} />
      )
    );

  // if (loading) return <div>로그인 중...</div>;
  let errorMsg = "";
  if (error) {
    if (error.status === 400) errorMsg = "이메일이나 비밀번호가 틀렸습니다.";
    else errorMsg = JSON.parse(error.responseText).non_field_errors[0];
  }
  // if (error) console.log(error.status);
  // if (success) {
  //   setOpenLogIn(false);
  //   reset();
  // }
  return (
    <>
      <LoginForm onSubmit={handleSubmit(onSubmit)}>
        <SubHeader
          title="Login"
          subtitle="로그인"
          color="#000"
          marginBottom="30px"
        />

        <ValidationInput
          id="email-input"
          name="email"
          label="email"
          placeholder={t("email")}
          autoComplete="off"
          onFocus={onFocus}
          // onBlur={onBlur}
          register={register}
          validation={{
            required: true,
            minLength: 5,
            pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
          }}
        />
        {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
        <ValidationInput
          type="password"
          name="password"
          label="password"
          placeholder={t("password")}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          validation={{ required: true, minLength: 8 }}
        />
        {errors.password && <Alert>{t("valid:not-valid-password")}</Alert>}
        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
        <LoginBtn active={!!(watchEmail && watchPassword)}>
          {t("login")}
        </LoginBtn>
      </LoginForm>
      <LoginList>
        <li onClick={onClickSignUp}>{t("signup")}</li>
        <li onClick={onClickFindID}>{t("find-account")}</li>
        <li onClick={onClickFindPW}>{t("find-password")}</li>
      </LoginList>
      <SocialLoginWrapper>
        <SocialButtons2 checkSocial={checkSocial} />
      </SocialLoginWrapper>
    </>
  );
};

export default SelectLogin;
