/* eslint-disable no-unused-vars */
import React, { FC } from "react";
import { Flex, FlexProps } from "@react-yuki/ui";

export const SlideContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
        height: "100vh",
      },
    }}
  />
);

export const MainBrandSlideContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
      },
    }}
  />
);

export const PhotoSlideContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "400px",
        height: "660px",
        marginTop: "10px",
      },
      ".swiper-pagination": {
        bottom: "5px",
      },
      ".swiper-pagination-bullet": {
        width: "10px",
        height: "10px",
      },
      ".swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet": {
        margin: "0 7px",
      },
    }}
  />
);

export const RelatedProductContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
        marginTop: "20px",
        backgroundColor: "#EFEFEF",
      },
      ".swiper-button-next": {
        backgroundImage: "url()",
      },
      ".swiper-button-prev": {
        backgroundImage: "url()",
      },
    }}
  />
);

export const MainLookbookContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
        marginTop: "20px",
      },
      ".swiper-button-next": {
        backgroundImage: "url()",
      },
      ".swiper-button-prev": {
        backgroundImage: "url()",
      },
    }}
  />
);

export const HistorySliderContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
        marginTop: "20px",
      },
      ".swiper-button-next": {
        backgroundImage: "url()",
      },
      ".swiper-button-prev": {
        backgroundImage: "url()",
      },
    }}
  />
);

export const MagazineSliderContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
      },
      ".swiper-button-next": {
        backgroundImage: "url()",
      },
      ".swiper-button-prev": {
        backgroundImage: "url()",
      },
    }}
  />
);

export const BrandSliderContainer = (props) => (
  <Flex
    {...props}
    __css={{
      position: "relative",
      ".swiper-container": {
        width: "100%",
      },
      ".swiper-button-next": {
        backgroundImage: "url()",
      },
      ".swiper-button-prev": {
        backgroundImage: "url()",
      },
    }}
  />
);
