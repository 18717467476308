
import React from 'react';
import { PopupWrapper } from "../Layout/PopupWrapper";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Media from "../../Styles/MediaQuery";

const PopupPhoto = styled.div`
  width: 100%;
  height: 100%;
  /* 
  background-image: url(${(props) => props.detailImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  img {
    display: block;
    width: auto;
    height: 80vh;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: none;
  `}
`;

const MobilePopupPhoto = styled(PopupPhoto)`
  display: none;

  img {
    display: block;
    width: 90vw;
    height: auto;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: block;
  `}
`;


export const Popup = ({ open, setOpen }) => (
  <PopupWrapper open={open} setOpen={setOpen}>
    <PopupPhoto width="100%">
      <Link to="/magazine/challenge/pepsi-korea" onClick={ () => setOpen(false) }>
        <img src="/Img/taste-of-korea/cha-pop-pc-re-re.png" width="100%" alt="popup" />
      </Link>
    </PopupPhoto>
    <MobilePopupPhoto width="100%">
      <Link to="/magazine/challenge/pepsi-korea" onClick={ () => setOpen(false) }>
        <img src="/Img/taste-of-korea/cha-pop-mo-re-re.png" width="100%" alt="popup" />
      </Link>
    </MobilePopupPhoto>
  </PopupWrapper>
)

export const PopupChallenge = ({ open, setOpen }) => (
    <PopupWrapper open={open} setOpen={setOpen}>
      <PopupPhoto width="100%">
        <Link to="#" onClick={ () => setOpen(false) }>
          <img src="/Img/zero/popup-challenge.jpg" width="100%" alt="popup" />
        </Link>
      </PopupPhoto>
      <MobilePopupPhoto width="100%">
        <Link to="#" onClick={ () => setOpen(false) }>
          <img src="/Img/zero/popup-challenge-mo.jpg" width="100%" alt="popup" />
        </Link>
      </MobilePopupPhoto>
    </PopupWrapper>
)
