import styled from "styled-components";
import React from "react";
import { TiktokFeed } from "../../TiktokFeed";
import {
  Heading,
  Wrapper,
  Description,
  Slogan,
  MobileLogo,
  TiktokFeedList,
  ChallengeBlock,
} from '../styles';

const tiktokUrls = [
  "https://www.tiktok.com/@starship_ent/video/6986899757339856130",
  "https://www.tiktok.com/@starship_ent/video/6987224643123924226",
  "https://www.tiktok.com/@starship_ent/video/6987240063239343362",
  "https://www.tiktok.com/@starship_ent/video/6987595695096122626",
  "https://www.tiktok.com/@starship_ent/video/6987611107976285442",
  "https://www.tiktok.com/@starship_ent/video/6987966668907613442",
  "https://www.tiktok.com/@starship_ent/video/6987982144257740033",
  "https://www.tiktok.com/@starship_ent/video/6988338159230684417",
  "https://www.tiktok.com/@starship_ent/video/6988353265557638402",
  "https://www.tiktok.com/@starship_ent/video/6988708866498366722",
  "https://www.tiktok.com/@starship_ent/video/6988724393102904578",
  "https://www.tiktok.com/@starship_ent/video/6989497472976522498",
  "https://www.tiktok.com/@starship_ent/video/6989914833198107906",
  "https://www.tiktok.com/@starship_ent/video/6989079981188009218",
  "https://www.tiktok.com/@starship_ent/video/6989095392579161346",
  "https://www.tiktok.com/@starship_ent/video/6989110845955509505",
  "https://www.tiktok.com/@starship_ent/video/6990239619023506689",
  "https://www.tiktok.com/@starship_ent/video/6994368779694263554",
  "https://www.tiktok.com/@starship_ent/video/6994383538220961025",

]

const NewChallengeBlock = styled(ChallengeBlock)`
  align-items: flex-start;
  flex-wrap: nowrap;
  padding-top: 0px;
  padding-bottom: 0px;

  @media (max-width: 1125px) {
    flex-direction: column;
    align-items: center;
    padding: 0px;
  }
`;

const NewWrapper = styled(Wrapper)`
  flex: 3;
  margin: 0;
  width: auto;
  padding: 50px 60px 80px 50px !important;
  @media (max-width: 1125px) {
    padding: 0px !important;
    width: 100% !important;
  }
  @media (min-width: 768px) and (max-width: 1125px) {
    padding-bottom: 80px !important;
  }
`;

const Container = styled.div`
  @media (min-width: 1126px) {
    padding: 0px !imporant;
  }
  @media (min-width: 768px) and (max-width: 1125px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0px;
  }
  @media (max-width: 767px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0px;
  }
`;

const ImageWrapper = styled.div`
  flex: 2;
  flex-grow: 1;
  img {
    // @yiearth header height 제외
    height: calc(100vh - 90px);
    width: auto;
  }
  position: -webkit-stikcy;
  position: sticky;
  // @yiearth header height 제외
  top: 90px;
  @media (max-width: 1125px) {
    display: none;
  }
`;

const NewHeading = styled(Heading)`
  > img {
    margin-left: 0px;
  }
  > div {
    padding-right: 16px;
  }
  span {
    color: #fff !important;
  }
  justify-content: space-between;
  @media (min-width: 768px) and (max-width: 1125px) {
    padding-top: 50px;
    > div {
      font-size: 5.3vw;
      line-height: 7vw;
    }
    > img {
      width: 9%;
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    > div {
      padding: 0px;
      font-size: 6vw;
      line-height: 140%;
    }
    > img {
      display: none;
    }
  }
`;

const NewDescription = styled(Description)`
  font-size: 1.8vw;
  line-height: 140%;
  margin-top: 0px !important;
`;

const NewSlogan = styled(Slogan)`
  color: #fff;
  margin-top: 16px !important;
  font-size: 1.4vw;
  padding-bottom: 60px;
  @media (max-width: 767px) {
    font-size: 3.5vw;
    text-align: center;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
`;

const MobileImg = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  margin: 50px 0px;
  @media (min-width: 1126px) {
    display: none;
  }
`;

export const TasteOfKorea = () => (
  <NewChallengeBlock>
    <ImageWrapper>
      <img src="/Img/taste-of-korea/artist-pc.jpg" alt="taste-of-korea"  />
    </ImageWrapper>
    <NewWrapper>
      <Container>
        <MobileLogo>
          <img src="/Img/zero/mobile-logo@3x.png" alt="logo" />
        </MobileLogo>
        <NewHeading>
          <div>
            <span>2021 PEPSI TASTE OF KOREA</span>
            <NewDescription>
              K-POP 아티스트와 함께한 챌린지 영상을 만나보세요
            </NewDescription>
          </div>
          <img src="/Img/zero/desktop-logo@3x.png" alt="logo" />
        </NewHeading>
      </Container>
      <MobileImg src="/Img/taste-of-korea/artist-mo.jpg" alt="taste-of-korea" />
      <Container>
        <NewSlogan>SUMMER TASTE CHALLENGE Video</NewSlogan>
      </Container>
      <Container>
        <TiktokFeedList>
          {
            tiktokUrls.map((url, index) => <TiktokFeed url={url} key={`${url}-${index}`} />)
          }
        </TiktokFeedList>
      </Container>
    </NewWrapper>
  </NewChallengeBlock>
)