import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";

const RelatedProductBlock = styled.div`
  width: 100%;
  background-color: #efefef;
  padding-bottom: 70px;
  h2 {
    color: #000;
    font-size: 36px;
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 80px 0 16px 0;

    ${Media.mobile`
      font-size: 14px;
      padding: 32px 0 18px 0;
    `}
  }
  ${Media.mobile`
    padding-bottom: 60px;
  `}

`;

const RelatedProductContainer = styled.div`
  margin-top: 30px;
  width: 80%;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: space-between;
`;

const SwiperSlide = styled.li`
  width: 50% !important;
  margin-right: 30px !important;
  /* flex: 0 1 47%; */

  ${Media.mobile`
    margin-right: 15px !important;
  `}

  &:last-child {
    margin-right: 0 !important;
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;

  ${Media.mobile`
    display: none;
  `}
`;

const MobileImg = styled.img`
  width: 100%;
  display: none;

  ${Media.mobile`
    display: block;
  `}
`;

const relatedProducts = [
  {name: 'zero-sugar', id: 1},
  {name: 'regular', id: 2},
  {name: 'retro', id: 3},
]

const ProductCard = ({ product }) => {
  return (
    <SwiperSlide key={Math.random()} id={Math.random()}>
      <Link to={`/brand/${product.id}`}>
        <Img src={`/Img/brand-detail/ban-${product.name}.jpg`} alt="" />
        <MobileImg src={`/Img/brand-detail-m/ban-${product.name}.jpg`} alt="" />
      </Link>
    </SwiperSlide>
  )
}

const RelatedProduct = ({ PageId }) => {
  const isMobile = window.visualViewport.width <= 767
  return (
    <RelatedProductBlock>
      <h2>RELATED PRODUCT</h2>
      <RelatedProductContainer>
      {
        !isMobile
          ? relatedProducts
              .filter((product) => product.id !== PageId)
              .map((product) => (
                <ProductCard
                  product={product}
                  key={product.name}
                  id={product.name}
                />
              ))
          : relatedProducts.map((product) => (
            <ProductCard
              product={product}
              key={product.name}
              id={product.name}
            />
        ))
      }        
        {/* {PageId === 2 ? (
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/brand/1">
              <Img src="/Img/brand-detail/ban-zero-sugar.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-zero-sugar.jpg" alt="" />
            </Link>
          </SwiperSlide>
        ) : (
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/brand/2">
              <Img src="/Img/brand-detail/ban-regular.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-regular.jpg" alt="" />
            </Link>
          </SwiperSlide>
        )}
        <SwiperSlide key={Math.random()} id={Math.random()}>
          <Link to="/brand/3">
            <Img src="/Img/brand-detail/ban-retro.jpg" alt="" />
            <MobileImg src="/Img/brand-detail-m/ban-retro.jpg" alt="" />
          </Link>
        </SwiperSlide> */}
      </RelatedProductContainer>
    </RelatedProductBlock>
  );
};

export default RelatedProduct;
