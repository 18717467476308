import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  background: white;
  padding: 90px 37px 56px;
  min-height: calc(100vh - 56px);
`;

const Title = styled.p`
  font-weight: 900;
  font-size: 22px;
  line-height: 32px;
`;

const Description = styled.p`
  margin-top: 12px;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 23px;
`;

const SubDescription = styled.p`
  margin-top: 12px;
  font-size: 11px;
  line-height: 19px;
  font-weight: bold;
`;

const Size = styled.p`
  margin: 12px 0 20px;;
  font-size: 11px;
  line-height: 19px;
  font-weight: normal;
`;

const ChairSubDescription = styled.p`
  font-size: 3.2vw;
  background: rgb(182, 202, 225);
  padding: 3vw 5vw;
  line-height: 2;
  margin: -10px 0 0;
`;

const ProductCard = styled.div`
  padding-top: calc(100% * 153 / 315);
  position: relative;
  > div {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  border: 1px solid #eeeeee;
  margin-bottom: 10px;
  ${(props) =>
    props.selected &&
    `
    border: 5px solid #014A99;
    &::after{
        content: 'Selected';
        position: absolute;
        right: 0;
        top: 0;
        font-family: MontrealTS;
        font-style: italic;
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        background: #014A99;
        color: #FFFFFF;
        padding: 8px 12px;
    }
  `}
`;

const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
  ${(props) => (props.active ? 'background: black;' : 'background: #D6D6D6;')}
`;

let initialProductList = [
  {
    id: 1,
    name: '펩시 후드',
    description: `펩시 후드는 레트로 화이트와 제로라임 두 옵션으로<br/>
    구성되어 있습니다. 원하시는 사이즈를 선택해주세요.`,
    subDescription: `사이즈 안내(측정단위 : cm)`,
    size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/hoody.png'),
    width: 'calc(100% * 198 / 315)',
    options: [
      {
        code: 'E',
        name: '후드 화이트 / L',
        quantity: 30,
      },
      {
        code: 'F',
        name: '후드 화이트 / XL',
        quantity: 30,
      },
      {
        code: 'G',
        name: '후드 블랙 / L',
        quantity: 30,
      },
      {
        code: 'H',
        name: '후드 블랙 / XL',
        quantity: 30,
      },
    ],
  },
  {
    id: 2,
    name: '펩시 티셔츠',
    description: `펩시 티셔츠는 L 사이즈와 XL 사이즈로 구성되어 있습니다.<br/>
    원하시는 사이즈를 선택해주세요.`,
    subDescription: `사이즈 안내(측정단위 : cm)`,
    size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/tshirt.png'),
    width: 'calc(100% * 184 / 315)',
    productImages: [
      {
        title: '1940s 펩시 티셔츠',
        url: require('./images/detail-shirt-1940.png'),
      },
      {
        title: '1950s 펩시 티셔츠',
        url: require('./images/detail-shirt-1950.png'),
      },
      {
        title: '1960s 펩시 티셔츠',
        url: require('./images/detail-shirt-1960.png'),
      },
      {
        title: '1980s 펩시 티셔츠',
        url: require('./images/detail-shirt-1980.png'),
      },
      {
        title: '1990s 펩시 티셔츠',
        url: require('./images/detail-shirt-1990.png'),
      },
    ],
    options: [
      {
        code: 'I',
        name: '1940s 펩시 티셔츠 / L',
        quantity: 10,
      },
      {
        code: 'J',
        name: '1940s 펩시 티셔츠 / XL',
        quantity: 10,
      },
      {
        code: 'L',
        name: '1950s 펩시 티셔츠 / L',
        quantity: 10,
      },
      {
        code: 'M',
        name: '1950s 펩시 티셔츠 / XL',
        quantity: 10,
      },
      {
        code: 'N',
        name: '1960s 펩시 티셔츠 / L',
        quantity: 10,
      },
      {
        code: 'O',
        name: '1960s 펩시 티셔츠 / XL',
        quantity: 10,
      },
      {
        code: 'P',
        name: '1980s 펩시 티셔츠 / L',
        quantity: 10,
      },
      {
        code: 'Q',
        name: '1980s 펩시 티셔츠 / XL',
        quantity: 10,
      },
      {
        code: 'R',
        name: '1990s 펩시 티셔츠 / L',
        quantity: 10,
      },
      {
        code: 'S',
        name: '1990s 펩시 티셔츠 / XL',
        quantity: 10,
      },
    ],
  },
  /*{
    id: 3,
    name: '펩시 캠핑 체어',
    description: `캠핑 체어는 단일 옵션으로 선택을 원하시면 아래<br/>
    선택하기 버튼을 눌러 선택해주세요`,
    chairSubDescription: ``,
    subDescription: ``,
    img: require('./images/detail-chair.png'),
    width: 'calc(100% * 141 / 315)',
    options: [
      {
        code: 'K',
        name: '캠핑 의자',
      },
    ],
  },*/
  {
    id: 3,
    name: '맨투맨티',
    description: `맨투맨티는 L과 XXL 두가지 사이즈로 구성되어 있습니다. `,
    subDescription: `사이즈를 선택해주세요`,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/men-full-shirt.png'),
    width: 'calc(100% * 141 / 315)',
    options: [
      {
        code: 'B',
        name: '맨투맨티 / L',
        quantity: 30,
      },
      {
        code: 'C',
        name: '맨투맨티 / XXL',
        quantity: 30,
      },
    ],
  },
  
{
    id: 4,
    name: '크롭티',
  description: `펩시 크롭티는 단일 옵션으로 선택을 원하시면 아래<br/>
    선택하기 버튼을 눌러 선택해주세요`,
    subDescription: ``,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/women-full-shirt.png'),
    width: 'calc(100% * 141 / 315)',
    options: [
      {
        code: 'A',
        name: '크롭티 / M',
        quantity: 30,
      },
    ],
  },
  
  {
    id: 5,
    name: '장우산',
    description: `장우산 블랙컬러입니다. 선택을 원하시면 아래 <br/>
      선택하기 버튼을 눌러 선택해주세요.,`,
    subDescription: ``,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/umbrella-black.png'),
    width: 'calc(100% * 141 / 315)',
    options: [
      {
        code: 'D',
        name: '장우산',
      },
    ],
  },
  
  {
    id: 6,
    name: '장우산',
    description: `장우산 블루컬러입니다. 선택을 원하시면 아래 <br/>
      선택하기 버튼을 눌러 선택해주세요.`,
    subDescription: ``,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/umbrella-blue.png'),
    width: 'calc(100% * 141 / 315)',
    options: [
      {
        code: 'T',
        name: '장우산',
      },
    ],
  },
  {
    id: 7,
    name: '게토레이 보틀',
    description: `발송되는 보틀 색상은 이미지와 다를 수 있습니다.<br>`,
    subDescription: ``,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/detail-bottle.png'),
    width: 'calc(100% * 186 / 315)',
    options: [
      {
        code: 'U',
        name: '보틀',
        quantity: 40,
      },
    ],
  },
  {
    id: 8,
    name: '게토레이 타월',
    description: `게토레이 타월은 단일 색상 및 옵션입니다.<br/>`,
    subDescription: ``,
    // size: `L : 가슴 60 / 어깨 55 / 기장 67<br/>
    // XL : 가슴 63 / 어깨 59 / 기장 74`,
    img: require('./images/detail-towel.png'),
    width: 'calc(100% * 186 / 315)',
    options: [
      {
        code: 'V',
        name: '타월',
        quantity: 400,
      },
    ],
  }
];

export const ProductSelectPage = () => {
  const [productList, setProductList] = useState(initialProductList);

  useEffect(() => {
    setProductList(
      initialProductList.map((product) => ({
        
        ...product,
        options: product.options.map((option) => {
          const items = JSON.parse(localStorage.getItem('ITEMS_DATA'));
          const stock = items.filter(
            (item) => item.giveaway_code === option.code,
          )[0].stock;
          option.quantity = stock;
          return option;
        }),
      })),
    );
  }, []);

  if (localStorage.getItem('option_id') && localStorage.getItem('product_id')) {
    return <ProductList productList={productList} />;
  } else if (localStorage.getItem('product_id')) {
    return (
      <ProductOptionModal
        product={productList[Number(localStorage.getItem('product_id')) - 1]}
      />
    );
  } else {
    return <ProductList productList={productList} />;
  }
};

const OptionWrapper = styled.div`
  padding: 10px 16px 10px 19px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #2181d9;
  border-radius: 4px;
  align-items: center;
  > p {
    font-family: sofia_pro_bold;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22.4px;
    text-transform: capitalize;
    color: #c8c8c8;
  }

  ${(props) =>
    props.opened &&
    `
    >svg{
        transform: rotate(180deg);
    }
  `}
`;

const OptionItems = styled.div`
  border: 1px solid #2181d9;
  border-radius: 4px;
  padding: 5px 0;
  margin-top: 14px;
`;

const OptionItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px 8px 19px;
  color: #0064ab;
  > p:first-child {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
  > p:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #c4c4c4;
  }

  ${(props) =>
    props.selected &&
    `
  background: #0064AB;
  color: white;

  `}

  ${(props) =>
    props.outOfStock &&
    `
  color: rgba(196, 196, 196, 1);
  `}
`;

const ProductImageTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  margin-bottom: 20px;
  + img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }
`;

const ProductOptionModal = ({ product }) => {
  const [isOptionOpened, setIsOptionOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    product.options.length === 1 ? product.options[0].code : null,
  );
  return (
    <Container>
      <img
        alt=""
        src={product.img}
        style={{ width: '100%', marginBottom: 29 }}
      />
      <Title>{product.name}</Title>
      <Description
        style={{ lineHeight: '22px' }}
        dangerouslySetInnerHTML={{ __html: product.description }}
      />
      {product.chairSubDescription && (
        <ChairSubDescription
          dangerouslySetInnerHTML={{ __html: product.chairSubDescription }}
        />
      )}

      {product.subDescription && (
        <SubDescription
          dangerouslySetInnerHTML={{ __html: product.subDescription }}
        />
      )}

      {product.size && (
          <Size
              dangerouslySetInnerHTML={{ __html: product.size }}
          />
      )}

      {product.options.length > 1 && (
        <>
          <OptionWrapper
            opened={isOptionOpened}
            onClick={() => setIsOptionOpened(!isOptionOpened)}
          >
            <p>옵션 선택하기</p>
            <svg
              width="17"
              height="10"
              viewBox="0 0 17 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.63934e-07 1.67416L8.5 10L17 1.67416L15.2908 -1.02839e-06L8.5 6.65167L1.70919 -1.62206e-06L3.63934e-07 1.67416Z"
                fill="#2181D9"
              />
            </svg>
          </OptionWrapper>
          <>
            {isOptionOpened && (
              <OptionItems>
                {product.options.map((option) => {
                  return (
                    <OptionItem
                      data-stock={option.quantity}
                      outOfStock={option.quantity===0}
                      selected={option.code === selectedOption}
                      onClick={() =>
                        option.quantity != 0 && setSelectedOption(option.code)
                      }
                    >
                      <p>{option.name}</p>
                      {option.quantity === 0 && <p>재고 없음</p>}
                    </OptionItem>
                  );
                })}
              </OptionItems>
            )}
          </>
        </>
      )}

      {product.productImages && (
        <div style={{ marginTop: 48 }}>
          {product.productImages.map((item) => (
            <div style={{ marginBottom: 28 }}>
              <ProductImageTitle>{item.title}</ProductImageTitle>
              <img src={item.url} />
            </div>
          ))}
        </div>
      )}

      <CheckResultButton
        bottom
        onClick={() => {
          localStorage.setItem('option_id', selectedOption);
          window.location.reload();
        }}
        active={!!selectedOption}
      >
        선택하기
      </CheckResultButton>
    </Container>
  );
};

const ProductList = ({ productList }) => {
  const history = useHistory();
  const { code } = useParams();

  const [selectedIndex, setSelectedIndex] = useState(
    localStorage.getItem('option_id')
      ? productList.filter(
          (product) =>
            product.options.filter(
              (option) => option.code === localStorage.getItem('option_id'),
            ).length !== 0,
        )[0].id
      : null,
  );

  return (
    <Container>
      <Title>경품에 당첨되었습니다.</Title>
      <Description>
        아래 경품 중 원하는 경품과 세부 옵션을 선택해주세요!
      </Description>

      {productList.map((item) => {
        return (
          <ProductCard
            selected={selectedIndex === item.id}
            onClick={() => {
              localStorage.removeItem('product_id');
              localStorage.removeItem('option_id');
              localStorage.setItem('product_id', item.id);
              window.location.reload();
            }}
          >
            <div>
              <img alt="" style={{ width: item.width }} src={item.img} />
            </div>
          </ProductCard>
        );
      })}

      <CheckResultButton
        bottom
        onClick={() => {
          // 배송지 입력 페이지로 이동
          history.push(`/qr/delivery/${code}`);
        }}
        active={selectedIndex !== null}
      >
        다음 단계로
      </CheckResultButton>
    </Container>
  );
};
