import React from "react";
import "react-responsive-modal/styles.css";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import Meta from "../../Utils/Meta";
import SamsungCelebritesSection from "../Layout/SamsungCelebritesSection";
import SamsungMeetOn from "../Layout/SamsungMeetOn";
import Footer2 from "./Footer2";

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
    width: 100%;
  `}
`;

const MainSection = styled.div`
  width: 100%;
  padding: 120px 130px 84px;
  background-color: #010000;
  ${Media.mobile`
    padding: 107px 0 65px;
  `}
`;

const MainTitle = styled.div`
  color: white;
  padding: 0 6px 23px;
  ${Media.mobile`
    padding: 0 30px 26px;
  `}
  p {
    font-size: 2.25rem;
    font-weight: 400;
    margin: 30px 0;
    ${Media.mobile`
      font-size: 2rem;
    `}
  }
  text-transform: uppercase;
  .sub-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const MainAnimation = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(/Img/samsung/samsung-main-bg.png),
      linear-gradient(to right, #1a1a1a, #1a1a1a);
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;

    @keyframes samsung-box-animation {
      from {
        opacity: 0;
        top: 100%;
      }
      to {
        opacity: 1;
        top: 50%;
      }
    }

    .samsung-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);
      width: calc(738 / 1020 * 100%);
      animation-duration: 0.5s;
      animation-name: samsung-box-animation;
      animation-fill-mode: both;
      animation-delay: 0.2s;
    }
    .logo-bar {
      width: calc(868 / 1080 * 100%);
      position: absolute;
    }

    @keyframes logo-bar-left-animation {
      from {
        transform: rotate(-45deg) translate(123px, -123px);
      }
      to {
        transform: rotate(-45deg) translate(0, 0);
      }
    }

    #logo-bar-left {
      left: -45px;
      top: -90px;
      animation-duration: 0.8s;
      animation-name: logo-bar-left-animation;
      animation-fill-mode: both;
    }

    @keyframes logo-bar-right-animation {
      from {
        transform: rotate(-45deg) translate(0, 0);
      }
      to {
        transform: rotate(-45deg) translate(123px, -123px);
      }
    }

    #logo-bar-right {
      right: -51px;
      bottom: -270px;
      animation-duration: 0.8s;
      animation-name: logo-bar-right-animation;
      animation-fill-mode: both;
    }
  }
`;

const BrandHeaderTitle = styled.div`
  h1 {
    font-size: 36px;
    margin: 0;
    ${Media.mobile`
      font-size: 14px;
    `}
  }
  em {
    font-family: "MontrealTS", sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    ${Media.mobile`
      font-size: 14px;
    `}
  }
`;

const ProductViewSection = styled.section`
  position: relative;
  padding: 120px 130px 0px;
  ${Media.mobile`
    padding: 37px 28px 0px;
  `}
  #samsung-full-package1 {
    width: 80%;
    margin: 52px auto;
    ${Media.mobile`
      width: 100%;
      margin: 0px auto;
    `}
  }
`;

const VMiddleDot = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 99px;
  vertical-align: middle;
  background: black;
  position: relative;
  top: -2px;
`;

const ULDescription = styled.ul`
  position: absolute;
  ${Media.mobile`
    position: initial;
    margin-top: 11px;
  `}
  li {
    font-family: MontrealTS;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 6px;
  }
`;

const descriptions = [
  "GALAXY Z FLIP & PEPSI COLLABO CASE",
  "GALAXY WATCH & PEPSI COLLABO STRAP",
  "GALAXY BUDS PRO & PEPSI COLLABO CASE",
];

const SamsungGalaxyEvent = () => {
  const metaData = {
    title: "K하우스오브펩시",
    description: "PEPSI SUMMER TASTE, 탑아티스트와 함께한 서머테이스트",
    canonical: "",
    keyword:
      "한국펩시콜라,펩시콜라,pepsi,pepsi-korea,pepsi korea,K하우스오브펩시, Khouseofpepsi,케이하우스오브펩시,코리아하우스오브펩시,한정판 이벤트,펩시 한정판 선물 이벤트",
  };

  return (
    <>
      <Meta data={metaData} />
      <Wrapper>
        <MainSection>
          <MainTitle className="eng">
            <p>pepsi special edition</p>
            {/*<p className="sub-title">special edition</p>*/}
          </MainTitle>
          <MainAnimation>
            <div>
              <img
                alt=""
                class="logo-bar"
                id="logo-bar-left"
                src="/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/pepsi-logo-bar.png"
              />
              <img
                alt=""
                class="logo-bar"
                id="logo-bar-right"
                src="/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/pepsi-logo-bar.png"
              />
              <img
                alt=""
                class="samsung-box"
                src="/Img/samsung/samsung-box.png"
              />
            </div>
          </MainAnimation>
        </MainSection>

        <ProductViewSection>
          <BrandHeaderTitle>
            <h1>PEPSI SPECIAL EDITION</h1>
            {/*<em className="eng">special edition</em>*/}
          </BrandHeaderTitle>
          <img
            style={{ width: "100%", marginTop: 52 }}
            src="/Img/samsung/samsung-special-edition.png"
            alt=""
          />
        </ProductViewSection>

        <ProductViewSection>
          <BrandHeaderTitle>
            <h1>
              PEPSI SPECIAL EDITION
              <br />
              full package
            </h1>
            <em className="eng"></em>
          </BrandHeaderTitle>
          <img
            id="samsung-full-package1"
            src="/Img/samsung/samasung-full-package-1.png"
            alt=""
          />
          <img
            style={{ width: "100%", margin: "0 auto" }}
            src="/Img/samsung/samasung-full-package-4.png?w=1200"
            alt=""
          />

          <div>
            <ULDescription
              style={{
                top: "24%",
                right: " 16%",
              }}
            >
              {descriptions.map((item) => (
                <li key={item}>
                  {" "}
                  <VMiddleDot /> {item}
                </li>
              ))}
            </ULDescription>
          </div>
        </ProductViewSection>

        <ProductViewSection>
          <BrandHeaderTitle>
            <h1>
              PEPSI SPECIAL EDITION
              <br />
              ESSENTIAL PACKAGE
            </h1>
            <em className="eng"></em>
          </BrandHeaderTitle>
          <img
            style={{ width: "100%", marginTop: 52 }}
            src="/Img/samsung/samsung-essential-package-4.png?w=1200"
            alt=""
          />

          <ULDescription
            style={{
              top: "31%",
              right: "17%",
            }}
          >
            {descriptions.map((item) => (
              <li key={item}>
                {" "}
                <VMiddleDot /> {item}
              </li>
            ))}
          </ULDescription>
        </ProductViewSection>

        <SamsungCelebritesSection />
        <SamsungMeetOn />
        <Footer2 />
      </Wrapper>
    </>
  );
};

export default SamsungGalaxyEvent;
