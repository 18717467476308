/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
// swiper
import { globalStyles } from "../../Utils/stylesConfig";
import { Global } from "@emotion/core";
import MainBrandSlider from "../Layout/MainBrandSlider";

const BrandSectionBlock = styled.div`
  padding: 80px 0 50px 0;

  ${Media.tablet`

  `}

  ${Media.mobile`
    padding: 50px 0;
  `}

  h1 {
    margin-left: 82px;
    font-size: 46px;
    margin-bottom: 10px;

    ${Media.mobile`
    margin-left: 32px;
    margin-top: 0;
  `}
  }

  em {
    margin-left: 82px;
    font-family: "noto sans kr";
    font-size: 16px;
    font-weight: 400;

    ${Media.mobile`
    margin-left: 32px;

  `}
  }
`;

const BrandImageData = [
  { id: 1, canImg: "/Img/main/pepsi-zero-sugar.png", link: "/brand/1" },
  { id: 2, canImg: "/Img/main/pepsi-regular.png", link: "/brand/2" },
  { id: 3, canImg: "/Img/main/bigcan_40.png", link: "/brand/3" },
  { id: 4, canImg: "/Img/main/bigcan_50.png", link: "/brand/3" },
  { id: 5, canImg: "/Img/main/bigcan_60.png", link: "/brand/3" },
  { id: 6, canImg: "/Img/main/bigcan_80.png", link: "/brand/3" },
  { id: 7, canImg: "/Img/main/bigcan_90.png", link: "/brand/3" },
];

const BrandSection = () => {
  return (
    <BrandSectionBlock>
      <h1>ALL RIGHT HERE.</h1>
      <em>FOR THE LOVE OF IT</em>
      <Global styles={globalStyles} />
      <MainBrandSlider
        params={{
          slidesPerView: 5,
          spaceBetween: 50,
          mousewheel: false,
          loop: false,
          breakpoints: {
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
            980: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
              loop: true,
            },
            320: {
              slidesPerView: 2,
              spaceBetween: 18,
              loop: true,
            },
          },
        }}
        data={BrandImageData}
      />
    </BrandSectionBlock>
  );
};

export default BrandSection;
