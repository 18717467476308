import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { EventButton } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  width: 100%;
  height: 100%;

  background-color: rgba(256, 256, 256, 0.6);
`;

const FalseAnswerPopUpBlock = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 991;
  min-width: 200px;
  width: 40%;
  padding: 50px 0;
  border-radius: 5px;
  /* width: ${(props) => props.width || null};
  height: ${(props) => props.height || null}; */

  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  line-height: 2.5;
  text-align: center;

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
  }

  div {
    margin: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }

  ${Media.tablet`
  width: 50%;
  `}

  ${Media.mobile`
  width: 80%;
  h3{
    font-size: 1.2rem;
   }
  `}
`;

const FalseAnswerPopUp = ({ setState, openState }) => {
  const closePopUp = () => setState(!openState);
  const { t } = useTranslation();

  return (
    <>
      <FalseAnswerPopUpBlock>
        <h3 className="centered">
          {t("event:wrong-answer")
            .split("\n")
            .map((line) => (
              <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
            ))}
        </h3>
        <img className="answer-img" src="/Img/icon_quiz.jpg" alt="" />
        <EventButton className="popup-btn-small" onClick={closePopUp}>
          {t("event:try-again")}
        </EventButton>
      </FalseAnswerPopUpBlock>
      <Dimmed />
    </>
  );
};

export default FalseAnswerPopUp;
