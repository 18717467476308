/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
  logout,
  refresh,
} from "../../Context/UserContext";
import axios from "axios";
import { loginSuccess } from "../../Service/UserApi";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Media from "../../Styles/MediaQuery";
import SignUp from "../Pages/SignUp";
import Login from "../Pages/Login";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { resources } from "../../config/lang/i18n";

const MainMenuBlock = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  background-color: #fff;
`;

const MainMenuList = styled.ul`
  text-align: center;
  margin-bottom: 2rem;

  li a {
    color: #000;
    display: inline-block;
    font-size: 3rem;
    line-height: 1.5;
    transition: all 0.5s;

    ${Media.tablet`

    `}

    ${Media.mobile`
      font-size: 2.4rem;
      line-height: 1.3;
    `}
  }
  li a:hover {
    color: #004caf;
  }

  h3 {
    color: #000;
    font-size: 3rem;
    line-height: 1.5;
    transition: all 0.5s;
    cursor: pointer;

    ${Media.mobile`
      font-size: 2.4rem;
      line-height: 1.3;
    `}
  }
  h3:hover {
    color: #004caf;
  }
`;

const MemberMenu = styled.ul`
  text-align: center;
`;
const MemberItem = styled.li`
  padding: 8px 0;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
`;
const MemberItem2 = styled.li`
  padding: 8px 0;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
  color: #fff;
`;

const LanguageButton = styled.h3`
  padding: 8px 0;
  margin: 5px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
`;

const SubMenuList = styled.ul`
  li a {
    font-size: 1.5rem;
  }
`;

const MainMenu = ({ openSignUpPopUp, openLogInPopUp }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const initializeUserInfo = async () => {
    const loggedInfo = localStorage.getItem("refresh");
    if (!loggedInfo) return;
    await refresh(dispatch);
  };

  const api = axios.create({
    baseURL: "https://khouseofpepsi.com",
  });

  const checkSocial = async (payload) => {
    // console.log(socialState);
    const response = await api.post("/api/v1/auth/social/check/", payload);
    // console.log(response);
    // console.log(response.data.email);
    if (response.status === 200 && response.data.email) {
      socialDispatch({
        type: "CHECK_SOCIAL_SUCCESS",
        social_email: response.data.email,
      });
      popupSetState({
        ...popupState,
        signup: true,
        login: false,
      });
      // setOpenSignUp(true);
      // setOpenLogIn(false);
    } else {
      const { refresh } = response.data;
      localStorage.setItem("refresh", refresh);
      loginSuccess(response);
      initializeUserInfo();
      // return response;
    }
  };

  const { is_login, is_reset, userInfo } = state;

  const [langState, setLangState] = useState("ENG");

  const closeMainMenu = () =>
    popupSetState({
      ...popupState,
      mainmenu: false,
    });

  const onLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("acc");
    logout(dispatch);
  };

  const body = document.querySelector("body");

  const { t, i18n, ready } = useTranslation();

  const onChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ko");
      setLangState("ENG");
    } else if (i18n.language === "ko") {
      i18n.changeLanguage("en");
      setLangState("KOR");
    }
  };

  const [toggle, setToggle] = useState(false);
  const onMenuToggle = () => setToggle(!toggle);

  const [eventToggle, setEventToggle] = useState(false);
  const onEventMenuToggle = () => setEventToggle(!eventToggle);

  return (
    <Modal
      open={popupState.mainmenu}
      onClose={closeMainMenu}
      classNames={{
        overlay: "customMainmenuOverlay",
        modal: "customMainmenu",
        animationIn: "customEnterAnimation",
        animationOut: "customLeaveAnimation",
        closeButton: "customMainCloseBtn",
      }}
      animationDuration={0}
    >
      <MainMenuBlock>
        <MainMenuList className="eng">
          <li onClick={closeMainMenu}>
            <Link to="/brand">brand</Link>
          </li>
          <li onClick={closeMainMenu}>
            <Link to="/history">history</Link>
          </li>
          {/*Event Single Menu Ver*/}
          {/*<li onClick={closeMainMenu}>
            <Link to="/qr">event</Link>
          </li>*/}

          {/*Event Sub Menu Ver*/}
          {/*<li>
            <h3 onClick={onEventMenuToggle}>Event</h3>
            {eventToggle && (
                <SubMenuList>
                  <li>
                    <Link
                        to="/magazine/challenge"
                        onClick={closeMainMenu}
                    >
                      - CHALLENGE
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/newtropepsicustomer"
                        onClick={closeMainMenu}
                    >
                      - PAST
                    </Link>
                  </li>
                </SubMenuList>
            )}
          </li>*/}
          <li>
            <h3 onClick={onMenuToggle}>Magazine</h3>
            {toggle && (
              <SubMenuList>
                <li>
                  <Link
                    to="/magazine/lookbook/pepsi-2022zero"
                    onClick={closeMainMenu}
                  >
                    - CURRENT
                  </Link>
                </li>
                <li>
                  <Link
                    to="/magazine/lookbook/pepsi-korea"
                    onClick={closeMainMenu}
                  >
                    - PAST
                  </Link>
                </li>
              </SubMenuList>
            )}
          </li>
          <li onClick={closeMainMenu}>
            <Link to="/samsung_galaxy">COLLABO</Link>
          </li>
          {/*<li onClick={closeMainMenu}>
            <Link to="/magazine/challenge">CHALLENGE</Link>
          </li>*/}
        </MainMenuList>

        {/*<MemberMenu>
          {!is_login ? (
            <>
              <MemberItem onClick={openLogInPopUp}>{t("login")}</MemberItem>
              <MemberItem onClick={openSignUpPopUp}>{t("signup")}</MemberItem>
            </>
          ) : (
            <>
              <MemberItem onClick={onLogout}>{t("logout")}</MemberItem>
              <MemberItem onClick={closeMainMenu}>
                <Link to="/mypage">{t("mypage")}</Link>
              </MemberItem>
            </>
          )}
          <LanguageButton onClick={onChangeLanguage}>
            {langState}
          </LanguageButton>
           <MemberItem2 onClick={onOpenModal}>{t("signup")}</MemberItem2>
        </MemberMenu>*/}
      </MainMenuBlock>
    </Modal>
  );
};

export default MainMenu;
