/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import BrandSection from "../Layout/BrandSection";
import MagazineSection from "../Layout/MagazineSection";
import Footer from "./Footer";
import Meta from "../../Utils/Meta";
import 'react-responsive-modal/styles.css'
import moment from 'moment'
import { Popup } from '../Items/Popup';

const MainBlock = styled.div`
  /* padding: 70px 0; */
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  /* padding: 20px; */

  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  width: 100%;
  `}
`;
const MainSection = styled.div`
  width: 100%;
`;

const MainVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BtnScrollDown = styled.div`
  color: #fff;
  opacity: 0.7;
  font-size: 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  text-align: center;
  transform: translateX(-50%);
  z-index: 100;
  height: 100px;
  animation: showtxt 0.7s 0.8s both;
  @keyframes showtxt {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }
  &::after {
    content: "";
    width: 1px;
    height: 80px;
    background-color: #fff;
    display: block;
    margin: 10px auto 0;
    animation: down 0.7s 1.4s both;
  }
  @keyframes down {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }
`;
const ButtonWrapper = styled.div``;

const Main = () => {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const initialPopupState = useMemo(() => {
    if(!localStorage.getItem('taste-of-korea-popup-hide-expire')) return true
    if(moment(localStorage.getItem('taste-of-korea-popup-hide-expire')).isAfter(moment())) return false
    return true
  })

  const [open, setOpen] = useState(initialPopupState);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "main",
    });
  }, []);

  useEffect(() => {
    if (socialState.auto_login) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        login: true,
      });
      socialDispatch({
        type: "AUTO_LOGIN_SUCCESS",
      });
    }

    if (socialState.auto_signup) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        signup: true,
      });
      socialDispatch({
        type: "AUTO_SIGN_UP_SUCCESS",
      });
    }
  }, []);


  const metaData = {
    title: "K하우스오브펩시",
    description: "PEPSI ZERO SUGAR 짜릿한 맛, 펩시 제로 슈거. 아이유와 함께 '펩톡하!'",
    canonical: "",
    keyword:
      "한국펩시콜라,펩시콜라,pepsi,pepsi-korea,pepsi korea,K하우스오브펩시, Khouseofpepsi,케이하우스오브펩시,코리아하우스오브펩시,한정판 이벤트,펩시 한정판 선물 이벤트, 펩시, 펩시굿즈, 펩시 이벤트, 펩시콜라 웹사이트, 펩시콜라 홈페이지",
  };

  return (
    <MainBlock>
      <Meta data={metaData} />
      <Wrapper >
        {/*<Popup open={ open } setOpen={ setOpen } />*/}
        <MainSection>
          <MainVideo>
            <video
              type="video/mp4"
              src="Img/video/iu-tvc-30.mp4"
              poster="Img/intro.jpg"
              controls={true}
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video>
            <BtnScrollDown className="eng">SCROLL</BtnScrollDown>
          </MainVideo>
        </MainSection>
        {/*<SamsungEventSection />*/}
        <BrandSection />
        {/* <EventSection /> */}
        <MagazineSection />
        <Footer />
      </Wrapper>
    </MainBlock>
  );
};

export default Main;
