/* eslint-disable no-unused-vars */
import { Global } from "@emotion/core";
import React, { useRef, useState } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";
import { MainBrandSlideContainer } from "../../Utils/styledComponents";
import { globalStyles } from "../../Utils/stylesConfig";
import MainBrandSliderMobile from "./MainBrandSliderMobile";

const BrandSectionBlock = styled.div`
  display: none;
  padding: 85px 0 120px;
  ${Media.mobile`
    padding: 44px 0 60px;
  `}
  .desktop {
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/brand-bg.png);
    ${Media.mobile`
      display: none;
    `}
  }
  .mobile {
    display: none;
    ${Media.mobile`
      display: block;
    `}
  }
  .swiper-container {
    padding-left: 5%;
    padding-right: 5%;
    ${Media.mobile`
      padding: 0;
    `}
  }
`;

const BrandHeader = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 85px;
  ${Media.mobile`
    width: 84%;
    padding-bottom: 22px;
  `}
`;

const BrandHeaderTitle = styled.div`
  h1 {
    font-size: 46px;
    margin: 0;
    ${Media.mobile`
      font-size: 22px;
    `}
  }
  em {
    font-family: "MontrealTS", sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    ${Media.mobile`
      font-size: 14px;
    `}
  }
`;

const ControllerButtons = styled.div`
  display: flex;
  div {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/brand-menu.png);
  }
  .navigation {
    ${Media.mobile`
        display: none;
      `}
  }
  .prev {
    margin: 5px;
    margin-right: 42px;
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/left-arrow${(
      props
    ) => (props.prevDisable ? "-d" : "")}.svg);
  }
  .next {
    margin: 5px;
    margin-right: 74px;
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/right-arrow${(
      props
    ) => (props.nextDisable ? "-d" : "")}.svg);
  }
`;

const SwiperSlide = styled.li`
  background-image: url(${(props) => props.mainImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: translate3d(120px, 0px, 0px);

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    align-self: center;
    height: 368px;
    width: 182px;
    padding-bottom: 30px;
  }

  p {
    line-height: 39px;
  }

  p,
  em {
    align-self: center;
    font-size: 26px;
  }

  em {
    font-size: 14px;
  }
`;

const BrandImageData = [
  {
    id: 2,
    canImg: "/Img/main/pepsi-regular.png",
    link: "/brand/2",
    name: "pepsi regular",
    description: "SOCIALS, THE TOP",
  },
  {
    id: 4,
    canImg: "/Img/main/bigcan_50.png",
    link: "/brand/3",
    name: "1950’",
    description: "SOCIALS, THE TOP",
  },
  {
    id: 5,
    canImg: "/Img/main/bigcan_60.png",
    link: "/brand/3",
    name: "1960’",
    description: "SOCIALS, THE CAN",
  },
  {
    id: 6,
    canImg: "/Img/main/bigcan_80.png",
    link: "/brand/3",
    name: "1980’",
    description: "SOCIALS, GLOBAL PHENOMENON",
  },
  {
    id: 7,
    canImg: "/Img/main/bigcan_90.png",
    link: "/brand/3",
    name: "1990’",
    description: "SOCIALS, THE TOP",
  },
];

const BrandSection2 = () => {
  const swiperParams = {
    slidesPerView: 5,
    spaceBetween: 132,
    mousewheel: false,
    loop: false,
    breakpoints: {
      1024: {
        slidesPerView: 3.4,
        spaceBetween: 132,
      },
      980: {
        slidesPerView: 4,
        spaceBetween: 100,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
        loop: true,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 18,
        loop: true,
      },
    },
  };

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const openBrandModal = () => {
    popupSetState({
      ...popupState,
      brand: !popupState.brand,
    });
  };

  const swiperRef = useRef(null);

  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const updateIsDisabled = () => {
    setIsNextDisabled(getIsEnd());
    setIsPrevDisabled(getIsBeginning());
  };

  const getIsBeginning = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper.isBeginning;
    }
  };

  const getIsEnd = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper.isEnd;
    }
  };

  const navigatePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (!swiperRef.current.swiper.allowSlidePrev) return;
      swiperRef.current.swiper.slidePrev();
      updateIsDisabled();
    }
  };

  const navigateNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (!swiperRef.current.swiper.allowSlideNext) return;
      swiperRef.current.swiper.slideNext();
      updateIsDisabled();
    }
  };

  return (
    <BrandSectionBlock>
      <BrandHeader>
        <BrandHeaderTitle>
          <h1>ALL RIGHT HERE.</h1>
          <em className="eng">FOR THE LOVE OF IT</em>
        </BrandHeaderTitle>
        <ControllerButtons
          prevDisable={isPrevDisabled}
          nextDisable={isNextDisabled}
        >
          <div onClick={navigatePrev} className="navigation prev" />
          <div onClick={navigateNext} className="navigation next" />
          <div onClick={openBrandModal} />
        </ControllerButtons>
      </BrandHeader>

      <Global styles={globalStyles} />

      <MainBrandSlideContainer className="desktop">
        <Swiper {...swiperParams} ref={swiperRef}>
          {BrandImageData.map((item, index) => (
            <SwiperSlide tag={"li"} key={index} id={"item" + item.id}>
              <Link key={"key" + item.id} to={item.link}>
                <img href="hi" src={item.canImg} alt={item.name} />
                <p className="eng">{item.name}</p>
                <em className="eng">{item.description}</em>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </MainBrandSlideContainer>

      <MainBrandSliderMobile data={BrandImageData} className="mobile" />
    </BrandSectionBlock>
  );
};

export default BrandSection2;
