import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { instagram, others } from "../../Constants/SnsData";
import SnsGridItem from "../Items/SnsGridItem";

const SocialMediaBlock = styled.div`
  width: 100%;
  background-color: #fff;
  padding-top: 64px;
  padding-bottom: 116px;
  ${Media.mobile`
		padding-top: 22px;	
		padding-bottom: 52px;
  `}
`;

const SocialMediaHeader = styled.div`
  display: flex;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: space-between;
  padding-bottom: 36px;
  ${Media.mobile`
		padding-bottom: 28px;
	`}
  h2 {
    color: #000;
    font-size: 36px;
    ${Media.mobile`
			font-size: 14px;
			line-height: 22px;
		`}
  }
`;

const CustomTabs = styled.div`
  display: flex;
`;

const CustomTab = styled.div`
  width: 130px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  border-radius: 500px;
  margin-right: 10px;
  p {
    padding-top: 12px;
    font-size: 16px;
  }
  ${Media.mobile`
		display: none;
	`}
`;

const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 18px;
  row-gap: 20px;
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  ${Media.mobile`
  	  grid-template-columns: repeat(2, 1fr);
  `}
`;

const CustomTabButton = ({ isSelected, children, ...props }) => {
  return (
    <CustomTab
      style={{
        backgroundColor: isSelected ? "#0065C3" : "#DDDDDD",
      }}
      {...props}
    >
      <p
        className={`eng ${isSelected ? "selected" : ""}`}
        style={{
          color: isSelected ? "white" : "gray",
        }}
      >
        {children}
      </p>
    </CustomTab>
  );
};

const RelatedProductSocialMedia = ({ brandId }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabData = { instagram };

  const [tabs, setTabs] = useState(["instagram", "others"]);

  const isEmpty = (items) =>
    !items.filter((item) => item.brandId === brandId).length;

  useEffect(() => {
    if (isEmpty(instagram)) {
      setTabs(tabs.filter((tab) => tab !== "instagram"));
    }
    if (isEmpty(others)) {
      setTabs(tabs.filter((tab) => tab !== "others"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = tabData[tabs[currentTab]].filter(
    (item) => item.brandId === brandId
  );

  return (
    <SocialMediaBlock>
      <SocialMediaHeader>
        <h2>SNS</h2>
        <CustomTabs>
          {tabs.map((tab, index) => (
            <CustomTabButton
              isSelected={currentTab === index}
              onClick={() => setCurrentTab(index)}
            >
              {tab}
            </CustomTabButton>
          ))}
        </CustomTabs>
      </SocialMediaHeader>
      <MediaGrid>
        {items.map((item) => (
          <SnsGridItem
            key={item.img}
            src={item.img}
            isVideo={item.isVideo}
            onClick={() => window.open(item.externalLink, "_blank")}
          />
        ))}
      </MediaGrid>
    </SocialMediaBlock>
  );
};

export default RelatedProductSocialMedia;
