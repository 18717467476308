import React from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MainBrandSlideContainer } from "../../Utils/styledComponents";

const SwiperSlide = styled.li`
  transform: translate3d(120px, 0px, 0px);

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  img {
    align-self: center;
    height: 204px;
    width: 100px;
    padding-bottom: 16px;
  }

  p {
    align-self: center;
    font-size: 14px;
  }
`;

const PrevButton = styled.div`
  background-image: none !important;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 32px !important;
  height: 32px !important;
  left: 12px !important;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  img {
    align-self: center;
    width: 16px;
    height: 16px;
  }
`;

const NextButton = styled.div`
  background-image: none !important;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 32px !important;
  height: 32px !important;
  right: 12px !important;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  img {
    align-self: center;
    width: 16px;
    height: 16px;
  }
`;

const MainBrandSliderMobile = ({ data, ...props }) => {
  const params = {
    slidesPerView: 5,
    centeredSlides: true,
    spaceBetween: 132,
    mousewheel: false,
    loop: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <PrevButton className="swiper-button-prev">
        <img
          src="/Asset/01FYBC86H6SPZGXDPV7Z29Q8K9/arrow-right.svg"
          alt="left"
        />
      </PrevButton>
    ),
    renderNextButton: () => (
      <NextButton className="swiper-button-next">
        <img
          src="/Asset/01FYBC86H6SPZGXDPV7Z29Q8K9/arrow-left.svg"
          alt="right"
        />
      </NextButton>
    ),

    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
        loop: true,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 18,
        loop: true,
      },
    },
  };

  return (
    <MainBrandSlideContainer {...props}>
      <Swiper {...params}>
        {data.map((item) => (
          <SwiperSlide tag={"li"} key={"item" + item.id} id={"item" + item.id}>
            <Link key={"key" + item.id} to={item.link}>
              <img href="hi" src={item.canImg} alt={item.name} />
              <p className="eng">{item.name}</p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </MainBrandSlideContainer>
  );
};

export default MainBrandSliderMobile;
