import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HistoryMobileBlock = styled.div`
  padding-top: 15px;
  padding-bottom: 126px;
  background: url("/Img/bg_pattern2.jpg") center center fixed;
  background-size: cover;
`;

const HistoryListBlock = styled.div`
  padding-top: 40px;
  margin: 0 auto;
  a {
  }
  img {
    margin-top: -44px;
    width: 100%;
  }
`;

const HistoryImg = ({ img }) => {
  return (
    <Link to={`/history/history${img}`}>
      <img
        src={`/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/history-m_${img}.png`}
        alt={img}
      />
    </Link>
  );
};

const HistorySlider2 = ({ ...rest }) => {
  return (
    <HistoryMobileBlock {...rest}>
      <HistoryListBlock>
        {["40", "50", "60", "80", "90"].map((img) => (
          <HistoryImg img={img} key={img} />
        ))}
      </HistoryListBlock>
    </HistoryMobileBlock>
  );
};

export default HistorySlider2;
