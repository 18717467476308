import React, { useState } from 'react'
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SnsGridItem from '../Items/SnsGridItem';
import { instagram, others, youtube } from '../../Constants/SnsData';

const BrandHeaderTitle = styled.div`
  h1 {
    font-size: 46px;
    margin: 0;
    ${Media.mobile`
      font-size: 22px;
    `}
  }
  em {
    font-family: 'MontrealTS', sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    ${Media.mobile`
      font-size: 14px;
    `}
  }
`
const BrandHeader = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 85px;
  ${Media.mobile`
    padding-bottom: 22px;
    flex-direction: column;
  `}
`

const Tabs = styled.div`
  ${Media.mobile`
     padding-top: 20px;
  `}
`

const TabButton = styled.button`
  text-transform: uppercase;
  font-style: italic;
  font-family: 'MontrealTS', sans-serif;
  border: none;
  border-radius: 999px;
  padding-top: 4px;
  background-color: ${props => props.selected ? '#0065C3' : '#DDDDDD'};
  color: ${props => props.selected ? '#FFFFFF' : '#999999'};
  font-size: 16px;
  width: 130px;
  height: 36px;
  margin-right: ${props => props.index === 2 ? '0px' : '10px'};
  ${Media.mobile`
    padding-top: 2px;
    font-size: 11px;
    width: 80px;
    height: 26px;
    margin-right: ${props => props.index === 2 ? '0px' : '3px'};
  `}
`

const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 18px;
  row-gap: 20px;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 167px;
  ${Media.mobile`
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    padding-bottom: 72px;
  `}
`;

export default () => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = ['instagram', 'others', 'youtube'];
  const tabData = { instagram, others, youtube };

  return <>
    <BrandHeader>
      <BrandHeaderTitle>
        <h1>Meet on</h1>
        <em className="eng">social network</em>
      </BrandHeaderTitle>
      <Tabs>
        {
          tabs.map((tab, index) => (
            <TabButton 
              key={`${tab}-${index}`}
              index={index}
              selected={index === currentTab}
              onClick={() => setCurrentTab(index)}
            >
              {tab}
            </TabButton>
          ))
        }
      </Tabs>
    </BrandHeader>
    <MediaGrid>
      {tabData[tabs[currentTab]].map(item =>
        <SnsGridItem
          key={item.img}
          src={item.img}
			    isVideo={item.isVideo}
          onClick={() => window.open(item.externalLink, '_blank')}
        />
      )}
    </MediaGrid>
  </>;
}
