import React, { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { TVCDataGatorade } from "../../Constants/TVCDataGatorade";
import Media from "../../Styles/MediaQuery";
import MagazineMenuItem from "../Items/MagazineMenuItem";
import VideoCard from "../Items/VideoCard";
import MagazineMenuListGatorade from "../Items/MagazineMenuItemGatorade";

const TVCBlock = styled.div`
  margin: 0 auto;
  padding: 20px 0 164px 0;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 84px;
  column-gap: 28px;
  ${Media.mobile`
    padding: 20px 0 50px 0;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  `}
`;

const NavSection = styled.div`
  margin: 32px auto;
  display: flex;
  flex-direction: row;
  ${Media.mobile`
    display: none;
  `}
`;

const NavSectionMobile = styled.div`
  margin: 10px auto;
  display: none;
  select {
    font-size: 12px;
  }
  ${Media.mobile`
    display: block;
  `}
`;

const TVCGatorade = ({ wrapper: Wrapper }) => {
  const history = useHistory();
  const {
    params: { detail },
  } = useRouteMatch();

  useEffect(() => {
    if (TVCDataGatorade.filter((data) => data.path === detail).length === 0)
      history.push("/gatorade/magazine/tvc/2022-gatorade-move-like-this");
  }, []);

  const getVideos = () => {
    const matchedVideo = TVCDataGatorade.filter(
      (data) => data.path === detail
    )[0]?.videos;
    if (!!matchedVideo) return matchedVideo;
    return TVCDataGatorade.filter(
      (data) => data.path === "2022-gatorade-move-like-this"
    )[0].videos;
  };

  return (
    <Wrapper>
      <NavSection>
        {TVCDataGatorade?.map((data) => (
          <MagazineMenuListGatorade
            key={data.id}
            title={data.title}
            detail={detail}
            path={data.path}
            pathType="mv"
          />
        ))}
      </NavSection>
      <NavSectionMobile>
        <select
          value={detail}
          className="eng"
          name="path"
          onChange={(e) =>
            history.push(`/gatorade/magazine/tvc/${e.target.value}`)
          }
        >
          {TVCDataGatorade?.map((data) => (
            <option key={data.id} value={data.path}>
              {data.title}
            </option>
          ))}
        </select>
      </NavSectionMobile>
      <TVCBlock>
        {getVideos().map((item) => (
          <VideoCard key={item.url} src={item.url} title={item.title} />
        ))}
      </TVCBlock>
    </Wrapper>
  );
};

export default TVCGatorade;
