import React, { useState } from "react";
import styled from "styled-components";
import { instagram, others } from "../../Constants/SnsData"
import SnsGridItem from "../Items/SnsGridItem";
import Media from "../../Styles/MediaQuery";
const SnsBlock = styled.div`
  
  padding: 156px 0;
  ${Media.tablet`
  padding: 60px 0;
  `}
  ${Media.mobile`
    padding: 60px 0;
  `}
  
  h3 {
    font-family: 'MontrealTS', sans-serif;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 400;
    ${Media.mobile`
      font-size: 14px;
      padding-bottom: 14px;
    `}
  }
`

const SnsHeader = styled.div`
  display: flex;
  padding-bottom: 36px;
  width: 90%;
  ${Media.mobile`
    width: 84%;
    flex-direction: column;
    padding-bottom: 20px;
  `}
  margin: 0 auto;
  
  max-width: 1280px;
  justify-content: space-between;
  align-content: center;
`

const Tabs = styled.div`
`

const TabButton = styled.button`
  text-transform: uppercase;
  font-style: italic;
  font-family: 'MontrealTS', sans-serif;
  border: none;
  border-radius: 999px;
  padding-top: 4px;
  background-color: ${props => props.selected ? '#0065C3' : '#DDDDDD'};
  color: ${props => props.selected ? '#FFFFFF' : '#999999'};
  font-size: 16px;
  width: 130px;
  height: 36px;
  margin-right: ${props => props.index === 2 ? '0px' : '10px'};
  ${Media.mobile`
    padding-top: 2px;
    font-size: 11px;
    width: 80px;
    height: 26px;
    margin-right: ${props => props.index === 2 ? '0px' : '3px'};
  `}
`

const MediaGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 18px;
  row-gap: 20px;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  ${Media.mobile`
    width: 84%;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  `}
`;

const SnsSection = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = ['instagram', 'others'];
  const tabData = { instagram, others };
  
  return (
    <SnsBlock>
      <SnsHeader>
        <h3 className="eng">SNS</h3>
        <Tabs>
          {
            tabs.map((tab, index) => (
              <TabButton 
                key={`${tab}-${index}`}
                index={index}
                selected={index === currentTab}
                onClick={() => setCurrentTab(index)}
              >
                {tab}
              </TabButton>
            ))
          }
        </Tabs>
      </SnsHeader>
      <MediaGrid>
        {tabData[tabs[currentTab]].map(item =>
          <SnsGridItem
            key={item.img}
            src={item.img}
            isVideo={item.isVideo}
            onClick={() => window.open(item.externalLink, '_blank')}
          />
        )}
      </MediaGrid>
    </SnsBlock>
  );
};

export default SnsSection;
