import React, { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import { lookbookDataGatorade } from "../../Constants/LookBookDataGatorade2";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Media from "../../Styles/MediaQuery";
import MagazineMenuListGatorade from "../Items/MagazineMenuItemGatorade";

const NavSection = styled.div`
  margin: 32px auto 54px;
  display: flex;
  flex-direction: row;
  ${Media.mobile`
    display: none;
  `}
`;

const NavSectionMobile = styled.div`
  margin: 10px auto 30px;
  display: none;
  select {
    font-size: 12px;
  }
  ${Media.mobile`
    display: block;
  `}
`;

const LookBookSection = styled.div`
  padding-bottom: 15px;
  ${Media.mobile`
    padding-bottom: 4px;
  `}
  img {
    width: 100%;
  }
`;

const LookBookGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
  ${Media.mobile`
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;
  `}
`;
const LookBookGridItem = styled.img`
  grid-column: span ${(props) => props.span};
  ${Media.mobile`
    grid-column: span ${(props) => (props.span === 2 ? 1 : 2)};
  `}
`;

const LookBookGatorade2 = ({ wrapper: Wrapper }) => {
  const history = useHistory();

  const match = useRouteMatch();

  useEffect(() => {
    if (
      lookbookDataGatorade.filter((data) => data.path === detail).length === 0
    )
      history.push("/gatorade/magazine/lookbook/2022-gatorade-move-like-this");
  }, []);

  const { detail } = match.params;

  const getData = () => {
    const matchedData = lookbookDataGatorade?.filter(
      (item) => item.path === detail
    );
    if (!!matchedData && matchedData.length !== 0) {
      return matchedData;
    }
    return lookbookDataGatorade?.filter(
      (data) => data.path === "2022-gatorade-move-like-this"
    );
  };

  const data = getData()[0];

  const modalOptions = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showThumbnailsButton: false,
      showNextButton: true,
    },
    thumbnails: {
      showThumbnails: false,
    },
  };

  return (
    <Wrapper>
      <SimpleReactLightbox style={{ "background-color": "red" }}>
        <SRLWrapper options={modalOptions}>
          <NavSection>
            {lookbookDataGatorade?.map((data) => (
              <MagazineMenuListGatorade
                key={data.id}
                title={data.title}
                detail={detail}
                path={data.path}
                pathType="lookbook"
              />
            ))}
          </NavSection>
          <NavSectionMobile>
            <select
              value={detail}
              className="eng"
              name="path"
              onChange={(e) =>
                history.push(`/gatorade/magazine/lookbook/${e.target.value}`)
              }
            >
              {lookbookDataGatorade?.map((data) => (
                <option key={data.title} value={data.path}>
                  {data.title}
                </option>
              ))}
            </select>
          </NavSectionMobile>
          <LookBookSection>
            <LookBookGrid>
              {!!data.imgs &&
                data.imgs.map((img) => (
                  <LookBookGridItem src={img.src} alt="" span={img.span} />
                ))}
            </LookBookGrid>
          </LookBookSection>
        </SRLWrapper>
      </SimpleReactLightbox>
    </Wrapper>
  );
};

export default LookBookGatorade2;
