/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useRouteMatch } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { lookbookData } from "../../Constants/LookBookData";
import PhotoSlider from "../Layout/PhotoSlider";
import LookBookSubMenu from "../Items/LookBookSubMenu";
import LookBookMenuList from "../Items/LookBookMenuList";

const LookBookBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: flex-end; */

  ${Media.tablet`
    
`}

  ${Media.mobile`
  display: block;
`}
`;

const MainSection = styled.div`
  position: relative;
  width: 100%;

  h2 {
    position: absolute;
    bottom: 0;
    left: 20px;
    color: #fff;
    opacity: 0.7;
    font-size: 62px !important;

    ${Media.mobile`
    font-size: 26px !important;
`}
  }
  img {
    width: 100%;
    cursor: pointer;
  }
`;

const SubWrapper = styled.div`
  padding: 0 45px;
  ${Media.tablet` 


`}

  ${Media.mobile`
  padding: 0 15px;
`}
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${(props) =>
    props.layout === "left" ? "row" : "row-reverse"};
  align-items: center;

  margin: 60px 0;

  ${Media.tablet` 


`}

  ${Media.mobile`
    flex-direction: column;
`}

  h3 {
    font-size: 43px;
    color: rgba(0, 0, 0);
  }
`;

const SubMainPhoto = styled.div`
  width: 50%;
  flex: 1 1 50%;
  img {
    width: 100%;
    cursor: pointer;
  }

  ${Media.tablet`
    display: block;
`}

  ${Media.mobile`
  width: 100%;
  flex: 1 1 100%;
`}
`;
const SubPhoto = styled.div`
  width: 50%;
  flex: 1 1 50%;

  display: flex;
  flex-direction: column;
  align-items: center;
  ${Media.tablet` 


`}

  ${Media.mobile`
   display: none;
`}
`;

const MobileSubPhoto = styled.ul`
  display: none;

  ${Media.tablet` 
`}

  ${Media.mobile`
   display: flex;
   justify-content: space-between;
   margin-top: 10px;

   li {
    flex: 1 1 30%;
    margin-right: 10px;
   }

   li:last-child {
     margin-right: 0;
   }

   img {
     width: 100%;
     cursor: pointer;
   }
`}
`;
const FinalSection = styled.ul`
  display: flex;

  li {
    width: 50%;
  }

  img {
    width: 100%;
    cursor: pointer;
  }
`;

const HorizonSection = styled.ul`
  display: flex;
  flex-wrap: wrap;

  li:nth-last-child(2) {
    padding-right: 5px;
  }
  
  li:nth-last-child(1) {
    padding-left: 5px;
  }

  li {
  margin: 5px 0;
    width: 50%;
  }
  
  h3 {
    display: block;
    width: 100%;
    text-align: center;
    margin: 30px 0;
    font-size: 43px;
  }

  img {
    width: 100%;
    cursor: pointer;
  }
  ${Media.mobile`
   h3 {
    display: none;
   }
`}
`;

const PhotoSection = styled.ul`
  display: flex;

  li {
    width: 50%;
  }

  img {
    width: 100%;
    cursor: pointer;
  }
`;

const NavSection = styled.div`
  margin: 60px 0;
  padding: 0 45px;

  ${Media.mobile`
  padding: 0 15px;
  `}
`;

const LookBook = ({wrapper: Wrapper}) => {
  // const [data, setData] = useState(null);

  const match = useRouteMatch();
  // console.log(match);

  const { detail } = match.params;
  // console.log(detail);
  // console.log(lookbookData);

  const data = lookbookData.filter((item) =>
    detail ? item.path === detail : item.path === "pepsi-2022zero"
  );

  const {
    id,
    title,
    subtitle,
    subtitle2,
    artist1,
    artist2,
    artist3,
    artist4,
    artist5,
    artist6,
    artist7,
    artist8,
    path,
    mainPhoto,
    horizontal1,
    horizontal2,
    horizontal3,
    horizontal4,
    horizontal5,
    horizontal6,
    set1,
    set2,
    set3,
    set4,
    set5,
    set6,
    set7,
    set8,
    final,
  } = data[0];

  // console.log(mainPhoto);
  return (
    <Wrapper>
      <SimpleReactLightbox>
        <SRLWrapper>
          <LookBookBlock>
            <MainSection>
              <h2 className="eng">{title}</h2>
              <img src={mainPhoto} alt="" />
            </MainSection>

            <SubWrapper>
              <Section layout="left">
                <SubMainPhoto>
                  {set1 && (<img src={set1.main} alt="" />)}
                </SubMainPhoto>
                <SubPhoto>
                  {/*Taste of korea art1 - rain + mx*/}
                  {artist1 && (<h3 className="eng">{artist1}</h3>)}
                  <h3 className="eng">{subtitle}</h3>
                  {set1 && (<PhotoSlider data={set1.sub} />)}
                </SubPhoto>
                {set1 && (
                    <MobileSubPhoto>
                      {set1.sub.map((item) => (
                          <li>
                            <img src={item} alt=""/>
                          </li>
                      ))}
                    </MobileSubPhoto>
                )}
              </Section>

              {set2 && (
                <Section layout="right">
                  <SubMainPhoto>
                    <img src={set2.main} alt="" />
                  </SubMainPhoto>
                  <SubPhoto>
                    {/*Taste of korea art2 - rain + mx*/}
                    {artist2 && (<h3 className="eng">{artist2}</h3>)}
                    <h3 className="eng">{subtitle2}</h3>
                    <PhotoSlider data={set2.sub} />
                  </SubPhoto>
                  <MobileSubPhoto>
                    {set2.sub.map((item) => (
                      <li>
                        <img src={item} alt="" />
                      </li>
                    ))}
                  </MobileSubPhoto>
                </Section>
              )}

              {set3 && (
                  <Section layout="left">
                    <SubMainPhoto>
                      <img src={set3.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      {artist3 && (<h3 className="eng">{artist3}</h3>)}
                      <h3 className="eng">{subtitle2}</h3>
                      <PhotoSlider data={set3.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set3.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}

              {horizontal1 && (
                  <HorizonSection>
                    {horizontal1.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </HorizonSection>
              )}

              {horizontal2 && (
                  <HorizonSection>
                    {horizontal2.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </HorizonSection>
              )}

              {set4 && (
                  <Section layout="right">
                    <SubMainPhoto>
                      <img src={set4.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      <h3 className="eng">{subtitle}</h3>
                      <PhotoSlider data={set4.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set4.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}

              {set5 && (
                  <Section layout="left">
                    <SubMainPhoto>
                      <img src={set5.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      {artist4 && (<h3 className="eng">{artist4}</h3>)}
                      <h3 className="eng">{subtitle}</h3>
                      <PhotoSlider data={set5.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set5.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}

              {horizontal3 && (
                  <HorizonSection>
                    {horizontal3.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </HorizonSection>
              )}

              {horizontal4 && (
                  <HorizonSection>
                    {horizontal4.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </HorizonSection>
              )}

              {horizontal5 && (
                  <HorizonSection>
                    {horizontal5.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </HorizonSection>
              )}

              {horizontal6 && (
                  <HorizonSection>
                    {artist6 && (<h3 className="eng">{artist6}</h3>)}
                    {horizontal6.map((item) => (
                      <li>
                        <img src={item} alt=""/>
                      </li>
                      ))}
                  </HorizonSection>
              )}

              {set6 && (
                  <Section layout="right">
                    <SubMainPhoto>
                      <img src={set6.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      {/*Taste of korea art4- mx*/}
                      {artist5 && (<h3 className="eng">{artist5}</h3>)}
                      <h3 className="eng">{subtitle2}</h3>
                      <PhotoSlider data={set6.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set6.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}
              {set7 && (
                  <Section layout="left">
                    <SubMainPhoto>
                      <img src={set7.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      <h3 className="eng">{subtitle}</h3>
                      <PhotoSlider data={set7.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set7.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}
              {set8 && (
                  <Section layout="right">
                    <SubMainPhoto>
                      <img src={set8.main} alt="" />
                    </SubMainPhoto>
                    <SubPhoto>
                      <h3 className="eng">{subtitle}</h3>
                      <PhotoSlider data={set8.sub} />
                    </SubPhoto>
                    <MobileSubPhoto>
                      {set8.sub.map((item) => (
                          <li>
                            <img src={item} alt="" />
                          </li>
                      ))}
                    </MobileSubPhoto>
                  </Section>
              )}

              {final && (
                  <FinalSection>
                    {final.map((item) => (
                        <li>
                          <img src={item} alt=""/>
                        </li>
                    ))}
                  </FinalSection>
              )}
            </SubWrapper>
          </LookBookBlock>
        </SRLWrapper>
        <NavSection>
          <LookBookSubMenu />
          <LookBookMenuList />
        </NavSection>
      </SimpleReactLightbox>
    </Wrapper>
  );
};

export default LookBook;
