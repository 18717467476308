import styled from 'styled-components';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import prize1 from './event-code-assets/prize1.svg';
import prize2 from './event-code-assets/prize2.svg';
import prize3 from './event-code-assets/prize3.svg';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  margin-top: 90px;
  background: #094f9b;
  padding: 0 0 51px;
`;
const PaddingH = styled.div`
  padding-left: 29px;
  padding-right: 29px;
`;
const PaddingHS = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

const Title = styled.p`
  font-family: MontrealTS;
  font-style: italic;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  /* or 31px */
  padding-top: 70px;
  text-transform: uppercase;

  color: #ffffff;
`;
const KorTitle = styled.p`
  margin-top: 32px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 140%;
  /* or 31px */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
  margin-bottom: 21px;
`;
const AgreementButton = styled.button`
  background: #073f7c;
  padding: 9px 14px;
  width: initial;
  border: none;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-transform: capitalize;

  color: #ffffff;
`;
const EventDate = styled.div`
  margin-top: 28px;
  text-align: center;
  background: #ffffff;
  border-radius: 100px;
  padding: 8px 29px 6px;
  > b {
    font-weight: 700;
    margin-right: 20px;
  }
  font-family: Noto Sans KR;
  font-style: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  color: #000000;
`;

const OutDesc = styled.p`
  margin-top: 12px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  /* identical to box height, or 21px */
  text-transform: capitalize;

  color: #ffffff;

  opacity: 0.8;
  margin-bottom: 30px;
`;
const Prize = styled.img`
width 100%;
margin-bottom: 14px;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 46px;
  text-transform: capitalize;

  color: #ffffff;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;

const Image = styled.img`
  width: 100%;
  margin-top: 49px;
`;
const Iframe = styled.iframe`
  width: 100%;
  margin-top: 20px;
`;

const SeeMore = styled.a`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 40px;
  color: #ffffff;
`;
export const EventCodeFailContents = (props) => {
  const history = useHistory();
  const code = props.match.params.code;

  return (
    <div>
      <Container>
        <PaddingH>
          <Title>
            2020 PEPSI
            <br />
            zero sugar
          </Title>
        </PaddingH>
        <Image src="/Img/lookbook-zero/lookbook-visimg.jpg" />
        <PaddingHS>
          <Iframe
            src="https://www.youtube.com/embed/xp9uPLTMWO4"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen=""
            title="video"
          ></Iframe>
        </PaddingHS>
        <div style={{ textAlign: 'center' }}>
          <SeeMore href="/magazine/lookbook/pepsi-zero">자세히 보기</SeeMore>
        </div>

        <PaddingH>
          <Title>
            2020 PEPSI
            <br />
            heritage
          </Title>
        </PaddingH>
        <Image src="/Img/lookbook-heri/lookbook-visimg.jpg" />
        <PaddingHS>
          <Iframe
            src="https://www.youtube.com/embed/h7r2PV6QLxk"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen=""
            title="video"
          ></Iframe>
        </PaddingHS>
        <div style={{ textAlign: 'center' }}>
          <SeeMore href="/magazine/lookbook/pepsi-heritage">
            자세히 보기
          </SeeMore>
        </div>

        <PaddingH>
          <Title>
            2020 PEPSI
            <br />
            culture
          </Title>
        </PaddingH>
        <Image src="/Img/lookbook-kpop/lookbook-visimg.jpg" />
        <PaddingHS>
          <Iframe
            src="https://www.youtube.com/embed/k7aIhVBX3hw"
            frameborder="0"
            allow="autoplay; encrypted-media"
            allowfullscreen=""
            title="video"
          ></Iframe>
        </PaddingHS>
        <div style={{ textAlign: 'center' }}>
          <SeeMore href="/magazine/lookbook/pepsi-culture">자세히 보기</SeeMore>
        </div>
      </Container>
      <CheckResultButton bottom href="/">
        메인페이지로 이동
      </CheckResultButton>
    </div>
  );
};
