import React from "react";
import styled from "styled-components";
// import Media from "../../Styles/MediaQuery";

export const Input = styled.input`
  width: ${(props) => (props.name === "address" ? "65%" : "100%")};

  margin: 5px 0;
  padding: 22px 0;
  text-indent: 10px;

  color: rgb(45, 49, 56);

  font-size: 16px;
  letter-spacing: 0.14px;
  font-weight: 500;

  border: 0;
  border: 1px solid rgb(217, 219, 224);
  &::placeholder {
    color: #999;
  }
`;

export const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

export const ValidationInput = ({
  label,
  register,
  validation,
  pattern,
  ...rest
}) => (
  <>
    <Input name={label} ref={register(validation, pattern)} {...rest} />
  </>
);
