import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MagazineMenuItemBlock = styled.div`
  a {
    font-size: 14px;
    color: ${(props) => props.color};
    padding-right: 32px;
  }
`

const MagazineMenuItem = ({ title, detail, path, pathType }) => {
  return (
    <MagazineMenuItemBlock className="eng" color={path === detail ? '#0065C3' : '#999999'}>
      <Link to={`/magazine/${pathType}/${path}`}>
        {title}
      </Link>
    </MagazineMenuItemBlock>
  );
};

export default MagazineMenuItem;