import styled from 'styled-components';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import searchIcon from './event-code-assets/search-icon.svg';
import DaumPostcode from 'react-daum-postcode';
import Checkbox from 'react-custom-checkbox';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  margin-top: 60px;
  background: #094f9b;
  padding: 89px 29px 46px;
  min-height: calc(100vh - 90px - 56px);

  input[type='checkbox'] {
    display: none;
  }
  label::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url('/Img/check.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }
  label {
    font-size: 0.875rem;
    a {
      text-decoration: underline;
      display: inline;
    }
  }
  input[type='checkbox']:checked + label::before {
    background-color: #0065c3;
  }
`;

const KorTitle = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  ${(props) => (props.small ? 'font-size: 22px;' : 'font-size: 28px;')}

  line-height: 140%;
  color: #ffffff;
  margin-bottom: 30px;
`;

const Caution = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 3.2vw;
  background: rgba(255, 255, 255, 0.7);
  padding: 4vw 5vw;
  line-height: 2;
  margin: 30px 0;
`;
const SadFace = styled.img`
  margin-bottom: 39px;
  max-width: 124px;
  width: 30%;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 14px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 30px;
  text-transform: capitalize;

  color: #ffffff;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;
const Input = styled.input`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 160%;
  padding: 10px 19px;
  background: #ffffff;
  border: 1px solid #2181d9;
  border-radius: 4px;
  &::placeholder {
    color: #c8c8c8;
  }
  ${(props) => (props.nomb ? '' : ' margin-bottom: 15px;')}
`;
const TextArea = styled.textarea`
  width: 100%;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 160%;
  padding: 10px 19px;
  background: #ffffff;
  border: 1px solid #2181d9;
  border-radius: 4px;
  color: #c8c8c8;
  margin-bottom: 15px;
`;
const SearchWithIcon = styled.div`
  position: relative;
  > img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
  > input {
    padding-right: 58px;
  }
  margin-bottom: 15px;
`;
const PostcodeWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background: white;
`;
export const EventCodeDelivery = (props) => {
  const history = useHistory();
  const code = props.match.params.code;
  const location = useLocation();
  const giveaway_code = localStorage.getItem('option_id');

  const [data, setData] = useState({
    name: '',
    phonenumber: '',
    addressBig: '',
    addressEtc: '',
    addressSpecific: '',
    agreement1: false,
    agreement2: false,
  });

  const onChange = (name) => (e) => {
    setData({
      ...data,
      [name]: e.target.value,
    });
  };

  const [showPostCode, setShowPostcode] = useState(false);
  const onSubmit = async () => {
    console.log(data);
    if (data.name==='') {
      alert('성함을 입력해주세요');
      return;
    }
    if (data.phonenumber==='') {
      alert('휴대폰번호를 입력해주세요');
      return;
    }
    if (giveaway_code !== 'ZZ') {
      if (data.addressBig==='') {
        alert('도로명, 지번주소를 입력해주세요');
        return;
      }
      if (data.addressSpecific==='') {
        alert('상세주소를 입력해주세요');
        return;
      }
    }

    console.log(data);
    if (!data.agreement1) {
      alert('개인정보취급위탁안내에 동의해주세요');
      return;
    }
    if (!data.agreement2) {
      alert('이벤트 약관에 동의해주세요');
      return;
    }

    const address = `${data.addressBig} ${data.addressSpecific}  ${data.addressEtc}`;
    const phonenumber = data.phonenumber;
    const name = data.name;

    try {
      const res = await Axios.patch(`https://web.pep1.kr/api/v1/events/codes/${code}/`, {
        phonenumber,
        address: address,
        name,
        giveaway_code: giveaway_code,
      });
      history.push(`/qr/complete`);
    } catch (e) {
      switch (e.response.status) {
        case 400:
          // 이미 사용한 코드
          alert('이미 사용된 코드입니다');
          break;
        case 404:
          // 존재하지 않는 코드
          alert('존재하지 않는 코드입니다.');
          break;
        case 403:
          // 낙첨 코드
          alert('당첨 코드가 아닙니다.');
          break;
        default:
          alert(`서버에러: ${e.response.status}`);
          break;
      }
    }
  };
  const handleComplete = (data1) => {
    let fullAddress = data1.address;
    let extraAddress = '';

    if (data1.addressType === 'R') {
      if (data1.bname !== '') {
        extraAddress += data1.bname;
      }
      if (data1.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data1.buildingName}` : data1.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setData({
      ...data,
      addressBig: fullAddress,
    });
    setShowPostcode(false);
  };
  const { t } = useTranslation();
  return (
    <div>
      {showPostCode && (
        <PostcodeWrapper>
          <DaumPostcode
            onComplete={handleComplete}
            height={document.documentElement.clientHeight}
          />
        </PostcodeWrapper>
      )}

      <Container>
        <KorTitle>
          경품 수령 정보를
          <br />
          입력해주세요.
        </KorTitle>
        <Caution>
          당첨되신 QR 이미지는 꼭 보관 부탁 드리며,
          <br />
          접속 오류 발생 시, 당첨된 QR 사진과 함께 정보를
          <br />
          문의 메일로 전달 부탁 드립니다.
          <br />
          * 문의 메일 : helpservice2020@naver.com
        </Caution>

        <Input
          placeholder="성함"
          value={data.name}
          onChange={onChange('name')}
        />
        <Input
          placeholder="휴대폰 번호를 입력해주세요"
          value={data.phonenumber}
          onChange={onChange('phonenumber')}
        />

        {giveaway_code !== 'ZZ' && (
          <>
            <SearchWithIcon>
              <Input
                nomb
                placeholder="도로명, 지번주소"
                value={data.addressBig}
                readOnly
                onClick={() => setShowPostcode(true)}
                onChange={onChange('addressBig')}
              />
              <img src={searchIcon} onClick={() => setShowPostcode(true)} />
            </SearchWithIcon>
            <Input
              placeholder="참고사항"
              value={data.addressEtc}
              onChange={onChange('addressEtc')}
            />
            <Input
              placeholder="상세주소"
              value={data.addressSpecific}
              onChange={onChange('addressSpecific')}
            />
          </>
        )}

        <TextArea
          rows={4}
          readOnly
          value={`${t('event:qr-event-privacy-title')}\n\n${t(
            'event:qr-event-privacy',
          )}`}
        ></TextArea>
        <div style={{ margin: '0 0 12px' }}>
          <input
            type="checkbox"
            id="agree-policy"
            checked={data.agreement1}
            onChange={(e) =>
              setData({
                ...data,
                agreement1: e.target.checked,
              })
            }
          />
          <label
            htmlFor="agree-policy"
            style={{
              fontFamily: 'Noto Sans KR',
              fontWeight: 'bold',
              fontSize: 16,
              color: 'white',
              userSelect: 'none',
            }}
          >
            네, 동의합니다
          </label>
        </div>

        <TextArea
          rows={4}
          readOnly
          value={`${t('event:qr-event-policy-title')}\n\n${t(
            'event:qr-event-policy',
          )}`}
        ></TextArea>

        <div style={{ margin: '0 0 12px' }}>
          <input
            type="checkbox"
            id="event-policy"
            checked={data.agreement2}
            onChange={(e) =>
              setData({
                ...data,
                agreement2: e.target.checked,
              })
            }
          />
          <label
            htmlFor="event-policy"
            style={{
              fontFamily: 'Noto Sans KR',
              fontWeight: 'bold',
              fontSize: 16,
              color: 'white',
              userSelect: 'none',
            }}
          >
            네, 동의합니다
          </label>
        </div>
      </Container>

      <CheckResultButton bottom onClick={onSubmit}>
        경품 수령 정보 입력하기
      </CheckResultButton>
    </div>
  );
};
