/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import styled from 'styled-components';
import Media from '../../Styles/MediaQuery';
import axios from 'axios';
import { instaToken } from "../../Service/key";
const InstagramBlock = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  a {
    position: relative;
    display: block;
    width: calc(20% - 6px);
    margin: 3px;
  }

  ${Media.tablet`
    a {
    width: calc(25% - 6px);
  }
    `}

  ${Media.mobile`
    a {width: calc(33% - 6px);}
    `}
`;

const InstaItem = styled.li`
  width: 100%;
  padding-top: calc(100% - 6px);

  background-image: ${(props) => (props.img ? `url(${props.img})` : null)};
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    content: '';
    display: block;
    width: 100%;
    padding-top: calc(100% - 6px);
    z-index: 1;
  }
  &:hover::before {
    background-color: rgba(0, 0, 0, 0.4);
    ${Media.mobile`
    display: none;
    `}
  }
  em {
    display: block;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 90%;
    color: #fff;

    padding: 30px;
    line-height: 1.4;
    font-weight: 100;

    text-overflow: ellipsis;
    /* white-space: nowrap; */
    overflow: hidden;

    opacity: 0;
    z-index: 2;
  }

  em:hover {
    opacity: 1;
    ${Media.mobile`
    display: none;
    `}
  }
`;

const InstaFeed = () => {
  const [feedItems, setFeedItems] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  let feedData = [];
  const fetchItems = async () => {
    setErrorMsg('로딩중...');
    try {
      const response = await axios.get(
        'https://graph.instagram.com/me/media?access_token=' +
          instaToken +
          '&fields=id,caption,media_type,media_url,thumbnail_url,permalink',
      );
      // console.log(response.data);
      feedData = response.data.data;
      feedData.length = 5;
      setFeedItems(feedData);
    } catch (err) {
      // console.log(err.response);
      setErrorMsg('다시 시도해주세요');
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  if (!feedItems) return <div>{errorMsg}</div>;
  // console.log(feedItems);

  // feedItems.data.map((item) => {
  //   console.log(item);
  //   console.log(item.id);
  //   console.log(item.media_url);
  //   console.log(item.permalink);
  //   console.log(item.caption);
  // });
  return (
    <InstagramBlock>
      {feedItems.map((item) => (
        <a key={`link` + item.id} href={item.permalink} target="new">
          {item.media_type === 'IMAGE' ? (
            <InstaItem key={item.id} img={item.media_url}>
              <em>{item.caption}</em>
            </InstaItem>
          ) : (
            <InstaItem key={item.id} img={item.thumbnail_url}>
              <em>{item.caption}</em>
            </InstaItem>
          )}
        </a>
      ))}
    </InstagramBlock>
  );
};

export default InstaFeed;
