/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SelectSignUp from "../Layout/SelectSignUp";
import AgreementPersonal from "../Layout/AgreementPersonal";
import AgreementBusiness from "../Layout/AgreementBusiness";
import PersonalSignUp from "../Layout/PersonalSignUp";
import BusinessSignUp from "../Layout/BusinessSignUp";
import CompleteSignUp from "../Layout/CompleteSignUp";
import SocialSignUp from "../Layout/SocialSignUp";

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SignUp = ({ checkSocial }) => {
  const [popUpPage, setPopUpPage] = useState(1);

  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const onCloseModal = () => {
    socialDispatch({
      type: "CHECK_SOCIAL_RESET",
    });
    popupSetState({
      ...popupState,
      mainmenu: false,
      signup: false,
    });
  };

  const pageComponent = {
    1: (
      <SelectSignUp
        checkSocial={checkSocial}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    2: <AgreementPersonal popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    3: <PersonalSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    4: (
      <CompleteSignUp
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
        closePopUp={onCloseModal}
      />
    ),

    5: <AgreementBusiness popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    6: <BusinessSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    7: <SocialSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
  };

  return (
    <>
      <Modal
        open={popupState.signup}
        onClose={onCloseModal}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          closeButton: "customCloseBtn",
          animationIn: "customEnterAnimation",
          animationOut: "customLeaveAnimation",
        }}
        animationDuration={0}
      >
        <SignUpWrapper>{pageComponent[popUpPage]}</SignUpWrapper>
      </Modal>
    </>
  );
};

export default SignUp;
