import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEn from "./en/common.json";
import translationKo from "./ko/common.json";
import translationEnEvent from "./en/event.json";
import translationKoEvent from "./ko/event.json";
import privacyPolicyEn from "./en/policy.json";
import privacyPolicyKo from "./ko/policy.json";
import validCheckEn from "./en/valid-check.json";
import validCheckKo from "./ko/valid-check.json";

/*i18n.use(LanguageDetector).init({
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'https://localhost:3000',
    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement,
    // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    cookieOptions: { path: '/', sameSite: 'strict' }
});*/

export const resources = {
    en: {
        translation: translationEn,
        event: translationEnEvent,
        policy: privacyPolicyEn,
        valid: validCheckEn,
    },
    ko: {
        translation: translationKo,
        event: translationKoEvent,
        policy: privacyPolicyKo,
        valid: validCheckKo,

    }
};
i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(XHR)
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources: resources,
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p'],
        lng: "ko",
        fallbackLng: "en",
        debug: false,
        interpolation: {
            escapeValue: false, // not needed for react  as it escapes by default
        },
        /*detection: {
            // order and from where user language should be detected
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

            // keys or params to lookup language from
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            cookieDomain: 'http://localhost:3000',

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: { path: '/', sameSite: 'strict' }
        }*/
    });
export default i18n;
