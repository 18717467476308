/* eslint-disable no-unused-vars */
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const SamsungEventSectionBlock = styled.div`
  display: none;
  height: 35vw;
  background: url("/Img/zero-banner.jpg");
  background-size: cover;
  padding: 68px 0 40px;
  background-repeat: no-repeat;
  ${Media.mobile`
    display: block;
    padding: 30px 0 40px;
  `}
  a {
  	display: block;
    width:100%;
    height:100%;
  }
`;

const SamsungEventSectionBlockM = styled.div`
  display: none;
  background: url("/Img/samsung-bg-m.jpg");
  background-size: cover;
  padding: 33px 0 69px;
  height: 210;
  ${Media.mobile`
    display: flex;
  `}
`;

const InnerBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 77%;
  margin: 0 auto;
  ${Media.mobile`
    flex-direction: column;
    width: 84%;
    

  `}
`;
const Description = styled.div`
  ${Media.desktop`
    margin-left: 40px;
  `}
  em {
    font-size: 36px;
    line-height: 54px;
    padding-bottom: 30px;
    ${Media.mobile`
      font-size: 24px;
      line-height: 36px;
    `}
  }
  p {
    font-size: 16px;
    line-height: 24px;
    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
      padding-bottom: 12px;
    `}
  }
  color: #fff;
`;

const JoinButton = styled.div`
  display: flex;
  padding-top: 15px;
  a {
    align-self: center;
    background-color: #0065c3;
    width: 220px;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
    ${Media.mobile`
      width: 176px;
      height: 42px;
      justify-content: center;
    `}
  }
  p {
    align-self: center;
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    justify-content: center;
    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
    `}
  }
  div {
    align-self: center;
    margin: 0 24px 0 18px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/event-join.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    ${Media.mobile`
      display: none;
    `}
  }
`;

const SamsungEventSectionMobile = () => {
	return (
			<SamsungEventSectionBlock>
				<a href={"https://mall.lottechilsung.co.kr/search/showSearch.lecs?query=%ED%8E%A9%EC%8B%9C"} target={"_blank"}>
					<InnerBlock className="eng">
						{/*<Description>
          <em>
            pepsi
            <br />
            collaboration
          </em>
        </Description>*/}
						{/*<JoinButton>
          <Link to="/samsung_galaxy">
            <p>지금 바로보기</p>
          </Link>
        </JoinButton>*/}
					</InnerBlock>
				</a>
			</SamsungEventSectionBlock>
	);
};

export default SamsungEventSectionMobile;
