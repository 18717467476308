export const brandData = [
  {
    id: 1,
    name: "PEPSI ZERO",
    name2: "SUGAR LIME",
    mobileName: "Zero Sugar",
    mainCopy: "GERAT TASTE, ZERO SUGAR",
    subCopy: "",
    bgColor: "#000",
    textColor: "#fff",
    mainImg: "/Img/brand01/bk-vis.jpg",
    mobileMainImg: "/Img/brand01-m/bk-banner.jpg",
    canImg: "/Img/brand-detail/zero-sugar-can.png",
    detailImg: "/Img/brand-detail/black-vis-mg-re.jpg",
    mobiledetailImg: "/Img/brand-detail-m/bk-vis-m-re.jpg",
    concept: "PEPSI BLACK",
    description:
      "Great Taste, Zero Sugar",
    size: ["250ml", "355ml", "500ml"],
    nutritionFacts: {
      title: "pepsi zero sugar",
      serve: 355,
      calories: 0,
      carbo: '0.0',
      sugar: 0,
      protein: '0.00',
      fat: '0.0',
      cholesterol: '',
      sodium: 95,
    },
  },
  {
    id: 2,
    name: "PEPSI",
    name2: "REGULAR",
    mobileName: "PEPSI REULGAR",
    mainCopy: "GREAT TASTE",
    subCopy: "Fun! Food! Festa",
    bgColor: "#0065C3",
    textColor: "#fff",
    mainImg: "/Img/brand01/blue-vis.jpg",
    mobileMainImg: "/Img/brand01-m/blue-banner.jpg",
    canImg: "/Img/brand-detail/regular-can.png",
    detailImg: "/Img/brand-detail/blue-vis-mg.jpg",
    mobiledetailImg: "/Img/brand-detail-m/blue-vis-m.jpg",
    concept: "PEPSI",
    description:
      "Fun! Food! Festa",
    size: ["250ml", "355ml", "600ml", "1.5L"],
    nutritionFacts: {
      title: "Pepsi Regular",
      serve: 355,
      calories: 160,
      carbo: 40,
      sugar: 40,
      protein: 0,
      fat: '0.',
      cholesterol: 0,
      sodium: 10,
    },
  },
  {
    id: 3,
    name: "RETRO",
    name2: "PEPSI",
    mobileName: "RETRO",
    mainCopy: "RETRO PEPSI",
    subCopy: "",
    bgColor: "#fff",
    textColor: "#000",
    mainImg: "/Img/brand01/retro-vis.jpg",
    mobileMainImg: "/Img/brand01-m/retro-banner.jpg",
    canImg: "/Img/brand-detail/retro-can.png",
    detailImg: "/Img/brand-detail/retro-vis.jpg",
    mobiledetailImg: "/Img/brand-detail-m/retro-vis-m.jpg",
    concept: "PEPSI RETRO",
    description:
      "",
    size: ["355ml"],
    nutritionFacts: {
      title: "Pepsi Retro",
      serve: 355,
      calories: 160,
      carbo: 40,
      sugar: 40,
      protein: 0,
      fat: '0.',
      cholesterol: 0,
      sodium: 10,
    },
  },
];
