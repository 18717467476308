import React, { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { TVCData } from "../../Constants/TVCData";
import Media from "../../Styles/MediaQuery";
import VideoCard from "../Items/VideoCard";
import MagazineMenuList from "../Items/MagazineMenuItem";

const TVCBlock = styled.div`
  margin: 0 auto;
  padding: 20px 0 164px 0;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 84px;
  column-gap: 28px;
  ${Media.mobile`
    padding: 20px 0 50px 0;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  `}
`;

const NavSection = styled.div`
  margin: 32px auto;
  display: flex;
  flex-direction: row;
  ${Media.mobile`
    display: none;
  `}
`;

const NavSectionMobile = styled.div`
  margin: 10px auto;
  display: none;
  select {
    font-size: 12px;
  }
  ${Media.mobile`
    display: block;
  `}
`

const TVC = ({ wrapper: Wrapper }) => {
  const history = useHistory();
  const { params: { detail }} = useRouteMatch();


  useEffect(() => {
    if (TVCData.filter(data => data.path === detail).length === 0)
      history.push('/magazine/tvc/2023-pepsi-iu');
  });

  const getVideos = () => {
    const matchedVideo = TVCData.filter(data => data.path === detail)[0]?.videos;
    if (!!matchedVideo) return matchedVideo;
    return TVCData.filter(data => data.path === '2023-pepsi-iu')[0].videos;
  };

  return (
    <Wrapper>
      <NavSection>
        {TVCData?.map((data) => (
            <MagazineMenuList
                key={data.id}
                title={data.title}
                detail={detail}
                path={data.path}
                pathType="tvc"
            />
        ))}
      </NavSection>
      <NavSectionMobile>
        <select
          value={detail}
          className="eng"
          name="path"
          onChange={(e) => history.push(`/magazine/tvc/${e.target.value}`)}
        >
          {TVCData?.map((data) => (
            <option key={data.id} value={data.path}>
              {data.title}
            </option>
          ))}
        </select>
      </NavSectionMobile>
      <TVCBlock>
        {
          getVideos().map((item) => (
            <VideoCard
              key={item.url}
              src={item.url}
              title={item.title}
            />
          ))
        }
      </TVCBlock>
    </Wrapper>
  );
};

export default TVC;
