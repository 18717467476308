/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import { Route, Link, Switch } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
// import Instagram from "../Layout/Instagram";
import SubHeader from "../Layout/SubHeader";
import InstaFeed from "../Layout/InstaFeed";
import SelectTabStyle from "../../Styles/SelectTabStyle";
import LookBook from "../Layout/LookBook";
import MV from "../Layout/MV";
import Challenge from "../Layout/ChallengeLayout";
import Meta from "../../Utils/Meta";
import Footer from "./Footer";
import MagazineSection from "../Layout/MagazineSection";
import _ from "lodash";
import Footer2 from "./Footer2";
import MV2 from "../Layout/MV2";
import SNS from "../Layout/SNS";
import LookBook2 from "../Layout/LookBook2";
import TVC from "../Layout/TVC";

const MagazineBlock = styled.div`
  padding-top: 126px;

  ${Media.mobile`
    padding: 75px 0 0 0;
  `}
`;

const Wrapper = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  
  ${Media.tablet`

  `}

  ${Media.mobile`
    padding: 0 0 50px;
  `}
`;

const MagazineHeader = styled.div`
  max-width: 1280px;
  width: 80%;
  margin: 0 auto 20px;
  ${Media.mobile`
    margin: 0 auto 8px;
  `}
  h2 {
    font-size: 36px;
    ${Media.mobile`
      font-size: 22px;
    `}
  }
`

const ChallengeWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 70px;
  // background-image: url('/Img/zero/coke-bg.jpg');
  background-image: ${props => props.bgImg};
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: bottom;

  ${Media.tablet`

  `}

  ${Media.mobile`
    margin-top: 20px;
    padding: 20px 0 50px;
  `}
`;

const SelectTab = styled.ul`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;


  li {
    display: inline-block;
    margin-bottom: 10px;
  }
  li a {
    padding: 10px 20px 6px;
    border-radius: 500px;
    margin-right: 10px;
    background-color: #ddd;
    color: #999;
    font-size: 0.9rem;
    cursor: pointer;
    min-width: 136px;
    text-align: center;
    ${Media.mobile`
      font-size: 11px;
      padding: 8px 10px 4px;
      margin-right: 3px;
      min-width: 0;
    `}
  }

  .active a {
    background-color: #0065c3;
    color: #fff;
  }
  .disable a {
    background-color: #ddd;
    color: #999;
  }

`;

const InstaWrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 45px 172px;
  ${Media.mobile`
    padding: 0 20px;
  `}
`;

const tabData = [
  {
    id: 0,
    title: "lookbook",
    path: "lookbook",
    component: <LookBook2 />,
    active: true,
  },
  {
    id: 1,
    title: "tvc",
    path: "tvc",
    component: <TVC />,
    active: false,
  },
  { 
    id: 2,
    title: "mv", 
    path: "mv", 
    component: <MV2 />, 
    active: false 
  },
  { id: 3, title: "challenge", path: "challenge", component: <Challenge />},
  { id: 4, title: "sns", path: "sns", component: <SNS />},
];

const Magazine = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { pathname } = useLocation();

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const match = useRouteMatch();
  const { detail } = match.params;

  useEffect(() => {
    goScrollTop();
  }, [detail]);

  const goScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "magazine",
      pepsi: true,
      gatorade: false,
    });
  }, []);

  useEffect(() => {
    if (pathname.startsWith('/magazine/lookbook'))
      selectActiveTab(0);
    if (pathname.startsWith('/magazine/tvc'))
      selectActiveTab(1);
    if (pathname.startsWith('/magazine/mv'))
      selectActiveTab(2);
    if (pathname.startsWith('/magazine/challenge'))
      selectActiveTab(3);
    if (pathname.startsWith('/magazine/sns'))
      selectActiveTab(4);
  }, [pathname]);

  const metaData = {
    title: "K하우스오브펩시 :: 매거진",
    description:
      "한국펩시콜라 K하우스오브펩시에서 탑 아티스트와 함께 진행한 펩시 룩북",
    canonical: "",
    keyword:
      "K하우스오브펩시, 펩시헤리티지, 펩시2020, 펩시 이벤트, 펩시콜라 이벤트, 펩시콜라, 펩시 이벤트" +
      "진영 컨셉 포토, 요한 컨셉 포토, 펩시 진영, 펩시 요한",
  };

  const selectActiveTab = (id) => {
    setActiveTab(id);
  };

  return (
    <MagazineBlock>
      <Meta data={metaData} />
      {/* <SubHeader title="Magazine" subtitle="매거진" color="#000" /> */}
      <MagazineHeader>
        <h2 className="eng">magazine</h2>
      </MagazineHeader>
      <SelectTab className="eng">
        {tabData.map((tab) => (
          <li
            id={tab.id}
            key={tab.id}
            onClick={() => selectActiveTab(tab.id)}
            className={tab.id === +activeTab ? "active" : "disable"}
            style={{ paddingTop: '10px' }}
          >
            <Link to={`/magazine/${tab.path}/${_.last(pathname.split('/'))}`}>{tab.title}</Link>
          </li>
        ))}
      </SelectTab>
        <Switch>
          <Route exact path="/magazine/lookbook/:detail" component={() => <LookBook2 wrapper={Wrapper}/>} />
          <Route path="/magazine/tvc/:detail" component={() => <TVC wrapper={Wrapper}/>} />
          <Route path="/magazine/mv/:detail" component={() => <MV2 wrapper={Wrapper}/>} />
          <Route path="/magazine/challenge/" component={() => <Challenge wrapper={ChallengeWrapper}/>} />
          <Route path="/magazine/sns/:detail" component={() => <SNS wrapper={Wrapper}/>} />
        </Switch>
      <InstaWrapper>
        <InstaFeed />
      </InstaWrapper>
      <Footer2 />
    </MagazineBlock>
  );
};

export default Magazine;
