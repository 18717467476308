export const addSearch = {
  url: "/addrlink/addrLinkApi.do",
  key: "U01TX0FVVEgyMDIwMDgyNDE0MTA1ODExMDA5MTQ=",
};

export const socialKey = {
  kakao: "71ae1b37276619a7ac3c2b51953a5610",
  naver: "OE6el1XAzOjY92DMopcJ",
  facebook: "742044436372286",
  google:
    "152813795624-nhn5t6et7qbo7n3nendld0ffeae29ftj.apps.googleusercontent.com",
};

export const instaToken = "IGQVJWSVVHREdWM2lUVXZAtbUU2ZA0tYTGxUNzVnSk43MTRqNzB5M2hqM2twcWRQeExybWRYZADVJdFBPVkZAPLVBwM2xZAZAjlpQ1JDeHVPZAU9KLW5JV2JMSjhjbVUzZAHhsdDBzTGRKUGxoQi1ZATDZARMnVIUAZDZD";