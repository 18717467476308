/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import { useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import RelatedProductStatic from "../Layout/RelatedProductStatic";
import RetroRelatedProduct from "../Layout/RetroRelatedProduct";
import RelatedProductSocialMedia from "../Layout/RelatedProductSocialMedia"
import { brandData } from "../../Constants/BrandData";
import Footer2 from "./Footer2";

const BrandDetailBlock = styled.div`
  background-color: ${(props) => props.background || "#fff"};
  width: 100%;
  padding: 0;

  ${Media.tablet`
  `}

  ${Media.mobile`
    height: 100%;
    overflow-x: hidden;
  `}
`;

const BrandDetailMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  height: 100vh;
  margin: 0 auto;

  ${Media.mobile`
    display: block;
    height: auto;
    padding: 80px 0;
  `}
`;

const Wrapper = styled.div`
  color: ${(props) => props.textColor || "#fff"};

  display: flex;
  justify-content: center;

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: block;
    height: auto;
    padding: 0;
  `}

  h2 {
    font-size: 2rem;
  }
`;

const CanWrapper = styled.div`
  position: relative;
  min-width: 380px;
  height: 530px;
  margin-right: 50px;

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: none;
  `}
`;

const CanImage = styled.div`
  position: absolute;
  width: 285px;
  height: 530px;
  z-index: 99;
  top: calc(50% - 265px);
  left: 0;

  background-image: url(${(props) => props.canImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: none;
  `}
`;
const CanShadow = styled.div`
  position: absolute;
  width: 230px;
  height: 430px;

  top: calc(50% - 215px);
  right: 0;

  background-image: url(${(props) => props.canImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  opacity: 0.5;

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: none;
  `}
`;

const RetroCan = styled.div`
  width: 684px;
  height: 650px;
  margin-right: 30px;

  background-image: url(${(props) => props.canImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  ${Media.mobile`
    display: none;
  `}
`;

const MobileCanImage = styled.div`
  display: none;
  width: 100%;
  height: ${(props) => (props.canId === 3 ? "0" : "450px")};
  padding-top: ${(props) => (props.canId === 3 ? "160%" : "0")};
  margin: ${(props) => (props.canId === 3 ? "10px" : "30px")} auto;
  background-image: url(${(props) => props.canImg || null});
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.canId === 3 ? "center top" : "center center"};
  background-size: ${(props) => (props.canId === 3 ? "160%" : "contain")};

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: block;
  `}
`;

const Description = styled.div`
  width: 520px;
  padding: 0 30px;

  ${Media.tablet`
  `}

  ${Media.mobile`
    width: 100%;
    height: auto;
    padding: 0;
    margin-top: 40px;
  `}

  h1 {
    margin: 0 auto 10px;
    font-size: 38px;
    margin-top: ${(props) => props.id === 3 ? '100px' : '0'};
    ${Media.mobile`
    display: none;
  `}
  }

  h2 {
    display: none;

    ${Media.mobile`
    display: block;
    width: 100%;
    margin: 0 0 0 40px;
    font-size: 46px;
    `}
  }

  em {
    display: block;
    /* margin: 15px auto; */
    margin-top: 2px;
    font-size: 16px;
    line-height: 22.4px;
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;

    ${Media.mobile`
      margin-left: 20px;
      margin-right: 20px;
  `}
  }
`;

const SizeList = styled.ul`
  display: flex;
  /* justify-content: space-between; */

  margin: 36px auto 20px auto;

  ${Media.mobile`
      margin-left: 20px;
      margin-right: 20px;
  `}

  li {
    display: inline-block;
    margin-right: 12px;
    font-size: 12px;
    vertical-align: middle;
    &:last-child::after {
      display: none;
    }
  }

  li::after {
    display: inline-block;
    margin-left: 12px;
    margin-top: 0;
    content: "|";
    font-size: 7px;
    font-family: "Noto Sans KR";
    font-weight: 600;
    vertical-align: middle;
  }
`;

const NutritionBlock = styled.div`
  width: 340px;
  padding: 23px 14px;

  border: 1px solid #fff;
  border-color: ${(props) => props.textColor || "#fff"};

  ${Media.tablet`
  `}

  ${Media.mobile`
    width: auto;
    margin-left: 20px;
    margin-right: 20px;
  `}

  h2 {
    font-size: 18px;
  }
`;

const NutritionInfo = styled.dl`
  padding-top: 5px;
`;

const ServingSizeBlock = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 12px 0;
  border-bottom: 3px solid #fff;
  line-height: 21px;
  font-weight: 700;
  em {
    text-align: right;
    font-size: 12px;
    font-weight: 400;
  }
`;
const NutritionItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding: 8px 0;
  border-bottom: 1px solid #fff;
  dd {
    font-family: "Noto Sans KR";
  }
  &:last-child {
    border-bottom: 3px solid #fff;
  }
`;

const ProductPhoto = styled.div`
  width: 100%;
  height: 100%;
  /* 
  background-image: url(${(props) => props.detailImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover; */
  img {
    display: block;
    width: 100%;
    height: 100%;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: none;
  `}
`;

const MobileProductPhoto = styled.div`
  display: none;

  ${Media.tablet`
  `}

  ${Media.mobile`
    display: block;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
/* 
    background-image: url(${(props) => props.detailImg || null});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; */
  `}
`;

const BrandDetail = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  // const location = useLocation();
  const match = useRouteMatch();
  // console.log(location);
  // console.log(match);

  const { detail } = match.params;

  // console.log(typeof detail);

  // console.log(brandData);

  useEffect(() => {
    if (+detail === 3) {
      popupSetState({
        ...popupState,
        menu_button: "#000",
        page: "brandDetail",
      });
    } else {
      popupSetState({
        ...popupState,
        menu_button: "#fff",
        page: "brandDetail",
      });
    }
  }, []);

  const data = brandData.filter((item) => item.id === +detail);
  
  const {
    id,
    bgColor,
    textColor,
    canImg,
    name,
    name2,
    description,
    size,
    nutritionFacts,
    detailImg,
    mobiledetailImg,
  } = data[0];

  return (
    <BrandDetailBlock background={bgColor}>
      <BrandDetailMain>
        <Wrapper textColor={textColor}>
          {id === 3 ? (
            <RetroCan canImg={canImg} />
          ) : (
            <CanWrapper>
              <CanImage canImg={canImg} />
              <CanShadow canImg={canImg} />
            </CanWrapper>
          )}

          <Description id={data[0].id}>
            <h1>{name} {name2}</h1>
            <h2>{name}</h2>
            {name2 && <h2>&nbsp;{name2}</h2>}
            <MobileCanImage canImg={canImg} canId={id} />
            <em>{description}</em>
            <SizeList>
              {size.map((item) => (
                <li className="eng">{item}</li>
              ))}
            </SizeList>
            {/*<NutritionBlock textColor={textColor}>
              <h3>NUTRITION FACTS</h3>
              <NutritionInfo>
                <ServingSizeBlock>
                  <dt>서빙 사이즈</dt>
                  <dd>
                    1캔 ({nutritionFacts.serve}ML)<em>서브당</em>
                  </dd>
                </ServingSizeBlock>
                <NutritionItem>
                  <dt>열량</dt>
                  <dd>{nutritionFacts.calories}kcal</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>탄수화물</dt>
                  <dd>{nutritionFacts.carbo}g</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>당류</dt>
                  <dd>{nutritionFacts.sugar}g</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>단백질</dt>
                  <dd>{nutritionFacts.carbo}0g</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>지방</dt>
                  <dd>{nutritionFacts.fat}0g</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>콜레스테롤</dt>
                  <dd>{nutritionFacts.cholesterol}mg</dd>
                </NutritionItem>
                <NutritionItem>
                  <dt>나트륨</dt>
                  <dd>{nutritionFacts.sodium}mg</dd>
                </NutritionItem>
              </NutritionInfo>
            </NutritionBlock>*/}
          </Description>
        </Wrapper>
      </BrandDetailMain>
      <ProductPhoto detailImg={detailImg}>
        <img src={detailImg} alt="" />
      </ProductPhoto>
      <MobileProductPhoto detailImg={mobiledetailImg}>
        <img src={mobiledetailImg} alt="" />
      </MobileProductPhoto>
      <RelatedProductSocialMedia brandId={id}/>
      {/* {id === 3 ? (
        <RetroRelatedProduct />
      ) : (
        <RelatedProductStatic PageId={id} />
      )} */}
      <RelatedProductStatic PageId={id} />
      <Footer2 />
    </BrandDetailBlock>
  );
};

export default BrandDetail;
