/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import styled from "styled-components";
import Media from "../Styles/MediaQuery";

const Wrapper = styled.div`
  /* padding: 80px 5%; */
`;

const initialState = {
  province: "",
  street_name_address: "",
  land_lot_number_address: "",
  details: "",
  postcode: "",
};

const Postcode = ({
  setOpenAddress,
  address,
  setAddress,
  setAddress1,
  setAddress2,
  setPostcode,
}) => {
  // const [fullAddress, setFullAddress] = useState(initialState);
  // const [streetAdd, setStreetAdd] = useState("");
  // const [landAdd, setLandAdd] = useState("");

  const closePopUp = () => setOpenAddress(false);

  const handleComplete = async (data) => {
    let addr = ""; // 주소 변수
    let extraAddr = ""; // 참고항목 변수

    let extraAddress = "";
    let postcode = "";
    let address = "";
    //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
    if (data.userSelectedType === "R") {
      // 사용자가 도로명 주소를 선택했을 경우
      // addr = data.roadAddress;
      setAddress1(data.roadAddress);
      // setStreetAdd(data.roadAddress);
    } else {
      // 사용자가 지번 주소를 선택했을 경우(J)
      // addr = data.jibunAddress;
      // addr = data.roadAddress;
      setAddress1(data.jibunAddress);
      // setLandAdd(data.jibunAddress);
    }

    // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
    if (data.userSelectedType === "R") {
      // 법정동명이 있을 경우 추가한다. (법정리는 제외)
      // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
      if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
        extraAddr += data.bname;
      }
      // 건물명이 있고, 공동주택일 경우 추가한다.
      if (data.buildingName !== "" && data.apartment === "Y") {
        extraAddr +=
          extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
      }
      // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
      if (extraAddr !== "") {
        extraAddr = " (" + extraAddr + ")";
      }
      // 조합된 참고항목을 해당 필드에 넣는다.
      extraAddress = extraAddr;
    } else {
      extraAddress = "";
    }

    // 우편번호와 주소 정보를 해당 필드에 넣는다.
    postcode = data.zonecode;
    address = addr;
    // 커서를 상세주소 필드로 이동한다.
    // document.getElementById("sample2_detailAddress").focus();

    // console.log("extra", extraAddress);
    // console.log("postcode", postcode);
    // console.log("address", address);
    // setAddress1(address);
    setAddress2(extraAddress);
    setPostcode(postcode);

    const PROVINCE_CHOICES = {
      강원: "GW",
      경기: "GG",
      충북: "CB",
      충남: "CN",
      서울: "SE",
      경북: "GB",
      경남: "GN",
      전북: "JB",
      전남: "JN",
      제주특별자치도: "JJ",
      부산: "BS",
      대구: "DG",
      인천: "IC",
      광주: "GJ",
      대전: "DJ",
      울산: "US",
      세종특별자치시: "SJ",
    };

    const selectProvince = (province) => {
      // console.log(province);
      // console.log(PROVINCE_CHOICES[province]);

      if (PROVINCE_CHOICES[province]) return PROVINCE_CHOICES[province];
      else return "UN";
    };

    setAddress({
      ...address,
      province: selectProvince(data.sido),
      land_lot_number_address: data.jibunAddress,
      street_name_address: data.roadAddress,
      detail: extraAddress,
      postcode: postcode,
    });

    if (postcode) setOpenAddress(false);
  };

  const postCodeStyle = {
    display: "block",
    position: "absolute",
  };

  return (
    <Wrapper>
      <DaumPostcode
        onComplete={handleComplete}
        autoClose={false}
        width="100%"
        height="500"
        autoResize={false}
        style={postCodeStyle}
      />
    </Wrapper>
  );
};

export default Postcode;
