import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterBlock = styled.footer`
  padding: 30px 0 40px;
  color: #fff;
  background: #333;
  ${Media.mobile`
    padding: 28px 0 100px;
  `}
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
`;

const Policy = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    margin: 10px 0;
    color: #fff;
  }
  li {
    margin: 0 10px 5px;
  }
  li a {
    color: #fff;
    font-weight: bold;
  }
  ${Media.mobile`
    li a {
      color: #AFAFAF;
      font-size: 0.6875rem;
    }
  `}
`;

const FooterBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  ${Media.mobile`
    margin: 0;
  `}
`;

const FooterInfo = styled.div`
  text-transform: uppercase;
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    line-height: 1.6;
    color: #fff;
    ${Media.mobile`
      flex-direction: column;
    `}
  }
  li {
    margin: 0 10px;
    font-family: Noto Sans KR;
  }
  li:last-child a::after {
    display: none;
  }
  .inquiry {
    ${Media.mobile`
      color: #fff;
    `}
  }
  li a {
    color: #fff;
  }
  ${Media.mobile`
    ul {
      color: #AFAFAF;
      font-size: 0.625rem;
    }
  `}
`;

const Copyright = styled.div`
  width: 100%;
  margin: 8px 10px 0px;
  font-size: 0.6875rem;
  font-family: Noto Sans KR;
  ${Media.mobile`
    font-size: 0.625rem;
    color: #AFAFAF;
  `}
`;

const Footer2 = () => {
  const { t } = useTranslation();

  return (
    <FooterBlock>
      <Wrapper>
        <FooterBox>
          <Policy>
            <ul>
              <li>
                <Link to="/privacy">{t("policy:privacy-policy-title")}</Link>
              </li>
              <li>
                <Link to="/policy">{t("policy:policy-title")}</Link>
              </li>
            </ul>
          </Policy>

          <div className="footer-sns">
            <a href="https://www.instagram.com/pepsi.korea/" target="new">
              <img
                src="/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/ig.svg"
                alt="pepsi-instagram"
              />
            </a>
            <a href="https://www.facebook.com/PepsiKorea/" target="new">
              <img
                src="/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/fb.svg"
                alt="pepsi-facebook"
              />
            </a>
          </div>
        </FooterBox>

        <FooterInfo>
          <ul>
            <li>{t("policy:company")}</li>
            <li>{t("policy:owner")}</li>
            <li>{t("policy:business-registration-number")}</li>
            <li>{t("policy:business-address")}</li>
            <li className="inquiry">{t("policy:inquiry")}</li>
          </ul>
          <Copyright>
            copyright &copy; 2020 Pepsi. All rights reserved.
          </Copyright>
        </FooterInfo>
      </Wrapper>
    </FooterBlock>
  );
};

export default Footer2;
