import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Footer2 from "./Footer2";

const PrivacyBlock = styled.div`
  padding: 60px 0 0 0;
  max-width: 1280px;
  margin: 0 auto;
  line-height: 1.4;
  word-break: keep-all;

  h2 {
    font-size: 1.6rem;
    text-align: left;
    margin-bottom: 30px;
    font-weight: bold;
  }
`;
const Wrapper = styled.div`
  padding: 60px 30px;
  word-wrap: break-word;

  div {
    line-height: 1.6;
    text-align: justify;
    word-break: break-all;
  }
  
  p {
    margin: 15px 0;
  }
`;

const Privacy = () => {
  const { t } = useTranslation();
  return (
    <>
      <PrivacyBlock>
        <Wrapper>
          <h2>{t("policy:privacy-policy-title")}</h2>
          <div>
            <p>
              {t("policy:privacy-1")
                .split("\n")
                .map((line) => (
                  <p>{line}</p>
                ))}
            </p>
          </div>
        </Wrapper>
      </PrivacyBlock>
      <Footer2 />
    </>
  );
};

export default Privacy;
