/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SelectLogin2 from "../Layout/SelectLogin2";
import FindID from "../Layout/FindID";
import FindIDComplete from "../Layout/FindIDComplete";
import FindPW from "../Layout/FindPW";
import FindPWComplete from "../Layout/FindPWComplete";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 990;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.63);
`;

const PopUpBlock = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 991;

  overflow-y: auto;

  width: 500px;
  height: 700px;
  min-height: 85vh;

  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;

  ${Media.tablet`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100vh;
  max-height: 100vh;
  transform: none;
  `}

  ${Media.mobile`
  
  `}
`;

const Inner = styled.div`
  ${Media.tablet`
height: 120vh;
  `}

  ${Media.mobile`
  
  `}
`;

const GuestBtn = styled.div`
  width: 100%;
  padding: 0 30px 50px;

  a {
    width: 100%;
    padding: 20px;
    background-color: #ccc;
    color: #fff;
    border: 0;
    text-align: center;
  }

  ${Media.tablet`
`}

  ${Media.mobile`
`}
`;
export const CloseBtn = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 999;

  cursor: pointer;
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const Login = ({ checkSocial }) => {
  const [popUpPage, setPopUpPage] = useState(1);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const pageComponent = {
    1: (
      <SelectLogin2
        checkSocial={checkSocial}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    2: (
      <FindID
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    3: (
      <FindIDComplete
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),

    4: <FindPW popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    5: <FindPWComplete popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
  };

  // const closePopUp = () => {
  //   setOpenLogIn(false);
  //   setOpenMainMenu(false);
  // };

  const closePopUp = () => {
    popupSetState({
      ...popupState,
      login: false,
      signup: false,
      mainmenu: false,
      event_login: false,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <PopUpBlock>
        <Inner>
          {pageComponent[popUpPage]}

          {popUpPage === 1 && popupState.event_login && (
            <GuestBtn onClick={closePopUp}>
              <Link to="quiz">{t("continue-non-member")}</Link>
            </GuestBtn>
          )}
        </Inner>
        <CloseBtn onClick={closePopUp}>
          <svg width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <defs>
              <path
                id="icon-close_svg__a"
                d="M0 1.5L1.5 0 8 6.5 14.5 0 16 1.5 9.5 8l6.5 6.5-1.5 1.5L8 9.5 1.5 16 0 14.5 6.5 8z"
              />
            </defs>
            <use xlinkHref="#icon-close_svg__a" transform="translate(4 4)" />
          </svg>
        </CloseBtn>
      </PopUpBlock>
      <Dimmed onClick={closePopUp} />
    </>
  );
};

export default Login;
