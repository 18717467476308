/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  getUsers,
} from "../../Context/UserContext";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import {
  ValidationInput,
  Alert,
  onFocus,
  onBlur,
} from "../../Styles/InputStyle";
import { Link } from "react-router-dom";
import { passwordChange } from "../../Service/UserApi";
import { MainButton } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";
import { EventButton } from "../../Styles/ButtonStyle";
import MypageHeader from "./MypageHeader";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";

const PasswordChangeForm = styled.form`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  padding: 150px 30px 30px;
  color: rgb(45, 49, 56);

  h3 {
    margin: 0 0 40px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
  strong {
    display: block;
    margin-bottom: 20px;
  }
`;

const PwWrap = styled.div`
  margin-bottom: 80px;
`;

const ErrorMsg = styled.div`
  color: rgb(1, 74, 153);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const PasswordChange = ({ popUpPage, setPopUpPage }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [userEmail, setUserEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  const fetchData = () => {
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
    else if (!userInfo) getUsers(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, [state.acc]);

  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: "all",
  });

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const response = await passwordChange(data);
      setUserEmail(response.data.email);
      // console.log(response);
      // console.log("data", data);
    } catch (e) {
      let errorMsg = "";
      errorMsg = JSON.parse(e.response.request.responseText)[0];
      // console.log(errorMsg);
      setErrorMsg(errorMsg);
    }
    reset();
  };

  const watchEmail = watch("email");
  const watchCPassword = watch("current_password");
  const watchPassword = watch("password1");
  const watchPassword2 = watch("password2");

  const { t } = useTranslation();

  // const onFindPWComplete = () => {
  //   // setPopUpPage(5);
  // };

  if (!userInfo) return <DataLoadingStyle fetchData={fetchData} />;
  if (loading) return <DataLoadingStyle fetchData={fetchData} />;
  // if (error) console.log(error);

  return (
    <PasswordChangeForm onSubmit={handleSubmit(onSubmit)}>
      <h3>{t("change-password")}</h3>
      {/* <MypageHeader
        title="비밀번호 변경"
        subtitle="비밀번호 변경"
        color="#000"
        textAlign="center"
        marginBottom="30px"
      /> */}

      {userEmail ? (
        <>
          <div>{userEmail}의 비밀번호가 변경되었습니다.</div>
          <Link to="/mypage">
            <MainButton bgColor="#0065c3">마이페이지로 돌아가기</MainButton>
          </Link>
        </>
      ) : (
        <>
          <PwWrap>
            <ValidationInput
              name="email"
              label="email"
              placeholder={userInfo.email}
              autoComplete="off"
              readOnly={true}
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              validation={{
                required: true,
                minLength: 5,
                pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
              }}
              value={userInfo.email}
            />
            {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
            <ValidationInput
              type="password"
              name="current_password"
              label="current_password"
              placeholder={t("password-origin")}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              validation={{ required: true, minLength: 8, maxLength: 16 }}
            />
            {errors.current_password && (
              <Alert>{t("valid:not-valid-password-origin")}</Alert>
            )}
            <ValidationInput
              type="password"
              name="password1"
              label="password1"
              placeholder={t("password")}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              validation={{ required: true, minLength: 8, maxLength: 16 }}
            />
            {errors.password1 && <Alert>{t("valid:not-valid-password")}</Alert>}
            <ValidationInput
              type="password"
              name="password2"
              label="password2"
              placeholder={t("password-check")}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              validation={{
                required: true,
                validate: (value) => value === watchPassword,
              }}
            />
            {errors.password2 && (
              <Alert>{t("valid:not-valid-password-check")}</Alert>
            )}
            {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
          </PwWrap>
          <EventButton
            // onClick={onFindPWComplete}
            bgColor="#0065c3"
            active={
              !!(
                watchEmail &&
                watchCPassword &&
                watchPassword &&
                watchPassword2
              )
            }
          >
            {t("change-password")}
          </EventButton>
        </>
      )}
    </PasswordChangeForm>
  );
};

export default PasswordChange;
