import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
`;

const Popup = styled.div`
  padding: 2rem 0 0 0;
  background: white;
  display: flex;
  flex-direction: column;

  // 모바일 스타일
  height: 100vh;
  width: 100vw;

  @media (min-width: 640px) {
    // 데스크탑 스타일
    width: 45vw;
    height: 80vh;
  }
`;

const MobileBR = styled.br`
  @media (min-width: 640px) {
    display: none;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  p {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 0.9rem;
    line-height: 1.6rem;
    color: #000000;
    word-break: keep-all;

    flex-grow: 1;
    overflow-y: auto;
    padding: 0 1.7rem;
  }

  p:last-child {
    padding-bottom: 2rem;
  }
`;

const Title = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #000000;

  flex-shrink: 0;
  flex-grow: 0;
`;

const Header = styled.div`
  padding: 0 1.7rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EventCodeAgreementPopup = ({ opened, setOpened }) => {
  useEffect(() => {
    document.getElementsByTagName("html")[0].style.overflow = opened
      ? "hidden"
      : "auto";
  }, [opened]);

  const onBGClick = (e) => {
    setOpened(false);
  };

  const onPoupContentsClick = (e) => {
    e.stopPropagation();
  };

  const { t } = useTranslation();

  if (!opened) {
    return null;
  }

  return (
    <Container onClick={onBGClick}>
      <Popup onClick={onPoupContentsClick}>
        <Header>
          <Title>
            {t("event:qr-event-policy-title")
              .split("\n")
              .map((line) => (
                <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
              ))}
          </Title>
          <XIcon onClick={onBGClick} />
        </Header>
        {/*<Content dangerouslySetInnerHTML={{__html: text.split('\n').join('<br/>')}}/>*/}
        <Content>
          {t("event:qr-event-policy")
            .split("\n")
            .map((line) => (
              <p key={Math.floor(Math.random() * 999) + 1}>{line}</p>
            ))}
        </Content>
      </Popup>
    </Container>
  );
};

const XIcon = (props) => {
  return (
    <svg
      style={{
        cursor: "pointer",
      }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="31"
      height="31"
      fill="none"
      viewBox="0 0 31 31"
      {...props}
    >
      <path fill="url(#pattern0)" d="M0 0H31V31H0z"></path>
      <defs>
        <pattern
          id="pattern0"
          width="1"
          height="1"
          patternContentUnits="objectBoundingBox"
        >
          <use transform="scale(.01)" xlinkHref="#image0"></use>
        </pattern>
        <image
          id="image0"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAABmJLR0QA/wD/AP+gvaeTAAAB+UlEQVR4nO3dO1LDQBCE4SlOhI+EI27gEIdcGkxgqwpkyeixu9O9/r8qAiJ3z1g8ZKkUAQAAAAAAADyH1+wAgtJmco6I74h4zwog6C0iviLis/ULnyPicvtiKVfDMoa5NFvKYfTCl9v3x1YBBB1jeiaHVgHG74ZnPlJkZiETJJHcDOQCNSTbXTZYRfKd5QMWZNPVJugOdh3tAq9g2802+AP2newL/NJNlx6K9NDhD+dCztkfcizmmHkVp4JOWXdxKOqQsSjlwsrZqlIsrpipKaUBKGVJpTAIhQxSMgfCMmZkDIZl/KPlgFjGQi0GxTJWqjkwlrFRjcGxjJ1KDpBlFFJikCyjsD0DZRmVbBksy6hszYBZRiNLBs0yGns0cJaRZO5eDO5ZSTR1NHBkJJtbivUyXrIDoA/8yBLCL3Uh/NkrhH8MhXDqRAgnF4Vw+l0IH1AJ4SNcIVzkIITLgIRwoZwQLiUVwsXWQrgdQYjCQBQySFAahFKWFIoDUMzUhHJx5WxVOBR2yFiEU1GnrJs4FnTMvIhzMefsk3oo1EOHiOioSHTQxb7ABNtOtsEXsOtmF3gDm442QQuQ7yofsALZzrLBGpDrLhcogcwMZIIISJ8FDwW7l/5QsFNwZIyNj5SP1gFOwTLGhqU0X8aAR6/eYyYAAAAAAAB4Cj9F9iU49afdsgAAAABJRU5ErkJggg=="
        ></image>
      </defs>
    </svg>
  );
};
