/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";

import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";
// swiper
import { globalStyles } from "../../Utils/stylesConfig";
import { Global } from "@emotion/core";
import Swiper from "react-id-swiper";
import { MainLookbookContainer } from "../../Utils/styledComponents";

// import SubHeader from "./SubHeader";
// import SelectTabStyle from "../../Styles/SelectTabStyle";
// import LookBook from "./LookBook";
// import MV from "./MV";
import InstaFeed from "./InstaFeed";

const MagazineBlock = styled.div`
  padding-top: 135px;

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 3rem auto 1rem;
    width: 90%;
    max-width: 1280px;
  }
  ${Media.mobile`
    padding: 90px 0 20px;
    h3{
      margin: 20px auto 10px;
    }
  `}
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 170px 0;

  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
    padding: 20px 0 150px 0;
  `}
`;

const LookBookBlock = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  ${Media.tablet`
  
    display: block;
    
`}

  ${Media.mobile`

    display: none;
  `}
`;
const Section = styled.div`
  a {
    width: 100%;
    display: flex;
  }
  ${Media.mobile`
   
`}
`;
const ImageList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  li {
    width: 49%;
  }

  ${Media.tablet`

`}

  ${Media.mobile`
    transform: translateY(-40px);
`}
`;

const LookBookMobile = styled.div`
  display: none;

  ${Media.mobile`
display: block;
`}

  img {
    width: 100%;
  }
`;

const MVBlock = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 0 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${Media.tablet`

  `}

  ${Media.mobile`

  `}
`;

const VideoWrapper = styled.div`
  /* width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0; */
  position: relative;
  padding-bottom: 28%;
  width: 49%;
  height: 0;
  margin-bottom: 20px;

  /*&::after {
    content: "";
    display: block;
    width: 70px;
    height: 70px;
    background: url("/Img/play-btn.png") no-repeat center;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35px, -35px);
    cursor: pointer;
  } */
  img {
    width: 100%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${Media.tablet`

  iframe {

  }
  `}

  ${Media.mobile`
   width: 100%;
   padding-bottom: 55%;
   margin-right: 0;
  iframe {
    
  }
  `}
`;

const MainPhoto = styled.div`
  width: 50%;
  margin-right: 20px;

  img {
    width: 100%;
  }
`;

const SubWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    width: 100%;
    padding: 30px 140px;
    margin: 0;
    /* background-color: #ccc; */
    font-size: 42px;
  }
`;

const SubPhoto = styled.div`
  display: flex;
  align-self: flex-end;

  img {
    display: block;
    width: calc(50% - 10px);
    margin-right: 20px;
    height: 100%;
    // for safari
  }
`;

const PrevButton = styled.div`
  background-color: #000;
  width: 65px !important;
  height: 48px !important;
  left: -1px !important;
  border-radius: 0px 100px 100px 0px;
  color: #fff;
  font-size: 14px;
  padding: 17px 15px;
`;

const NextButton = styled.div`
  background-color: #000;
  width: 65px !important;
  height: 48px !important;
  right: -1px !important;
  border-radius: 100px 0px 0px 100px;
  color: #fff;
  font-size: 14px;
  padding: 17px 15px;
`;

const SwiperSlide = styled.li`
  /* width: 50% !important;
  margin-right: 30px !important; */
  /* flex: 0 1 47%; */
  /* transform: translate3d(50%, 0px, 0px); */
`;

const Magazine = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const swiperParams = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    mousewheel: false,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
        <PrevButton className="swiper-button-prev eng">PREV</PrevButton>
    ),
    renderNextButton: () => (
        <NextButton className="swiper-button-next eng">NEXT</NextButton>
    ),
  };

  return (
      <MagazineBlock>
        {/* <SubHeader title="Magazine" subtitle="매거진" color="#000" /> */}
        {/* <h3 className="eng">Lookbook</h3> */}
        <LookBookBlock>
          <Section>
            <Link to="/magazine/lookbook/pepsi-2022zero">
              <MainPhoto>
                <img src="/Img/lookbook-2022zero/iu-main.jpg" alt="" />
              </MainPhoto>
              <SubWrapper>
                <h3 className="eng">
                  2022 PEPSI
                  <br />ZERO SUGAR
                </h3>
                <SubPhoto>
                  <img src="/Img/lookbook-2022zero/iu-main2.jpg" alt="" />
                  <img src="/Img/lookbook-2022zero/iu-main3.jpg" alt="" />
                </SubPhoto>
              </SubWrapper>
            </Link>
          </Section>
        </LookBookBlock>
        <LookBookMobile>
          <MainLookbookContainer>
            <Global styles={globalStyles} />
            <Swiper {...swiperParams}>
              <SwiperSlide key={Math.random()} id={Math.random()}>
                <Link to="/magazine/lookbook/pepsi-2022zero">
                  <img src="/Img/lookbook-2022zero/iu-main.jpg" alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide key={Math.random()} id={Math.random()}>
                <Link to="/magazine/lookbook/pepsi-korea">
                  <img src="/Img/lookbook-2022zero/iu-main2.jpg" alt="" />
                </Link>
              </SwiperSlide>
              <SwiperSlide key={Math.random()} id={Math.random()}>
                <Link to="/magazine/lookbook/pepsi-korea">
                  <img src="/Img/lookbook-2022zero/iu-main3.jpg" alt="" />
                </Link>
              </SwiperSlide>
            </Swiper>
          </MainLookbookContainer>
        </LookBookMobile>
        <h3 className="eng">Music video</h3>
        <MVBlock>
          <VideoWrapper>
            {/* <img src="/Img/mv1.jpg" alt="" /> */}
            <iframe
                src="https://www.youtube.com/embed/Po0a1VAezgo"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
            />
          </VideoWrapper>
          <VideoWrapper>
            {/* <img src="/Img/mv1.jpg" alt="" /> */}
            <iframe
                src="https://www.youtube.com/embed/9X6aA_-FfD0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
            />
          </VideoWrapper>
        </MVBlock>
        {/*<h3 className="title-insta">pepsi.korea</h3>*/}
        <Wrapper>
          <InstaFeed />
        </Wrapper>
      </MagazineBlock>
  );
};

export default Magazine;
