import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import Event from "./Event";
import Magazine from "./Magazine";

const MainBlock = styled.div`
  /* padding: 70px 0; */
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  /* padding: 20px; */

  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  width: 100%;
  `}
`;
const MainSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const MainVideo = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const BtnScrollDown = styled.div`
  color: #fff;
  opacity: 0.7;
  font-size: 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  text-align: center;
  transform: translateX(-50%);
  z-index: 100;
  height: 100px;
  animation: showtxt 0.7s 0.8s both;
  @keyframes showtxt {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }
  &::after {
    content: "";
    width: 1px;
    height: 80px;
    background-color: #fff;
    display: block;
    margin: 10px auto 0;
    animation: down 0.7s 1.4s both;
  }
  @keyframes down {
    from {
      height: 0;
    }
    to {
      height: 80px;
    }
  }
`;

const MainSignUp = ({ setOpenMainMenu }) => {
  setOpenMainMenu(true);
  return (
    <MainBlock>
      <Wrapper>
        {/* <h2 className="eng">Main</h2> */}
        <MainSection>
          <MainVideo>
            {/* <iframe
              width="1333"
              height="906"
              src="https://www.youtube.com/embed/9DU39ApIJtw"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="pepsi"
            ></iframe> */}
            {/* <video
              type="video/mp4"
              src="blob:https://www.youtube.com/9ea53b01-1f5f-1744-a3ef-f7f431335a54"
              poster="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/Img/videomain.png"
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video> */}
            {/* <video
              // style="width: 409px; height: 230px; left: 129.556px; top: 0px;"
              tabindex="-1"
              class="video-stream html5-main-video"
              controlslist="nodownload"
              src="blob:https://www.youtube.com/9ea53b01-1f5f-1744-a3ef-f7f431335a54"
              poster="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/Img/2016_One_Pepsi_Visual_Identity_System-77.jpg"
            ></video> */}
            <video
              type="video/mp4"
              src="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/Img/video2_wide.mp4"
              poster="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/intro.jpg"
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video>
            {/* <video
              type="video/mp4"
              src="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/Img/video_pepsi.mp4"
              poster="https://s3.ap-northeast-2.amazonaws.com/khouseofpepsi.com/intro.jpg"
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video> */}
            {/* <video
              type="video/mp4"
              src="https://www.pepsi.com/en-us/uploads/media/PepsiWildCherry.Copier.15.mp4"
              poster="https://www.pepsi.com/en-us/uploads/media/Cherry-Copier.png"
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video> */}
          </MainVideo>
          <BtnScrollDown className="eng">SCROLL</BtnScrollDown>
        </MainSection>
        <Event />
        <Magazine />
      </Wrapper>
    </MainBlock>
  );
};

export default MainSignUp;
