import React, { useRef } from "react";
import styled from "styled-components";
import useLazyLoading from "../../Utils/useLazyLoading";

const HistoryItems = styled.div`
  padding: 10px;
  position: relative;
  width: 100%;
  cursor: pointer;
`;

const HistoryItemBlock = styled.div`
  position: relative;
  width: 100%;
  padding-top: 172%;
  background-color: #fff;
  overflow: hidden;
  filter: drop-shadow(0px 4px 22px rgba(0, 0, 0, 0.15));
`;

const HistoryDescription = styled.div`
  position: absolute;
  top: 0;
  padding: 33px 22px 0;

  p {
    font-family: MontrealTS;
    font-size: 14px;
    font-weight: 400;
    lien-height: 21px;
    padding-bottom: 40px;
  }
  li {
    font-family: MontrealTS;
    font-size: 18px;
    font-weight: 400;
    lien-height: 27px;
    padding: 4px 0;
  }
`;

const HistoryItemImage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 54%;
  img {
    width: 100%;
    height: 100%;
  }
`;

const HistoryItem2 = ({ history }) => {
  const imgRef = useRef(null);
  const isLoad = useLazyLoading(imgRef);
  const getImageUrl = (name) =>
    `/Asset/01FY977ZJY7JX5882PRQEETZP4/history_can_${name}.png`;
  const details = history.detail_info;
  console.log(details);

  return (
    <HistoryItems>
      <HistoryItemBlock>
        <HistoryDescription className="english">
          <p>{history.name}’</p>
          <ul>
            <li>SOCIALS,</li>
            <li>{details.card2_subtitle2}</li>
            <li>{details.card2_subtitle3}</li>
          </ul>
        </HistoryDescription>
        <HistoryItemImage>
          <img
            ref={imgRef}
            className={isLoad ? "activeLazy" : "disableLazy"}
            src={getImageUrl(history.name)}
            alt={history.name}
          />
        </HistoryItemImage>
      </HistoryItemBlock>
    </HistoryItems>
  );
};

export default HistoryItem2;
