/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  getUsers,
} from "../../Context/UserContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useTranslation } from "react-i18next";
// import SubHeader from "../Layout/SubHeader";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import Footer from "./Footer";
import Footer2 from "./Footer2";

const MyPageBlock = styled.div`
  padding: 90px 0;
  height: 80vh;
`;

const Wrapper = styled.div`
  /* padding: 20px 0; */

  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  `}
`;

// const Content = styled.div``;

const UserInfo = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto 50px;
  font-size: 1rem;
  line-height: 1.5;
`;
const UserName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
`;
const UserEmail = styled.div`
  color: #666;
`;

const UserPoint = styled.div`
  padding: 15px 0;

  &::before {
    content: "P";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #0065c3;
    border-radius: 500px;
    color: #fff;
    font-size: 0.875rem;
    text-align: center;
    line-height: 20px;
    margin-right: 10px;
  }
`;

const MyPageList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;

  li {
    width: 50%;
    border: solid 1px #ddd;
    border-right: 0;
    box-sizing: border-box;
    background: #fff url("./Img/arrow-right.svg") no-repeat 95% center;
    background-size: 20px;
  }
  li:nth-child(3) {
    border-right: solid 1px #ddd;
  }
  li a {
    padding: 35px 5%;
  }

  ${Media.tablet`
  width: 100%;
  display: block;
  li{
    width: 100%;
    border: 0;
    border-top: solid 1px #ddd;
  }
  li:last-child{
    border-bottom: solid 1px #ddd;
    border-right: 0;
  }
  `}

  ${Media.mobile`
  `}
`;

// const userdata = {
//   email: "account@gmail.com",
//   name: "홍길동",
//   account_type: "P",
//   phonenumber: "010-1111-2222",
//   password1: "test12345",
//   password2: "test12345",
//   address: {
//     province: "SE",
//     city: "서울",
//     land_lot_number_address: "강남구 100번지",
//     details: "101호",
//   },
//   point: 1500,
//   event: 15,
// };

// const { name, email, point, event } = userdata;

const MyPage = () => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const fetchData = () => {
    // console.log("fetch data");
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
    // else if (!userInfo) getUsers(dispatch);
    getUsers(dispatch);
    // else if (userInfo) return;
    // else if (acc) return;
    // else getUsers(dispatch);
  };

  useEffect(() => {
    // console.log("use effect");
    // fetchData();
    fetchData();
  }, [state.acc]);

  const { t } = useTranslation();

  // if (loading) return <div>로딩중..</div>;
  // if (error) return <h3>로그인 상태가 아닙니다{console.log(error)}</h3>;
  // if (!is_login) return <div>로그인 상태가 아닙니다</div>;
  if (!userInfo) return <DataLoadingStyle fetchData={fetchData} />;

  // console.log(userInfo);
  return (
    <>
      <MyPageBlock>
        <Wrapper>
          {/* <SubHeader title="My page" color="#000" paddingLeft="20px" /> */}
          {is_login ? (
            <>
              <UserInfo>
                <UserName>
                  {t("Hello")} {userInfo.name}
                  {t("end-of-greeting")}
                </UserName>
                <UserEmail>{userInfo.email}</UserEmail>
                {/* 현재까지 누적된 포인트는 {point}P입니다. */}
                <UserPoint className="eng">{userInfo.point}</UserPoint>
              </UserInfo>
              <MyPageList>
                {!localStorage.getItem("social") && (
                  <li>
                    <Link to="pwchange">{t("change-password")}</Link>
                  </li>
                )}
                <li>
                  <Link to="userinfo">{t("change-account-info")}</Link>
                </li>
                <li>
                  <Link to="eventhistory">{t("event-entry-detail")}</Link>
                </li>
              </MyPageList>
            </>
          ) : (
            <h3>로그인 상태가 아닙니다.</h3>
          )}
        </Wrapper>
      </MyPageBlock>
      <Footer2 />
    </>
  );
};

export default MyPage;
