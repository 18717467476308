import React, { useState } from "react";
import styled from "styled-components";
// import Media from "../../Styles/MediaQuery";

const SelectTab = styled.ul`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;

  li {
    padding: 10px 20px;
    display: inline-block;
    border-radius: 500px;
    margin-right: 0.5rem;
    background-color: #ddd;
    color: #999;
    cursor: pointer;
  }

  .active {
    background-color: #0065c3;
    color: #fff;
  }
  .disable {
    background-color: #ddd;
    color: #999;
  }
`;

// const tabData = [
//   { id: 0, title: "LookBook", component: <LookBook />, active: true },
//   { id: 1, title: "MV", component: <MV />, active: false },
// ];

const SelectTabStyle = ({ tabData }) => {
  const [activeTab, setActiveTab] = useState(0);

  const selectActiveTab = (id) => {
    setActiveTab(id);
  };

  // console.log(activeTab);
  let selectComponent = tabData.filter((tab) => tab.id === +activeTab);
  // console.log(selectComponent[0]);

  return (
    <>
      <SelectTab className="eng">
        {tabData.map((tab) => (
          <li
            id={tab.id}
            key={tab.id}
            onClick={() => selectActiveTab(tab.id)}
            className={tab.id === +activeTab ? "active" : "disable"}
          >
            {tab.title}
          </li>
        ))}
      </SelectTab>
      {selectComponent[0].component}
    </>
  );
};

export default SelectTabStyle;
