/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import { Link } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
// import Media from "../../Styles/MediaQuery";

const showHeader = keyframes`
  from {
		top: -100%;
	}

	to {
		top: 0;
	}
`;

const Sentinal = styled.div`
  // DOM element for triggering the intersection observer event
  // you can use 'height' to determine when the event is triggered
  height: 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0);
`;

const HeaderBlock = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 890;

  background-color: rgba(255, 255, 255, 0);
  /* box-shadow: ${(props) =>
    props.show ? "0 10px 30px rgba(0, 0, 0, 0.05)" : null}; */

  display: ${(props) => (props.show ? "none" : "block")};

  ${(props) =>
    props.bgHidden &&
    css`
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    `};
`;

const ScrollHeaderBlock = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 890;

  background-color: ${(props) =>
    props.show ? "#fff" : "rgba(255, 255, 255, 0)"};
  box-shadow: ${(props) =>
    props.show ? "0 10px 30px rgba(0, 0, 0, 0.05)" : null};

  animation-delay: 0s;
  animation-duration: 0.5s;
  /* animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); */

  animation-name: ${(props) => (props.show ? showHeader : null)};

  ${(props) =>
    props.bgHidden &&
    css`
      background-color: rgba(255, 255, 255, 0);
      box-shadow: none;
    `}
`;

const HeaderInner = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;

  display: flex;
  justify-content: space-between;
  z-index: 950;
`;

const Logo = styled.h1`
  margin: 0;
  a {
    width: 30px;
    height: 30px;

    /* background-color: #333; */

    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/header-icon.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    text-indent: -9999px;
  }
`;

const MainMenuIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 5%;

  width: 30px;
  height: 30px;

  z-index: 950;
  cursor: pointer;

  div {
    width: 24px;
    height: 2px;
    background-color: ${(props) => props.color || "#000"};
    position: absolute;
    top: 14px;
    left: 3px;
    transition: all 0.4s;

    ${(props) =>
      props.mainmenu &&
      css`
        background-color: #000;
      `};
  }
`;

const IconBar1 = styled.div`
  transform: ${(props) => (!props.open ? "translateY(-6px)" : "rotate(45deg)")};
  /* transform: translateY(-6px); */
`;
const IconBar2 = styled.div`
  transform: ${(props) =>
    !props.open ? "transform-origin: 0 50% 0" : "scaleX(0)"};
`;
const IconBar3 = styled.div`
  transform: ${(props) => (!props.open ? "translateY(6px)" : "rotate(-45deg)")};
`;

const ScrollMainMenuIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 5%;

  width: 30px;
  height: 30px;

  z-index: 950;
  cursor: pointer;

  div {
    width: 24px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 14px;
    left: 3px;
    transition: all 0.4s;
  }
`;

// .icon_gnb:hover .list_icon.bar1 {
// 	transform: translateY(-10px);
// }

// .icon_gnb:hover .list_icon.bar2 {}

// .icon_gnb:hover .list_icon.bar3 {
// 	transform: translateY(10px);
// }

const HeaderContainer = ({ menuIconColor }) => {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [show, setShow] = useState(false);

  // useEffect(() => {
  //   if (popupState.page === "history" || popupState.page === "event") {
  //     popupSetState({
  //       ...popupState,
  //       menu_button: "#fff",
  //     });
  //     // console.log(popupState);
  //   } else if (popupState.page === "brand" || popupState.page === "magazine") {
  //     popupSetState({
  //       ...popupState,
  //       menu_button: "#000",
  //       page: "",
  //     });
  //   }
  // }, [popupState.mainmenu]);

  const openMainMenuSlider = () => {
    popupSetState({
      ...popupState,
      mainmenu: !popupState.mainmenu,
    });
  };

  const closeMainMenu = () =>
    popupSetState({
      ...popupState,
      mainmenu: false,
    });

  const onIntersect = async ([entry], observer) => {
    if (entry.isIntersecting) {
      setShow(false);

      // observer.unobserve(entry.target);
      // await fetchItems();
      // observer.observe(entry.target);
    } else {
      setShow(true);
      // popupSetState({
      //   ...popupState,
      //   menu_button: "#000",
      // });
    }
  };

  const [target, setTarget] = useState(null);

  useEffect(() => {
    let observer;
    if (target) {
      observer = new IntersectionObserver(onIntersect, { threshold: 0.5 });
      observer.observe(target);
    }
    return () => {
      return observer && observer.disconnect();
    };
  }, [target]);

  return (
    <>
      <Sentinal ref={setTarget} />
      <HeaderBlock show={show}>
        <HeaderInner>
          <Logo onClick={closeMainMenu}>
            <Link to="/">Pepsi</Link>
          </Logo>

          <MainMenuIcon
            color={popupState.menu_button}
            mainmenu={popupState.mainmenu}
            onClick={openMainMenuSlider}
          >
            <IconBar1 open={popupState.mainmenu}></IconBar1>
            <IconBar2 open={popupState.mainmenu}></IconBar2>
            <IconBar3 open={popupState.mainmenu}></IconBar3>
          </MainMenuIcon>
        </HeaderInner>
      </HeaderBlock>
      <ScrollHeaderBlock show={show} bgHidden={popupState.mainmenu}>
        <HeaderInner>
          <Logo onClick={closeMainMenu}>
            <Link to="/">Pepsi</Link>
          </Logo>

          <ScrollMainMenuIcon show={show} onClick={openMainMenuSlider}>
            <IconBar1 open={popupState.mainmenu}></IconBar1>
            <IconBar2 open={popupState.mainmenu}></IconBar2>
            <IconBar3 open={popupState.mainmenu}></IconBar3>
          </ScrollMainMenuIcon>
        </HeaderInner>
      </ScrollHeaderBlock>
    </>
  );
};

export default HeaderContainer;
