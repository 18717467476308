import React from "react";
import styled from "styled-components";
import { MainButton } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";


const FindIDCompleteBlock = styled.div`
  width: 100%;
  padding: 150px 30px 0 30px;

  color: rgb(45, 49, 56);

  h3 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
`;
const FindIDComplete = ({ userEmail, popUpPage, setPopUpPage }) => {
  const onLogin = () => {
    setPopUpPage(1);
  };

    const { t } = useTranslation();

    return (
    <FindIDCompleteBlock>
      <h3>{t("find-account")}</h3>
      {userEmail ? (
        <strong>가입한 이메일은 {userEmail} 입니다.</strong>
      ) : (
        <strong>이메일을 찾지 못하였습니다. 다시 시도해주세요.</strong>
      )}

      <br />
      <MainButton onClick={onLogin} bgColor="#0065c3">
        바로 로그인 하기
      </MainButton>
    </FindIDCompleteBlock>
  );
};

export default FindIDComplete;
