/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM, { render, hydrate } from "react-dom";
import ReactGA from "react-ga";
import "./config/lang/i18n";
import { Router } from "react-router-dom";
// import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { UsersProvider } from "./Context/UserContext";
import { createBrowserHistory } from "history";
import { wrapHistory } from "oaf-react-router";
// import browserUpdate from "browser-update";
import ScrollToTop from "./Utils/ScrollToTop";

ReactGA.initialize("UA-178888868-1");

const history = createBrowserHistory(); // or createHashHistory()

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const settings = {
  shouldHandleAction: (previousLocation, nextLocation) => {
    // Inspect/compare nextLocation and/or previousLocation.
    return true; // false if you don't want scroll restoration.
  },
};

wrapHistory(history, settings);

// browserUpdate({
//   required: {
//     e: -2,
//     i: 11,
//     f: -3,
//     o: -3,
//     s: 10.1,
//     c: "64.0.3282.16817",
//     samsung: 7.0,
//     vivaldi: 1.2,
//   },
//   insecure: true,
// });

ReactDOM.render(
  <Router history={history}>
    <UsersProvider>
      <App />
    </UsersProvider>
  </Router>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
