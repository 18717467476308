import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const Dimmed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.5);
`;

const circle = keyframes`
    0%{
        top:60px;
        height:5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40%{
        height:20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100%{
        top:0%;
    }
`;

const shadow = keyframes`
 0%{
        transform: scaleX(1.5);
    }
    40%{
        transform: scaleX(1);
        opacity: .7;
    }
    100%{
        transform: scaleX(.2);
        opacity: .4;
    }
`;

const Wrapper = styled.div`
  width: 150px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
const Circle = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 50%;
  background-color: #666;
  left: 15%;
  transform-origin: 50%;
  animation: ${circle} 0.5s alternate infinite ease;
  /* 
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
  animation-name: ${circle}; */

  &:nth-child(2) {
    left: 45%;
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
`;
const Shadow = styled.div`
  width: 16px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: ${shadow} 0.5s alternate infinite ease;

  &:nth-child(4) {
    left: 45%;
    animation-delay: 0.2s;
  }
  &:nth-child(5) {
    left: auto;
    right: 15%;
    animation-delay: 0.3s;
  }
`;

const Failed = styled.div`
  position: relative;
  padding-top: 30vh;
  text-align: center;
  z-index: 2;
  h2 {
    font-size: 1.5rem;
    margin: 0 0 10px;
    color: #222;
  }
  button {
    background-color: #222;
    color: #fff;
    padding: 16px 0;
    width: 270px;
    min-width: 270px;
    border: 0;
    cursor: pointer;
    margin-bottom: 10px;
  }
`;

const LoadingStyle = ({ fetchData }) => {
  const [failed, setFailed] = useState(false);
  // console.log("loading");

  setTimeout(() => {
    setFailed(true);
  }, 5000);

  return (
    <>
      {failed ? (
        <Failed>
          <h2>데이터 로딩이 지연되고 있습니다.</h2>
          {fetchData ? (
            <button onClick={fetchData}>다시 시도하기</button>
          ) : null}
          <Link to="/">
            <button>메인으로</button>
          </Link>
        </Failed>
      ) : (
        <Wrapper>
          <Circle />
          <Circle />
          <Circle />
          <Shadow />
          <Shadow />
          <Shadow />
        </Wrapper>
      )}
      <Dimmed />
    </>
  );
};

export default LoadingStyle;
