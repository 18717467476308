import React from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Media from "../../Styles/MediaQuery";
import { MagazineSliderContainer } from "../../Utils/styledComponents";

const MagazineBlock = styled.div`
  padding-top: 60px;
  padding-bottom: 105px;
  h3 {
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 auto 36px;
    max-width: 1280px;
    width: 90%;
    ${Media.mobile`
      width: 84%;
      margin: 0px auto 10px;
      font-size: 14px;
    `}
  }
  ${Media.mobile`
    padding-bottom: 80px;
  `}
`;

const PrevButton = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 50px !important;
  height: 50px !important;
  left: 30px !important;
  ${Media.mobile`
    width: 32px !important;
    height: 32px !important;
    left: 12px !important;
  `}
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  img {
    align-self: center;
    width: 25px;
    height: 25px;
    ${Media.mobile`
      width: 16px;
      height: 16px;
    `}
  }
`;

const NextButton = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 50px !important;
  height: 50px !important;
  right: 30px !important;
  ${Media.mobile`
    width: 32px !important;
    height: 32px !important;
    right: 12px !important;
  `}
  background: rgba(0, 0, 0, 0.6);
  border-radius: 3px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  img {
    align-self: center;
    width: 25px;
    height: 25px;
    ${Media.mobile`
      width: 16px;
      height: 16px;
    `}
  }
`;

const ImgWrapper = styled.div`
  img {
    width: 100%;
    ${Media.mobile`
  height: auto;
`}
  }
`;

const GatoradeMagazine2 = () => {
  const swiperParams = {
    slidesPerView: 6,
    centeredSlides: true,
    spaceBetween: 10,
    mousewheel: false,
    loop: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },

    renderPrevButton: () => (
      <PrevButton className="swiper-button-prev eng">
        <img
          src="/Asset/01FYBC86H6SPZGXDPV7Z29Q8K9/arrow-right.svg"
          alt="left"
        ></img>
      </PrevButton>
    ),
    renderNextButton: () => (
      <NextButton className="swiper-button-next eng">
        <img
          src="/Asset/01FYBC86H6SPZGXDPV7Z29Q8K9/arrow-left.svg"
          alt="right"
        ></img>
      </NextButton>
    ),
    breakpoints: {
      2048: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
      1024: {
        slidesPerView: 4,
        centeredSlides: true,
        spaceBetween: 30,
      },
      980: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 30,
        centeredSlides: true,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 18,
      },
    },
  };

  return (
    <MagazineBlock>
      <h3 className="eng gatorade">Magazine.</h3>
      <MagazineSliderContainer>
        <Swiper {...swiperParams} activeSlideKey="2">
          {[1, 2, 3, 7, 8].map((yuna) => {
            const getImage = () =>
              `/Img/lookbook-gatorade/yuna/yuna-0${yuna}.jpg`;
            return (
              <ImgWrapper key={yuna}>
                <Link to="/gatorade/magazine/lookbook/2022-gatorade-yunakim">
                  <img src={getImage()} alt="yuna" />
                </Link>
              </ImgWrapper>
            );
          })}
        </Swiper>
      </MagazineSliderContainer>
    </MagazineBlock>
  );
};

export default GatoradeMagazine2;
