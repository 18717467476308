import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { AgreementPopup } from "../AgreementPopup";
import { ChallengeBlock } from "./styles";
import _ from "lodash";
import { ZeroSugar } from "./components/zero-sugar";
import { TasteOfKorea } from "./components/taste-of-korea";

const Challenge = ({ wrapper: ParentWrapper }) => {
  const [agreementPopup, setAgreementPopup] = useState(false);
  const { pathname } = useLocation();
  const lastPathname = _.last(pathname.split("/"));

  const isNew = () => {
    console.log(pathname, lastPathname);
    return lastPathname === "pepsi-2022zero" || "2022-pepsi-iu" || "2022-pepsi-black-blue";
  };
  const challengeBgImg = isNew()
    ? `url('/Img/taste-of-korea/cha-bg-pc.jpg')`
    : `url('/Img/zero/coke-bg.jpg')`;

  console.log("isNew", isNew());

  return (
    <ParentWrapper bgImg={challengeBgImg}>
      {isNew() ? (
        <TasteOfKorea />
      ) : (
        <ChallengeBlock>
          <ZeroSugar setAgreementPopup={setAgreementPopup} />
        </ChallengeBlock>
      )}
      <AgreementPopup opened={agreementPopup} setOpened={setAgreementPopup} />
    </ParentWrapper>
  );
};

export default Challenge;
