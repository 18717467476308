/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    useUsersState,
    useUsersDispatch,
    useSocialState,
    useSocialDispatch,
} from '../../Context/UserContext';
import Main2 from '../Pages/Main2';
import Main from '../Pages/Main';
import MainSignUp from '../Pages/MainSignUp';
import Brand from '../Pages/Brand';
import BrandDetail from '../Pages/BrandDetail';
import History from '../Pages/History';
import Event from '../Pages/Event';
import Magazine from '../Pages/Magazine';
import UserContainer from '../Containers/UserContainer';
// import SignUp from "../Pages/SignUp";
import MyPage from '../Pages/MyPage';
import UserInfo from '../Pages/UserInfo';
import EventHistory from '../Pages/EventHistory';
import Quiz from '../Pages/Quiz';
import HistoryDetail from '../Pages/HistoryDetail';
import EventHistoryDetail from '../Pages/EventHistoryDetail';
// import VerticalSlider from "../../Utils/VerticalSlider";
// import Error404 from "../Components/Pages/Error404";
import SelectTabStyle from '../../Styles/SelectTabStyle';
import Postcode from '../../Utils/Postcode';
import PasswordChange from '../Layout/PasswordChange';
import OAuth from '../Items/OAuth';
import Unregister from '../Pages/Unregister';
import Page404 from '../Pages/Page404';
import SelectStyle from '../../Styles/SelectStyle';
import AuthRoute from './AutoRoute';
import Policy from '../Pages/Policy';
import Privacy from '../Pages/Privacy';
import Login2 from '../Pages/Login2';
import Maintenance from '../Pages/Maintenance';
import ModalStyle from '../Pages/ModalStyle';
import InstaFeed from '../Layout/InstaFeed';

/*Gatorade*/
import GatoradeMain from '../Pages/GatoradeMain';
import MagazineGatorade from '../Pages/MagazineGatorade';

import LookBook from '../Layout/LookBook';
import {EventCodeMain} from '../Pages/EventCodeMain';
import {EventCodeFail} from '../Pages/EventCodeFail';
import {EventCodeSuccess} from '../Pages/EventCodeSuccess';
import {EventCodeDelivery} from '../Pages/EventCodeDelivery';
import {EventCodeComplete} from '../Pages/EventCodeComplete';
import {EventCodeFailContents} from '../Pages/EventCodeFailContents';
import {EventCodePreview} from '../Pages/EventCodePreview';
import {ProductSelectPage} from '../Pages/EventExclusive/ProductSelect';
import SamsungGalaxyEvent from '../Pages/SamsungGalaxyEvent';
import Brand2 from '../Pages/Brand2';
import {useLocation} from "react-router";

function AdminRedirector() {
  let location = useLocation();

  useEffect(() => {
    if (location.pathname === '/admin') {
      window.location.href = 'https://web.pep1.kr/admin'; // 백엔드 서버 URL로 대체
    }
  }, [location]);

  return null;
}

const MainRouter = ({checkSocial}) => {
    const state = useUsersState();
    const dispatch = useUsersDispatch();
    const socialState = useSocialState();
    const socialDispatch = useSocialDispatch();

    const {is_login, userInfo} = state;

    // console.log(is_login);
    return (
        <Switch>
          <Route exact path="/" render={(props) => <Main2 {...props} />}/>
          {/* <AdminRedirector/> */}
            <Route exact path="/samsung_galaxy" render={(props) => <SamsungGalaxyEvent {...props} />}/>
            <Route exact path="/qr" component={EventCodePreview}/>
            <Route exact path="/qr/c/:code" component={EventCodeMain}/>

            <Route exact path="/qr/nexttime" component={EventCodeFail}/>
            <Route exact path="/qr/nexttime/contents" component={EventCodeFailContents}/>

            <Route
                exact
                path="/qr/exclusive/select/:code"
                component={ProductSelectPage}
            />

            <Route exact path="/qr/success/:code" component={EventCodeSuccess}/>
            <Route exact path="/qr/delivery/:code" component={EventCodeDelivery}/>
            <Route exact path="/qr/complete" component={EventCodeComplete}/>

            <Route exact path="/brand/:detail" component={BrandDetail}/>
            <Route path="/brand" component={Brand2}/>

            <Route exact path="/gatorade" render={(props) => <GatoradeMain {...props} />}/>
            <Route path="/gatorade/magazine/" component={MagazineGatorade}/>

            {/* <Route path="/historydetail" component={HistoryDetail} /> */}
            {/* https://khouseofpepsi.com/newtropepsicustomer
https://khouseofpepsi.com/newtropepsipartner */}
            <Route path="/newtropepsicustomer" component={Event}/>
            <Route path="/newtropepsipartner" component={Event}/>
            <Route path="/quiz" component={Quiz}/>

            <Route path="/magazine/" component={Magazine}/>

            <Route exact path="/history/:detail" component={HistoryDetail}/>
            <Route path="/history" component={History}/>

            <Route path="/oAuth" component={OAuth}/>
            <Route path="/policy" component={Policy}/>
            <Route path="/privacy" component={Privacy}/>

            {/* <Route path="/modal" component={ModalStyle} /> */}
            <Route path="/insta" component={InstaFeed}/>
            {/* <Route path="/maintenance" component={Maintenance} /> */}
            {/* <Route
        path="/oAuth"
        render={(props) => <OAuth checkSocial={checkSocial} />}
      /> */}

            <AuthRoute
                is_login={is_login}
                path="/mypage"
                render={(props) => <MyPage userInfo={userInfo} {...props} />}
            />
            {/* <AuthRoute
        is_login={is_login}
        path="/oAuth"
        render={(props) => <OAuth {...props} />}
      /> */}
            <AuthRoute
                is_login={is_login}
                path="/pwchange"
                render={(props) => <PasswordChange {...props} />}
            />
            <AuthRoute
                is_login={is_login}
                path="/userinfo"
                render={(props) => <UserInfo {...props} />}
            />
            <AuthRoute
                exact
                is_login={is_login}
                path="/eventhistory/:detail"
                render={(props) => <EventHistoryDetail {...props} />}
            />
            <AuthRoute
                is_login={is_login}
                path="/eventhistory"
                render={(props) => <EventHistory {...props} />}
            />

            <AuthRoute
                is_login={is_login}
                path="/unregister"
                render={(props) => <Unregister {...props} />}
            />

            {/* <Route path="/pwchange" component={PasswordChange} /> */}
            {/* <Route path="/userinfo" component={UserInfo} /> */}

            {/* <Route path="/eventhistory" component={EventHistory} /> */}
            {/* <Route path="/eventhistorydetail" component={EventHistoryDetail} /> */}

            {/* <Route path="/select" component={SelectStyle} /> */}

            {/* <Route
        path="/signup"
        component={MainSignUp}
        setOpenMainMenu={setOpenMainMenu}
        openMainMenu={openMainMenu}
      /> */}

            {/* <Route
        render={({ location }) => {
          return (
            <div>
              <h2>없는 페이지 입니다.</h2>
              <p>{location.pathname}</p>
            </div>
          );
        }}
      /> */}

            {/* <Route path="/tab" component={SelectTabStyle} />
      <Route path="/post" component={Postcode} /> */}

            <Route component={Page404}/>
        </Switch>

    );
};

export default MainRouter;
