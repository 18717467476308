import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import Media from "./MediaQuery";

const GlobalStyles = createGlobalStyle`


    ${reset};
    /* a {
        text-decoration: none;
        color: inherit;
    } */

    body *, body *::before, body *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'sofia_pro_bold', 'noto sans kr', '맑은 고딕', 'Malgun Gothic', sans-serif; 
    outline: none;
    }
    
    /* agreement*/
    .agree-all{
        font-weight: bold;
        margin: 50px 0 40px;
    }
    .agree-all label{
        font-size: 1.2rem;
    }
    
    /* social btn color */
    .naver{
    background: #1EC800 url(/Img/login-icon_naver.svg) no-repeat  5% center;
    background-size: 20px;
    color: #fff;
    width: 100%;

    margin-top: 5px;
    padding: 20px;
    text-align: center;
    border: 1px;
    cursor: pointer;
    }
    .kakao{
    background: #fae100 url(/Img/login-icon_kakao.png) no-repeat  5% center;
    background-size: 20px;
    color: #000;
    }
    .google{
    background: #fff url(/Img/login-icon_google.svg) no-repeat  5% center;
    background-size: 20px;
    color: #333;
    border: solid 1px #ddd !important; 
    box-sizing: border-box;
    }
    .facebook{
    background: #4267b2 url(/Img/login-icon_facebook.png) no-repeat  5% center;
    background-size: 20px;
    color: #fff;
    }
    /* Mainmenu style */
    .customMainmenuOverlay {
    padding: 0px;
    animation: none !important;
    z-index: 800;
    }
    .customMainmenu {
    width: 100%;
    max-width: 100%;
    margin: 0;
    min-height: 100vh;
    animation: none !important;
    /* background-color: #ccc; */

        ${Media.tablet`
        `}
    }
    .customMainmenu2 {
    padding: 0;
    }
    .customBlur {
    padding: 0;
    background: rgba(255,255,255,0.9);
    backdrop-filter: blur(5px);
    ${Media.mobile`
        padding: 0;
    `}
    }
    .customMainCloseBtn {
        display: none;
    }
    /* Modal style */
    .customOverlay {
    padding: 0px;
    animation: none !important;
    overflow-y: scroll;
    }   
    .customModal {

    width: 500px;
    min-height: 700px;
    padding: 60px 20px;
    animation: none !important;
    top: 7%;
   

        ${Media.tablet`

        width: 100%;
        min-height: 100vh;
        top: 0;
        `}
    }
    .customCloseBtn {
        width: 28px;
        height: 28px;


        ${Media.tablet`

`}

    }
    .customEnterAnimation {
        }
    .customLeaveAnimation {
      
    }
    
    
    /* Modal style */
    .eventOverlay {
    padding: 0px;
    animation: none !important;
    overflow-y: scroll;
    }   
    
    .eventModal {

    width: 500px;
    padding: 70px 30px;
    animation: none !important;
    top: 7%;
    text-align: center;
    
    h2 {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 1em;
    }
        
    p  {
        line-height: 1.5em;
        word-break: keep-all;
    }    
   

        ${Media.tablet`
    
            width: 100%;
            top: 20%;
            `}
            
            ${Media.mobile`
    
            width: 100%;
            top: 20%;
            `}
        }
    
    .eventCloseBtn {
        width: 28px;
        height: 28px;


        ${Media.tablet`

        `}
    }
    
    
    .mainModal {

    width: 550px;
    padding: 0;
    margin: 0;
    animation: none !important;
    top: 5%;
    left: 20%;
    text-align: center;
    background: unset;
   
   

        ${Media.tablet`
    
            width: 50%;
            top: 5%;
            `}
            
            ${Media.mobile`
    
            width: 85%;
            top: 20%;
            left: 7%;
            `}
        }
    
    .mainCloseBtn {
        width: 28px;
        height: 28px;
        fill: #fff;
    }
    
    .mainOverlay {
    background: rgba(0, 0, 0, 0.1);
    }   
    
    .challengeModal {

    width: 550px;
    padding: 0;
    margin: 0;
    animation: none !important;
    top: 10%;
    left: 20%;
    text-align: center;
    background: unset;
   
   

        ${Media.tablet`
    
            width: 50%;
            top: 5%;
            `}
            
            ${Media.mobile`
    
            width: 85%;
            top: 20%;
            left: 7%;
            `}
        }
    
    .challengeCloseBtn {
        width: 28px;
        height: 28px;
        fill: #fff;
    }
    
    .challengeOverlay {
    background: rgba(0, 0, 0, 0.1);
    }   
    
    /* address modal */
    .postcodeBlock>div>iframe {
        padding: 40px 10px 20px 10px !important;
    }
    .customAddressOverlay {
    padding: 0px;
    animation: none !important;
    overflow-y: scroll;
    }
    .customAddressModal {
    position: relative;
    width: 500px;
    min-height: 700px;
    animation: none !important;
    top: 7%;

    ${Media.tablet`

    width: 100%;
    min-height: 100vh;
    top: 0;
    `}
}
    /* input style - address */
    .input-address{
    width: calc(100% - 80px);
    display: inline-block;
    vertical-align: middle;
    }
    /* .btn-address{
    width: 80px;
    height: 50px;
    vertical-align: middle;
    background: #0065c3 url(./Img/search-icon.svg) no-repeat center;
    background-size: 20px;
    text-indent: -99999px;
    
    } */
    
    /* quiz answer*/
    .centered{
        text-align: center;
        font-size: 1.2rem !important;
    }
    
    .popup-btn-small{
        padding: 4px 0;
    }
    
    .answer-img{
        width: 60px;
        margin: 10px auto;
        display: block;
    }
    
    /* vertical slider dots */

    .ver-slider-dots {
    position: fixed;
    top: calc(50vh - 80px);
    right: 5%;
    display: block;
    width: 20px;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    }

    .ver-slider-dots li {
    position: relative;
    /* display: inline-block; */
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
    }

    .ver-slider-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    }

    /* button::-moz-focus-inner, input::-moz-focus-inner {
    padding: 0;
    border: 0;
    } */
    .ver-slider-dots li.slick-active button::before {
        font-size: 15px;
        opacity: .75;
        color: black;
    }
    .ver-slider-dots li button:hover::before {
        font-size: 12px;
        opacity: .75;
        color: black;
    }

    .ver-slider-dots li button::before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        content: '•';
        text-align: center;
        opacity: .75;
        color: black;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    /* history slider dots */
    .his-slider-dots {
    position: absolute;
    bottom: 23px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    }

    .his-slider-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    }

    .his-slider-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    }

.his-slider-dots li.slick-active button::before {
    opacity: 1;
    color: #0065c3;
}
.his-slider-dots li button::before {
    font-family: 'slick';
    font-size: 7px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
    .nextArrow::after {
        content: "NEXT";
        color: #fff;
        font-size: 0.6rem;
    }
    @media all and (max-width: 768px){
        .nextArrow, .prevArrow{
            padding: 16px 16px !important;
            
        }
        .nextArrow::after, .prevArrow::after{
            font-size: 0.875rem !important;
        }
    }
    
    
    .nextArrow {
        padding: 12px 10px 17px 13px;
        position: absolute;
        top: calc(50% - 30px);
        right: 0;
        background: #000;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        z-index: 200;
        cursor: pointer;
    }

    
    .prevArrow::after {
        content: "PREV";
        color: #fff;
        font-size: 0.6rem;
    }
    .prevArrow {
        padding: 12px 13px 17px 10px;
        position: absolute;
        top: calc(50% - 30px);
        left: 0;
        background: #000;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        z-index: 200;
        cursor: pointer;
    }
    
    .SRLElementWrapper {
        width: 100% !important;
        height: 100% !important;
    }

    .SRLNextButton {
        display: block !important;
        width: 53px !important;
        height: 53px !important;
        display: flex !important;
        justify-content: center !important;
        border-radius: 3px !important;
    }
    .SRLPrevButton {
        display: block !important;
        width: 53px !important;
        height: 53px !important;
        display: flex !important;
        justify-content: center !important;
        border-radius: 3px !important;
    }

    .SRLNextButton svg {
        width: 14px !important;
        height: 14px !important;
    }
    .SRLPrevButton svg {
        width: 14px !important;
        height: 14px !important;
    }
    
    /* goods */
    .goods-5{
        justify-content: flex-start !important;
    }
    .goods-5 li{
        width: 19% !important;
        margin-right: 1%;
        margin-bottom: 1%;
    }
    /*.goods-5 li:nth-child(5){
        margin-right: 0;
    }*/
    
    .goods-info-small{
        padding-bottom: 50px;
    }
    .goods-info-small p{
        font-size: 0.875rem;
      margin-bottom: 0 !important;
      color: rgba(255,255,255,0.8) !important;
      transform: translateY(-50px);
      word-break: keep-all;
      
      ${Media.mobile`
          text-align: left;
          margin: 10px 0;
         
      `}
    }
       
    /*select*/
    select{
        -webkit-appearance: none; /* 네이티브 외형 감추기 */ 
        -moz-appearance: none; 
        appearance: none; 
        background: url(/Img/arrow_open.png) no-repeat 98% 50%;
        background-size: 10px;
        border: solid 1px #ddd;
        text-indent: 10px;
    }
    select::-ms-expand { display: none; }
    
    .title-insta::before{
        content: '';
        display: inline-block;
        width: 26px;
        height: 26px;
        background: url(/Img/sns-insta-dark.svg) no-repeat center;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px;
        margin-bottom: 2px;
    }
    
    /* footer */
    .footer-sns{ display: flex; }
    .footer-sns a{ display: block; margin: 0 5px; }
    /* .footer-sns a img{ width: 30px; } */
    .footer-sns a img{ width: 25px; }
    
    /* arco */
    .MuiAccordionSummary-content{ margin: 0 0 !important; }

    /* history detail slider dots */
    .text-slider-dots {
    position: absolute;
    left: -5px;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
    }

    .text-slider-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
    }

    .text-slider-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    }

.text-slider-dots li.slick-active button::before {
    opacity: 1;
    color: #000;
}
.text-slider-dots li button::before {
    font-family: 'slick';
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
   
    /* event slider */
    .eventSlider .slick-slide {
        padding: 5px;
    }

    .eventSlider .his-slider-dots {
        bottom: -20px;
    }

    /* .eventSlider .prevArrow {
        left: 19%;
        top: calc(50% - 35px);
        background-color: transparent;
    }
    .eventSlider .nextArrow {
        right: 15%;
        top: calc(50% - 35px);
        background-color: transparent;
    } */
    /* .eventSlider .prevArrow::after {
        content: "<";
        color: #999;
        font-size: 3rem;
        font-family: 'Catamaran', sans-serif;
    }
    .eventSlider .nextArrow::after {
        content: ">";
        color: #999;
        font-size: 3rem;
        font-family: 'Catamaran', sans-serif;
    } */

    .eventSlider .nextArrow::after {
        content: "NEXT";
        color: #fff;
        font-size: 0.6rem !important;
    }
    .eventSlider .nextArrow {
        padding: 12px 10px 17px 13px !important;
        position: absolute;
        top: calc(50% - 20px);
        right: 0;
        background: #000;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        z-index: 200;
        cursor: pointer;
    }
    .eventSlider .prevArrow::after {
        content: "PREV";
        color: #fff;
        font-size: 0.6rem !important;
    }
    .eventSlider .prevArrow {
        padding: 12px 13px 17px 10px !important;
        position: absolute;
        top: calc(50% - 20px);
        left: 0;
        background: #000;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        z-index: 200;
        cursor: pointer;
    }

    /* lazy loading */
  .activeLazy {
    opacity: 1;
    transition: opacity 1s;
  }
  .disableLazy {
    opacity: 0;
  }
    /* CLEAR FIX */
    .clearfix::after {
    display: block;
    content: '';
    clear: both;
    }

    button {
    cursor: pointer;
    }

    /* custom */
    .eng, .eng * {
        font-family: 'MontrealTS', sans-serif;
        text-transform: uppercase;
        font-weight: bold;
    }
    .blue{
        background-color: #0065c3 !important;
        color: #fff !important;
    }
    
    .subtitle{
        font-size: 2.4rem;
        text-align: left;
        margin-bottom: 2rem;
    }
    .subtitle.members{
        text-align: center;
    }
    .subtitle span{
        font-size:1rem;
        display:block;
        margin-top: 0.4rem;
    }
    
    .subpage_tab{
        margin-bottom: 2rem; 
    }
    
    .subpage_tab li{
        padding: 16px 20px;
        display: inline-block;
        border-radius: 500px;
        background-color: #ddd;
        color: #999;
        margin-right: 0.5rem;
    }

    /* reset css */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }

    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
`;

export default GlobalStyles;
