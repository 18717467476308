/* eslint-disable react/prop-types */
import { Global } from "@emotion/core";
import React from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { HistorySliderContainer } from "../../Utils/styledComponents";
import { globalStyles } from "../../Utils/stylesConfig";
import HistoryItem2 from "../Items/HistoryItem2";
import { historyData } from "../../Constants/HistoryData";

const HistorySliderBlock = styled.div`
  padding-top: 23px;
  padding-bottom: 114px;
`;

const HistoryListBlock = styled.div`
  position: relative;
`;

const HistorySlider2 = ({ ...rest }) => {
  const swiperParams = {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 29,
    mousewheel: false,
    loop: false,
  
    breakpoints: {
      1024: {
        slidesPerView: 4.3,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
    },
  };

  return (
    <HistorySliderBlock {...rest}>
      <HistoryListBlock>
        <HistorySliderContainer>
          <Global styles={globalStyles} />
          <Swiper {...swiperParams} activeSlideKey='1960'>
            {historyData.map((history) => (
              <Link key={history.name} to={`/history/${history.id}`}>
                <HistoryItem2
                  history={history}
                  key={history.name}
                />
              </Link>
            ))}
          </Swiper>
        </HistorySliderContainer>
      </HistoryListBlock>
    </HistorySliderBlock>
  );
};

export default HistorySlider2;
