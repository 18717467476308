import React from 'react'
import styled from 'styled-components'
import { useEffect, useState } from "react"
import InnerHTML from "dangerously-set-html-content";

const Wrapper = styled.div`
    position: relative;
    cursor: pointer;
    &:hover{
        &::after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0.3);
            pointer-events: none;
        }
    }
`

const PlayButton = styled.span`
    pointer-events: none;   
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -48px;
    margin-top: -48px;
    z-index: 9;
    display: block;
    width: 96px;
    height: 96px;
    cursor: pointer;
    background-image: url("https://sf16-scmcdn-sg.ibytedtos.com/goofy/tiktok/web/node/_next/static/images/img-embed-controller-play-50633fd697e3a54c76c42c2a03aab3ea.svg");
    background-size: contain;
`
const Image = styled.img`
    width: 100%;
`

const Background = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.8);
    z-index: 9999;
`

const Card = styled.div`
    background: white;
    max-width: 605px;
    width: 100%;
    padding: 32px 0;
    position: relative;

    @media (max-width: 767px) {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const XButton = styled.div`
    @media (min-width: 768px) {
        position: absolute !important;
        top: 0 !important;
        margin-left: 309px !important;
    }
`

export const TiktokFeed = ({url}) => {
    const [data, setData] = useState(null)
    const [modalOpened, setModalOpened] = useState(false)
  
    useEffect(()=>{
      let isMounted = true
      fetch(`https://www.tiktok.com/oembed?url=${url}`)
      .then(res => res.json())
      .then(res => {
        if(isMounted){
          setData(res)
        }
      })
      return () => {
        isMounted = false
      }
    }, [url])
  
    useEffect(() => {
      document.getElementsByTagName('html')[0].style.overflow = modalOpened ? 'hidden' : 'auto'
  
    }, [modalOpened])
    const onImgClickToggle = () => {
      setModalOpened(!modalOpened)
    }
  
    if(!data) return null;
    return (
      <Wrapper>
        <PlayButton className="play-button"/>
        <Image src={data.thumbnail_url} onClick={onImgClickToggle}/>
        {modalOpened && (
          <Background
          onClick={onImgClickToggle}
          >
            <Card >
                <XButton 
                    onClick={onImgClickToggle}
                    className="eapps-instagram-feed-popup-close"
                />
              <InnerHTML html={data.html}/>
            </Card>
          </Background>
        )}
      </Wrapper>
    )
  }
  