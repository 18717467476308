import React from "react";
import styled from "styled-components";
import Media from "./MediaQuery";

export const MainButton = styled.button`
  /* width: auto;
  min-width: 50%; */
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }
  ${Media.tablet`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  `}

  ${Media.mobile`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  `}
`;

export const StaticButton = styled.button`
  /* width: auto;
  min-width: 50%; */
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }
  ${Media.tablet`

  `}

  ${Media.mobile`

  `}
`;

export const MainButton2 = styled.button`
  /* width: auto;
  min-width: 50%; */
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }
  ${Media.tablet`
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  `}

  ${Media.mobile`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  `}
`;

export const ModalButton = styled.button`
  /* width: auto;
  min-width: 50%; */
  /* position: fixed;
  left: 0;
  right: 0;
  bottom: 0; */
  position: static;
  width: 100%;

  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }
  ${Media.tablet`
  position: fixed;
  left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 700;
  `}

  ${Media.mobile`
  position: fixed;
  left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 700;
  `}
`;

export const EventButton = styled.button`
  position: static;
  width: auto;
  min-width: 50%;
  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#000")};
  color: #fff;
  cursor: pointer;
  margin: 0 auto;
  display: block;

  &:disabled {
    background-color: #999;
    color: #ddd;
  }

  ${Media.tablet`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 30px 20px;
  `}

  ${Media.mobile`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 700;
    padding: 20px;
  `}
`;

export const MainButtonStyle = ({ text }) => {
  return <MainButton>{text}</MainButton>;
};
