/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Media from "../../Styles/MediaQuery";
import Swiper from "react-id-swiper";
import { SlideContainer } from "../../Utils/styledComponents";
import styled from "styled-components";
import { brandData } from "../../Constants/BrandData";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";

const SwiperSlide = styled.li`
  background-image: url(${(props) => props.mainImg || null});
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.name === "retro" ? "center bottom" : "center center"};
  background-size: cover;

  ${Media.mobile`
    background-image: url(${(props) => props.mobileMainImg || null});
  `}

  a {
    display: block;
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    padding: 150px 0 0 100px;
    color: ${(props) => props.textColor || "#fff"};

    ${Media.mobile`
    padding: 100px 0 0 40px;
  `}
  }

  h1 {
    font-size: 56px;
    margin: 10px 0;
  }

  em {
    /* margin-left: 30px; */
    font-family: "noto sans kr";
    font-size: 22px;
    font-weight: 400;
  }

  h2 {
    font-size: 56px;
    position: absolute;
    bottom: 200px;
    ${Media.mobile`
    position: static;
  `}
  }

  strong {
    position: absolute;
    bottom: 168px;
    /* margin-left: 30px; */
    font-family: "noto sans kr";
    font-size: 22px;
    font-weight: 400;
    ${Media.mobile`
    position: static;
  `}
  }
`;

const SlideBlock = styled.div``;

const Slider = ({ params }) => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const brandSwiperRef = useRef(null);
  const [wheel, setWheel] = useState(0);

  const setMenuIconColor = () => {
    if (brandSwiperRef.current.swiper.activeIndex === 2)
      popupSetState({
        ...popupState,
        menu_button: "#000",
      });
    else
      popupSetState({
        ...popupState,
        menu_button: "#FFF",
      });
  };

  useEffect(() => {
    setMenuIconColor();
  }, [wheel]);

  const catchWheel = (e) => setWheel(e.deltaY);

  return (
    <SlideBlock onWheel={catchWheel}>
      <SlideContainer>
        <Swiper {...params} ref={brandSwiperRef}>
          <SwiperSlide
            tag={"li"}
            key={"item" + brandData[0].id}
            id={"item" + brandData[0].id}
            mainImg={brandData[0].mainImg}
            mobileMainImg={brandData[0].mobileMainImg}
            textColor={brandData[0].textColor}
            // style={{ backgroundColor: bgColor }}
          >
            <Link
              key={"key" + brandData[0].id}
              to={`/brand/${brandData[0].id}`}
            >
              <h1>GREAT TASTE,<br/>ZERO SUGAR</h1>
              <em>{brandData[0].subCopy}</em>
            </Link>
          </SwiperSlide>
          <SwiperSlide
            tag={"li"}
            key={"item" + brandData[1].id}
            id={"item" + brandData[1].id}
            mainImg={brandData[1].mainImg}
            mobileMainImg={brandData[1].mobileMainImg}
            textColor={brandData[1].textColor}
            // style={{ backgroundColor: bgColor }}
          >
            <Link
              key={"key" + brandData[1].id}
              to={`/brand/${brandData[1].id}`}
            >
              <h2>{brandData[1].subCopy}</h2>
              {/*<strong>{brandData[1].subCopy}</strong>*/}
            </Link>
          </SwiperSlide>
          <SwiperSlide
            tag={"li"}
            key={"item" + brandData[2].id}
            id={"item" + brandData[2].id}
            mainImg={brandData[2].mainImg}
            mobileMainImg={brandData[2].mobileMainImg}
            textColor={brandData[2].textColor}
            name="retro"
          >
            <Link
              key={"key" + brandData[2].id}
              to={`/brand/${brandData[2].id}`}
            >
              <h1>RETRO PEPSI</h1>
              <em>{brandData[2].subCopy}</em>
            </Link>
          </SwiperSlide>
        </Swiper>
      </SlideContainer>
    </SlideBlock>
  );
};

export default Slider;
