import React from "react";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";

const BrandModalSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 90%;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1100px;
`;

const BrandCloseButton = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: -5%;
  ${Media.mobile`
    top: -14%;
  `}
  div {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/close.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    margin-right: 64px;
    ${Media.mobile`
      margin-right: 12px;
    `}
  }
`;

const BrandImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  ${Media.mobile`
    display: grid;
    flex-wrap: nowrap;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
  `}
`;

const CanImg = styled.div`
  display: flex;
  flex-direction: column;
  width: 242px;
  margin: 32px 15px;
  ${Media.mobile`
    width: 100%;
    margin: 10px 0;
  `}
  img {
    cursor: pointer;
    width: 100%;
  }
  em {
    align-self: center;
    font-size: 16px;
    padding-top: 10px;
    ${Media.mobile`
      font-size: 12px;
    `}
  }
`;

const BrandImageData = [
  { id: 1, canImg: "zero", link: "/brand/1", name: "Pepsi zero sugar" },
  { id: 2, canImg: "regular", link: "/brand/2", name: "Pepsi regular" },
  { id: 3, canImg: "40", link: "/history/history40", name: "1940’" },
  { id: 4, canImg: "50", link: "/history/history50", name: "1950’" },
  { id: 5, canImg: "60", link: "/history/history60", name: "1960’" },
  { id: 6, canImg: "80", link: "/history/history80", name: "1980’" },
  { id: 7, canImg: "90", link: "/history/history90", name: "1990’" },
];

const BrandModal = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const closeBrandModal = () =>
    popupSetState({
      ...popupState,
      mainmenu: false,
    });

  const closePopUp = () => {
    popupSetState({
      ...popupState,
      login: false,
      signup: false,
      mainmenu: false,
      event_login: false,
      brand: false,
    });
  };

  return (
    <Modal
      open={popupState.brand}
      onClose={closeBrandModal}
      overlay
      classNames={{
        overlay: "customMainmenuOverlay",
        modal: "customMainmenu customBlur",
        animationIn: "customEnterAnimation",
        animationOut: "customLeaveAnimation",
        closeButton: "customMainCloseBtn",
      }}
      animationDuration={0}
    >
      <BrandModalSection>
        <Wrapper>
          <BrandCloseButton>
            <div onClick={closePopUp}></div>
          </BrandCloseButton>
          <BrandImages>
            {BrandImageData.map((img) => (
              <Link className="eng" to={img.link} onClick={closePopUp}>
                <CanImg>
                  <img
                    src={`/Asset/01FY5VRPZ40X1B33XEG97Z6JEW/${img.canImg}.png`}
                    alt=""
                  />
                  <em>{img.name}</em>
                </CanImg>
              </Link>
            ))}
          </BrandImages>
        </Wrapper>
      </BrandModalSection>
    </Modal>
  );
};

export default BrandModal;
