import styled from 'styled-components';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import sadFace from './event-code-assets/sad-face.svg';
import Media from '../../Styles/MediaQuery';
import { useHistory, useLocation } from 'react-router';
import prize1 from './event-code-assets/prize1_without_num.svg';
import prize2 from './event-code-assets/prize2_without_num.svg';
import prize3 from './event-code-assets/prize3_without_num.svg';
import prize4 from './event-code-assets/prize4_without_num.svg';
import prize5 from './event-code-assets/exclusive_without_num-1.png';
import prize6 from './event-code-assets/exclusive_without_num-2.png';
import prize7 from './event-code-assets/exclusive_without_num-3.png';
import prize8 from './event-code-assets/exclusive_without_num-4.png';
import qs from 'query-string';

const Container = styled.div`
  margin-top: 60px;
  background: #094f9b;
  padding: 8vw;
  min-height: calc(100vh - 90px - 56px);
  ${Media.iphoneP`
      
    `}
  ${Media.iphoneS`
      padding: 30px;
    `}
`;

const KorTitle = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  ${(props) => (props.small ? 'font-size: 5vw;' : 'font-size: 7vw;')}

  line-height: 140%;
  color: #ffffff;
  margin-bottom: 5vw;
`;

const KorTitleC = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 3.2vw;
  background: rgba(255, 255, 255, 0.7);
  padding: 4vw 6vw;
  line-height: 2;
  margin-bottom: 5vw;
  word-break: keep-all;
`;

const SadFace = styled.img`
  margin-bottom: 39px;
  max-width: 124px;
  width: 30%;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 5vw;
  margin-top: 14px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 30px;
  text-transform: capitalize;

  color: #ffffff;
`;
export const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;
const PrizeImage = styled.img`
  width: 100%;
`;
export const EventCodeSuccess = (props) => {
  const history = useHistory();
  const code = props.match.params.code;

  const location = useLocation();
  const { giveaway_name, giveaway_code } = qs.parse(location.search);

  let prizeNum = 1;
  switch (giveaway_code) {
    case 'A':
      prizeNum = 1;
      break;
    case 'B':
      prizeNum = 2;
      break;
    case 'C':
      prizeNum = 3;
      break;
    case 'D':
      prizeNum = 3;
      break;
    default:
      prizeNum = '';
      break;
  }
  return (
    <div>
      <Container>
        {giveaway_code === 'A' && (
          <KorTitle>
            축하합니다!
            <br />
            {prizeNum}등에 당첨되셨어요!
          </KorTitle>
        )}

        {giveaway_code === 'B' && (
          <KorTitle>
            축하합니다!
            <br />
            {prizeNum}등에 당첨되셨어요!
          </KorTitle>
        )}

        {giveaway_code === 'C' && (
          <KorTitle>
            축하합니다!
            <br />
            {prizeNum}등에 당첨되셨어요!
          </KorTitle>
        )}

        {giveaway_code === 'D' && (
          <KorTitle>
            축하합니다!
            <br />
            {prizeNum}등에 당첨되셨어요!
          </KorTitle>
        )}

        {giveaway_code === 'E' && (
          <KorTitle>
            축하합니다!
            <br />
            경품에 당첨되었습니다!
          </KorTitle>
        )}

        {giveaway_code === 'F' && (
          <KorTitle>
            축하합니다!
            <br />
            경품에 당첨되었습니다!
          </KorTitle>
        )}

        {giveaway_code === 'G' && (
          <KorTitle>
            축하합니다!
            <br />
            경품에 당첨되었습니다!
          </KorTitle>
        )}

        {giveaway_code === 'H' && (
          <KorTitle>
            축하합니다!
            <br />
            경품에 당첨되었습니다!
          </KorTitle>
        )}

        {giveaway_code === 'A' && (
          <>
            <PrizeImage src={prize1} />
            <PrizeDesc>1등 상품. 스마트 워치</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {giveaway_code === 'B' && (
          <>
            <PrizeImage src={prize2} />
            <PrizeDesc>2등 상품. 블루투스 이어폰</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {giveaway_code === 'C' && (
          <>
            <PrizeImage src={prize3} />
            <PrizeDesc>3등 상품. 펩시 굿즈 랜덤발송</PrizeDesc>
            <KorTitleC>
              당첨되신 경품의 경우, 현재 생산 공정의 문제로 9월말 일괄 배송으로
              일정이 미뤄지게 됐습니다. 이점 양해 부탁 드리며, 참여해주셔서
              감사합니다.
            </KorTitleC>
          </>
        )}
        {giveaway_code === 'D' && (
          <>
            <PrizeImage src={prize4} />
            <PrizeDesc>3등 상품. 기프티콘 랜덤발송</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {/*QR for exclusive*/}
        {giveaway_code === 'E' && (
          <>
            <PrizeImage src={prize5} />
            <PrizeDesc>펩시 굿즈. 플레이트</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {giveaway_code === 'F' && (
          <>
            <PrizeImage src={prize6} />
            <PrizeDesc>펩시 굿즈. 펩시 유리컵</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {giveaway_code === 'G' && (
          <>
            <PrizeImage src={prize7} />
            <PrizeDesc>펩시 굿즈. 펩시 스푼/포크/나이프 세트</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
        {giveaway_code === 'H' && (
          <>
            <PrizeImage src={prize8} />
            <PrizeDesc>펩시 굿즈. 테이블 매트</PrizeDesc>
            <KorTitle small>
              지금 바로 경품 수령 정보를
              <br />
              입력해주세요!
            </KorTitle>
          </>
        )}
      </Container>
      <CheckResultButton
        bottom
        onClick={() =>
          history.push(`/qr/delivery/${code}?giveaway_code=${giveaway_code}`)
        }
      >
        경품 수령 정보 입력하기
      </CheckResultButton>
    </div>
  );
};
