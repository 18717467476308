/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
// swiper
import { globalStyles } from "../../Utils/stylesConfig";
import { Global } from "@emotion/core";
import Slider from "../Layout/BrandSliderNew";
// meta
import Meta from "../../Utils/Meta";

const BrandBlock = styled.div``;

const Wrapper = styled.div`
  ${Media.tablet`
  `}

  ${Media.mobile`
  `}
`;

const Brand = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#fff",
      page: "brand",
    });
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const metaData = {
    title: "K하우스오브펩시 :: 브랜드",
    description: "펩시콜라 제품의 브랜드 소개",
    canonical: "",
    keyword:
      "K하우스오브펩시,하우스오브펩시,한국펩시콜라,펩시콜라,pepsi,khouseofpepsi,pepsi-korea,pepsicola,pesi cola, 진영 요한과 함께한 펩시 한정판 선물 이벤트, 펩시헤리티지, 펩시 브랜드 소개, 펩시 브랜드",
  };

  return (
    <>
      <Meta data={metaData} />
      <BrandBlock>
        <Wrapper>
          <Global styles={globalStyles} />
          <Slider
            params={{
              direction: "vertical",
              slidesPerView: 1,
              spaceBetween: 0,
              mousewheel: true,
            }}
          />
        </Wrapper>
      </BrandBlock>
    </>
  );
};

export default Brand;
