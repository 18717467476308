export const MVData2 = [
  {
    id: 1,
    title: "2022 pepsi black & blue",
    path: "2022-pepsi-black-blue",
    videos: [
      {
        title: "[TEASER] 'OHMYGIRL' 효정, 아린 X 'IVE' 장원영, 이서 X 'CRAVITY' 세림, 정모 - BLUE & BLACK\n",
        url: "https://www.youtube.com/embed/80yCp0pwg-E",
      },
      {
        title: "[MV] 'OHMYGIRL' 효정, 아린 X 'IVE' 장원영, 이서 X 'CRAVITY' 세림, 정모 - BLUE & BLACK\n",
        url: "https://www.youtube.com/embed/ur6IoC1B3-M",
      },
      {
        title: "[Making Film] 'OHMYGIRL' 효정, 아린 X 'IVE' 장원영, 이서 X 'CRAVITY' 세림, 정모 - BLUE & BLACK 녹음실\n",
        url: "https://www.youtube.com/embed/8VsLgCdwdUE",
      },
      {
        title: "[Making Film] 'OHMYGIRL' 효정, 아린 X 'IVE' 장원영, 이서 X 'CRAVITY' 세림, 정모 - BLUE & BLACK\n",
        url: "https://www.youtube.com/embed/4jR6GEXhQ5k",
      },
      {
        title: "[SPOILER INTERVIEW] 'OHMYGIRL' X 'IVE' X 'CRAVITY ' - 2022 PEPSI X STARSHIP K-POP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/1Qr8Q5n6NTY",
      },
      {
        title: "[COMING SOON] 아이브 (IVE) - 2022 PEPSI X STARSHIP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/KACD4I0Zigs",
      },
      {
        title: "[COMING SOON] 오마이걸 (OHMYGIRL) - 2022 PEPSI X STARSHIP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/L4vY-Z-yqkU",
      },
      {
        title: "[COMING SOON] 크래비티 (CRAVITY) - 2022 PEPSI X STARSHIP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/GFjMa8za400",
      },
      {
        title: "[COMING SOON] 2022 PEPSI X STARSHIP CAMPAIGN\n",
        url: "https://www.youtube.com/embed/dNzWu-B6GdM",
      },
      {
        title: "'IVE' 장원영, 이서 - BLUE & BLACK (BLACK ver.)\n",
        url: "https://www.youtube.com/embed/z7AaQsZMtDA",
      },
      {
        title: "'OHMYGIRL' 효정, 아린 - BLUE & BLACK (BLUE ver.)\n",
        url: "https://www.youtube.com/embed/fokWNTcZ8Ao",
      },
      {
        title: "[Making Film] 'OHMYGIRL' 효정, 아린 X 'IVE' 장원영, 이서 X 'CRAVITY' 세림, 정모 - BLUE & BLACK Photoshoot Behind\n",
        url: "https://www.youtube.com/embed/nKipzyCOhWQ",
      }
    ],
  },
  {
    id: 2,
    title: "2021 taste of korea",
    path: "2021-taste-of-korea",
    videos: [
      {
        title: "[MV] RAIN,MONSTA X,BraveGirls,ATEEZ(비,몬스타엑스,브레이브걸스,에이티즈) _ Summer Taste",
        url: "https://www.youtube.com/embed/BUjI4XPPfh0",
      },
      {
        title: "[Teaser] RAIN(비) X MONSTA X(몬스타엑스) X Brave Girls(브레이브걸스) X ATEEZ(에이티즈) - Summer Taste",
        url: "https://www.youtube.com/embed/I2y543LT2xQ",
      },
      {
        title: "[Making Film] [PEPSIxSTARSHIP] 2021 PEPSI TASTE OF KOREA CAMPAIGN",
        url: "https://www.youtube.com/embed/a4BSFAGwIKY",
      },
    ],
  },
  {
    id: 3,
    title: "2021 pepsi zero sugar",
    path: "2021-pepsi-zero-sugar",
    videos: [
      {
        title: "[MV] 소유 (SOYOU) X 아이즈원 (IZ*ONE) - ZERO:ATTITUDE (Feat.pH-1)",
        url: "https://www.youtube.com/embed/hxiELGqlv5s",
      },
      {
        title: "[Making Film] 소유 (SOYOU) X 아이즈원 (IZ*ONE) - ZERO:ATTITUDE (Feat.pH-1)",
        url: "https://www.youtube.com/embed/xp9uPLTMWO4",
      },
      {
        title: "[Making Film] 소유 (SOYOU) X 아이즈원 (IZ*ONE) - ZERO:ATTITUDE (Feat.pH-1) 녹음실",
        url: "https://www.youtube.com/embed/0IEWWL1aSjM",
      },
      {
        title: "[Teaser] 소유 (SOYOU) X 아이즈원 (IZ*ONE) - ZERO:ATTITUDE (Feat.pH-1)",
        url: "https://www.youtube.com/embed/sa-uJXR87X0",
      },
      {
        title: "[SPOILER INTERVIEW] IZ*ONE(아이즈원) - 2021 PEPSI X STARSHIP K-POP CAMPAIGN",
        url: "https://www.youtube.com/embed/wBEags5kygY",
      },
      {
        title: "[COMING SOON] IZ*ONE(아이즈원) & SOYOU(소유) & pH-1(피에이치원) - 2021 PEPSI X STARSHIP K-POP CAMPAIGN",
        url: "https://www.youtube.com/embed/pVqQ3ER5gT4",
      },
    ],
  },
  {
    id: 4,
    title: "2020 pepsi heritage",
    path: "2020-pepsi-heritage",
    videos: [
      {
        title: "[MV] 배진영 (BAE JIN YOUNG) X 김요한 (KIM YO HAN) - I Believe",
        url: "https://www.youtube.com/embed/h7r2PV6QLxk",
      },
      {
        title: "[Making Film] 배진영 (BAE JIN YOUNG) X 김요한 (KIM YO HAN) - I Believe",
        url: "https://www.youtube.com/embed/d4JNGtR3oMA",
      },
      {
        title: "[Teaser] 배진영 (BAE JIN YOUNG) X 김요한 (KIM YO HAN) - I Believe",
        url: "https://www.youtube.com/embed/ZfSnIcFHEZ0",
      },
      {
        title: "[SPECIAL CLIP] 강다니엘 (KANG DANIEL) - 2020 PEPSI X STARSHIP PROJECT",
        url: "https://www.youtube.com/embed/jDOwYcpueMM",
      },
      {
        title: "[SPECIAL CLIP] 배진영 (BAE JIN YOUNG) - 2020 PEPSI X STARSHIP HERITAGE PROJECT",
        url: "https://www.youtube.com/embed/Z1cgGO6Um-k",
      },
      {
        title: "[SPECIAL CLIP] 김요한 (KIM YO HAN) - 2020 PEPSI X STARSHIP HERITAGE PROJECT",
        url: "https://www.youtube.com/embed/ri0Qz_MdzyU",
      },
    ],
  },
  {
    id: 5,
    title: "2020 pepsi culture",
    path: "2020-pepsi-culture",
    videos: [
      {
        title: "[MV] 지코 (ZICO) X 강다니엘 (KANG DANIEL) - Refresh",
        url: "https://www.youtube.com/embed/k7aIhVBX3hw",
      },
      {
        title: "[Making Film] 지코 (ZICO) X 강다니엘 (KANG DANIEL) - Refresh",
        url: "https://www.youtube.com/embed/tcY90yQuGK4",
      },
      {
        title: "[Teaser] 지코 (ZICO) X 강다니엘 (KANG DANIEL) - Refresh",
        url: "https://www.youtube.com/embed/ks7CpzzifxE",
      },
      {
        title: "[COMING SOON] 지코 (ZICO) X 강다니엘 (KANG DANIEL) - 2020 PEPSI X STARSHIP PROJECT",
        url: "https://www.youtube.com/embed/nyi7cz0pvBM",
      },
    ],
  },
];
