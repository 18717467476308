/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import GatoradeMagazineSection2 from "../Layout/MagazineSectionGatorade2";

import Meta from "../../Utils/Meta";
import "react-responsive-modal/styles.css";
import moment from "moment";
import { Popup } from "../Items/Popup";
import Footer2 from "./Footer2";
import musicVideosGatorade from "../Layout/MusicVideosGatorade";
import BrandSectionGatorade2 from "../Layout/BrandSectionGatorade2";
import GatoradeMagazine from "./MagazineGatorade";
import _ from "lodash";
import LookBookGatorade2 from "../Layout/LookBookGatorade2";
import TVCGatorade from "../Layout/TVCGatorade";
import MVGatorade2 from "../Layout/MVGatorade2";
import { Link, useLocation } from "react-router-dom";
import MusicVideosGatorade from "../Layout/MusicVideosGatorade";

const MainBlock = styled.div``;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
  width: 100%;
  `}
`;
const MainSection = styled.div`
  width: 100%;
  margin-top: 60px;
  padding: 60px 130px 84px;
  background-color: #010000;

  ${Media.tablet`
    padding: 120px 30px 84px;
  `}
  ${Media.mobile`
    margin-top: 30px;
    padding: 30px 0 65px;
  `}
`;

const MainTitle = styled.div`
  font-family: "gatorade", "noto sans kr", "맑은 고딕", "Malgun Gothic",
    sans-serif;
  color: white;
  padding: 0 6px 23px;
  ${Media.mobile`
    padding: 0 30px 26px;
  `}
  p {
    font-size: 2.25rem;
    font-weight: 600;
    ${Media.mobile`
      font-size: 2rem;
    `}
  }

  img {
    ${Media.mobile`
      width: 50%;
    `}
  }
  text-transform: uppercase;
  .sub-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const MainVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BottomEventSection = styled.div`
  .desktop {
    ${Media.mobile`
      display: none;
    `}
  }
  .mobile {
    display: none;
    ${Media.mobile`
      display: block;
    `}
  }
`;

const MagazineHeader = styled.div`
  padding-top: 126px;
  max-width: 1280px;
  width: 80%;
  margin: 0 auto 20px;
  ${Media.mobile`
    margin: 0 auto 8px;
    padding: 75px 0 0 0;
  `}
  h2 {
    font-size: 36px;
    ${Media.mobile`
      font-size: 22px;
    `}
  }
`;

const SelectTab = styled.ul`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0;

  li {
    display: inline-block;
    margin-bottom: 10px;
  }
  li a {
    padding: 10px 20px 6px;
    border-radius: 500px;
    margin-right: 10px;
    background-color: #ddd;
    color: #999;
    font-size: 0.9rem;
    font-family: "gatorade", "noto sans kr", "맑은 고딕", "Malgun Gothic";
    cursor: pointer;
    min-width: 136px;
    text-align: center;
    ${Media.mobile`
      font-size: 11px;
      padding: 8px 10px 4px;
      margin-right: 3px;
      min-width: 0;
    `}
  }

  .active a {
    background-color: #000;
    color: #fff;
  }
  .disable a {
    background-color: #ddd;
    color: #999;
  }
`;

const tabData = [
  {
    id: 0,
    title: "main",
    path: "main",
    active: true,
  },
  {
    id: 1,
    title: "lookbook",
    path: "lookbook",
    component: <LookBookGatorade2 />,
    active: false,
  },
  {
    id: 2,
    title: "tvc",
    path: "tvc",
    component: <TVCGatorade />,
    active: false,
  },
  {
    id: 3,
    title: "mv",
    path: "mv",
    component: <MVGatorade2 />,
    active: false,
  },
];

const GatoradeMain = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { pathname } = useLocation();

  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const initialPopupState = useMemo(() => {
    if (!localStorage.getItem("taste-of-korea-popup-hide-expire")) return true;
    if (
      moment(localStorage.getItem("taste-of-korea-popup-hide-expire")).isAfter(
        moment()
      )
    )
      return false;
    return true;
  }, []);

  const [open, setOpen] = useState(initialPopupState);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "gatorade",
      gatorade: true,
      pepsi: false,
    });
  }, []);

  useEffect(() => {
    if (socialState.auto_login) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        login: true,
      });
      socialDispatch({
        type: "AUTO_LOGIN_SUCCESS",
      });
    }

    if (socialState.auto_signup) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        signup: true,
      });
      socialDispatch({
        type: "AUTO_SIGN_UP_SUCCESS",
      });
    }
  }, []);

  useEffect(() => {
    if (pathname.startsWith("/gatorade")) selectActiveTab(0);
    if (pathname.startsWith("/gatorade/magazine/lookbook")) selectActiveTab(1);
    if (pathname.startsWith("/gatorade/magazine/tvc")) selectActiveTab(2);
    if (pathname.startsWith("/gatorade/magazine/mv")) selectActiveTab(3);
  }, [pathname]);

  const metaData = {
    title: "K하우스오브펩시",
    description: "PEPSI SUMMER TASTE, 탑아티스트와 함께한 서머테이스트",
    canonical: "",
    keyword:
      "한국펩시콜라,펩시콜라,pepsi,pepsi-korea,pepsi korea,K하우스오브펩시, Khouseofpepsi,케이하우스오브펩시,코리아하우스오브펩시,한정판 이벤트,펩시 한정판 선물 이벤트",
  };

  const selectActiveTab = (id) => {
    setActiveTab(id);
  };

  const GatoradeLink = ({ children, to, condition }) =>
    !!condition && to ? (
      <Link to={to}>{children}</Link>
    ) : (
      <Link to={"/gatorade"}>{children}</Link>
    );

  return (
    <MainBlock className="gatorade">
      <Meta data={metaData} />
      <Wrapper>
        {/*<Popup open={ open } setOpen={ setOpen } />*/}
        <MagazineHeader>
          <h2 className="eng gatorade">gatorade</h2>
        </MagazineHeader>

        <SelectTab className="eng">
          {tabData.map((tab) => (
            <li
              id={tab.id}
              key={tab.id}
              onClick={() => selectActiveTab(tab.id)}
              className={tab.id === +activeTab ? "active" : "disable"}
              style={{ paddingTop: "10px" }}
            >
              <GatoradeLink
                to={`/gatorade/magazine/${tab.path}/${_.last(
                  pathname.split("/")
                )}`}
                condition={!tab.id===0}
              >
                {tab.title}
              </GatoradeLink>
            </li>
          ))}
        </SelectTab>

        <MainSection>
          <MainTitle className="gatorade">
            <img src="/Img/gatorade/typo.png" width="30%" alt="gatorade" />
          </MainTitle>
          <MainVideo>
            <video
              type="video/mp4"
              src="Img/gatorade/gatorade.mp4"
              /*poster="Img/intro.jpg"*/
              controls={true}
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video>
          </MainVideo>
        </MainSection>

        <BrandSectionGatorade2 />
        <GatoradeMagazineSection2 />
        <MusicVideosGatorade />

        <Footer2 />
      </Wrapper>
    </MainBlock>
  );
};

export default GatoradeMain;
