/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  getUsers,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import TrueAnswerPopup from "../Layout/TrueAnswerPopup";
import FalseAnswerPopup from "../Layout/FalseAnswerPopup";
import { EventButton } from "../../Styles/ButtonStyle";
import SubHeader from "../Layout/SubHeader";
import { useTranslation } from "react-i18next";
import LoadingStyle from "../../Styles/LoadingStyle";
// import Footer from "./Footer";

const QuizBlock = styled.div`
  padding: 135px 0 50px;
  width: 100%;
  min-height: 100vh;
  position: relative;
  ${Media.mobile`
    padding: 90px 0 70px;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
    line-height: 1.2;
    font-weight: bold;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
  `}
`;

const PepsiImage = styled.div`
  margin: 30px auto;
  width: 200px;
  height: 400px;
  /* background-color: #333; */
  background-image: url(${(props) => props.img || null});
  /* background-image: url("./Img/bigcan_50.png"); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`;

const AnswerList = styled.ul`
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: space-between;
  li {
    width: 19%;
    margin: 10px 0;
    font-size: 3rem;
    cursor: pointer;
    color: #ddd;
    transition: all 0.5s;
    font-family: "MontrealTS", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    &:hover {
      color: #000;
    }
  }

  .active {
    color: #000;
  }
  .disable {
    color: #ddd;
  }
  ${Media.tablet`
    flex-wrap: wrap;
    justify-content: flex-start;
    li{ width: 32%; }
  `}

  ${Media.mobile`
    flex-wrap: wrap;
    li{
      width: 50%;
      margin: 20px 0;
    }
  `}
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);

  font-size: 12px;
  line-height: 20px;
`;
const Alert2 = styled.div`
  color: #fff;

  font-size: 12px;
  line-height: 20px;
`;

const ButtonWrapper = styled.div``;

const AllQuizList = [
  {
    id: 1,
    quiz_img: "./Img/bigcan_40.png",
    answer: "1940s",
    answer_list: [
      ["1940s", "1950s", "1960s", "1980s", "1990s"],
      ["1840s", "1940s", "2040s", "2140s", "2240s"],
      ["1920s", "1930s", "1940s", "1950s", "1960s"],
      ["1910s", "1920s", "1930s", "1940s", "1950s"],
      ["1900s", "1910s", "1920s", "1930s", "1940s"],
    ],
  },
  {
    id: 2,
    quiz_img: "./Img/bigcan_50.png",
    answer: "1950s",
    answer_list: [
      ["1940s", "1950s", "1960s", "1980s", "1990s"],
      ["1750s", "1850s", "1950s", "2050s", "2150s"],
      ["1920s", "1930s", "1940s", "1950s", "1960s"],
      ["1910s", "1920s", "1930s", "1940s", "1950s"],
      ["1950s", "1960s", "1980s", "1990s", "2000s"],
    ],
  },
  {
    id: 3,
    quiz_img: "./Img/bigcan_60.png",
    answer: "1960s",
    answer_list: [
      ["1940s", "1950s", "1960s", "1980s", "1990s"],
      ["1660s", "1760s", "1860s", "1960s", "2060s"],
      ["1920s", "1930s", "1940s", "1950s", "1960s"],
      ["1960s", "1980s", "1990s", "2000s", "2010s"],
      ["1950s", "1960s", "1980s", "1990s", "2000s"],
    ],
  },
  {
    id: 4,
    quiz_img: "./Img/bigcan_80.png",
    answer: "1980s",
    answer_list: [
      ["1940s", "1950s", "1960s", "1980s", "1990s"],
      ["1580s", "1680s", "1780s", "1880s", "1980s"],
      ["1980s", "1990s", "2000s", "2010s", "2020s"],
      ["1960s", "1980s", "1990s", "2000s", "2010s"],
      ["1950s", "1960s", "1980s", "1990s", "2000s"],
    ],
  },
  {
    id: 5,
    quiz_img: "./Img/bigcan_90.png",
    answer: "1990s",
    answer_list: [
      ["1940s", "1950s", "1960s", "1980s", "1990s"],
      ["1990s", "2000s", "2010s", "2020s", "2030s"],
      ["1980s", "1990s", "2000s", "2010s", "2020s"],
      ["1960s", "1980s", "1990s", "2000s", "2010s"],
      ["1950s", "1960s", "1980s", "1990s", "2000s"],
    ],
  },
];

const Quiz = () => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "quiz",
    });
  }, []);

  const [quizList, setQuizList] = useState(null);
  const [answerList, setAnswerList] = useState(null);

  const { is_login, userInfo } = state;

  let randomId = Math.floor(Math.random() * (6 - 1)) + 1;

  const randomQuiz = () => {
    let randomQuizList = AllQuizList.filter((quiz) => quiz.id === randomId);
    let randomAnswerList = randomQuizList[0].answer_list[randomId - 1];

    // console.log(randomQuizList);
    // console.log(randomAnswerList);

    setQuizList(randomQuizList);
    setAnswerList(randomAnswerList);
  };

  // console.log(quizList);

  useEffect(() => {
    if (is_login && !userInfo) getUsers(dispatch);
  }, []);

  useEffect(() => {
    randomQuiz();
  }, []);

  // useEffect(() => {
  //   if (quizList) {
  // console.log(quizList[0].answer);
  // console.log(quizList[0]);
  // console.log(quizList[0].answer_list[randomId - 1]);
  //     setAnswerList(quizList[0].answer_list[randomId]);
  //   }
  // }, [quizList]);

  const [activeTab, setActiveTab] = useState(0);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const selectActiveTab = (id) => {
    setActiveTab(id);
  };

  const [Answer, setAnswer] = useState(null);
  let active = "";

  const selectAnswer = (e) => {
    // console.log(e.target.id);
    selectActiveTab(e.target.id);
    setDisabledBtn(false);
    if (e.target.id === quizList[0].answer) setAnswer(true);
    else setAnswer(false);
  };
  // console.log(activeTab);
  const [loadingState, setLoadingState] = useState(false);

  const checkAnswer = () => {
    if (Answer) {
      setLoadingState(true);
      setTimeout(() => {
        setLoadingState(false);
        onClickOpenTruePopUp();
      }, 2000);
    } else {
      setLoadingState(true);
      setTimeout(() => {
        setLoadingState(false);
        onClickOpenFalsePopUp();
      }, 1000);
    }
  };

  const [openTruePopUp, setOpenTruePopUp] = useState(false);
  const [openFalsePopUp, setOpenFalsePopUp] = useState(false);

  const onClickOpenTruePopUp = () => setOpenTruePopUp(!openTruePopUp);
  const onClickOpenFalsePopUp = () => setOpenFalsePopUp(!openFalsePopUp);

  const { t } = useTranslation();

  if (!quizList) return <div> loading... quiz... </div>;

  // if (!answerList) return <div> 답안 로딩 중... </div>;

  // console.log(answerList);

  return (
    <>
      <QuizBlock>
        <Wrapper>
          {loadingState && <LoadingStyle />}
          {!openTruePopUp ? (
            <>
              <SubHeader
                title="Quiz"
                subtitle="퀴즈"
                color="#000"
                marginBottom="20px"
                width="100%"
              />
              <h3>
                {t("event:event-quiz")
                  .split("\n")
                  .map((line) => (
                    <div key={Math.random()}>{line}</div>
                  ))}
              </h3>
              <PepsiImage img={quizList[0].quiz_img} />
              {/* {Answer === null && (
                <Alert2>
                  정답을 선택해주세요. (test용 정답표시 : {quizList[0].answer})
                </Alert2>
              )}
              {Answer === false && <Alert2>오답</Alert2>}
              {Answer === true && <Alert2>정답</Alert2>} */}
              <AnswerList>
                {answerList.map((answer) => (
                  <li
                    key={answer}
                    className={answer === activeTab ? "active" : "disable"}
                    onClick={selectAnswer}
                    id={answer}
                    active={active}
                  >
                    {answer}
                  </li>
                ))}
              </AnswerList>

              <EventButton onClick={checkAnswer} disabled={disabledBtn}>
                {t("event:submit-answer")}
              </EventButton>
            </>
          ) : (
            <TrueAnswerPopup
              setState={setOpenTruePopUp}
              openState={openTruePopUp}
            />
          )}

          {openFalsePopUp && (
            <FalseAnswerPopup
              setState={setOpenFalsePopUp}
              openState={openFalsePopUp}
            />
          )}
        </Wrapper>
      </QuizBlock>
      <ButtonWrapper />
      {/*<Footer />*/}
    </>
  );
};

export default Quiz;
