import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import VideoCard from "../Items/VideoCard";

const MusicVideoBlock = styled.div`
  padding-bottom: 50px;
  h3 {
    font-size: 2.25rem;
    font-weight: bold;
    line-height: 1.5;
    margin: 0 auto 26px;
    width: 90%;
    max-width: 1280px;
    ${Media.mobile`
      width: 84%;
      font-size: 14px;
      margin: 0px auto 16px;
    `}
  }
  ${Media.mobile`
    padding-bottom: 26px;
  `}
`;

const MVBlock = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding-bottom: 20px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 23px;
  column-gap: 24px;
  ${Media.mobile`
    width: 84%;
    column-gap: 9px;
    grid-template-columns: repeat(1, 1fr);
  `}
`;

const MusicVideosGatorade = () => {
  return (
      <MusicVideoBlock>
        <h3 className="eng gatorade">Music video.</h3>
        <MVBlock>
          <VideoCard
            src="https://www.youtube.com/embed/eXHUrpV44gA"
            title="[TEASER] 강다니엘(KANGDANIEL), 아이브 안유진(IVE ANYUJIN) - Move Like This (Feat. 김연아 (YUNA KIM))"
          />
          <VideoCard
            src="https://www.youtube.com/embed/VA3j_Vp8wHI"
            title="[INTERVIEW] 김연아 X 강다니엘 X 안유진 (IVE)- 2022 GATORADE X STARSHIP CAMPAIGN"
          />
        </MVBlock>
      </MusicVideoBlock>
  );
};

export default MusicVideosGatorade;
