import React from 'react';

export const RightArrowIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      fill="none"
      viewBox="0 0 9 14"
      {...props}
    >
      <path
        fill="#fff"
        d="M1.507 0L9 7l-7.493 7L0 12.592 5.987 7 0 1.408 1.507 0z"
      ></path>
    </svg>
  );
};
