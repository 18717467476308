export const goodsData = [
  {
    id: 1,
    category: "camp",
    uuid: "519ae8c1-0f4e-4fab-a6e4-bddf2cd5f229",
    name: "Portable Dishes",
    color: "Gray",
    description: "Aluminium Material Mesh Bag",
    option: "",
    image: "/Img/goods/camping01.jpg",
  },
  {
    id: 2,
    category: "camp",
    uuid: "dd5bcb57-099c-488a-b7db-fa6542671057",
    name: "Folding Box",
    color: "Dark Grey",
    description: "Folding Box with Plastic Frame(25L)",
    option: "",
    image: "/Img/goods/camping02.jpg",
  },
  {
    id: 3,
    category: "camp",
    uuid: "3a9f7027-11d5-41f5-bb43-8ba876d105af",
    name: "Blanket",
    color: "",
    description: "Double Sharing Priting acrylic",
    option: "",
    image: "/Img/goods2/blanket01.jpg",
  },
  {
    id: 4,
    category: "camp",
    uuid: "5efbea1a-de05-436d-b6b2-d5805c1711d8",
    name: "Relax Chair",
    color: "White",
    description: "Double Sharing Priting acrylic",
    option: "",
    image: "/Img/goods/camping04-bk.jpg",
  },
  {
    id: 5,
    category: "camp",
    uuid: "26b293c3-5593-4913-b515-963c0aea1b7a",
    name: "Tarp",
    color: "Silver Gray",
    description:
      "Polyester Material Tarp (3m*3m) Polyester Material Tarp Bag, Cover Printing",
    option: "",
    image: "/Img/goods/camping05.jpg",
  },
  {
    id: 11,
    category: "fashion",
    uuid: [
      "df746efe-74ca-4dfe-a40a-2b2ff1a2d4e0",
      "4fd04419-e55e-42d6-b26c-8aae5cd8fcae",
    ],
    name: "T-Shirt",
    color: "White",
    description:
      "Type of Short-sleeved T-shirt Size 1 & 2 / Cotton yarn / 1940/50/60/80/90s logo",
    option: [
      { value: "df746efe-74ca-4dfe-a40a-2b2ff1a2d4e0", label: "XL" },
      { value: "4fd04419-e55e-42d6-b26c-8aae5cd8fcae", label: "XXL" },
    ],
    image: "/Img/goods/fashion03.jpg",
  },
  {
    id: 12,
    category: "fashion",
    uuid: [
      "e83f8806-1a86-409b-afe3-4623a3090670",
      "3c540576-1de5-4f05-80ab-7781cd0c9fff",
    ],
    name: "Hoodie",
    color: "White",
    description: "Type of Long Sleeve Hoodie, Cotton",
    option: [
      { value: "e83f8806-1a86-409b-afe3-4623a3090670", label: "XL" },
      { value: "3c540576-1de5-4f05-80ab-7781cd0c9fff", label: "XXL" },
    ],
    image: "/Img/goods2/hoodie.jpg",
  },
  {
    id: 13,
    category: "fashion",
    uuid: [
      "ba432140-abdd-4771-9124-0a48117236a8",
      "e27d9161-aad3-4e63-ae30-b011bc6c681a",
      "90940d0f-24d6-47d9-b8da-4905cab2a1ee",
      "b2eacd2c-de3f-4998-a2d7-0b026b66dfca",
    ],
    name: "Beach Slipper",
    color: "White",
    description: "Synthetic Rubber Net Bag",
    option: [
      { value: "ba432140-abdd-4771-9124-0a48117236a8", label: "250mm" },
      { value: "e27d9161-aad3-4e63-ae30-b011bc6c681a", label: "260mm" },
      { value: "90940d0f-24d6-47d9-b8da-4905cab2a1ee", label: "270mm" },
      { value: "b2eacd2c-de3f-4998-a2d7-0b026b66dfca", label: "280mm" },
    ],
    image: "/Img/goods/fashion07.jpg",
  },
  {
    id: 14,
    category: "fashion",
    uuid: [
      "b6f1149d-d61c-443a-abad-e0fcdeaddc30",
      "7a978b0b-9607-416a-9110-9132ef28861a",
    ],
    name: "Socks",
    color: "White",
    description: "Logo Priting Cotton yarn",
    option: [
      { value: "b6f1149d-d61c-443a-abad-e0fcdeaddc30", label: "Man" },
      { value: "7a978b0b-9607-416a-9110-9132ef28861a", label: "Woman" },
    ],
    image: "/Img/goods/fashion08.jpg",
  },
  {
    id: 15,
    category: "fashion",
    uuid: "9216b6e2-1b23-466b-a45b-a88226664bae",
    name: "Mask set (10 masks + cases)",
    color: "",
    description: "White(5)+Black(5) mask Set Portable Mask Case(1), string",
    option: "",
    image: "/Img/goods/fashion09.jpg",
  },
  {
    id: 21,
    category: "soloplay",
    uuid: "c40e1f04-1286-4751-8a4b-85f2eadca7c3",
    name: "Puzzle",
    color: "",
    description: "Paper Case, glue Logo Printing",
    option: "",
    image: "/Img/goods/soloplay09.jpg",
  },
  {
    id: 22,
    category: "soloplay",
    uuid: "c90d92f3-4983-4311-ae2d-033830e7a62d",
    name: "Smart Beam Projector",
    color: "",
    description: "Maximum 80 Center Lumens DC 5V / 2.0 A, Micro USB",
    option: "",
    image: "/Img/goods/soloplay10.jpg",
  },
  {
    id: 23,
    category: "soloplay",
    uuid: "cbd7186c-0513-42ea-bf6e-3a76997d5171",
    name: "Garland",
    color: "",
    description: "Fabric Type, Duplex Printing, Coating",
    option: "",
    image: "/Img/goods/soloplay11.jpg",
  },
];
