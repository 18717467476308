export const TVCData = [
	{
		id: 1,
		title: "2023 pepsi x iu",
		path: "2023-pepsi-iu",
		videos: [
			{
				title: "[MAKING FILM] 아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN\n",
				url: "https://www.youtube.com/embed/B-0ER_6HwOw",
			},
			{
				title: "아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN (BLUE & BLACK VER.)\n",
				url: "https://www.youtube.com/embed/uSznT1MjVr4",
			},
			{
				title: "아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN (PEPSI BLUE 15s VER.)\n",
				url: "https://www.youtube.com/embed/x0djwqFuw0c",
			},
			{
				title: "아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN (PEPSI BLUE 30s VER.)\n",
				url: "https://www.youtube.com/embed/fQFSF7i5_i8",
			},
			{
				title: "아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN (PEPSI ZERO SUGAR 15s VER.)\n",
				url: "https://www.youtube.com/embed/p2-gn2C-tfY",
			},
			{
				title: "아이유(IU) - 2023 PEPSIxSTARSHIP CAMPAIGN (PEPSI ZERO SUGAR 30s VER.)\n",
				url: "https://www.youtube.com/embed/8gTldHY0Pj4",
			},
			{
				title: "[COMING SOON] 아이유 (IU) - 2023 PEPSI X STARSHIP CAMPAIGN\n",
				url: "https://www.youtube.com/embed/bt6WyrVqFMs",
			}]
	},
	{
		id: 2,
		title: "2022 pepsi x iu",
		path: "2022-pepsi-iu",
		videos: [
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER -IU (아이유) TVC (재택근무 VER.)\n",
				url: "https://www.youtube.com/embed/qlZZkngyXCM",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER - IU (아이유) TVC (드라이브 VER.)\n",
				url: "https://www.youtube.com/embed/tl4dPFOLkYY",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER -IU (아이유) TVC (30s FULL VER.)\n",
				url: "https://www.youtube.com/embed/3chf3GzDJ50",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER - IU (아이유) TVC (60s)\n",
				url: "https://www.youtube.com/embed/cwIYxGNxm_Y",
			},
			{
				title: "[MAKING FILM] 2022 PEPSI PARTNER - IU (아이유) ‘PEPSI WITH FOOD’\n",
				url: "https://www.youtube.com/embed/P_3WumK5IDw",
			},
			{
				title: "[COMING SOON] IU (아이유) - 2022 PEPSI PARTNER\n",
				url: "https://www.youtube.com/embed/Po0a1VAezgo",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER - IU (아이유) TVC (FUN VER.)\n",
				url: "https://www.youtube.com/embed/9X6aA_-FfD0",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER - IU (아이유) TVC (60s)\n",
				url: "https://www.youtube.com/embed/cwIYxGNxm_Y",
			},
			{
				title: "[PEPSIxSTARSHIP] 2022 PEPSI PARTNER - IU (아이유) TVC (FOOD VER.)\n",
				url: "https://www.youtube.com/embed/tt8yG8VjrGs",
			},
			{
				title: "[MAKING FILM] 2022 PEPSI PARTNER - IU (아이유) ‘PEPSI ZERO SUGAR’\n",
				url: "https://www.youtube.com/embed/qYgs3iDwfOY",
			}
		],
	},
];