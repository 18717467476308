/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SubHeader from "./SubHeader";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation, initReactI18next } from "react-i18next";

const AgreementBusinessBlock = styled.div`

  width: 100%;

  textarea {
    width: 100%;
    height: 150px;
    border: solid 1px #aaa;
    box-sizing: border-box;
    padding: 20px;
    font-size: 0.875rem;
    color: #777;
    line-height: 1.5rem;
  }
  input[type="checkbox"] {
    display: none;
  }
  label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url("./Img/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }
  label {
    font-size: 0.875rem;
    a {
      text-decoration: underline;
      display: inline;
    }
  }
  input[type="checkbox"]:checked + label::before {
    background-color: #0065c3;
  }
`;

const AgreeCheck = styled.div`
  margin: 20px 0;
`;
const Terms = styled.textarea``;

const AgreementBusiness = ({
  popUpPage,
  setPopUpPage,
  socialToken,
  SetSocialToken,
}) => {
  const initialState = [
    { id: 1, name: "agree-policy", checked: false },
    // { id: 2, name: "agree-event", checked: false },
    { id: 2, name: "agree-consent", checked: false },
  ];

  const allCheckBox = useRef();

  const [checkedState, setCheckedState] = useState(initialState);
  const [buttonState, setButtonState] = useState(true);

  const checkCompleteMark = () => {
    let allComplete = checkedState.map((check) => check.checked);

    if (allComplete.every((check) => check)) {
      allCheckBox.current.checked = true;
      setButtonState(false);
    } else {
      allCheckBox.current.checked = false;
      setButtonState(true);
    }
  };

  useEffect(() => {
    checkCompleteMark();
  }, [checkedState]);

  const onCheckedBox = (e) => {
    setCheckedState(
      checkedState.map((check) =>
        check.name === e.target.id
          ? { ...check, checked: !check.checked }
          : check
      )
    );
  };

  const allCheckedBox = (e) => {
    setCheckedState(
      checkedState.map((check) => ({ ...check, checked: e.target.checked }))
    );
  };

  // console.table(checkedState);

  const pageChange = (page) => setPopUpPage(page);

  const { t, changeLanguage } = useTranslation();

  return (
    <>
      <AgreementBusinessBlock>
          <SubHeader
            title="JOIN"
            subtitle="회원가입"
            color="#000"
            textAlign="center"
          />
          <AgreeCheck className="agree-all">
            <input
              type="checkbox"
              id="all-agreement"
              ref={allCheckBox}
              // checked={checkedState[0].checked}
              onChange={allCheckedBox}
            />
            <label htmlFor="all-agreement">{t("policy:join-agree-all")}</label>
          </AgreeCheck>
          <Terms defaultValue={t("policy:privacy-policy")} />
          <AgreeCheck>
            <input
              type="checkbox"
              id="agree-policy"
              checked={checkedState[0].checked}
              onChange={onCheckedBox}
            />
            <label htmlFor="agree-policy">
              {t("policy:join-agree-privacy")}
            </label>
          </AgreeCheck>
          {/* 
          <Terms defaultValue="이벤트를 통한 개인정보 동의 (필수)" />
          <AgreeCheck>
            <input
              type="checkbox"
              id="agree-event"
              checked={checkedState[1].checked}
              onChange={onCheckedBox}
            />
            <label htmlFor="agree-event">{t("join-agree-event")}</label>
          </AgreeCheck> */}

          <Terms defaultValue={t("policy:policy")} />
          <AgreeCheck>
            <input
              type="checkbox"
              id="agree-consent"
              checked={checkedState[1].checked}
              onChange={onCheckedBox}
            />
            <label htmlFor="agree-consent">
              {t("policy:join-agree-policy")}
            </label>
          </AgreeCheck>
        <br/>
          {socialToken ? (
              <MainButton2
                  bgColor="#0065c3"
                  onClick={() => pageChange(7)}
                  disabled={buttonState}
              >
                {t("next-step")}
              </MainButton2>
          ) : (
              <MainButton2
                  bgColor="#0065c3"
                  onClick={() => pageChange(6)}
                  disabled={buttonState}
              >
                {t("next-step")}
              </MainButton2>
          )}
      </AgreementBusinessBlock>
    </>
  );
};

export default AgreementBusiness;
