export const historyData = [
  {
    id: "history40",
    name: "1940",
    image: "/Img/bigcan_40.png",
    detail_info: {
      main_img: "/Img/mainbigcan_40.png",
      card1_img: "/Img/retro_pack02-00.jpg",
      card2_img: "/Img/retro_pack04-00.jpg",
      card3_img: "/Img/retro_pack05-00.jpg",
      card1_description: `for the generation that worked its way through the great depression and fought a global war, pepsi represented the welcome comfort of home that anybody with a nickle could appreciate. on the radio, you'd hear our catchy jingle between big band broadcast- but it was due to the brand's spirit of generosity and inclusion that pepsi became a national phenomenon.`,
      card2_subtitle: "The",
      card2_subtitle2: "drink",
      card2_subtitle3: "for you",
    },
  },
  {
    id: "history50",
    name: "1950",
    image: "/Img/bigcan_50.png",
    detail_info: {
      main_img: "/Img/mainbigcan_50.png",
      card1_img: "/Img/retro_pack02-01.jpg",
      card2_img: "/Img/retro_pack04-01.jpg",
      card3_img: "/Img/retro_pack05-01.jpg",
      card1_description: `1950 popped open pepsi's fizziest slogan, "More Bounce to the Once", against a backdrop of bobby-soxers jitterbugging to the new sounds of Chuck Berry, Buddy Holly and Elvis, who was known to favor Pepsi-Cola himself. But we weren't only popular with the rock-and-roll set Hollywood royalty like Joan Crawford represented us to polite society.`,
      card2_subtitle: "Socials,",
      card2_subtitle2: "rebels",
      card2_subtitle3: "and rock&roll",

    },
  },
  {
    id: "history60",
    name: "1960",
    image: "/Img/bigcan_60.png",
    detail_info: {
      main_img: "/Img/mainbigcan_60.png",
      card1_img: "/Img/retro_pack02-02.jpg",
      card2_img: "/Img/retro_pack04-02.jpg",
      card3_img: "/Img/retro_pack05-02.jpg",
      card1_description: `At the same time music and consciousness were expanding, we introduced the iconic red, blue and white bottlecap as pepsi's logo. 
Placing this icon on a can helps identify an era where drinking from the bottle was the norm. 
The graphic blue stripes behind add contract and give a definitive nod to the decade.`,
      card2_subtitle: "This is",
      card2_subtitle2: "the",
      card2_subtitle3: "Can",
    },
  },
  {
    id: "history80",
    name: "1980",
    image: "/Img/bigcan_80.png",
    detail_info: {
      main_img: "/Img/mainbigcan_80.png",
      card1_img: "/Img/retro_pack02-03.jpg",
      card2_img: "/Img/retro_pack04-03.jpg",
      card3_img: "/Img/retro_pack05-03.jpg",
      card1_description: `in the decade that sang "we are the world." pepsi's footprint spanned  Asia, Eastern Europe and the former soviet Union- a global supergroup rivaled by our pop icon.
This reissued 80's packaging design symbolizes the pepsi as the pop culture icon.`,
      card2_subtitle: "A",
      card2_subtitle2: "global",
      card2_subtitle3: "phenomenon",
    },
  },
  {
    id: "history90",
    name: "1990",
    image: "/Img/bigcan_90.png",
    detail_info: {
      main_img: "/Img/mainbigcan_90.png",
      card1_img: "/Img/retro_pack02-04.jpg",
      card2_img: "/Img/retro_pack04-04.jpg",
      card3_img: "/Img/retro_pack05-04.jpg",
      card1_description: `through the birth of the internet and the launch of the hubble space telescope, the nineties allowed all of us to connect and explore like never before. 
For Pepsi, it was also a decade of wonder and expansion, as we became the drink of superstar, supermodels and superhuman sports heroes. 
The vertical red stripe of this design reflects our expansion by reaching toward new heights.`,
      card2_subtitle: "over",
      card2_subtitle2: "the",
      card2_subtitle3: "top",
    },
  },
];
