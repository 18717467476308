/* eslint-disable no-unused-vars */
import { Global } from "@emotion/core";
import React, { useRef, useState } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";
import { globalStyles } from "../../Utils/stylesConfig";
import MainBrandSliderMobile from "./MainBrandSliderMobile";

const BrandSectionBlock = styled.div`
  padding: 85px 0 120px;
  ${Media.mobile`
    padding: 44px 0 60px;
  `}
  .desktop {
    ${Media.mobile`
      display: none;
    `}
  }
  .mobile {
    display: none;
    ${Media.mobile`
      display: block;
    `}
  }
  .swiper-container {
    padding-left: 5%;
    padding-right: 5%;
    ${Media.mobile`
      padding: 0;
    `}
  }

  .swiper-button-next {
    background-image: url(/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/next-btn.png);
    right: 30px;
    width: 53px;
    height: 53px;
    ${Media.mobile`
      width: 33px;
      height: 33px;
      right: 12px;
    `}
  }
  .swiper-button-prev {
    background-image: url(/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/prev-btn.png);
    left: 30px;
    ${Media.mobile`
    width: 33px;
    height: 33px;
    left: 12px;
  `}
  }
`;

const BrandHeader = styled.div`
  display: flex;
  margin: 0 auto;
  width: 90%;
  max-width: 1280px;
  justify-content: space-between;
  align-content: center;
  padding-bottom: 85px;
  ${Media.mobile`
    padding-bottom: 22px;
  `}
`;

const BrandHeaderTitle = styled.div`
  h1 {
    font-size: 46px;
    margin: 0;
    ${Media.mobile`
      font-size: 22px;
    `}
  }
  em {
    font-family: "MontrealTS", sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    ${Media.mobile`
      font-size: 14px;
    `}
  }
`;

const ControllerButtons = styled.div`
  display: flex;
  div {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/brand-menu.png);
  }
  .navigation {
    ${Media.mobile`
        display: none;
      `}
  }
  .prev {
    margin: 5px;
    margin-right: 42px;
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/left-arrow${(
      props
    ) => (props.prevDisable ? "-d" : "")}.svg);
  }
  .next {
    margin: 5px;
    margin-right: 74px;
    width: 14px;
    height: 14px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    background-image: url(/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/right-arrow${(
      props
    ) => (props.nextDisable ? "-d" : "")}.svg);
  }
`;

const SwiperSlide = styled.li`
  background-image: url(${(props) => props.mainImg || null});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  img {
    align-self: center;
    width: 100%;
  }
`;

const MainBrandSlideContainer = styled.div`
  display: flex;
  position: relative;
  .swiper-container {
    width: 100%;
    margin-top: 40px;
    ${Media.mobile`
    margin-top: 0;
  `}
  }
`;

const imageData = Array(7)
  .fill(0)
  .map((item, index) => {
    return `/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/${index + 1}.png`;
  });

const SamsungCelebritesSection = () => {
  const swiperParams = {
    centeredSlides: false,
    slidesPerView: 5,
    spaceBetween: 14,
    mousewheel: false,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1024: {
        slidesPerView: 4.2,
      },
      980: {
        slidesPerView: 4,
      },
      768: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 3,
        loop: true,
      },
      320: {
        slidesPerView: 3.4,
        loop: true,
      },
    },
  };

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const openBrandModal = () => {
    popupSetState({
      ...popupState,
      brand: !popupState.brand,
    });
  };

  const swiperRef = useRef(null);

  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const updateIsDisabled = () => {
    setIsNextDisabled(getIsEnd());
    setIsPrevDisabled(getIsBeginning());
  };

  const getIsBeginning = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper.isBeginning;
    }
  };

  const getIsEnd = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      return swiperRef.current.swiper.isEnd;
    }
  };

  const navigatePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (!swiperRef.current.swiper.allowSlidePrev) return;
      swiperRef.current.swiper.slidePrev();
      updateIsDisabled();
    }
  };

  const navigateNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (!swiperRef.current.swiper.allowSlideNext) return;
      swiperRef.current.swiper.slideNext();
      updateIsDisabled();
    }
  };

  return (
    <BrandSectionBlock>
      <BrandHeader>
        <BrandHeaderTitle>
          <h1>Celebrities</h1>
          <em className="eng">with pepsi x samsung galaxy</em>
        </BrandHeaderTitle>
        <ControllerButtons
          prevDisable={isPrevDisabled}
          nextDisable={isNextDisabled}
        >
          <div onClick={navigatePrev} className="navigation prev" />
          <div onClick={navigateNext} className="navigation next" />
          {/* <div onClick={openBrandModal}/> */}
        </ControllerButtons>
      </BrandHeader>

      <Global styles={globalStyles} />

      <MainBrandSlideContainer>
        <Swiper {...swiperParams} ref={swiperRef}>
          {imageData.map((item, index) => (
            <SwiperSlide tag={"li"} key={index} id={"item" + item}>
              <img src={item} alt={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </MainBrandSlideContainer>
    </BrandSectionBlock>
  );
};

export default SamsungCelebritesSection;
