import React, { useState } from "react";
import styled, { css } from "styled-components";
import GoodsPopUp from "./GoodsPopUp";
import Media from "../../Styles/MediaQuery";
import { useTranslation } from "react-i18next";

const GoodsItemBlock = styled.li`
  position: relative;
  width: 18%;
  float: left;
  margin-right: 2%;
  margin-bottom: 2%;
  padding-top: 14%;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  /* border: (${(props) =>
    props.active ? "1px solid #333" : "1px solid #ccc"}); */

  border-width: 1px;
  border-style: solid;
  border-color: #ccc;

  background-image: url(${(props) => props.image || null});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  cursor: pointer;

  ${(props) =>
    props.select &&
    css`
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

      box-shadow: 0 0 0 5px rgb(1, 74, 153) inset;

      &::after {
        content: ${(props) => `"${props.select}"`};
        position: absolute;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 25px;
        top: 0;
        right: 0;
        background-color: rgb(1, 74, 153);
        color: #fff;
      }
    `}

  ${Media.tablet`
    width: 31%;
    padding-top: 24%;
  `}
  ${Media.mobile`
    width: 48%;
    padding-top: 36%;
  `}
`;

const GoodsItem = ({
  rankState,
  setRankState,
  applyEventInfo,
  setApplyEventInfo,
  uuid,
  id,
  name,
  color,
  description,
  option,
  image,
  active,
  select,
  goodsDataState,
  setGoodsDataState,
}) => {
  const [goodsPopup, setGoodsPopup] = useState(false);
  const [classSelect, setClassSelect] = useState(null);
  const { t } = useTranslation();

  // console.log(name);

  const onGoodsPopup = (e) => {
    // console.log(e.target.classList.contains("selected"));
    if (e.target.classList.contains("selected")) alert(t("event:choose-already"));
    else if (!rankState.length) alert(t("event:choose-three"));
    else setGoodsPopup(true);
  };

  // const onClassNameReset = () => setClassSelect(null);
  // console.log(applyEventInfo);
  // console.log("결과", rankState);
  return (
    <>
      <GoodsItemBlock
        id={id}
        onClick={onGoodsPopup}
        image={image}
        active={active}
        select={select}
        className={classSelect}
      />
      {goodsPopup && (
        <GoodsPopUp
          rankState={rankState}
          setRankState={setRankState}
          applyEventInfo={applyEventInfo}
          setApplyEventInfo={setApplyEventInfo}
          setState={setGoodsPopup}
          openState={goodsPopup}
          goodsDataState={goodsDataState}
          setGoodsDataState={setGoodsDataState}
          id={id}
          uuid={uuid}
          name={name}
          color={color}
          description={description}
          option={option}
          image={image}
          active={active}
          select={select}
          setClassSelect={setClassSelect}
        />
      )}
    </>
  );
};

export default GoodsItem;
