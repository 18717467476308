import React, { useState } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100vh;
  flex-wrap: wrap;
  .img {
    width: 70px;
    margin-right: 10px;
  }
  .txt {
    margin-left: 10px;
    padding-right: 14px;
  }
  h2 {
    font-size: 2rem;
    margin: 0 0 10px;
  }
  p {
    margin: 0 0 0;
  }
  .btn-wrap {
    width: 100%;
    text-align: center;
    padding: 50px 0 0;
  }
  button {
    background-color: #000;
    color: #fff;
    padding: 16px 0;
    width: 270px;
    min-width: 270px;
    border: 0;
  }
`;

const Page404 = () => {
  const [redirectState, setRedirectState] = useState(null);

  const goMain = () => {
    setRedirectState("/");
  };

  if (redirectState === "/") return <Redirect to={redirectState} />;
  return (
    <Wrapper>
      <div class="img">
        <img src="/Img/compass.svg" alt="" />
      </div>
      <div class="txt">
        <h2 class="eng">404</h2>
        <p class="eng">page not found</p>
      </div>
      <div class="btn-wrap">
        <button onClick={goMain}>메인으로</button>
      </div>
    </Wrapper>
  );
};

export default Page404;
