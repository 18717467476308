/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useUsersState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { EventButton } from "../../Styles/ButtonStyle";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import { Link } from "react-router-dom";
import { MainButton } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";

const ApplyUserInfoBlock = styled.div`
  text-align: center;

  h3 {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 20px;
    font-size: 1.4rem;
    line-height: 1.2;
    word-break: keep-all;
  }
  h4 {
    font-size: 1rem;
    line-height: 1.2;
    margin-bottom: 20px;
  }
  img {
    width: 100px;
    margin: 0 auto;
  }

  .btn-complete {
    width: 50%;
    margin: 50px auto 0;
  }
  ${Media.tablet`

  `}

  ${Media.mobile`
  .evt-complete h3 {
    font-size: 1.3rem;
    word-break: keep-all;
  }
  
  `}
`;

const ApplyComplete = () => {
  const goScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    goScrollTop();
  }, []);

  const { t } = useTranslation();

  const state = useUsersState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, applyEventUser } = state;

  // console.log("state", state);
  localStorage.removeItem("partner");

  if (loading) return <DataLoadingStyle />;
  // if (error) return <DataLoadingStyle />;
  let errorMsg = "";
  if (error) {
    // console.log(error.request);

    if (
      JSON.parse(error.request.responseText)[0] === "daily events limit is over"
    ) {
      return (
        <>
          <ApplyUserInfoBlock>
            <>
              <h3>
                {t("event:event-limit-already")
                  .split("\n")
                  .map((line) => (
                    <p>{line}</p>
                  ))}
              </h3>
            </>

            <Link to="/">
              <MainButton bgColor="#0065c3">{t("go-main")}</MainButton>
            </Link>
          </ApplyUserInfoBlock>
        </>
      );
    } else {
      errorMsg = JSON.parse(error.request.responseText);
      let first_key = Object.keys(errorMsg)[0];
      let first_value = errorMsg[Object.keys(errorMsg)[0]];

      // console.log(first_key);
      // console.log(first_value[0]);

      return (
        <>
          {errorMsg && (
            <ApplyUserInfoBlock>
              <h3>
                Error!
                <br />
                {first_key} : {first_value[0]} <br />
              </h3>
              <Link to="/">
                <MainButton bgColor="#0065c3">{t("go-main")}</MainButton>
              </Link>
            </ApplyUserInfoBlock>
          )}
        </>
      );
    }
  }

  if (!applyEventUser) return <DataLoadingStyle />;

  return (
    <>
      <ApplyUserInfoBlock>
        <>
          <h3 className="evt-complete">{t("event:event-apply-done")}</h3>
          <h4>
            {t("event:event-limit-front")} {applyEventUser.daily_count_left}{" "}
            {t("event:event-limit-back")}
          </h4>
          <img src="/Img/icon-quiz2.png" alt="" />
        </>

        <Link className="btn-complete" to="/">
          <MainButton bgColor="#0065c3">{t("go-main")}</MainButton>
        </Link>
      </ApplyUserInfoBlock>
    </>
  );
};

export default ApplyComplete;
