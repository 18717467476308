import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import {Link} from "react-router-dom";

const LookBookMenuListBlock = styled.div`
  width: 100%;
  padding: 90px 0 0;
  border-top: 1px solid #999;

  ${Media.mobile`
  padding: 35px 0;
  `}
`;

const MenuSection = styled.div`
  margin-bottom: 90px;

  ${Media.mobile`
    margin-bottom: 30px;
`}
  h3 {
    font-size: 28px;
    margin-bottom: 40px;
    color: #000;

    ${Media.mobile`
    font-size: 14px;
    margin-bottom: 5px;
`}
  }

  ul li {
    font-size: 56px;
    line-height: 42px;
    margin-left: 40px;
    margin-bottom: 30px;

    ${Media.mobile`
    margin-left: 15px;
    font-size: 22px;
    `}
  }

  &:hover {
    li a:hover {
      color: #0065c3;
    }
  }
`;

const LookBookMenuList = () => {
    return (
        <LookBookMenuListBlock>
            <MenuSection>
                <h3>current</h3>
                <ul className="eng">
                    <li>
                        <Link to="/magazine/lookbook/pepsi-2022zero">
                            2022 PEPSI ZERO SUGAR
                        </Link>
                    </li>
                </ul>
            </MenuSection>
            <MenuSection>
                <h3>past</h3>
                <ul className="eng">
                    <li>
                        <Link to="/magazine/lookbook/pepsi-korea">
                            2021 TASTE OF KOREA
                        </Link>
                    </li>
                </ul>
                <ul className="eng">
                    <li>
                        <Link to="/magazine/lookbook/pepsi-zero">
                            2021 PEPSI ZERO SUGAR
                        </Link>
                    </li>
                </ul>
                <ul className="eng">
                    <li>
                        <Link to="/magazine/lookbook/pepsi-heritage">2020 PEPSI HERITAGE</Link>
                    </li>
                </ul>
                <ul className="eng">
                    <li>
                        <Link to="/magazine/lookbook/pepsi-culture">2020 PEPSI CULTURE</Link>
                    </li>
                </ul>
            </MenuSection>
        </LookBookMenuListBlock>
    );
};

export default LookBookMenuList;
