import styled from 'styled-components';
import Axios from 'axios';
import Media from '../../Styles/MediaQuery';
import React, { useEffect, useState } from 'react';
import sadFace from './event-code-assets/sad-face.svg';
import { useHistory } from 'react-router-dom';

const Container = styled.div`
  margin-top: 60px;
  background: #094f9b;
  padding: 8vw;
  text-align: center;
  min-height: calc(100vh - 90px - 56px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${Media.iphoneP`
        
    `}
  ${Media.iphoneS`
        padding: 8vw 8vw 0;
    `}
`;

const KorTitle = styled.p`
  text-align: center;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  ${(props) => (props.small ? 'font-size: 22px;' : 'font-size: 26px;')}

  line-height: 140%;
  color: #ffffff;
  margin-bottom: 30px;
`;
const SadFace = styled.img`
  margin-bottom: 39px;
  max-width: 124px;
  width: 30%;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 140%;
  /* or 22px */
  text-align: center;
  text-transform: capitalize;
  color: white;
  margin-bottom: 30px;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;
export const EventCodeFail = (props) => {
  const history = useHistory();

  return (
    <div>
      <Container>
        <KorTitle>
          아쉽지만 이번에는
          <br />
          당첨되지 않으셨어요.
        </KorTitle>
        <KorTitle small>
          다음 기회에
          <br />
          다시 응모해주세요!
        </KorTitle>

        <SadFace src={sadFace} />
        <PrizeDesc>
          2021년 9월 30일까지 이벤트가 진행되니
          <br />꼭 다시 응모해주세요!
        </PrizeDesc>
      </Container>
      <CheckResultButton bottom onClick={() => history.push('/')}>
        펩시 콘텐츠로 아쉬움을 달래보세요
      </CheckResultButton>
    </div>
  );
};
