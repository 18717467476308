/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSocialState, useSocialDispatch } from "../../Context/UserContext";

function AuthRoute({ is_login, component: Component, render, ...rest }) {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();

  if (!is_login && !localStorage.getItem("refresh"))
    socialDispatch({
      type: "AUTO_LOGIN",
    });

  return (
    <Route
      {...rest}
      render={(props) =>
        is_login || localStorage.getItem("refresh") ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
}

export default AuthRoute;
