/* eslint-disable no-unused-vars */
import React, { createContext, useReducer, useContext, useState } from "react";
import axios from "axios";
import * as userApi from "../Service/UserApi";
import {
  createAsyncDispatcher,
  createAsyncHandler,
  createAuthAsyncHandler,
  createLogoutHandler,
  createRefreshHandler,
  createStatusResetHandler,
  initialAsyncState,
} from "../Utils/asyncActionUtils";

const usersHandler = createAsyncHandler("GET_USERS", "userInfo");
const getUserEventHandler = createAsyncHandler(
  "GET_USER_EVENT",
  "userEventList"
);
const getUserEventDetailHandler = createAsyncHandler(
  "GET_USER_EVENT_DETAIL",
  "userEventLogList"
);
// "GET_USER_EVENT_DETAIL",
// userApi.getUserEventDetail
// );
const userUpdateHandler = createAsyncHandler("USER_UPDATE", "userInfo");
const signUpHandler = createAuthAsyncHandler("SIGN_UP", "is_reset");
const loginHandler = createAuthAsyncHandler("LOGIN", "is_reset");
const logoutHandler = createLogoutHandler("LOGOUT");
const unRegisterHandler = createLogoutHandler("UN_REGISTER");

const refreshHandler = createRefreshHandler("REFRESH", "acc");
const applyNonMembersHandler = createAsyncHandler(
  "APPLY_NON_MEMBERS",
  "applyEventUser"
);
const applyMembersHandler = createAsyncHandler(
  "APPLY_MEMBERS",
  "applyEventUser"
);

const statusResetHandler = createStatusResetHandler("STATUS_RESET");

const success = (data) => ({
  loading: false,
  data,
  error: null,
});

export const usersReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_UP":
    case "SIGN_UP_SUCCESS":
    case "SIGN_UP_ERROR":
      return signUpHandler(state, action);
    case "LOGIN":
    case "LOGIN_SUCCESS":
    case "LOGIN_ERROR":
      return loginHandler(state, action);
    case "GET_USERS":
    case "GET_USERS_SUCCESS":
    case "GET_USERS_ERROR":
      return usersHandler(state, action);
    case "GET_USER_EVENT":
    case "GET_USER_EVENT_SUCCESS":
    case "GET_USER_EVENT_ERROR":
      return getUserEventHandler(state, action);
    case "GET_USER_EVENT_DETAIL":
    case "GET_USER_EVENT_DETAIL_SUCCESS":
    case "GET_USER_EVENT_DETAIL_ERROR":
      return getUserEventDetailHandler(state, action);
    case "USER_UPDATE":
    case "USER_UPDATE_SUCCESS":
    case "USER_UPDATE_ERROR":
      return userUpdateHandler(state, action);
    case "LOGOUT":
    case "LOGOUT_SUCCESS":
    case "LOGOUT_ERROR":
      return logoutHandler(state, action);
    case "UN_REGISTER":
    case "UN_REGISTER_SUCCESS":
    case "UN_REGISTER_ERROR":
      return unRegisterHandler(state, action);
    case "REFRESH":
    case "REFRESH_SUCCESS":
    case "REFRESH_ERROR":
      return refreshHandler(state, action);
    case "REFRESH_USERS":
    case "REFRESH_USERS_SUCCESS":
    case "REFRESH_USERS_ERROR":
      return {
        ...state,
        user: success(action.data),
      };
    case "APPLY_NON_MEMBERS":
    case "APPLY_NON_MEMBERS_SUCCESS":
    case "APPLY_NON_MEMBERS_ERROR":
      return applyNonMembersHandler(state, action);
    case "APPLY_MEMBERS":
    case "APPLY_MEMBERS_SUCCESS":
    case "APPLY_MEMBERS_ERROR":
      return applyMembersHandler(state, action);
    case "STATUS_RESET":
    case "STATUS_RESET_SUCCESS":
    case "STATUS_RESET_ERROR":
      return statusResetHandler(state, action);
    default:
      throw new Error(`Unhanded action type: ${action.type}`);
  }
};

const initialSocialState = {
  status: null,
  access_token: "",
  channel: "",
  social_email: "",
  auto_signup: false,
  auto_login: false,
};

function socialReducer(state, action) {
  switch (action.type) {
    case "LOADING":
      return { ...state, status: "loading" };
    case "CHECK_SOCIAL_LOADING":
      return {
        ...state,
        status: "loading",
        access_token: action.access_token,
        channel: action.channel,
        auto_signup: false,
      };
    case "CHECK_SOCIAL_SUCCESS":
      return {
        ...state,
        status: "success",
        social_email: action.social_email,
        auto_signup: true,
      };
    case "AUTO_SIGN_UP_SUCCESS":
      return {
        ...state,
        auto_signup: false,
      };
    case "CHECK_SOCIAL_RESET":
      return {
        ...state,
        status: null,
        access_token: "",
        channel: "",
        social_email: "",
        auto_signup: false,
      };
    case "SOCIAL_LOGIN":
      return {
        ...state,
        status: "login",
        auto_signup: false,
      };
    case "ERROR":
      return {
        ...state,
        status: "error",
        auto_signup: false,
      };
    case "AUTO_LOGIN":
      return {
        ...state,
        auto_login: true,
      };
    case "AUTO_LOGIN_SUCCESS":
      return {
        ...state,
        auto_login: false,
      };
    case "CHANGE_MENU_BTN":
      return {
        ...state,
        menu_button: action.menu_button,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

const initialPopupState = {
  login: false,
  signup: false,
  mainmenu: false,
  menu_button: "#000",
  page: "",
  event_login: false,
  event_signup: false,
  modal_test: false,
  address: false,
};

const UsersStateContext = createContext(null);
const UsersDispatchContext = createContext(null);
const SocialStateContext = createContext(null);
const SocialDispatchContext = createContext(null);
const PopupStateContext = createContext(null);
const PopupSetStateContext = createContext(null);

export const UsersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(usersReducer, initialAsyncState);
  const [socialState, socialDispatch] = useReducer(
    socialReducer,
    initialSocialState
  );
  const [popupState, setPopupState] = useState(initialPopupState);

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={dispatch}>
        <SocialStateContext.Provider value={socialState}>
          <SocialDispatchContext.Provider value={socialDispatch}>
            <PopupStateContext.Provider value={popupState}>
              <PopupSetStateContext.Provider value={setPopupState}>
                {children}
              </PopupSetStateContext.Provider>
            </PopupStateContext.Provider>
          </SocialDispatchContext.Provider>
        </SocialStateContext.Provider>
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
};

export function usePopupState() {
  const context = useContext(PopupStateContext);
  if (!context) {
    throw new Error("Cannot find PopupStateProvider");
  }
  return context;
}

export function usePopupSetState() {
  const context = useContext(PopupSetStateContext);
  if (!context) {
    throw new Error("Cannot find PopupSetStateProvider");
  }
  return context;
}

export function useSocialState() {
  const context = useContext(SocialStateContext);
  if (!context) {
    throw new Error("Cannot find SocialStateProvider");
  }
  return context;
}
export function useSocialDispatch() {
  const context = useContext(SocialDispatchContext);
  if (!context) {
    throw new Error("Cannot find SocialDispatchProvider");
  }
  return context;
}

export const useUsersState = () => {
  const state = useContext(UsersStateContext);
  if (!state) {
    throw new Error("Cannot find UsersProvider");
  }
  return state;
};

export const useUsersDispatch = () => {
  const dispatch = useContext(UsersDispatchContext);
  if (!dispatch) {
    throw new Error("Cannot find UsersProvider");
  }
  return dispatch;
};

export const getUsers = createAsyncDispatcher("GET_USERS", userApi.getUserInfo);
export const getUserEvent = createAsyncDispatcher(
  "GET_USER_EVENT",
  userApi.getUserEvent
);
export const getUserEventDetail = createAsyncDispatcher(
  "GET_USER_EVENT_DETAIL",
  userApi.getUserEventDetail
);

export const userUpdate = createAsyncDispatcher(
  "USER_UPDATE",
  userApi.userUpdate
);
export const signUp = createAsyncDispatcher("SIGN_UP", userApi.signUp);
export const login = createAsyncDispatcher("LOGIN", userApi.login);
export const logout = createAsyncDispatcher("LOGOUT", userApi.logout);
export const unRegister = createAsyncDispatcher(
  "UN_REGISTER",
  userApi.unRegister
);

export const refresh = createAsyncDispatcher("REFRESH", userApi.silentRefresh);
export const applyNonMembers = createAsyncDispatcher(
  "APPLY_NON_MEMBERS",
  userApi.applyNonMembers
);
export const applyMembers = createAsyncDispatcher(
  "APPLY_MEMBERS",
  userApi.applyMembers
);
export const checkSocial = createAsyncDispatcher("LOGIN", userApi.checkSocial);
export const socialSignUp = createAsyncDispatcher(
  "SIGN_UP",
  userApi.socialSignUp
);

export const statusReset = createAsyncDispatcher("STATUS_RESET");
