import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import Media from '../../Styles/MediaQuery';
import { useHistory, useLocation } from 'react-router-dom';
import box from './event-code-assets/gift-box.png';
import qs from 'query-string';

const Container = styled.div`
  margin-top: 60px;
  background: #094f9b;
  padding: 8vw;
  min-height: calc(100vh - 60px - 56px);
  ${Media.iphoneP`
    
  `}
  ${Media.iphoneS`
    
  `}
`;

const KorTitle = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;

  ${(props) => (props.small ? 'font-size: 22px;' : 'font-size: 23px;')}

  line-height: 140%;
  color: #ffffff;
  margin-bottom: 30px;
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 14px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 30px;
  text-transform: capitalize;

  color: #ffffff;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;
const Circle = styled.div`
  width: 33%;
  padding-top: 33%;
  position: relative;
  top: -50px;
  border-radius: 9999px;
  background-color: #2181d9;
  overflow: hidden;
  flex-shrink: 0;
  align-self: center;
  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
`;
const Card = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  margin-top: 80px;
  padding: 16px 20px 16px 12px;

  > div {
    margin-left: 19px;
  }
  .title {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;

    color: #000000;
  }
  .desc {
    font-family: Noto Sans KR;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 10px;
    color: #000000;
  }
`;
export const EventCodeComplete = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { giveaway_code } = qs.parse(location.search);

  return (
    <div>
      <Container>
        <KorTitle>
          경품 수령 정보 입력이
          <br />
          완료되었습니다.
        </KorTitle>
        <PrizeDesc>
          당첨된 QR은 경품 수령시까지
          <br />
          보관해주세요!
        </PrizeDesc>

        <Card>
          <Circle>
            <img src={box} />
          </Circle>
          <div>
            <p className="title">경품발송일</p>
            <p className="desc">
              {giveaway_code === 'D'
                ? '개인정보 입력일 기준, 7일 후에 SMS 발송됩니다.'
                : '개인정보 입력일 기준, 5일 이내에 경품 발송이 시작됩니다.' &&
                  giveaway_code === 'C'
                ? '펩시 굿즈는\n' + '9월 말부터 \n' + '순차배송됩니다.\n'
                : '개인정보 입력일 기준, 5일 이내에 경품 발송이 시작됩니다.'}
            </p>
          </div>
        </Card>
      </Container>
      <CheckResultButton
        bottom
        onClick={() => (window.location.href = 'https://khouseofpepsi.com')}
      >
        홈으로
      </CheckResultButton>
    </div>
  );
};
