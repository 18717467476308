import React from "react";
import { useRouteMatch, withRouter } from "react-router-dom";
import styled from "styled-components";
import { MVData } from "../../Constants/MVData";
import Media from "../../Styles/MediaQuery";
import MVMenuList from "../Items/MVMenuList";
import MVSubMenu from "../Items/MVSubMenu";
// import YouTube from "react-youtube";

const MVBlock = styled.div`
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${Media.tablet`
  flex-direction: column;
  width: 90%;
  `}

  ${Media.mobile`
  flex-direction: column;
  width: 90%;
  `}
`;

const VideoWrapper = styled.div`
  /* width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0; */
  position: relative;
  padding-bottom: 28%;
  width: 49%;
  height: 0;
  margin-right: 1%;
  margin-bottom: 20px;

  /* &::after {
    content: "";
    display: block;
    width: 70px;
    height: 70px;
    background: url("/Img/play-btn.png") no-repeat center;
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-35px, -35px);
    cursor: pointer;
  } */
  img {
    width: 100%;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  ${Media.tablet`
  width: 100%;
  margin-right: 0;
  padding-bottom: 56.25%;
  iframe {

  }
  `}

  ${Media.mobile`
  padding-bottom: 56.25%;
  width: 100%;
  iframe {

  }
  `}
`;

const NavSection = styled.div`
  margin: 60px 0;
  padding: 0 45px;

  ${Media.mobile`
  padding: 0 15px;
  `}
`;

const MV = () => {
  const { params: { detail }} = useRouteMatch();
  const videos = MVData.filter(item => detail ? item.path === detail : item.path === 'pepsi-korea')[0].videos;
  return (
    <>
      <MVBlock>
        {
          videos.map((item, idx) => (
            <VideoWrapper key={`${item}-${idx}`}>
              <iframe
                  src={item}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="video"
              />
            </VideoWrapper>
          ))
        }
      </MVBlock>
      <NavSection>
        <MVSubMenu />
        <MVMenuList />
      </NavSection>
    </>
  );
};

export default MV;
