/* eslint-disable no-unused-vars */
import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {usePopupSetState, usePopupState} from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";

const SamsungEventSectionBlock = styled.div`
  display: flex;
  background: url("/Img/zero-banner.jpg");
  background-size: cover;
  background-position: -1px -1px;
  width: 100%;
  height: 36vw;
  margin: 0;

  justify-content: center;
  
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
  ${Media.mobile`
    display: none;  
  `}
`;

const SamsungEventSectionBlockM = styled.div`
  display: none;
  background: url("/Img/zero-banner.jpg");
  background-size: cover;
  padding: 33px 0 69px;
  height: 35vw;
  ${Media.mobile`
    display: flex;
    display: none;
  `}
`;

const InnerBlock = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 77%;
  margin: 0 auto;
  flex-direction: column;
  ${Media.mobile`
    flex-direction: column;
    width: 84%;    

  `}
`;
const Description = styled.div`
  ${Media.desktop`
    // margin-left: 40px;
  `}
  em {
    font-size: 3vw;
    line-height: 1.5;
    ${Media.mobile`
      font-size: 24px;
      line-height: 36px;
    `}
  }
  p {
    font-size: 1vw;
    line-height: 24px;
    margin: 0.5vw 0;
    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
      padding-bottom: 12px;
    `}
  }
  color: #fff;
`;

const JoinButton = styled.div`
  display: flex;
  a {
    align-self: center;
    background-color: #0065c3;
    width: 220px;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;

    ${Media.mobile`
      width: 176px;
      height: 42px;
      justify-content: center;
    `}
  }
  p {
    align-self: center;
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
    `}
  }
  div {
    align-self: center;
    margin: 0 24px 0 18px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/event-join.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;

    display: none;
    ${Media.mobile`
      display: none;
    `}
  }
`;

const SectionInnerBlock = () => {
	const popupState = usePopupState();
	const popupSetState = usePopupSetState();

	const closeMainMenu = () =>
			popupSetState({
				...popupState,
				mainmenu: false,
			});

	return (
			<InnerBlock className="eng">
				{/*
      <Description>
        <em>
          PEPSI
          <br />
          Collaboration
        </em>
        <p>Collaboration</p>
      </Description>
*/}

				{/*<JoinButton>
        <Link to="/samsung_galaxy" onClick={closeMainMenu}>
          <div></div>
          <p>지금 바로보기</p>
        </Link>
      </JoinButton>*/}
			</InnerBlock>
	);
};

const SamsungEventSection = () => {
	return (
			<>
				<SamsungEventSectionBlock>
					<a href={"https://mall.lottechilsung.co.kr/search/showSearch.lecs?query=%ED%8E%A9%EC%8B%9C"} target={"_blank"}>
						<SectionInnerBlock/>
					</a>
				</SamsungEventSectionBlock>

				<SamsungEventSectionBlockM>
					<SectionInnerBlock/>
				</SamsungEventSectionBlockM>
			</>
	);
};

export default SamsungEventSection;
