import styled from 'styled-components';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Media from '../../Styles/MediaQuery';
import prize1 from './event-code-assets/prize1.svg';
import prize1Desktop from './event-code-assets/eventpage-1th.svg';
import prize2 from './event-code-assets/prize2.svg';
import prize2Desktop from './event-code-assets/eventpage-2nd.svg';
import prize3 from './event-code-assets/3rd-prize-spread.png';
import prize3Desktop from './event-code-assets/eventpage-3rd.svg';
import {
  usePopupSetState,
  usePopupState,
  useUsersDispatch,
  useUsersState,
} from '../../Context/UserContext';

import qr from './event-code-assets/qr.svg';

import can from './event-code-assets/event-can.svg';
import result from './event-code-assets/result.svg';
import box from './event-code-assets/box-circle.svg';
import Footer from './Footer';
import { EventCodeAgreementPopup } from './EventCodeAgreementPopup';
import { Modal } from 'react-responsive-modal';

const Container = styled.div`
  margin-top: 60px;
  padding: 4vw 4.5vw;
  background: #094f9b;

  ${Media.mobile`
     margin-top: 90px;
     background: #094f9b;
     padding: 15vw 5vw
  `}
`;

const Popup = styled.div`
  width: 100%;
  height: 50%;
`;

const Title = styled.p`
  font-family: MontrealTS;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  /* or 31px */

  text-transform: uppercase;

  color: #ffffff;
`;
const KorTitle = styled.p`
  margin-top: 32px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 140%;
  /* or 31px */

  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: #ffffff;
  margin-bottom: 21px;
`;
const AgreementButton = styled.button`
  background: #073f7c;
  padding: 9px 14px;
  width: initial;
  border: none;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  text-transform: capitalize;

  color: #ffffff;
`;
const EventDate = styled.div`
  width: max-content;
  margin-top: 28px;
  text-align: center;
  background: #ffffff;
  border-radius: 100px;
  padding: 8px 29px 6px;
  > b {
    font-weight: 700;
    margin-right: 20px;
  }
  font-family: Noto Sans KR;
  font-style: normal;
  font-size: 16px;
  line-height: 160%;
  /* identical to box height, or 26px */

  color: #000000;
  ${Media.mobile`
     width: unset;
  `}
`;

const OutDesc = styled.p`
  margin-top: 12px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  /* identical to box height, or 21px */
  text-transform: capitalize;

  color: #ffffff;

  opacity: 0.8;
  margin-bottom: 30px;
`;

const PrizeContainer = styled.div`
  display: flex;
  justify-content: space-around;
  ${Media.mobile`
    display: block;
  `}
`;

const PrizeWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 1vw 0 0;
  &:nth-child(3) {
    flex-grow: 1.7;
  }
  ${Media.tablet`
       &:nth-child(3) {
    /*flex-grow:1.8;*/
  }
   
  `}

  ${Media.mobile`
        margin: 0;
    `}
`;

const Prize = styled.img`
  display: none;
  ${Media.mobile`
    width 100%;
    display: block;
    margin-bottom: 14px;
  `}
`;

const PrizeDesktop = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 1vw;
  ${Media.mobile`
    display: none;
  `}
`;

const Prize3 = styled.img`
  display: none;
  margin-bottom: 1vw;
  ${Media.mobile`
        display: block;
        width: 100%;
        margin-bottom: 14px;
    `}
`;

const Prize3Desktop = styled.img`
  display: block;
  width: 100%;
  margin-bottom: 1vw;
  ${Media.mobile`
    display: none;
    `}
`;

const PrizeDesc = styled.p`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  margin-bottom: 46px;
  text-transform: capitalize;

  color: #ffffff;
`;
const CheckResultButton = styled.a`
  background: #000000;
  padding: 18px 0 16px;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-transform: uppercase;

  color: #efefef;
  text-align: center;
  width: 100%;
  ${(props) => (props.bottom ? '' : 'margin-top: 71px;')}
`;

const StepImage = styled.img`
  width: 16%;
  ${Media.mobile`
    width: 35%;
  `}
`;

const StepWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 0;
  > div {
    flex-direction: column;
    text-align: center;
  }
  > div > img {
    width: 18vw;
  }

  > div > div > p {
    margin: 1.5vw 0;
    line-height: 1.5;
  }
  ${Media.mobile`
    flex-direction: column;
    padding: 0;
    > div {
      flex-direction: row;
      text-align: left;
    }
    > div > img {
      width: 35%;
      position: relative;
    }
    `}
`;

const Step = styled.div`
  display: flex;
  align-items: center;
  font-family: Noto Sans KR;
  font-style: normal;
  line-height: 160%;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  margin-top: 60px;
  ul {
    margin-left: 2vw;
  }
  li {
    list-style: none;
    line-height: 2;
    font-size: 18px;
  }
  > div {
    margin-left: 18px;
    &:nth-child(1) {
      flex-grow: 1.7;
    }
  }

  > div p {
    font-size: 1.3vw;
    margin-left: 2vw;
    margin-bottom: 1vw;
  }
  ${Media.mobile`
    margin-top: 30px;
    ul {
        margin-left: 0;
    }
    
    li {
        list-style: none;
        font-size: 13px;
    }
    
    > div p {
      font-size: 16px;
      margin-left: 0;
    }
  `}
`;

const CautionWrapper = styled.div`
  display: block;
  margin: 4vw auto 0;
  width: 80vw;
  background: #08478c;
  padding: 2vw 3vw;
  ${Media.mobile`
      width: 88vw;
      margin: 0 auto;
      padding: 0;
      background: unset;
    `}
`;

const CautionTitle = styled.p`
  margin: 0 0 1vw;
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 160%;
  /* identical to box height, or 26px */

  text-transform: capitalize;
  color: #ffffff;

  ${Media.mobile`
    margin: 53px 0 12px;
  `}
`;
const Caution = styled.div`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 2.5;
  /* or 22px */

  color: #ffffff;

  ${Media.mobile`
    line-height: 2;
  `}
`;
export const EventCodePreview = (props) => {
  const [opened, setOpened] = useState(false);

  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [open, setOpen] = useState(true);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  useEffect(() => {
    // console.log(match.url);
    if (props.url === '/newtropepsipartner')
      localStorage.setItem('partner', true);
  }, []);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: '#fff',
      page: 'event',
    });
  }, []);

  return (
    <div>
      <EventCodeAgreementPopup opened={opened} setOpened={setOpened} />
      <Popup>
        <Modal
          open={open}
          onClose={onCloseModal}
          classNames={{
            modal: 'eventModal',
            closeButton: 'eventCloseBtn',
          }}
        >
          <h2>100 DAYS OF SUMMER</h2>
          <p>
            PEPSI 100 DAYS OF SUMMER
            <br />
            이벤트는 2021년 9월 30일을 마지막으로
            <br />
            종료되었습니다. 더욱 다채로운 혜택으로
            <br />
            새롭게 돌아올 펩시 이벤트를 기대해주세요!
          </p>
        </Modal>
      </Popup>
      <Container>
        <Title>
          100 Days of
          <br />
          Summer
        </Title>
        <KorTitle>
          QR코드 스캔하고
          <br />한 번에 당첨 확인!
        </KorTitle>
        <AgreementButton onClick={() => setOpened(true)}>
          이벤트 약관 보기
        </AgreementButton>
        <EventDate>
          <b>이벤트 기간</b> ~2021년 9월 30일까지
        </EventDate>
        <OutDesc>이벤트 제품 소진시 이벤트가 조기종료될 수 있습니다.</OutDesc>

        <PrizeContainer>
          <PrizeWrapper>
            <Prize src={prize1} />
            <PrizeDesktop src={prize1Desktop} />
            <PrizeDesc>1등 상품. 스마트 워치 (50명)</PrizeDesc>
          </PrizeWrapper>

          <PrizeWrapper>
            <Prize src={prize2} />
            <PrizeDesktop src={prize2Desktop} />
            <PrizeDesc>2등 상품. 블루투스 이어폰 프로 (100명)</PrizeDesc>
          </PrizeWrapper>

          <PrizeWrapper>
            <Prize3 src={prize3} />
            <Prize3Desktop src={prize3Desktop} />
            <PrizeDesc>
              3등 상품. 펩시 굿즈 or 기프티콘 랜덤발송 <br />
              (10,000명)
            </PrizeDesc>
          </PrizeWrapper>
        </PrizeContainer>

        <Title>how to</Title>
        <Step>
          <StepImage src={can} />
          <div>
            <p style={{ fontWeight: 500 }}>이벤트 참여 제품</p>
            <ul>
              <li>펩시 블루 245ml(업소용)</li>
              <li>펩시 블루 355ml(업소용)</li>
              <li>펩시 제로 슈거 355ml스터비(업소용)</li>
            </ul>
          </div>
        </Step>
        <StepWrapper>
          <Step>
            <StepImage src={qr} />
            <div>
              <p style={{ fontWeight: 500 }}>Step 01.</p>
              <p>
                펩시 캔따개 뒷면에 있는
                <br />
                QR코드 스캐닝!
              </p>
            </div>
          </Step>
          <Step>
            <StepImage src={result} />
            <div>
              <p style={{ fontWeight: 500 }}>Step 02.</p>
              <p>
                스캔 즉시 당첨결과 확인!
                <br />
                당첨 되셨다면,
                <br />
                경품 수령정보 입력
              </p>
            </div>
          </Step>
          <Step>
            <StepImage src={box} />
            <div>
              <p style={{ fontWeight: 500 }}>Step 03.</p>
              <p>
                수령정보를 제출하면
                <br />
                경품이 찾아갑니다!
              </p>
            </div>
          </Step>
        </StepWrapper>

        <CautionWrapper>
          <CautionTitle>꼭 확인하세요!</CautionTitle>
          <Caution>
            ✓ 당첨자의 개인정보가 사전 연락없이 변경되는 경우 당첨이 취소될 수
            있습니다.
            <br />
            ✓ 본이벤트는당사의사정에따라내용이변경될수있습니다.
            <br />
            ✓ 5만원 이상의 경품 수령에 대한 제세공과금(22%)은 당첨자 본인
            부담으로, 경품 발송은 제세공과금 납부 이후 이루어집니다.
            <br />
            ✓ 본이벤트경품이미지는실물과다를수있습니다.
            <br />✓ 이벤트 및 경품 문의 helpservice2020@naver.com
          </Caution>
        </CautionWrapper>
      </Container>
      <Footer />
    </div>
  );
};
