/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from "react";
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import MagazineSection2 from "../Layout/MagazineSection2";

import Meta from "../../Utils/Meta";
import 'react-responsive-modal/styles.css'
import moment from 'moment'
import { Popup } from '../Items/Popup';
import Footer2 from "./Footer2";
import MusicVideos from "../Layout/MusicVideos";
import BrandSection2 from "../Layout/BrandSection2";
import SamsungEventSection from "../Layout/SamsungEventSection";
import SamsungEventSection2 from "../Layout/SamsungEventSection2";
import SamsungEventSection2Mobile from "../Layout/SamsungEventSection2Mobile"
import SnsSection from "../Layout/SnsSection";

const MainBlock = styled.div`
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
  width: 100%;
  `}
`;
const MainSection = styled.div`
  width: 100%;
  padding: 120px 130px 84px;
  background-color: #010000;
  
  ${Media.tablet`
    padding: 120px 30px 84px;
  `}
  ${Media.mobile`
    padding: 107px 0 65px;
  `}
`;

const MainTitle = styled.div`
  color: white;
  padding: 0 6px 23px;
  ${Media.mobile`
    padding: 0 30px 26px;
  `}
  p {
    font-size: 2.25rem;
    font-weight: 400;
    ${Media.mobile`
      font-size: 2rem;
    `}
  }
  text-transform: uppercase;
  .sub-title {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  
`

const MainVideo = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const BottomEventSection = styled.div`
  .desktop {
    ${Media.mobile`
      display: none;
    `}
  }
  .mobile {
    display: none;
    ${Media.mobile`
      display: block;
    `}
  }
`

const Main2 = () => {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const initialPopupState = useMemo(() => {
    if(!localStorage.getItem('taste-of-korea-popup-hide-expire')) return true
    if(moment(localStorage.getItem('taste-of-korea-popup-hide-expire')).isAfter(moment())) return false
    return true
  })

  const [open, setOpen] = useState(initialPopupState);

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "main",
    });
  }, []);

  useEffect(() => {
    if (socialState.auto_login) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        login: true,
        gatorade: false,
      });
      socialDispatch({
        type: "AUTO_LOGIN_SUCCESS",
      });
    }

    if (socialState.auto_signup) {
      popupSetState({
        ...popupState,
        mainmenu: true,
        signup: true,
      });
      socialDispatch({
        type: "AUTO_SIGN_UP_SUCCESS",
      });
    }
  }, []);


  const metaData = {
    title: "K하우스오브펩시",
    description: "PEPSI SUMMER TASTE, 탑아티스트와 함께한 서머테이스트",
    canonical: "",
    keyword:
      "한국펩시콜라,펩시콜라,pepsi,pepsi-korea,pepsi korea,K하우스오브펩시, Khouseofpepsi,케이하우스오브펩시,코리아하우스오브펩시,한정판 이벤트,펩시 한정판 선물 이벤트",
  };

  return (
    <MainBlock>
      <Meta data={metaData} />
      <Wrapper >
        {/*<Popup open={ open } setOpen={ setOpen } />*/}
        <MainSection>
          <MainTitle className="eng">
           <p>pepsi zero sugarr</p>
           {/*<p className="sub-title">sub title</p>*/}
          </MainTitle>
          <MainVideo>
            <video
              type="video/mp4"
              src="Img/video/ive-tvc-30.mp4"
              /*poster="Img/intro.jpg"*/
              controls={true}
              autoPlay={true}
              loop={true}
              playsInline={true}
              muted={true}
            ></video>
          </MainVideo>
        </MainSection>
        {/*<SamsungEventSection />*/}
        {/*<SamsungEventSection2Mobile />*/}

        {/*<SamsungEventSection />*/}
        
        <BrandSection2 />
        <SamsungEventSection />
        <SamsungEventSection2Mobile />
        <MagazineSection2 />

        <MusicVideos />

        <SnsSection />
        <Footer2 />
      </Wrapper>
    </MainBlock>
  );
};

export default Main2;
