/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { findPassword } from "../../Service/UserApi";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import {
  ValidationInput,
  Alert,
  onFocus,
  onBlur,
} from "../../Styles/InputStyle";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";

const FindPWForm = styled.form`
  width: 100%;
  padding: 150px 0 0 0;
  color: rgb(45, 49, 56);

  h3 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
    margin-bottom: 10px;
  }

  strong {
    display: block;
    margin-bottom: 30px;
  }
`;

const ErrorMsg = styled.div`
  color: rgb(1, 74, 153);
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
`;

const FindPW = ({ popUpPage, setPopUpPage }) => {
  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: "all",
  });

  const [errorMsg, setErrorMsg] = useState("");

  const onSubmit = async (data) => {
    // console.log(data);
    try {
      const response = await findPassword(data);

      // console.log("data", data, response);
      onFindPWComplete();
    } catch (e) {
      let errorMsg = "";
      // console.log(e.response.request.responseText);
      errorMsg = JSON.parse(e.response.request.responseText);
      // console.log(errorMsg);

      let first_key = Object.keys(errorMsg)[0];
      let first_value = errorMsg[Object.keys(errorMsg)[0]];

      // console.log(first_key, first_value);

      if (first_value === "user with this email does not exist")
        setErrorMsg(t("valid:not-found-with-mail"));
      else if (first_value === "name and email does not match")
        setErrorMsg(t("valid:not-match-mail-name"));
      else if (first_value === "social user can't reset password")
        setErrorMsg(t("valid:social-cannot-find"));
      else if (first_value === "User Does Not Exist")
        setErrorMsg(t("valid:cannot-find-account"));
      else setErrorMsg(first_value);
    }

    reset();
  };

  const watchEmail = watch("email");
  const watchName = watch("name");

  const onFindPWComplete = () => {
    setPopUpPage(5);
  };

  const { t } = useTranslation();

  return (
    <>
      <FindPWForm onSubmit={handleSubmit(onSubmit)}>
        <h3>{t("find-password")}</h3>
        <strong>{t("input-email")}</strong>
        <ValidationInput
          name="name"
          label="name"
          placeholder={t("name")}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          validation={{ required: true, minLength: 2 }}
        />
        {errors.username && <Alert>{t("valid:not-valid-username")}</Alert>}

        <ValidationInput
          name="email"
          label="email"
          placeholder={t("email")}
          autoComplete="off"
          onFocus={onFocus}
          onBlur={onBlur}
          register={register}
          validation={{
            required: true,
            minLength: 5,
            pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
          }}
        />
        {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
        {errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
        <br />
        <MainButton2 bgColor="#0065c3" disabled={!(watchName && watchEmail)}>
          {t("find-password")}
        </MainButton2>
      </FindPWForm>
    </>
  );
};

export default FindPW;
