/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import SelectSignUp from "../Layout/SelectSignUp";
import AgreementPersonal from "../Layout/AgreementPersonal";
import AgreementBusiness from "../Layout/AgreementBusiness";
import PersonalSignUp from "../Layout/PersonalSignUp";
import BusinessSignUp from "../Layout/BusinessSignUp";
import CompleteSignUp from "../Layout/CompleteSignUp";
import SocialSignUp from "../Layout/SocialSignUp";

const Wrapper = styled.div`
  padding-top: 100px;
`;

const SignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const lorem = (
  <p>
    Mauris ac arcu sit amet dui interdum bibendum a sed diam. Praesent rhoncus
    congue ipsum elementum lobortis. Ut ligula purus, ultrices id condimentum
    quis, tincidunt quis purus. Proin quis enim metus. Nunc feugiat odio at eros
    porta, ut rhoncus lorem tristique. Nunc et ipsum eu ex vulputate consectetur
    vel eu nisi. Donec ultricies rutrum lectus, sit ame feugiat est semper
    vitae. Proin varius imperdiet consequat. Proin eu metus nisi. In hac
    habitasse platea dictumst. Vestibulum ac ultrices risus. Pellentesque arcu
    sapien, aliquet sed orci sit amet, pulvinar interdum velit. Nunc a rhoncus
    ipsum, maximus fermentum dolor. Praesent aliquet justo vitae rutrum
    volutpat. Ut quis pulvinar est.
  </p>
);

const ModalStyle = ({ checkSocial }) => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  // const onOpenModal = () =>
  //   popupSetState({
  //     ...popupState,
  //     modal_test: true,
  //   });

  const onCloseModal = () =>
    popupSetState({
      ...popupState,
      modal_test: false,
    });

  const onOpenAddress = () =>
    popupSetState({
      ...popupState,
      address: true,
    });

  const onCloseAddress = () =>
    popupSetState({
      ...popupState,
      address: false,
    });

  const [popUpPage, setPopUpPage] = useState(1);

  const pageComponent = {
    1: (
      <SelectSignUp
        checkSocial={checkSocial}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    2: <AgreementPersonal popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    3: <PersonalSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    4: (
      <CompleteSignUp
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
        closePopUp={onCloseModal}
      />
    ),

    5: <AgreementBusiness popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    6: <BusinessSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    7: <SocialSignUp popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
  };

  return (
    <>
      <Modal
        open={popupState.modal_test}
        onClose={onCloseModal}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          closeButton: "customCloseBtn",
        }}
      >
        <SignUpWrapper>{pageComponent[popUpPage]}</SignUpWrapper>
      </Modal>
    </>
  );
};

export default ModalStyle;
