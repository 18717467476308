import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const FooterBlock = styled.footer`
  padding: 60px 0;

  color: #fff;
  background: #333;
  ${Media.tablet`

  `}

  ${Media.mobile`
    padding-bottom: 20px;
  `}
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
`;

const FooterBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  ${Media.tablet`

  `}

  ${Media.mobile`
  `}
`;

const FooterInfo = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.875rem;
    line-height: 1.6;
    margin: 10px 0;
    color: #ddd;
  }
  li {
   margin: 0 10px 5px;
  }
  
  li {
  
  }
/*  li a::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 10px;
    background-color: #ddd;
    vertical-align: middle;
    margin: 0 10px 5px;
  }*/
  li:last-child a::after {
    display: none;
  }
  li a{
    color: #fff;
  }
`;

const FLogo = styled.div`
  width: 30px;
  img {
    width: 100%;
  }
  margin-right: 50px;
  margin-left: 10px;

  ${Media.tablet`

  `}

  ${Media.mobile`
  margin-right: 0;
  margin-left: 10px;
  padding: 0 0 10px;
  width: 100%;
  img{
    width: 30px;
  }
  `}
`;
const Copyright = styled.div`
  width: 100%;
  margin: 20px 10px 0px;
  font-size: 0.875rem;
  color: #aaa;

  ${Media.mobile`
    padding: 10px 0 0;
    font-size: 0.7rem;
  `}
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterBlock>
      <Wrapper>
        <FooterBox>
          <FLogo>
            <img src="/Img/logo.png" alt="K하우스오브펩시" />
          </FLogo>

          <div className="footer-sns">
            <a href="https://www.instagram.com/pepsi.korea/" target="new">
              <img src="/Img/sns-insta.svg" alt="pepsi-instagram" />
            </a>
            <a href="https://www.facebook.com/PepsiKorea/" target="new">
              <img src="/Img/sns-facebook.svg" alt="pepsi-facebook" />
            </a>
          </div>
        </FooterBox>
        <FooterInfo>
          <ul>
            <li>
              <Link to="/privacy">{t('policy:privacy-policy-title')}</Link>
            </li>
            <li>
              <Link to="/policy">{t('policy:policy-title')}</Link>
            </li>
          </ul>
          <ul>
            <li>{t('policy:company')}</li>
            <li>{t('policy:owner')}</li>
            <li>{t('policy:business-registration-number')}</li>
            <li>{t('policy:business-address')}</li>
            <li>{t('policy:inquiry')}</li>
          </ul>
        </FooterInfo>
        <Copyright className="eng">
          copyright &copy; 2020 Pepsi. All rights reserved.
        </Copyright>
      </Wrapper>
    </FooterBlock>
  );
};

export default Footer;
