/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
  refresh,
} from "../../Context/UserContext";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { loginSuccess } from "../../Service/UserApi";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";

const OAuth = ({ match }) => {
  // console.log(match);
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const [redirectState, setRedirectState] = useState(null);

  useEffect(() => {
    const { naver_id_login } = window;
    // console.log(naver_id_login);
    // console.log(window);

    const loginNaver = new naver_id_login(
      "OE6el1XAzOjY92DMopcJ",
      'https://web.pep1.kr/oAuth'
    );

    // console.log(loginNaver);

    // 접근 토큰 값 출력
    // alert(loginNaver.oauthParams.access_token);
    // console.log(loginNaver.oauthParams);
    // 네이버 사용자 프로필 조회
    // loginNaver.get_naver_userprofile("naverSignInCallback()");
    // 네이버 사용자 프로필 조회 이후 프로필 정보를 처리할 callback function
    // function naverSignInCallback() {
    //   alert(loginNaver.getProfileData("email"));
    //   alert(loginNaver.getProfileData("nickname"));
    //   alert(loginNaver.getProfileData("age"));

    const { access_token } = loginNaver.oauthParams;
    const payload = {
      access_token: access_token,
      channel: "N",
    };

    socialDispatch({
      type: "CHECK_SOCIAL_LOADING",
      access_token: access_token,
      channel: "N",
    });

    checkSocial(payload);
    // }
  }, []);

  const initializeUserInfo = async () => {
    const loggedInfo = localStorage.getItem("refresh");
    if (!loggedInfo) return;
    await refresh(dispatch);
  };

  // const api = axios.create({
  //   baseURL: "https://khouseofpepsi.com",
  // });

  const checkSocial = async (payload) => {
    // console.log(socialState);
    try {
      const response = await axios.post(
        `#{DOMAIN}#/api/v1/auth/social/check/`,
        payload
      );
      // console.log(response);
      // console.log(response.data.email);
      if (response.status === 200 && response.data.email) {
        // console.log("가입하지 않은 계정입니다.");
        socialDispatch({
          type: "CHECK_SOCIAL_SUCCESS",
          social_email: response.data.email,
        });
        // setOpenSignUp(true);
        // setOpenLogIn(false);
        popupSetState({
          ...popupState,
          signup: true,
          login: false,
        });
        setRedirectState("/");
      } else {
        // console.log("이미 가입된 계정입니다.");
        const { refresh } = response.data;
        localStorage.setItem("social", true);
        localStorage.setItem("refresh", refresh);
        loginSuccess(response);
        initializeUserInfo();
        // return response;
        popupSetState({
          ...popupState,
          signup: false,
          login: false,
        });
        socialDispatch({
          type: "SOCIAL_LOGIN",
        });
        setRedirectState("newtropepsicustomer");
      }
    } catch (err) {
      // console.error(err);
      // console.log(err.response);
      if (err.response.data[0] === "User with this e-mail already exists") {
        // this.props.setError(err.response.data.description)
        alert("일반회원 또는 다른 채널로 가입한 이메일입니다.");
        popupSetState({
          ...popupState,
          signup: false,
          login: true,
        });
      } else {
        alert(err.response.statusText + "고객센터로 문의바랍니다.");
      }
    }
  };
  // console.log(redirectState);
  // console.log(socialState);
  if (socialState.status === "loading") return <DataLoadingStyle />;
  if (socialState.status === "login")
    return <Redirect to="/" />;

  return (
    <div>
      {redirectState ? (
        <Redirect to={redirectState} />
      ) : (
        <div>
          로그인이 완료되었습니다.
          <br />
          이전 화면으로 이동합니다.
        </div>
      )}
    </div>
  );
};

export default OAuth;
