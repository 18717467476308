/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import {
  useUsersState,
  useUsersDispatch,
  socialSignUp,
} from "../../Context/UserContext";
import { useForm } from "react-hook-form";
import styled from "styled-components";
// import Media from "../../Styles/MediaQuery";
import { ValidationInput, Input } from "../../Styles/InputStyle";
import SubHeader from "./SubHeader";
import AddPopup from "./AddPopup";
import { MainButton } from "../../Styles/ButtonStyle";
import {useTranslation} from "react-i18next";

const SignUpForm = styled.form`
  width: 100%;

  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
  /* input {
    width: ${(props) =>
    props.name === "address"
      ? "65%"
      : "90%"};

    margin: 5px 14px;
    padding: 22px 0;

    color: rgb(45, 49, 56);

    font-size: 16px;
    letter-spacing: 0.14px;
    font-weight: 200;

    border: 0;
    border: 1px solid rgb(217, 219, 224);
  } */
`;

const SignUpWrapper = styled.div`
  padding: 80px 30px;
  /* height: 100vh;
  padding: 80px 20px 0 20px; */
  /* overflow: auto; */
  
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const AddressWrapper = styled.div`
`;

const SearchBtn = styled.div`
  display: inline-block;
  /* width: 20%; */
  padding: 5px;
  /* background-color: #ccc; */
  cursor: pointer;

  width: 80px;
  height: 50px;
  vertical-align: middle;
  background: #0065c3 url("/Img/search-icon.svg") no-repeat center;
  background-size: 20px;
  text-indent: -999px;
  overflow: hidden;
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const initialState = {
  email: "",
  password1: "",
  password2: "",
  name: "",
  phonenumber: "",
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
  account_type: "P",
};
const addInitialState = {
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};
const PersonalSignUp = ({
  popUpPage,
  setPopUpPage,
  socialToken,
  SetSocialToken,
}) => {
  const pageChange = () => setPopUpPage(4);
  // console.log(socialToken);
  const state = useUsersState();
  const dispatch = useUsersDispatch();

  const [openAddress, setOpenAddress] = useState(false);

  const [address, setAddress] = useState(addInitialState);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");

  const openAddressPopUp = () => setOpenAddress(true);

  const [inputs, setInputs] = useState({
    address3: "",
  });

  const { address3 } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const { register, handleSubmit, reset, watch, errors } = useForm({
    mode: "all",
  });

  const onSubmit = (data) => {
    // console.log(data);
    // console.log("address", address);
    // console.log(socialToken);

    let fullData = {
      name: data.name,
      phonenumber: data.phonenumber,
      address: {
        province: address.province,
        street_name_address: address.street_name_address,
        land_lot_number_address: address.land_lot_number_address,
        details: inputs.address3,
        postcode: address.postcode,
      },
      account_type: "P",
      access_token: socialToken.access_token,
      channel: socialToken.channel,
    };

    // console.log(fullData);
    socialSignUp(dispatch, fullData);

    reset();
    pageChange();
  };

  const watchName = watch("name");
  const watchPhone = watch("phonenumber");
  const watchAddress = watch("address1");

  const { t } = useTranslation();

  // console.log("state", state);
  // console.log(watchPassword);
  // console.log("address", address);
  return (
    <>
      <SignUpForm onSubmit={handleSubmit(onSubmit)}>
        <SignUpWrapper>
          <SubHeader
            title="JOIN"
            subtitle="회원가입"
            color="#000"
            textAlign="center"
            marginBottom="30px"
          />
          <Input
            name="email"
            label="email"
            placeholder={socialToken.email}
            autoComplete="off"
            readOnly={socialToken.email}
          />

          <ValidationInput
            name="name"
            label="name"
            placeholder="이름"
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            register={register}
            validation={{ required: true, minLength: 2 }}
          />
          {errors.name && <Alert>{t('valid:not-valid-username')}</Alert>}
          <ValidationInput
            name="phonenumber"
            label="phonenumber"
            placeholder="010-1234-5678"
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            register={register}
            validation={{
              required: true,
              minLength: 2,
              pattern: /^\d{3}-\d{3,4}-\d{4}$/,
            }}
          />
          {errors.phonenumber && <Alert>{t('valid:not-valid-phone-number')}</Alert>}
          <AddressWrapper>
            <Input
              _translateProps={["placeholder"]}
              className="input-postcode"
              name="postcode"
              label="postcode"
              placeholder="우편번호"
              readOnly={true}
              autoComplete="off"
              value={postcode}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={() => openAddressPopUp()}
            />
            <Input
              _translateProps={["placeholder"]}
              className="input-address"
              name="address1"
              label="address1"
              placeholder="주소지1"
              readOnly={true}
              autoComplete="off"
              value={address1}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={() => openAddressPopUp()}
            />
            <SearchBtn
              // className="btn-address"
              onClick={() => openAddressPopUp()}
            >
              검색
            </SearchBtn>
            <Input
              _translateProps={["placeholder"]}
              name="address2"
              label="address2"
              placeholder="참고항목"
              readOnly={true}
              autoComplete="off"
              value={address2}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <Input
              name="address3"
              label="address3"
              placeholder="상세주소"
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={address3}
            />
          </AddressWrapper>
        </SignUpWrapper>
        <MainButton
          bgColor="#0065c3"
          active={!!(watchName && watchPhone && watchAddress)}
        >
          회원가입
        </MainButton>
      </SignUpForm>

      {openAddress && (
        <AddPopup
          setOpenAddress={setOpenAddress}
          openAddress={openAddress}
          setAddress={setAddress}
          setAddress1={setAddress1}
          setAddress2={setAddress2}
          setPostcode={setPostcode}
        />
      )}
    </>
  );
};

export default PersonalSignUp;
