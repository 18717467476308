/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { usePopupSetState, usePopupState } from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";

const MainEventSectionBlock = styled.div`
  display: flex;
  // background: url("/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/samgsung-bg.png");
  background: url("/Img/samsung-bg.jpg");
  background-color: red;
  background-size: cover;
  padding: padding: 35px 11vw 40px;
  height: 12vw;  
  margin: 0;
  
  justify-content: center;
  ${Media.mobile`
    display: none;  
  `}
`;

const MainEventSectionBlockM = styled.div`
  display: none;
  background: url("/Img/samsung-bg-m.jpg");
  background-size: cover;
  padding: 8vw 0;
  height: 210px;
  ${Media.mobile`
    display: flex;
  `}
`;

const InnerBlock = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 62%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  ${Media.mobile`
    flex-direction: column;
    width: 84%;
    align-items: flex-start;
    justify-content: center;
  `}
`;
const Description = styled.div`
  ${Media.desktop`
    // margin-left: 40px;
  `}
  em {
    font-size: 2.4vw;
    line-height: 1.5;
    ${Media.mobile`
      font-size: 24px;
      line-height: 36px;
    `}
  }
  p {
    font-size: 1.4vw;
    line-height: 24px;
    margin: 0.5vw 0 0;
    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
      padding-bottom: 12px;
    `}
  }
  color: #fff;
`;

const JoinButton = styled.div`
  display: flex;
  padding-top: 15px;
  a {
    align-self: center;
    background-color: #0065c3;
    width: 220px;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;

    ${Media.mobile`
      width: 176px;
      height: 42px;
      justify-content: center;
    `}
  }
  p {
    align-self: center;
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    ${Media.mobile`
      font-size: 14px;
      line-height: 21px;
    `}
  }
  div {
    align-self: center;
    margin: 0 24px 0 18px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/event-join.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;

    display: none;
    ${Media.mobile`
      display: none;
    `}
  }
`;

const SectionInnerBlock = () => {
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const closeMainMenu = () =>
    popupSetState({
      ...popupState,
      mainmenu: false,
    });

  return (
    <InnerBlock className="eng">
      <Description>
        <em>
          pepsi
          <br />
          collaboration
        </em>
      </Description>

      <JoinButton>
        <Link to="/samsung_galaxy" onClick={closeMainMenu}>
          <div></div>
          <p>지금 바로보기</p>
        </Link>
      </JoinButton>
    </InnerBlock>
  );
};

const MainEventSection = () => {
  return (
    <>
      <MainEventSectionBlock>
        <SectionInnerBlock />
      </MainEventSectionBlock>

      <MainEventSectionBlockM>
        <SectionInnerBlock />
      </MainEventSectionBlockM>
    </>
  );
};

export default MainEventSection;
