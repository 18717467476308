/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import axios from "axios";

import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  signUp,
  socialSignUp,
} from "../../Context/UserContext";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { ValidationInput, Input } from "../../Styles/InputStyle";
import SubHeader from "./SubHeader";
import AddPopup from "./AddPopup";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import { Link } from "react-router-dom";
import { ReadOnlyInput } from "./PersonalSignUp";
import { Modal } from "react-responsive-modal";
import DaumPostcode from "../../Utils/DaumPostcode";

const SignUpForm = styled.form`
  width: 100%;
  /* height: 100vh; */

  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }

  input[type="radio"] {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url("./Img/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }
  /* label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url("./Img/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  } */
  label {
    display: inline-block;
    font-size: 0.875rem;
    a {
      text-decoration: underline;
      display: inline;
    }
  }
  input[type="radio"]:checked {
    background-color: #0065c3;
  }
`;

const SignUpWrapper = styled.div``;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const AddressWrapper = styled.div`
  padding-bottom: 50px;
`;

const SelectBType = styled.div`
  text-align: center;
  padding: 30px 0 20px;
  label {
    margin-right: 20px;
  }
`;

const SearchBtn = styled.div`
  display: inline-block;
  /* width: 20%; */
  padding: 5px;
  /* background-color: #ccc; */
  cursor: pointer;

  width: 80px;
  height: 50px;
  vertical-align: middle;
  background: #0065c3 url("./Img/search-icon.svg") no-repeat center;
  background-size: 20px;
  text-indent: -999px;
  overflow: hidden;
`;

const EmailWrapper = styled.div`
  display: flex;
`;
const EmailBtn = styled.div`
  padding: 0 5px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  background: #0065c3;
  color: #fff;
  border: 0;
  margin: 5px 0;
  font-size: 0.875rem;

  &:disabled {
    background: #ccc;
  }
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const initialState = {
  email: "",
  password1: "",
  password2: "",
  name: "",
  phonenumber: "",
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
  account_type: "P",
};
const addInitialState = {
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};

const BusinessSignUp = ({ popUpPage, setPopUpPage }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  const [openAddress, setOpenAddress] = useState(false);

  const [address, setAddress] = useState(addInitialState);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");

  const [bType, setBType] = useState("S");

  const openAddressPopUp = () => setOpenAddress(true);
  const closeAddress = () => setOpenAddress(false);

  const [inputs, setInputs] = useState({
    address3: "",
  });

  const { address3 } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const onChangeBtypeS = (e) => {
    // console.log("s", e.target.id);
    setBType(e.target.id);
  };

  const onChangeBtypeC = (e) => {
    // console.log("c", e.target.id);
    setBType(e.target.id);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (is_login && success) setPopUpPage(4);
  }, [state.is_login]);

  const [emailCheck, setEmailCheck] = useState(false);
  const [emailCheckMsg, setEmailCheckMsg] = useState(null);

  const { t } = useTranslation();

  const checkEmailDuplicates = async (payload) => {
    try {
      const response = await axios.post(
        `https://web.pep1.kr/api/v1/auth/email/duplicates/`,
        payload
      );
      // console.log(response);
      if (response.status === 200) {
        setEmailCheck(true);
        setEmailCheckMsg(t("valid:mail-available"));
      }
    } catch (err) {
      setEmailCheck(false);
      setEmailCheckMsg(t("valid:mail-exist"));
      console.error(err);
      console.log(err.response);
    }
  };

  const checkEmail = () => {
    let emailValues = {
      email: getValues("email"),
    };
    // console.log(emailValues);
    if (emailValues.email === "") setEmailCheckMsg("이메일을 입력해주세요.");
    else {
      checkEmailDuplicates(emailValues);
    }
  };

  useEffect(() => {
    if (address.postcode) {
      // console.log(address.postcode);
      setValue("postcode", address.postcode);
      // console.log(getValues("postcode"));
    }
  }, [address.postcode]);

  const onSubmit = (data) => {
    // console.log(data);
    // console.log("address", address);

    // const { password, passwordCheck } = data;
    // data.password = SHA256(password);
    // data.passwordCheck = SHA256(password);

    if (socialState.status !== "success") {
      let fullData = {
        business_type: bType,
        company_name: data.companyName,
        business_number: data.businessNumber,
        email: data.email,
        password1: data.password,
        password2: data.passwordCheck,
        name: data.username,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: inputs.address3,
          postcode: address.postcode,
        },
        account_type: "B",
      };

      // console.log(fullData);

      signUp(dispatch, fullData);
    } else {
      let fullData = {
        business_type: bType,
        company_name: data.companyName,
        business_number: data.businessNumber,
        name: data.username,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: inputs.address3,
          postcode: address.postcode,
        },
        account_type: "B",
        access_token: socialState.access_token,
        channel: socialState.channel,
      };

      // console.log(fullData);
      socialSignUp(dispatch, fullData);
    }
    // pageChange();
  };

  const cancleHypenBusiness = (e) => {
    let businessValue = getValues("businessNumber");
    var replaceNotInt = /[^0-9]/gi;

    if (businessValue !== replaceNotInt)
      setValue("businessNumber", businessValue.replace(replaceNotInt, ""));
  };

  const cancleHypen = (e) => {
    let phoneValue = getValues("phonenumber");
    var replaceNotInt = /[^0-9]/gi;

    if (phoneValue !== replaceNotInt)
      setValue("phonenumber", phoneValue.replace(replaceNotInt, ""));
  };

  const watchBusinessNumber = watch("businessNumber");
  const watchCompanyName = watch("companyName");
  const watchEmail = watch("email");
  const watchPassword = watch("password");
  const watchName = watch("username");
  const watchPhone = watch("phonenumber");
  const watchPostcode = watch("postcode");

  // console.log("state", state);
  // console.log(watchPassword);

  // const pageChange = () => {
  //   if (!success) return;
  //   else {
  //     console.log("success");
  //     setPopUpPage(4);
  //     // reset();
  //   }
  // };

  if (loading) return <DataLoadingStyle />;
  let errorMsg = "";
  if (error) {
    errorMsg = JSON.parse(error.responseText);
    // console.log(JSON.parse(error.responseText));

    let first_key = Object.keys(errorMsg)[0];
    let first_value = errorMsg[Object.keys(errorMsg)[0]];

    if (first_key === "phonenumber") alert("핸드폰번호 형식에 맞지 않습니다.");
    else alert(errorMsg);
    // return (
    //   <>
    //     {errorMsg && (
    //       <SignUpWrapper>
    //         <h3>
    //           Error!
    //           <br />
    //           {first_key} : {first_value[0]} <br />
    //           <Link to="/">
    //             <button>{t("try-again")}</button>
    //           </Link>
    //         </h3>
    //       </SignUpWrapper>
    //     )}
    //   </>
    // );
  }

  return (
    <>
      <SignUpForm onSubmit={handleSubmit(onSubmit)}>
        <SignUpWrapper>
          <SubHeader title="Join" subtitle="사업자회원가입" color="#000" />
          <SelectBType>
            {/* <SelectTabStyle tabData={tabData} /> */}
            <input
              type="radio"
              id="S"
              name="selectBusiness"
              onChange={onChangeBtypeS}
              defaultChecked={true}
            />
            <label for="Sole-proprietorship">{t("personal-business")}</label>
            <input
              type="radio"
              id="C"
              name="selectBusiness"
              onChange={onChangeBtypeC}
            />
            <label for="corporation">{t("corporate-business")}</label>
          </SelectBType>
          <ValidationInput
            name="businessNumber"
            label="businessNumber"
            placeholder={t("business-number")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={cancleHypenBusiness}
            register={register}
            validation={{
              required: true,
              minLength: 10,
              maxLength: 10,
            }}
          />
          {errors.businessNumber && (
            <Alert>{t("valid:not-valid-business-number")}</Alert>
          )}
          <ValidationInput
            name="companyName"
            label="companyName"
            placeholder={t("company-name")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            register={register}
            validation={{ required: true, minLength: 2 }}
          />
          {errors.companyName && <Alert>{t("valid:not-valid-company")}</Alert>}
          {socialState.status === "success" ? (
            <Input
              name="email"
              label="email"
              placeholder={t("email")}
              autoComplete="off"
              value={socialState.social_email}
              readOnly={true}
            />
          ) : (
            <>
              {/* <ValidationInput
                name="email"
                label="email"
                placeholder={t("email")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                register={register}
                validation={{
                  required: true,
                  minLength: 5,
                  pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                }}
              />
              {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>} */}
              <EmailWrapper>
                <ValidationInput
                  name="email"
                  label="email"
                  placeholder={t("email")}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  register={register}
                  validation={{
                    required: true,
                    minLength: 5,
                    pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                  }}
                />{" "}
                <EmailBtn
                  onClick={checkEmail}
                  disabled={
                    errors.email || getValues("email") === "" || !watchEmail
                  }
                >
                  중복 체크
                </EmailBtn>
              </EmailWrapper>
              {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
              {!emailCheck && <Alert>{t("valid:check-duplicate")}</Alert>}
              {emailCheckMsg && <Alert>{emailCheckMsg}</Alert>}
              <ValidationInput
                type="password"
                name="password"
                label="password"
                placeholder={t("password")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                register={register}
                validation={{ required: true, minLength: 8, maxLength: 16 }}
              />
              {errors.password && (
                <Alert>{t("valid:not-valid-password")}</Alert>
              )}
              <ValidationInput
                type="password"
                name="passwordCheck"
                label="passwordCheck"
                placeholder={t("password-check")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                register={register}
                validation={{
                  required: true,
                  validate: (value) => value === watchPassword,
                }}
              />
            </>
          )}
          {errors.passwordCheck && (
            <Alert>{t("valid:not-valid-password-check")}</Alert>
          )}
          <ValidationInput
            name="username"
            label="username"
            placeholder={t("name")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            register={register}
            validation={{ required: true, minLength: 2 }}
          />
          {errors.username && <Alert>{t("valid:not-valid-username")}</Alert>}
          <ValidationInput
            name="phonenumber"
            label="phonenumber"
            placeholder={t("number")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={cancleHypen}
            register={register}
            validation={{
              required: true,
              minLength: 10,
              maxLength: 11,
            }}
          />
          {errors.phonenumber && (
            <Alert>{t("valid:not-valid-phone-number")}</Alert>
          )}
          <AddressWrapper>
            <Input
              className="input-postcode"
              name="postcode"
              label="postcode"
              placeholder={t("postcode")}
              readOnly={true}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              ref={register}
              onClick={() => openAddressPopUp()}
            />
            {errors.postcode && <Alert>{t("valid:not-valid-address")}</Alert>}

            <Input
              className="input-address"
              name="address1"
              label="address1"
              placeholder={t("address01")}
              readOnly={true}
              autoComplete="off"
              value={address1}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={() => openAddressPopUp()}
            />
            <SearchBtn
              // className="btn-address"
              onClick={() => openAddressPopUp()}
            >
              검색
            </SearchBtn>
            <Input
              name="address2"
              label="address2"
              placeholder={t("address02")}
              readOnly={true}
              autoComplete="off"
              value={address2}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <Input
              name="address3"
              label="address3"
              placeholder={t("address03")}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={address3}
            />
          </AddressWrapper>
        </SignUpWrapper>
        {errorMsg && <Alert>{errorMsg}</Alert>}
        {socialState.status === "success" ? (
          <MainButton2
            bgColor="#0065c3"
            disabled={
              !(
                watchBusinessNumber &&
                watchCompanyName &&
                watchName &&
                watchPhone &&
                postcode
              )
            }
          >
            회원가입
          </MainButton2>
        ) : (
          <MainButton2
            bgColor="#0065c3"
            disabled={
              !(
                watchBusinessNumber &&
                watchCompanyName &&
                watchEmail &&
                emailCheck &&
                watchPassword &&
                watchName &&
                watchPhone &&
                postcode
              )
            }
          >
            회원가입
          </MainButton2>
        )}
      </SignUpForm>

      {openAddress && (
        <Modal
          open={openAddress}
          onClose={closeAddress}
          classNames={{
            overlay: "customAddressOverlay",
            modal: "customAddressModal",
            closeButton: "customCloseBtn",
            animationIn: "customEnterAnimation",
            animationOut: "customLeaveAnimation",
          }}
          animationDuration={0}
        >
          <DaumPostcode
            setOpenAddress={setOpenAddress}
            setAddress={setAddress}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setPostcode={setPostcode}
          />
        </Modal>
      )}
    </>
  );
};

export default BusinessSignUp;
