/* eslint-disable no-unused-vars */
import React from "react";
import { useUsersState, useUsersDispatch } from "../../Context/UserContext";
import styled from "styled-components";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation, initReactI18next } from "react-i18next";

const CompleteSignUpBlock = styled.div`
  width: 100%;
  /* padding: 150px 30px 0 30px; */
  padding-top: 50px;
  box-sizing: border-box;
  h3 {
    text-align: left;git l
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
  }
`;

const CompleteSignUp = ({ closePopUp }) => {
  const { t, changeLanguage } = useTranslation();

  return (
    <CompleteSignUpBlock>
      <h3>
        {t("complete-signup")
          .split("\n")
          .map((line) => (
            <div>{line}</div>
          ))}
      </h3>

      <MainButton2 bgColor="#0065c3" onClick={closePopUp}>
        {t("continue")}
      </MainButton2>
    </CompleteSignUpBlock>
  );
};

export default CompleteSignUp;
