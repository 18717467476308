/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
/* eslint-disable no-unused-vars */
import { useLocation, useRouteMatch } from "react-router-dom";
import { useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  getUserEvent,
  getUserEventDetail,
} from "../../Context/UserContext";
// import { Link } from "react-router-dom";
import styled from "styled-components";

// import Media from "../../Styles/MediaQuery";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Media from "../../Styles/MediaQuery";
import { useTranslation } from "react-i18next";
// import Typography from "@material-ui/core/Typography";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";

const EventHistoryBlock = styled.div`
  padding: 90px 0;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    width: 90%;
    max-width: 1280px;
  }
`;

const EventTitle = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;

  h3 {
    font-size: 1.6rem;
    font-weight: bold;
    word-break: keep-all;
  }

  em {
    margin-top: 10px;
    display: block;
  }
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px 0;

  ${Media.tablet`
  `}

  ${Media.mobile`
    width: 100%;
  `}
`;

const EventDetailCategory = styled.div`
  padding: 15px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;

  span {
    display: block;
  }
  span:nth-child(1) {
    width: 15%;
  }
  span:nth-child(2) {
    width: 50%;
  }
  span:nth-child(3) {
    width: 35%;
  }
`;
const EventDetailList = styled.ul`
  border-bottom: 1px solid #ddd;
  line-height: 1.4;
`;

const EventDetailItem = styled.li`
  padding: 15px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;

  strong {
    display: block;
    font-size: 1.2rem;
    width: 15%;
  }
  em {
    display: block;
  }
  em:nth-child(2) {
    width: 50%;
  }
  em:nth-child(3) {
    width: 35%;
  }
`;

const EventDetailPage = styled.ul`
  text-align: center;
  margin: 30px 0;
  li {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 0 5px;
    line-height: 30px;
    cursor: pointer;
  }
  .active {
    background-color: #000;
    color: #fff;
  }
  .disable {
    background-color: #fff;
    color: #000;
  }
`;

const Accordion = withStyles({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .00)",
    borderTop: "1px solid rgba(0, 0, 0, .1)",
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "50px 10% 30px",
    backgroundColor: "rgba(0, 0, 0, .05)",
    display: "block",

    "&>strong": {
      fontWeight: "bold",
      marginBottom: 10,
      display: "block",
    },
    "& ul": {
      marginBottom: 30,
      marginTop: 30,
    },
    "& ul li": {
      marginBottom: 10,
    },
    "& span": {
      marginBottom: 30,
      display: "block",
    },
  },
}))(MuiAccordionDetails);

const EventHistoryDetail = () => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, acc, userEventList, userEventLogList } = state;

  // console.log(state);

  const location = useLocation();
  const match = useRouteMatch();

  // console.log(location);
  // console.log(match);

  const { detail } = match.params;

  // console.log(detail);

  const fetchData = async () => {
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
    if (!userEventList) await getUserEvent(dispatch);
    if (!userEventLogList) await getUserEventDetail(dispatch, detail);

    await getUserEventDetail(dispatch, detail);
    // await getUserEvent(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, [state.acc]);

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const { t } = useTranslation();

  // 현재 보는 페이지
  const [currentPage, setCurrentPage] = useState(1);
  // 보여줄 갯수
  const [postPerPage, setPostPerPage] = useState(7);

  // 페이지 내에서 마지막 포스트의 index 값
  const indexOfLastPost = currentPage * postPerPage;
  // 페이지의 첫 포스트의 index 값
  const indexOfFirstPost = indexOfLastPost - postPerPage;

  if (!userEventList) return <DataLoadingStyle fetchData={fetchData} />;
  if (!userEventLogList) return <DataLoadingStyle fetchData={fetchData} />;

  const eventTitle = userEventList.events.filter(
    (event) => event.uuid === detail
  );

  // console.log(eventTitle);
  const eventDetail = userEventLogList.map((detail) =>
    Object.assign(detail, {
      id: userEventLogList.length - userEventLogList.indexOf(detail),
    })
  );

  // console.log(eventDetail);

  const currentPosts = eventDetail.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // console.log(currentPosts);
  // console.log(currentPage);

  const pageNumbers = [];
  const totalPosts = eventDetail.length;

  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pageNumbers.push(i);
  }

  // console.log(pageNumbers);

  return (
    <EventHistoryBlock>
      <EventTitle>
        <h3>{eventTitle[0].name}</h3>
        <em className="eng">
          {eventTitle[0].start_date} ~ {eventTitle[0].end_date}
        </em>
      </EventTitle>
      <Wrapper>
        <EventDetailCategory>
          <span>{t("eventTable-times")}</span>
          <span>{t("eventTable-date")}</span>
          <span>{t("eventTable-entries-of-day")}</span>
        </EventDetailCategory>
        <EventDetailList>
          {currentPosts.map((detail) => (
            <Accordion
              key={detail.uuid}
              square
              expanded={expanded === `panel${detail.uuid}`}
              onChange={handleChange(`panel${detail.uuid}`)}
            >
              <AccordionSummary
                aria-controls={`panel${detail.uuid}d-content`}
                id={`panel${detail.uuid}d-header`}
              >
                <EventDetailItem>
                  <strong className="eng">{detail.id}</strong>
                  <em>{detail.datetime}</em>
                  <em>
                    {detail.daily_acc_count}
                    {t("times")} / {t("3times")}
                  </em>
                </EventDetailItem>
              </AccordionSummary>
              <AccordionDetails>
                <strong>{t("priority-goods-submitted")}</strong>
                <ul>
                  <li key={detail.first_priority.uuid}>
                    <strong>{t("first-priority")}</strong>
                    <em> {detail.first_priority.name}</em>
                    <em>
                      {detail.first_priority.color && ` / `}
                      {detail.first_priority.color}
                    </em>
                    <em> / {detail.first_priority.option}</em>
                  </li>
                  <li key={detail.second_priority.uuid}>
                    <strong>{t("second-priority")}</strong>
                    <em> {detail.second_priority.name}</em>
                    <em>
                      {detail.second_priority.color && ` / `}
                      {detail.second_priority.color}
                    </em>
                    <em> / {detail.second_priority.option}</em>
                  </li>
                  <li key={detail.third_priority.uuid}>
                    <strong>{t("third-priority")}</strong>
                    <em> {detail.third_priority.name}</em>
                    <em>
                      {detail.third_priority.color && ` / `}
                      {detail.third_priority.color}
                    </em>
                    <em> / {detail.third_priority.option}</em>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
        </EventDetailList>

        <EventDetailPage>
          {pageNumbers.map((num) => (
            <li
              id={`key + ${num}`}
              key={`key + ${num}`}
              onClick={() => paginate(num)}
              className={num === +currentPage ? "active" : "disable"}
            >
              {num}
            </li>
          ))}
        </EventDetailPage>
      </Wrapper>
    </EventHistoryBlock>
  );
};

export default EventHistoryDetail;
