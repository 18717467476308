/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import SelectLogin from "../Layout/SelectLogin";
import FindID from "../Layout/FindID";
import FindIDComplete from "../Layout/FindIDComplete";
import FindPW from "../Layout/FindPW";
import FindPWComplete from "../Layout/FindPWComplete";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";

const GuestBtn = styled.div`
  width: 100%;
  padding: 0;

  a {
    width: 100%;
    padding: 20px;
    background-color: #ccc;
    color: #fff;
    border: 0;
    text-align: center;
  }
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const Login = ({ checkSocial }) => {
  const [popUpPage, setPopUpPage] = useState(1);
  const [userEmail, setUserEmail] = useState("");

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const pageComponent = {
    1: (
      <SelectLogin
        checkSocial={checkSocial}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    2: (
      <FindID
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),
    3: (
      <FindIDComplete
        userEmail={userEmail}
        setUserEmail={setUserEmail}
        popUpPage={popUpPage}
        setPopUpPage={setPopUpPage}
      />
    ),

    4: <FindPW popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
    5: <FindPWComplete popUpPage={popUpPage} setPopUpPage={setPopUpPage} />,
  };

  const closePopUp = () => {
    popupSetState({
      ...popupState,
      login: false,
      signup: false,
      mainmenu: false,
      event_login: false,
    });
  };

  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={popupState.login}
        onClose={closePopUp}
        classNames={{
          overlay: "customOverlay",
          modal: "customModal",
          closeButton: "customCloseBtn",
          animationIn: "customEnterAnimation",
          animationOut: "customLeaveAnimation",
        }}
        animationDuration={0}
      >
        {pageComponent[popUpPage]}
        {popUpPage === 1 && popupState.event_login && (
          <GuestBtn onClick={closePopUp}>
            <Link to="quiz">{t("continue-non-member")}</Link>
          </GuestBtn>
        )}
      </Modal>
    </>
  );
};

export default Login;
