/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import {
  useUsersState,
  useUsersDispatch,
  getUsers,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { EventButton } from "../../Styles/ButtonStyle";
import { goodsData } from "../../Constants/GoodsData";
import GoodsItem from "../Items/GoodsItem";
import ApplyEventUserInfo from "./ApplyEventUserInfo";
import ApplyEventNoUserInfo from "./ApplyEventNoUserInfo";
import { useTranslation } from "react-i18next";

const TrueAnswerPopUpBlock = styled.div`
  width: 100%;
  /* overflow-y: true; */
  h4 {
    font-size: 0.875rem;
    line-height: 1.2;
  }

  strong {
    display: block;
    margin: 14px 0;
  }
`;
const GoodsList = styled.ul`
  width: 100%;

  &::after {
    content: "";
    display: block;
    clear: both;
  }
`;

const GoodsCategory = styled.h3`
  font-size: 1rem;
  margin: 20px 0 10px;
`;

const initialState = {
  event_uuid: "3ef8341b-4383-451f-9dc9-0de76211013f",
  first: "",
  second: "",
  third: "",
  exclusive: "false",
  name: "",
  phonenumber: "",
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
  email: "",
};

const BtnWrap = styled.div`
  text-align: center;
  button {
    display: inline-block;
    box-sizing: border-box;
    background-color: #0065c3;
  }

  ${Media.tablet`
      position: fixed;
      // Mobile fixed adjusted - doun
      left: 0;
      bottom: 0;
      width: 100%;
      button{
        position: static;
        width: 60%;
      }
    `}
  ${Media.mobile`
    position: fixed;
    // Mobile fixed adjusted - doun
      left: 0;
      bottom: 0;
      width: 100%;
      button{
        position: static;
        width: 60%;
      }
  `}
`;

const SubButton = styled.button`
  display: inline-block;
  width: auto;
  padding: 20px;
  border: 0;
  border-radius: 0;
  background-color: #000 !important;
  color: #fff;
  cursor: pointer;
  margin: 0 5px 0 0;
  ${Media.tablet`
    width: 40% !important;
    margin-right: 0;
    padding: 30px 20px;
  `}
  ${Media.mobile`
    width: 40% !important;
    padding: 20px;
  `}
`;

const TrueAnswerPopUp = ({ setState, openState }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo } = state;

  const goScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    goScrollTop();
  }, []);

  const [applyUserPage, setApplyUserPage] = useState(false);

  const openApplyUserPage = () => {
    setApplyUserPage(true);
  };

  const { t } = useTranslation();
  // const rankInitialState = [{ 1: "first" }, { 2: "second" }, { 3: "third" }];
  const rankInitialState = [
    {
      value: "first",
      label: t("event:select-goods-en") + 1 + t("event:select-goods-kr"),
    },
    {
      value: "second",
      label: t("event:select-goods-en") + 2 + t("event:select-goods-kr"),
    },
    {
      value: "third",
      label: t("event:select-goods-en") + 3 + t("event:select-goods-kr"),
    },
  ];
  // useEffect(() => {
  //   document.body.style.position = "fixed";
  //   document.body.style.width = "100%";

  //   return () => {
  //     document.body.style.position = null;
  //     document.body.style.width = null;
  //   };
  // }, []);

  const [goodsDataState, setGoodsDataState] = useState(goodsData);
  const [applyEventInfo, setApplyEventInfo] = useState(initialState);
  const [rankState, setRankState] = useState(rankInitialState);
  // const [rankActive, setRankActive] = useState({});
  const [errorMsg, SetErrorMsg] = useState(null);
  const [goodsItemReset, SetGoodsItemReset] = useState(null);

  const goodsList = useRef();
  const goodsList2 = useRef();
  const goodsList3 = useRef();
  // console.log(goodsDataState);

  /*useEffect(() => {
    // user 인지 확인
    if (!rankState.length) SetErrorMsg(`굿즈를 3순위까지 모두 선택했습니다.`);
    else if (rankState.length)
      SetErrorMsg(`굿즈를 ${rankState.length}개 더 선택해주세요.`);
  }, [rankState]);*/

  useEffect(() => {
    const { childNodes } = goodsList.current;
    [...childNodes].forEach((goods) => goods.classList.remove("selected"));
    // console.log("render", childNodes);
    [...goodsList2.current.childNodes].forEach((goods) =>
      goods.classList.remove("selected")
    );
    [...goodsList3.current.childNodes].forEach((goods) =>
      goods.classList.remove("selected")
    );
    SetGoodsItemReset(null);
  }, [goodsItemReset]);

  const selectGoodsReset = () => {
    setRankState(rankInitialState);
    setGoodsDataState(goodsData);
    // console.log(goodsList);

    const { childNodes } = goodsList.current;
    // console.log("1", childNodes);
    [...childNodes].forEach((goods) => goods.classList.remove("selected"));
    // console.log("button", childNodes);

    [...goodsList2.current.childNodes].forEach((goods) =>
      goods.classList.remove("selected")
    );
    [...goodsList3.current.childNodes].forEach((goods) =>
      goods.classList.remove("selected")
    );

    SetGoodsItemReset(true);
  };
  const applyEvent = () => {
    if (rankState.length)
      SetErrorMsg(`굿즈를 ${rankState.length}개 더 선택해주세요.`);
    else {
      openApplyUserPage();
    }
  };

  // selectRank.map((rank) => console.log(selectRank.indexOf(rank), rank));
  const applyUserSelect = () => {
    if (!is_login) {
      return (
        <ApplyEventNoUserInfo
          applyEventInfo={applyEventInfo}
          setApplyEventInfo={setApplyEventInfo}
        />
      );
    } else {
      return (
        <ApplyEventUserInfo
          userInfo={userInfo}
          applyEventInfo={applyEventInfo}
          setApplyEventInfo={setApplyEventInfo}
        />
      );
    }
  };

  // if (!goodsDataState) return <div>로딩중...</div>;

  const campList = goodsDataState.filter((goods) => goods.category === "camp");
  const fashionList = goodsDataState.filter(
    (goods) => goods.category === "fashion"
  );
  const soloplayList = goodsDataState.filter(
    (goods) => goods.category === "soloplay"
  );
  // console.log(campList);

  return (
    <>
      {!applyUserPage ? (
        <>
          <TrueAnswerPopUpBlock>
            <h3 className="centered">{t("event:correct-answer")}</h3>
            <h4 className="centered">
              {t("event:choose-your-goods")
                .split("\n")
                .map((line) => (
                  <div key={Math.floor(Math.random() * 999) + 1}>{line}</div>
                ))}
            </h4>
            <img className="answer-img" src="/Img/icon-quiz2.png" alt="" />
            {/* {errorMsg && <strong>{errorMsg}</strong>} */}
            <GoodsCategory className="eng">Camp</GoodsCategory>
            <GoodsList ref={goodsList}>
              {campList.map((goods) => (
                <GoodsItem
                  applyEventInfo={applyEventInfo}
                  setApplyEventInfo={setApplyEventInfo}
                  rankState={rankState}
                  setRankState={setRankState}
                  goodsDataState={goodsDataState}
                  setGoodsDataState={setGoodsDataState}
                  key={goods.id}
                  id={goods.id}
                  uuid={goods.uuid}
                  name={goods.name}
                  color={goods.color}
                  description={goods.description}
                  option={goods.option}
                  image={goods.image}
                  active={goods.active}
                  select={goods.select}
                />
              ))}
            </GoodsList>

            <GoodsCategory className="eng">fashion</GoodsCategory>
            <GoodsList ref={goodsList2}>
              {fashionList.map((goods) => (
                <GoodsItem
                  applyEventInfo={applyEventInfo}
                  setApplyEventInfo={setApplyEventInfo}
                  rankState={rankState}
                  setRankState={setRankState}
                  goodsDataState={goodsDataState}
                  setGoodsDataState={setGoodsDataState}
                  key={goods.id}
                  id={goods.id}
                  uuid={goods.uuid}
                  name={goods.name}
                  color={goods.color}
                  description={goods.description}
                  option={goods.option}
                  image={goods.image}
                  active={goods.active}
                  select={goods.select}
                />
              ))}
            </GoodsList>

            <GoodsCategory className="eng">solo_play</GoodsCategory>
            <GoodsList ref={goodsList3}>
              {soloplayList.map((goods) => (
                <GoodsItem
                  applyEventInfo={applyEventInfo}
                  setApplyEventInfo={setApplyEventInfo}
                  rankState={rankState}
                  setRankState={setRankState}
                  goodsDataState={goodsDataState}
                  setGoodsDataState={setGoodsDataState}
                  key={goods.id}
                  id={goods.id}
                  uuid={goods.uuid}
                  name={goods.name}
                  color={goods.color}
                  description={goods.description}
                  option={goods.option}
                  image={goods.image}
                  active={goods.active}
                  select={goods.select}
                />
              ))}
            </GoodsList>
            <BtnWrap>
              <SubButton onClick={selectGoodsReset}>
                {t("event:reset-goods")}
              </SubButton>
              <EventButton onClick={applyEvent}>
                {t("event:apply-event")}
              </EventButton>
            </BtnWrap>
          </TrueAnswerPopUpBlock>
        </>
      ) : (
        applyUserSelect()
      )}
    </>
  );
};

export default TrueAnswerPopUp;
