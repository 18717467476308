/* eslint-disable no-unused-vars */
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const SamsungEventSectionBlock = styled.div`
  margin-bottom: 90px;
  display: flex;
  height: 208px;
  background: url("/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/samsung-background2.jpg");
  background-size: cover;
  padding: 54px 0 40px;
  ${Media.mobile`
    display: none;
    margin-bottom: 0;
    background: url("/Asset/01FYE3D2ZQMR1NQQYT572GCMZ2/samsung-background2-m-a.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding: 68px 0 40px; 
  `}
`;

const InnerBlock = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
`;
const Description = styled.div`
  em {
    color: #000000;
    font-size: 36px;
    line-height: 54px;
    margin-bottom: 10px;
    ${Media.mobile`
      color: #ffffff;
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 10px;
    `}
  }
`;

const JoinButton = styled.div`
  padding-top: 10px;
  margin: 0 auto;
  a {
    background-color: #0065c3;
    width: 220px;
    height: 50px;
    color: white;
    display: flex;
    justify-content: center;
  }
  p {
    align-self: center;
    font-family: Noto Sans KR;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

const SectionInnerBlock = () => {
  return (
    <InnerBlock className="eng">
      <Description>
        <em>
          pepsi
          <br />
          collaboration
        </em>
      </Description>
      <JoinButton>
        <Link to="/samsung_galaxy">
          <p>지금 바로보기</p>
        </Link>
      </JoinButton>
    </InnerBlock>
  );
};

const SamsungEventSection2 = () => {
  return (
    <SamsungEventSectionBlock>
      <SectionInnerBlock />
    </SamsungEventSectionBlock>
  );
};

export default SamsungEventSection2;
