/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  getUserEvent,
  getUserEventDetail,
  usePopupState,
  usePopupSetState,
} from "../../Context/UserContext";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SubHeader from "../Layout/SubHeader";
import Media from "../../Styles/MediaQuery";
import EventHistoryDetail from "./EventHistoryDetail";
import { useTranslation } from "react-i18next";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";

const EventHistoryBlock = styled.div`
  padding: 90px 0;

  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    width: 90%;
    max-width: 1280px;
  }
`;

const Wrapper = styled.div`
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;

  h2 {
    font-size: 2rem;
  }

  ${Media.tablet`
  `}

  ${Media.mobile`
  `}
`;

const EventCount = styled.div`
  text-align: center;
  margin-bottom: 30px;
  margin-top: 30px;
  strong {
    margin-bottom: 20px;
    display: inline-block;
    font-weight: bold;
  }
  h1 {
    font-size: 4rem;

    span {
      font-size: 1rem;
    }
  }
`;
const EventList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;

  a {
    width: 50%;
    border: solid 1px #ddd;
    margin-bottom: 15px;
    /* border-right: 0; */
    box-sizing: border-box;
    background: #fff url("./Img/arrow-right.svg") no-repeat 95% center;
    background-size: 20px;
    padding: 35px 5%;
    cursor: pointer;
  }
  a:nth-child(even) {
    border-right: solid 1px #ddd;
  }

  strong {
    display: block;
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  em {
    display: block;
    font-size: 0.875rem;
  }

  ${Media.tablet`
  width: 100%;
  flex-direction: column;
  a{
    width: 100%;
    border: 0;
    border-top: solid 1px #ddd;
    margin-bottom: 0;
  }
  a:last-child{
    border-bottom: solid 1px #ddd;
    /* border-right: 0; */
  }
  `}

  ${Media.mobile`
  a {
    padding: 35px 15% 35px 5%;
  }
  `}
`;

const EmptyBox = styled.div`
  text-align: center;
  img {
    max-width: 70px;
  }
  p {
    margin: 10px 0 30px;
    color: #999;
    font-size: 1rem;
  }
`;
const EmptyButton = styled.div`
  background-color: #000;
  color: #fff;
  padding: 16px 30px;
  width: auto;
  border: 0;
  display: inline-block;
  margin-bottom: 30px;
`;

const EventHistory = () => {
  // const [detailPage, setDetailPage] = useState(false);

  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userEventList, acc } = state;

  const fetchData = async () => {
    // console.log(userEventList);
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
    // if (!userEventList) await getUserEvent(dispatch);

    await getUserEvent(dispatch);
  };

  useEffect(() => {
    fetchData();
  }, [state.acc]);

  const { t } = useTranslation();

  if (!userEventList) {
    // console.log("111");
    return <DataLoadingStyle fetchData={fetchData} />;
    // return <div>loading...</div>;
  }
  if (!userEventList.events) {
    // console.log("222");
    return <DataLoadingStyle fetchData={fetchData} />;
  }

  // console.log(applyEventUser.events.length);
  return (
    <>
      <EventHistoryBlock>
        {!userEventList.events.length ? (
          <EmptyBox>
            <img src="/Img/empty.svg" alt="" />
            <p>응모내역이 없습니다.</p>
            <Link to="/newtropepsicustomer">
              <EmptyButton>이벤트 참여하기</EmptyButton>
            </Link>
          </EmptyBox>
        ) : (
          <>
            <Wrapper>
              <h3>{t("event-entry-detail")}</h3>
            </Wrapper>
            <EventCount>
              <strong>{t("number-of-entries")}</strong>
              <h1 className="eng">
                {userEventList.total_events_count}
                <span>{t("times")}</span>
              </h1>
            </EventCount>

            <EventList>
              {userEventList.events.map((event) => (
                <Link
                  key={"key" + event.uuid}
                  to={`/eventhistory/${event.uuid}`}
                >
                  <li key={event.uuid}>
                    <strong>{event.name}</strong>
                    <em>
                      {event.start_date} ~ {event.end_date}
                    </em>
                  </li>
                </Link>
              ))}
            </EventList>
          </>
        )}
      </EventHistoryBlock>
    </>
  );
};

export default EventHistory;
