/* eslint-disable no-unused-vars */
import axios from "axios";

export function createAsyncDispatcher(type, promiseFn) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  async function actionHandler(dispatch, ...rest) {
    dispatch({ type });
    try {
      // console.log(type);
      const data = await promiseFn(...rest);
      // console.log(data);
      dispatch({
        type: SUCCESS,
        data,
      });
    } catch (e) {
      // console.error(e);
      dispatch({
        type: ERROR,
        error: e,
      });
    }
  }

  return actionHandler;
}
// userInfo: {
//   id: 0,
//   name: "",
//   email: "",
//   phonenumber: "",
//   address: "",
//   point: 0,
// },
// applyEventUser: {
//   uuid: "",
//   user: 0,
//   event_user: 0,
//   first_priority: "",
//   second_priority: "",
//   third_priority: "",
//   fourth_priority: "",
//   exclusive: false,
//   datetime: "",
//   daily_count_left: "",
// },

export const initialAsyncState = {
  is_login: false,
  is_reset: false,
  acc: null,
  userInfo: null,
  applyEventUser: null,
  userEventList: null,
  userEventLogList: null,
  status: {
    loading: false,
    success: null,
    error: null,
  },
};

const loadingState = {
  loading: true,
  success: null,
  error: null,
};

const success = {
  loading: false,
  success: true,
  error: null,
};

const error = (e) => ({
  loading: false,
  success: null,
  error: e,
});

export function createAsyncHandler(type, key) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  function handler(state, action) {
    // console.log("state", state);
    // console.log(action);
    switch (action.type) {
      case type:
        return {
          ...state,
          [key]: null,
          status: loadingState,
        };
      case SUCCESS:
        return {
          ...state,
          [key]: action.data,
          status: success,
        };
      case ERROR:
        return {
          ...state,
          [key]: null,
          status: error(action.error),
        };
      default:
        return state;
    }
  }

  return handler;
}

export function createAuthAsyncHandler(type, key) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;
  // console.log("logout_handler", type);
  function handler(state, action) {
    // console.log("state", state);
    // console.log("action", action);
    switch (action.type) {
      case type:
        return {
          ...state,
          is_login: false,
          [key]: false,
          status: loadingState,
        };
      case SUCCESS:
        return {
          ...state,
          is_login: true,
          [key]: action.data.data.is_reset,
          status: success,
        };
      case ERROR:
        return {
          ...state,
          is_login: false,
          [key]: false,
          status: error(action.error.request),
        };
      default:
        return state;
    }
  }

  return handler;
}

export function createLogoutHandler(type) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;
  // console.log("logout_handler", type);

  function handler(state, action) {
    switch (action.type) {
      case type:
        return {
          ...state,
          is_login: state.is_login,
          status: loadingState,
        };
      case SUCCESS:
        return {
          ...state,
          is_login: false,
          is_reset: false,
          acc: null,
          userInfo: null,
          applyEventUser: null,
          userEventList: null,
          userEventLogList: null,
          status: success,
        };
      case ERROR:
        return {
          ...state,
          is_login: false,
          is_reset: false,
          acc: null,
          userInfo: null,
          applyEventUser: null,
          userEventList: null,
          userEventLogList: null,
          status: error(action.error),
        };
      default:
        return state;
    }
  }

  return handler;
}

export function createStatusResetHandler(type) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;
  // console.log("logout_handler", type);

  function handler(state, action) {
    switch (action.type) {
      case type:
        return {
          ...state,
          status: {
            loading: false,
            success: null,
            error: null,
          },
        };
      case SUCCESS:
        return {
          ...state,
          status: {
            loading: false,
            success: null,
            error: null,
          },
        };
      case ERROR:
        return {
          ...state,
          status: {
            loading: false,
            success: null,
            error: null,
          },
        };
      default:
        return state;
    }
  }

  return handler;
}

export function createRefreshHandler(type, key) {
  const SUCCESS = `${type}_SUCCESS`;
  const ERROR = `${type}_ERROR`;

  function handler(state, action) {
    switch (action.type) {
      case type:
        return {
          ...state,
          [key]: false,
          is_login: false,
          status: loadingState,
        };
      case SUCCESS:
        // const { access } = action.data.data.access;
        // console.log(action.data.data.access);
        return {
          ...state,
          [key]: true,
          is_login: true,
          status: success,
        };
      case ERROR:
        return {
          ...state,
          [key]: false,
          is_login: false,
          status: error(action.error),
        };
      default:
        return state;
    }
  }

  return handler;
}
