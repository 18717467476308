export const lookbookDataGatorade = [
  {
    id: 1,
    title: "2022 gatorade move like this",
    path: "2022-gatorade-move-like-this",
    imgs: [
      { src: `/Img/lookbook-gatorade/move/likeThis-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/move/likeThis-01.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/move/likeThis-02.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/move/likeThis-03.jpg`, span: 6 },
    ],
  },
  {
    id: 2,
    title: "2022 gatorade x yuna kim",
    path: "2022-gatorade-yunakim",
    imgs: [
      { src: `/Img/lookbook-gatorade/yuna/yuna-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-01.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-02.jpg`, span: 3 },
      // { src: `/Img/lookbook-gatorade/yuna/yuna-03.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-04.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-05.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-06.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-07.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-08.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-09.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-10.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-11.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-12.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-13.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-14.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-15.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yuna/yuna-16.jpg`, span: 3 },
    ],
  },
  {
    id: 3,
    title: "2022 gatorade x kangdaniel",
    path: "2022-gatorade-kangdaniel",
    imgs: [
      { src: `/Img/lookbook-gatorade/daniel/daniel-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-01.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-02.jpg`, span: 3 },
      // { src: `/Img/lookbook-gatorade/daniel/daniel-03.jpg`, span: 1.5 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-04.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-05.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-06.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-07.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-08.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-09.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-10.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-11.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-12.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-13.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-14.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-15.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-16.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-17.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-20.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-21.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-22.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-18.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/daniel/daniel-19.jpg`, span: 3 },
    ],
  },
  {
    id: 4,
    title: "2022 gatorade x ive an yujin",
    path: "2022-gatorade-ive-anyujin",
    imgs: [
      { src: `/Img/lookbook-gatorade/yujin/yujin-visimg.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-01.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-02.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-03.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-04.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-05.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-08.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-09.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-10.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-06.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-07.jpg`, span: 3 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-11.jpg`, span: 6 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-12.jpg`, span: 2 },
      // { src: `/Img/lookbook-gatorade/yujin/yujin-13.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-14.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-15.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-16.jpg`, span: 2 },
      // { src: `/Img/lookbook-gatorade/yujin/yujin-17.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-18.jpg`, span: 2 },
      { src: `/Img/lookbook-gatorade/yujin/yujin-19.jpg`, span: 2 },
      // { src: `/Img/lookbook-gatorade/yujin/yujin-20.jpg`, span: 2 },
    ],
  },
];
