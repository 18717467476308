/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import {
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
} from '../../Context/UserContext';
import styled from 'styled-components';
import KaKaoLogin from 'react-kakao-login';
import Google from '../Items/Google';
import Facebook from '../Items/Facebook';
import NaverLogin from 'react-login-by-naver';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import { resources } from '../../config/lang/i18n';
import { socialKey } from "../../Service/key";
import Naver from '../Items/Naver';
import FacebookLogin from 'react-facebook-login';

const SocialLoginWrapper = styled.div`
  /* padding: 0 30px; */
  margin: 10px 0;

  button {
    width: 100%;

    margin-top: 5px;
    padding: 20px;

    /* background-color: #ccc; */
    /* color: #fff; */
    border: 1px;
  }
`;

// const SocialLoginBtn = styled.button``;

const SocialButtons = ({ checkSocial }) => {
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const responseKaKao = (res) => {
    // console.log(res.profile.kakao_account.email);
    // console.log(res.response.access_token);
    // console.log(res.response.refresh_token);
    // console.log(res);
    const { access_token } = res.response;
    const payload = {
      access_token: access_token,
      channel: 'K',
    };

    socialDispatch({
      type: 'CHECK_SOCIAL_LOADING',
      access_token: access_token,
      channel: 'K',
    });

    checkSocial(payload);
    popupSetState({
      ...popupState,
      login: false,
      singup: false,
    });
  };

  const responseFail = (err) => {
    alert(err);
  };

  // const responseNaver = (res) => {
  //   console.log(res);
  //   console.log("111");
  // };

  const checkNaver = () => {
    const { naver_id_login } = window;
    // console.log(naver_id_login);

    const loginNaver = new naver_id_login(
      'OE6el1XAzOjY92DMopcJ',
      'https://web.pep1.kr/oAuth',
    );

    // console.log(loginNaver);

    const state = loginNaver.getUniqState();
    loginNaver.setButton('white', 1, 40);
    loginNaver.setDomain('#{DOMAIN}#');
    loginNaver.setState(state);
    loginNaver.setPopup();
    loginNaver.init_naver_id_login();
  };

  // const naverIdEl = useRef();

  // useEffect(() => {
  //   console.log(naverIdEl.current);
  //   if (naverIdEl.current) checkNaver();
  // }, [naverIdEl]);

  const [fbToken, setFbToken] = useState(null);
  const responseFacebook = (response) => {
    // console.log(response);
    const { accessToken } = response;
    setFbToken(accessToken);

    const payload = {
      access_token: accessToken,
      channel: 'F',
    };

    // console.log(payload);

    socialDispatch({
      type: 'CHECK_SOCIAL_LOADING',
      access_token: accessToken,
      channel: 'F',
    });

    checkSocial(payload);
    // popupSetState({
    //   ...popupState,
    //   login: false,
    //   singup: false,
    // });
  };

  // console.log(fbToken);

  const componentClicked = (e) => {
    // console.log(e);
  };

  const { t, changeLanguage } = useTranslation();

  // console.log(socialState);

  return (
    <SocialLoginWrapper>
      {/* <div id="naver_id_login" className="naver" onClick={checkNaver}>
        {t("continue-naver")}
      </div> */}
      <Naver />
      <KaKaoLogin
        className="kakao"
        jsKey={socialKey.kakao}
        onSuccess={responseKaKao}
        onFailure={responseFail}
        getProfile={true}
      >
        {t('continue-kakao')}
      </KaKaoLogin>
      {/* <Facebook checkSocial={checkSocial} /> */}
      <FacebookLogin
        appId={socialKey.facebook}
        autoLoad={false}
        fields="email"
        scope="email"
        onClick={componentClicked}
        callback={responseFacebook}
        cssClass="facebook"
        textButton={t('continue-facebook')}
        disableMobileRedirect={true}
      />
      <Google checkSocial={checkSocial} />
    </SocialLoginWrapper>
  );
};

export default SocialButtons;
