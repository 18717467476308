/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

export default function useLazyLoading(imgRef) {
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    function loadImage() {
      setIsLoad(true);
    }

    const img = imgRef.current;
    img && img.addEventListener(loadImageEvent, loadImage);

    return () => {
      img && img.removeEventListener(loadImageEvent, loadImage);
    };
  }, []);

  useEffect(() => {
    if (!observer) {
      observer = new IntersectionObserver(onIntersection, {
        threshold: 0.5,
      });
    }
    imgRef.current && observer.observe(imgRef.current);
  }, []);

  return isLoad;
}

let observer = null;
const loadImageEvent = "loadImage";

function onIntersection(entries, io) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      io.unobserve(entry.target);
      entry.target.dispatchEvent(new CustomEvent(loadImageEvent));
    }
  });
}
