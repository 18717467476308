/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { usePopupState, usePopupSetState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import Slider from "react-slick";
import SubHeader from "../Layout/SubHeader";
import { historyData } from "../../Constants/HistoryData";
import useLazyLoading from "../../Utils/useLazyLoading";
import Meta from "../../Utils/Meta";
import Footer from "./Footer";
import Footer2 from "./Footer2";

const HistoryDetailBlock = styled.div`
  padding: 230px 0 0;
  background-color: rgba(250, 250, 250, 1);

  ${Media.mobile`
    padding: 75px 0 0;
    position: relative;
  `}
`;

const Wrapper = styled.div`
  width: 75%;
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  /* 
  ${Media.tablet`
  `}

  ${Media.mobile`
    position: static;
  `} */
`;

const HistoryTitle = styled.div`
  margin: 40px 0;
  font-size: 18px;

  strong {
    font-size: 58px;
    display: block;
    margin-top: 20px;
    line-height: 70px;
  }
  ${Media.tablet`
    margin-bottom: 400px;
    strong{
    font-size: 3rem;
  }
  `}
  ${Media.mobile`
    p {
      font-size: 18px;
    }
    strong{
      font-size: 32px;
      margin-top: 8px;
      line-height: 40px;
    }
  `}
`;
const HistoryContent = styled.div`
  background: linear-gradient(to right, #016aa1, #005595);
  padding: 50px 0;
  position: relative;

  ul {
    display: flex;
    justify-content: space-between;
    padding: 160px 0 50px;
  }
  ${Media.mobile`
    ul{
      padding: 30px 0 30px;
    }
  `}
  li {
    width: 32%;

    img {
      width: 100%;
    }
  }
`;
const CanImg = styled.div`
  position: absolute;
  right: -20%;
  bottom: -210%;
  width: 80%;
  background-image: url(${(props) => props.image || null});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  height: 800px;

  ${Media.tablet`
    display: none;
  `}

  ${Media.mobile`
    
  `}
`;

const CanImgMobile = styled.div`
  background-image: url(${(props) => props.image || null});
  background-repeat: no-repeat;
  background-position: 45% top;
  background-size: cover;
  height: 800px;
  display: none;
  position: absolute;
  top: -430px;
  left: 0;
  width: 100%;
  ${Media.tablet`
    display: block;
  `}
`;

const Description = styled.div`
  width: 50%;
  color: #fff;
  line-height: 1.4;
  font-size: 1rem;

  ${Media.tablet`
    width: 100%;
    margin-top: 200px;
  `}
`;

const ImgWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`

const HistoryDetail = () => {
  const imgRef = useRef(null);
  const isLoad = useLazyLoading(imgRef);

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "historyDetail",
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    dotsClass: "text-slider-dots",
  };

  const location = useLocation();
  const match = useRouteMatch();
  // console.log(location);
  // console.log(match);

  const { detail } = match.params;

  // console.log(detail);

  const data = historyData.filter((his) => his.id === detail);

  // console.log(data[0].detail_info);

  const {
    main_img,
    card1_img,
    card2_img,
    card3_img,
    card2_subtitle,
    card2_subtitle2,
    card2_subtitle3,
    card1_description,
  } = data[0].detail_info;

  const metaData = {
    title: "K하우스오브펩시 :: 히스토리",
    description:
      "펩시콜라 브랜드의 역사를 한 눈에 확인할 수 있는 페이지입니다.",
    canonical: "",
    keyword:
      "K하우스오브펩시,하우스오브펩시,한국펩시콜라,펩시콜라,pepsi,khouseofpepsi,pepsi-korea,pepsicola,pesi cola, 진영 요한과 함께한 펩시 한정판 선물 이벤트, 펩시헤리티지, 펩시 브랜드 소개, 펩시 브랜드",
  };

  return (
    <HistoryDetailBlock>
      <Meta data={metaData} />
      <Wrapper>
        <SubHeader title="History" subtitle="펩시의역사" color="#000" width="100%"/>
        <HistoryTitle className="eng">
          <p>
            {data[0].name}
          </p>
          <strong>
            {/*{card2_subtitle}*/}
            SOCIALS,
            <br />
            {card2_subtitle2}
            <br />
            {card2_subtitle3}
          </strong>
        </HistoryTitle>
      </Wrapper>
      <HistoryContent>
        <CanImgMobile image={main_img} />
        <Wrapper>
          <CanImg
            ref={imgRef}
            image={main_img}
            className={isLoad ? "activeLazy" : "disableLazy"}
          />
          <Description className="eng description">{card1_description}</Description>

      
        </Wrapper>
        <ImgWrapper>
          <ul>
            <li>
              <img src={card1_img} alt="" />
            </li>
            <li>
              <img src={card2_img} alt="" />
            </li>
            <li>
              <img src={card3_img} alt="" />
            </li>
          </ul>
        </ImgWrapper>
      </HistoryContent>
      <Footer2 />
    </HistoryDetailBlock>
  );
};

export default HistoryDetail;
