/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  signUp,
  socialSignUp,
} from "../../Context/UserContext";
import { useForm } from "react-hook-form";
import styled from "styled-components";
// import Media from "../../Styles/MediaQuery";
import { ValidationInput, Input } from "../../Styles/InputStyle";
import SubHeader from "./SubHeader";
import AddPopup from "./AddPopup";
import { MainButton2 } from "../../Styles/ButtonStyle";
import { useTranslation } from "react-i18next";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import { Link } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import DaumPostcode from "../../Utils/DaumPostcode";

const SignUpForm = styled.form`
  width: 100%;

  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
  #email {
    width: calc(90% - 70px);
  }
  /* input {
    width: ${(props) => (props.name === "address" ? "65%" : "90%")};

    margin: 5px 14px;
    padding: 22px 0;

    color: rgb(45, 49, 56);

    font-size: 16px;
    letter-spacing: 0.14px;
    font-weight: 200;

    border: 0;
    border: 1px solid rgb(217, 219, 224);
  } */
`;

const EmailWrapper = styled.div`
  display: flex;
`;
const EmailBtn = styled.div`
  padding: 0 5px;
  cursor: pointer;
  box-sizing: border-box;
  width: 100px;
  height: 50px;
  line-height: 50px;
  vertical-align: middle;
  background: #0065c3;
  color: #fff;
  border: 0;
  margin: 5px 0;
  font-size: 0.875rem;

  &:disabled {
    background: #ccc;
  }
`;

const SignUpWrapper = styled.div`
  /* padding: 80px 30px 90px; */
  /* height: 100vh;
  padding: 80px 20px 0 20px; */
  /* overflow: auto; */
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const AddressWrapper = styled.div`
  padding-bottom: 120px;
`;

const SearchBtn = styled.div`
  display: inline-block;
  /* width: 20%; */
  padding: 5px;
  /* background-color: #ccc; */
  cursor: pointer;

  width: 80px;
  height: 50px;
  vertical-align: middle;
  background: #0065c3 url("/Img/search-icon.svg") no-repeat center;
  background-size: 20px;
  text-indent: -999px;
  overflow: hidden;
`;

const AddressModal = styled.div``;

export const ReadOnlyInput = styled.div`
  width: ${(props) =>
    props.name === "postcode" ? "calc(100% - 80px)" : "100%"};
  display: ${(props) => (props.name === "postcode" ? "inline-block" : "block")};
  vertical-align: middle;
  margin: 5px 0;
  margin-top: ${(props) => (props.name === "address2" ? "10px" : "5px")};
  padding: 17px 0;
  text-indent: 10px;

  font-size: 16px;
  letter-spacing: 0.14px;
  font-weight: 500;
  text-align: left;
  border: 0;
  border: 1px solid rgb(217, 219, 224);

  color: ${(props) => (props.onText ? "#333" : "#ccc")};
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const initialState = {
  email: "",
  password1: "",
  password2: "",
  name: "",
  phonenumber: "",
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
  account_type: "P",
};
const addInitialState = {
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};

const PersonalSignUp = ({ popUpPage, setPopUpPage }) => {
  // const pageChange = () => setPopUpPage(4);
  // console.log(socialToken);
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  const [openAddress, setOpenAddress] = useState(false);

  const [address, setAddress] = useState(addInitialState);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");

  const openAddressPopUp = () => setOpenAddress(true);
  const closeAddress = () => setOpenAddress(false);

  const [inputs, setInputs] = useState({
    address3: "",
  });

  const { address3 } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (is_login && success) setPopUpPage(4);
  }, [state.is_login]);

  const [emailCheck, setEmailCheck] = useState(false);
  const [emailCheckMsg, setEmailCheckMsg] = useState(null);

  const checkEmailDuplicates = async (payload) => {
    try {
      const response = await axios.post(
        `https://web.pep1.kr/api/v1/auth/email/duplicates/`,
        payload
      );
      // console.log(response);
      if (response.status === 200) {
        setEmailCheck(true);
        setEmailCheckMsg(t("valid:mail-available"));
      }
    } catch (err) {
      setEmailCheck(false);
      setEmailCheckMsg(t("valid:mail-exist"));
      // console.error(err);
      // console.log(err.response);
    }
  };

  const checkEmail = () => {
    let emailValues = {
      email: getValues("email"),
    };
    // console.log(emailValues);
    if (emailValues.email === "") setEmailCheckMsg("이메일을 입력해주세요.");
    else {
      checkEmailDuplicates(emailValues);
    }
  };

  useEffect(() => {
    if (address.postcode) {
      // console.log(address.postcode);
      setValue("postcode", address.postcode);
      // console.log(getValues("postcode"));
    }
  }, [address.postcode]);

  const onSubmit = (data) => {
    // console.log(data);
    // console.log("address", address);
    // console.log(socialState);

    if (socialState.status !== "success") {
      let fullData = {
        email: data.email,
        password1: data.password,
        password2: data.passwordCheck,
        name: data.username,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: inputs.address3,
          postcode: address.postcode,
        },
        account_type: "P",
      };

      // console.log(fullData);
      signUp(dispatch, fullData);
    } else if (socialState.status === "success" && !socialState.email) {
      let fullData = {
        email: data.email,
        name: data.username,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: inputs.address3,
          postcode: address.postcode,
        },
        account_type: "P",
        access_token: socialState.access_token,
        channel: socialState.channel,
      };

      socialSignUp(dispatch, fullData);
    } else {
      let fullData = {
        name: data.username,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: inputs.address3,
          postcode: address.postcode,
        },
        account_type: "P",
        access_token: socialState.access_token,
        channel: socialState.channel,
      };

      // console.log(fullData);
      socialSignUp(dispatch, fullData);
    }
  };

  const cancleHypen = (e) => {
    let phoneValue = getValues("phonenumber");
    var replaceNotInt = /[^0-9]/gi;

    if (phoneValue !== replaceNotInt)
      setValue("phonenumber", phoneValue.replace(replaceNotInt, ""));
  };

  const watchEmail = watch("email");
  const watchPassword = watch("password");
  const watchName = watch("username");
  const watchPhone = watch("phonenumber");
  const watchPostcode = watch("postcode");

  const { t } = useTranslation();

  if (loading) return <DataLoadingStyle />;

  if (error) {
    let errorMsg = "";
    let first_key = "";
    let first_value = "";
    // console.log(error);
    if (error.status === 500)
      errorMsg = "네트워크나 서버에 문제가 있습니다. 다시 시도해주세요.";
    else {
      errorMsg = JSON.parse(error.responseText);
      // console.log(JSON.parse(error.responseText));

      first_key = Object.keys(errorMsg)[0];
      first_value = errorMsg[Object.keys(errorMsg)[0]];

      if (first_key === "phonenumber")
        alert("핸드폰번호 형식에 맞지 않습니다.");
      else alert(errorMsg);
    }
  }

  return (
    <>
      <SignUpForm onSubmit={handleSubmit(onSubmit)}>
        <SignUpWrapper>
          <SubHeader
            title="JOIN"
            subtitle="회원가입"
            color="#000"
            textAlign="center"
            marginBottom="30px"
          />
          {socialState.status === "success" ? (
            socialState.social_email ? (
              <Input
                name="email"
                label="email"
                placeholder={t("email")}
                autoComplete="off"
                value={socialState.social_email}
                readOnly={true}
              />
            ) : (
              <>
                <EmailWrapper>
                  <ValidationInput
                    name="email"
                    label="email"
                    placeholder={t("email")}
                    autoComplete="off"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    register={register}
                    validation={{
                      required: true,
                      minLength: 5,
                      pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                    }}
                  />
                  <EmailBtn
                    onClick={checkEmail}
                    disabled={
                      errors.email || getValues("email") === "" || !watchEmail
                    }
                  >
                    중복 검사
                  </EmailBtn>
                </EmailWrapper>
                {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
                {!emailCheck && <Alert>{t("valid:check-duplicate")}</Alert>}
                {emailCheckMsg && <Alert>{emailCheckMsg}</Alert>}
              </>
            )
          ) : (
            <>
              <EmailWrapper>
                <ValidationInput
                  name="email"
                  label="email"
                  placeholder={t("email")}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  register={register}
                  validation={{
                    required: true,
                    minLength: 5,
                    pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                  }}
                />
                <EmailBtn
                  onClick={checkEmail}
                  disabled={
                    errors.email || getValues("email") === "" || !watchEmail
                  }
                >
                  중복 검사
                </EmailBtn>
              </EmailWrapper>
              {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
              {!emailCheck && <Alert>{t("valid:check-duplicate")}</Alert>}
              {emailCheckMsg && <Alert>{emailCheckMsg}</Alert>}
              <ValidationInput
                type="password"
                name="password"
                label="password"
                placeholder={t("password")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                register={register}
                validation={{ required: true, minLength: 8, maxLength: 16 }}
              />
              {errors.password && (
                <Alert>{t("valid:not-valid-password")}</Alert>
              )}
              <ValidationInput
                type="password"
                name="passwordCheck"
                label="passwordCheck"
                placeholder={t("password-check")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                register={register}
                validation={{
                  required: true,
                  validate: (value) => value === watchPassword,
                }}
              />
              {errors.passwordCheck && (
                <Alert>{t("valid:not-valid-password-check")}</Alert>
              )}
            </>
          )}
          <ValidationInput
            name="username"
            label="username"
            placeholder={t("name")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            register={register}
            validation={{ required: true, minLength: 2 }}
          />
          {errors.username && <Alert>{t("valid:not-valid-username")}</Alert>}
          <ValidationInput
            name="phonenumber"
            label="phonenumber"
            placeholder={t("number")}
            autoComplete="off"
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyUp={cancleHypen}
            register={register}
            validation={{
              required: true,
              minLength: 10,
              maxLength: 11,
            }}
          />
          {errors.phonenumber && (
            <Alert>{t("valid:not-valid-phone-number")}</Alert>
          )}
          <AddressWrapper>
            <Input
              className="input-postcode"
              name="postcode"
              label="postcode"
              placeholder={t("postcode")}
              readOnly={true}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              ref={register}
              onClick={() => openAddressPopUp()}
            />
            {errors.postcode && <Alert>{t("valid:not-valid-address")}</Alert>}

            <Input
              className="input-address"
              name="address1"
              label="address1"
              placeholder={t("address01")}
              readOnly={true}
              autoComplete="off"
              value={address1}
              onFocus={onFocus}
              onBlur={onBlur}
              onClick={() => openAddressPopUp()}
            />
            <SearchBtn
              // className="btn-address"
              onClick={() => openAddressPopUp()}
            >
              검색
            </SearchBtn>
            <Input
              name="address2"
              label="address2"
              placeholder={t("address02")}
              readOnly={true}
              autoComplete="off"
              value={address2}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <Input
              name="address3"
              label="address3"
              placeholder={t("address03")}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={onChange}
              value={address3}
            />
          </AddressWrapper>
        </SignUpWrapper>
        {socialState.status === "success" ? (
          <MainButton2
            bgColor="#0065c3"
            disabled={!(watchName && watchPhone && watchPostcode)}
          >
            {t("signup")}
          </MainButton2>
        ) : (
          <MainButton2
            bgColor="#0065c3"
            disabled={
              !(
                watchEmail &&
                emailCheck &&
                watchPassword &&
                watchName &&
                watchPhone &&
                postcode
              )
            }
          >
            {t("signup")}
          </MainButton2>
        )}
      </SignUpForm>

      {openAddress && (
        <Modal
          open={openAddress}
          onClose={closeAddress}
          classNames={{
            overlay: "customAddressOverlay",
            modal: "customAddressModal",
            closeButton: "customCloseBtn",
            animationIn: "customEnterAnimation",
            animationOut: "customLeaveAnimation",
          }}
          animationDuration={0}
        >
          <DaumPostcode
            setOpenAddress={setOpenAddress}
            setAddress={setAddress}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setPostcode={setPostcode}
          />
        </Modal>
      )}
    </>
  );
};

export default PersonalSignUp;
