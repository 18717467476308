import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const ErrorBlock = styled.div`
  width: 100%;
  height: 100vh;
  /* min-height: calc(100vh - 72px); */
  color: rgb(45, 49, 56);
  background-color: #f1f5fc;

  img {
    width: 200px;

    ${Media.mobile`
    margin: 0 auto;
  `}
  }
`;
const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;

  margin: 0 auto;
  padding: 30px 0 0 0;
`;
const MainMessage = styled.div`
  position: relative;
  width: 670px;
  margin: 100px auto 0 auto;
  display: flex;
  flex-direction: row;

  ${Media.mobile`
    width: 100%;
    flex-direction: column;
  `}
`;
const Title = styled.div`
  padding: 70px 0 0 0;
  font-size: 40px;
  font-weight: bold;
  text-align: center;

  ${Media.mobile`
  padding: 20px 0 0 0;
  font-size: 36px;
  `}
`;
// const Image = styled.div`
//   position: absolute;
//   top: 27px;
//   left: 124px;
//   width: 392px;
//   height: 350px;

//   background-image: url("https://buyer-static-gcp.postmates.com/dist/prod/404-image@2x.772cea069bcbfc170e74e96ad95ded3db03871728bd900a1aecd7e2b4fe3201b598784e78c45a43bda52252e3c17ae07a73265d5f3d9e642eb89f84348e6f412.png");
//   background-position: center center;
//   background-size: contain;

//   /* opacity: 1;
//   transition: opacity 300ms ease-in-out 0s; */
//   ${Media.mobile`
//     width: 200px;
//     height: 180px;
//     left: calc(50% - 100px);
//   `}
// `;

const Description = styled.div`
  width: 60%;
  margin: 30px 0 0 0;

  line-height: 1.6;

  text-align: center;
  word-break: keep-all;

  ${Media.mobile`
  width: 100%;
  `}

  * {
    display: block;
  }

  strong {
    font-size: 23px;
    font-weight: bold;
    letter-spacing: -1.6px;
    margin-bottom: 30px;

    ${Media.mobile`
    font-size: 19px;
  `}
  }

  em {
    font-size: 18px;
    font-weight: 200;
    letter-spacing: -0.3px;

    ${Media.mobile`
    font-size: 16px;
  `}
  }
`;

const Maintenance = () => {
  return (
    <ErrorBlock>
      <ErrorWrapper>
        <MainMessage>
          <img src="/Img/compass.svg" alt="" />
          <Title className="eng">We`ll Be Back Soon</Title>
        </MainMessage>
        <Description>
          <strong>
            Khouse of pepsi 홈페이지는 현재 원활한 사이트 이용을 위한 임시 점검
            중 입니다. <br />
            빠른 시간안에 복구될 예정입니다.
          </strong>
          <em>
            Khouse of pepsi is down for scheduled maintenance and expect to back
            online in a few minutes.
          </em>
          <em>PM 11:00 ~ PM 11:30</em>
        </Description>
      </ErrorWrapper>
    </ErrorBlock>
  );
};

export default Maintenance;
