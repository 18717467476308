import React, { useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import { MVData2 } from "../../Constants/MVData2";
import Media from "../../Styles/MediaQuery";
import MagazineMenuList from "../Items/MagazineMenuItem";
import VideoCard from "../Items/VideoCard";

const MVBlock = styled.div`
  margin: 0 auto;
  padding: 20px 0 164px 0;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 84px;
  column-gap: 28px;
  ${Media.mobile`
    padding: 20px 0 50px 0;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 40px;
  `}
`;

const NavSection = styled.div`
  margin: 32px auto;
  display: flex;
  flex-direction: row;
  ${Media.mobile`
    display: none;
  `}
`;

const NavSectionMobile = styled.div`
  margin: 10px auto;
  display: none;
  select {
    font-size: 12px;
  }
  ${Media.mobile`
    display: block;
  `}
`

const MV2 = ({ wrapper: Wrapper }) => {
  const history = useHistory();
  const { params: { detail }} = useRouteMatch();


  useEffect(() => {
    if (MVData2.filter(data => data.path === detail).length === 0)
      history.push('/magazine/mv/2022-pepsi-black-blue');
  });

  const getVideos = () => {
    const matchedVideo = MVData2.filter(data => data.path === detail)[0]?.videos;
    if (!!matchedVideo) return matchedVideo;
    return MVData2.filter(data => data.path === '2022-pepsi-black-blue')[0].videos;
  };

  return (
    <Wrapper>
      <NavSection>
        {MVData2?.map((data) => (
          <MagazineMenuList
            key={data.id}
            title={data.title}
            detail={detail}
            path={data.path}
            pathType="mv"
          />
        ))}
      </NavSection>
      <NavSectionMobile>
        <select
          value={detail}
          className="eng"
          name="path"
          onChange={(e) => history.push(`/magazine/mv/${e.target.value}`)}
        >
          {MVData2?.map((data) => (
            <option key={data.id} value={data.path}>
              {data.title}
            </option>
          ))}
        </select>
      </NavSectionMobile>
      <MVBlock>
        {
          getVideos().map((item) => (
            <VideoCard
              key={item.url}
              src={item.url}
              title={item.title}
            />
          ))
        }
      </MVBlock>
    </Wrapper>
  );
};

export default MV2;
