/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  usePopupState,
  usePopupSetState,
  getUsers,
  userUpdate,
} from "../../Context/UserContext";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
// import Media from "../../Styles/MediaQuery";
import { ValidationInput, Input } from "../../Styles/InputStyle";
import MypageHeader from "../Layout/MypageHeader";
import { MainButton } from "../../Styles/ButtonStyle";
import { EventButton } from "../../Styles/ButtonStyle";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import DaumPostcode from "../../Utils/DaumPostcode";

const UserInfoForm = styled.form`
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
  input::placeholder {
    color: #333;
  }
`;

const UserInfoWrapper = styled.div`
  padding: 80px 30px;
  /* height: 100vh;
  padding: 80px 20px 0 20px; */
  /* overflow: auto; */
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const AddressWrapper = styled.div``;

const SearchBtn = styled.div`
  display: inline-block;
  /* width: 20%; */
  padding: 5px;
  /* background-color: #ccc; */
  cursor: pointer;

  width: 80px;
  height: 50px;
  vertical-align: middle;
  background: #0065c3 url("./Img/search-icon.svg") no-repeat center;
  background-size: 20px;
  text-indent: -999px;
  overflow: hidden;
`;

const CancelMembershipBtn = styled.div`
  margin: 20px auto;
  text-align: left;
  em {
    font-size: 1rem;
    font-weight: bold;
    color: #666;
    cursor: pointer;
  }
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const initialState = {
  name: "",
  phonenumber: "",
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};

const addInitialState = {
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};

const UserInfo = () => {

  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
    formState
  } = useForm({
    mode: "all",
  });

  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  useEffect(() => {
    popupSetState({
      ...popupState,
      menu_button: "#000",
      page: "mypage",
    });
  }, []);

  const { loading, success, error } = state.status;
  const { is_login, is_reset, userInfo, acc } = state;

  const { isDirty } = formState;

  const { t } = useTranslation();

  const fetchData = () => {
    // console.log("fetch data");
    if (!is_login) return <div>로그인 상태가 아닙니다</div>;
    else if (!userInfo) getUsers(dispatch);
    // else if (userInfo) return;
    // else if (acc) return;
    // else getUsers(dispatch);
  };

  useEffect(() => {
    // console.log("use effect");
    // fetchData();
    fetchData();
  }, [state.acc]);

  const [openAddress, setOpenAddress] = useState(false);

  const [address, setAddress] = useState(addInitialState);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState(null);
  const [postcode, setPostcode] = useState("");

  const [detailDirty, setDetailDirty] = useState(false);

  const openAddressPopUp = () => setOpenAddress(true);
  const closeAddress = () => setOpenAddress(false);

  // const [inputs, setInputs] = useState({
  //   address3: null,
  // });

  // const { address3 } = inputs;

  // const onChange = (e) => {
  //   setDetailDirty(true);
  //   const { value, name } = e.target;

  //   if (value === null || value === undefined) return;

  //   setInputs({
  //     ...inputs,
  //     [name]: value,
  //   });
  // };

  useEffect(() => {
    if (address.postcode) {
      // console.log(address.postcode);
      setValue("postcode", address.postcode);
      // console.log(getValues("postcode"));
    }
  }, [address.postcode]);

  const onSubmit = (data) => {
    // console.log(data);
    // console.log("address", address);

    let detailsValue = null;
    if (!data.address2 && !data.address3) {
      detailsValue = '';
    } else if (!data.address2) {
      detailsValue = data.address3;
    } else if (!data.address3) {
      detailsValue = data.address2;
    } else {
      detailsValue = data.address2 + ' ' + data.address3;
    }

    let fullData = {
        name: data.name,
        phonenumber: data.phonenumber,
        address: {
          province: address.province,
          street_name_address: address.street_name_address,
          land_lot_number_address: address.land_lot_number_address,
          details: detailsValue,
          postcode: address.postcode,
        },
      };

    // console.log(fullData);
    userUpdate(dispatch, fullData);
  
    // reset();
    setAddress(addInitialState);
    setDetailDirty(false);
  };

  const watchName = watch("name");
  const watchPhone = watch("phonenumber");
  const watchPostcode = watch("postcode");

  const cancleHypen = (e) => {
    let phoneValue = getValues("phonenumber");
    var replaceNotInt = /[^0-9]/gi;

    if (phoneValue !== replaceNotInt)
      setValue("phonenumber", phoneValue.replace(replaceNotInt, ""));
  };

  // const [cancelMember, setCancelMember] = useState(false);

  // const onCancelMembership = () => setCancelMember(true);
  // console.log("state", state);
  // console.log(watchPassword);
  // console.log("address", address);

  if (!userInfo) return <DataLoadingStyle fetchData={fetchData} />;
  if (loading) return <DataLoadingStyle fetchData={fetchData} />;
  if (error) return <DataLoadingStyle fetchData={fetchData} />;

  // console.log(userInfo);

  let extraAddress = '';
  let detailAddress = '';

  if (userInfo.address.details) {
    const detailsArray = userInfo.address.details.split(') ');

    if (detailsArray[0].indexOf('(') === -1) {
      detailAddress = detailsArray[0];
    } else if (detailsArray[0].indexOf(')') === -1) {
      extraAddress = detailsArray[0] + ')';
      detailAddress = detailsArray[1];
    } else {
      extraAddress = detailsArray[0];
      detailAddress = detailsArray[1];
    }

  }
  

  var replaceNotInt = /[^0-9]/gi;
  let userPhonenumber = userInfo.phonenumber.replace(replaceNotInt, "");
  // console.log(userPhonenumber);

  return (
    <>
      <>
        <UserInfoForm onSubmit={handleSubmit(onSubmit)}>
          <UserInfoWrapper>
            <MypageHeader
              title={t("change-account-info")}
              subtitle={t("change-account-info")}
              color="#000"
              textAlign="center"
              marginBottom="30px"
            />

            <Input
              name="email"
              label="email"
              placeholder="이메일"
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              value={userInfo.email}
              readOnly={true}
            />
            {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
            <ValidationInput
              name="name"
              label="name"
              placeholder="이름"
              defaultValue={userInfo.name}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              register={register}
              validation={{ required: true, minLength: 2 }}
            />
            {errors.username && <Alert>{t("valid:not-valid-username")}</Alert>}
            <ValidationInput
              name="phonenumber"
              label="phonenumber"
              placeholder="핸드폰번호"
              defaultValue={userPhonenumber}
              autoComplete="off"
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyUp={cancleHypen}
              register={register}
              validation={{
                required: true,
                minLength: 10,
                maxLength: 11,
              }}
            />
            {errors.phonenumber && (
              <Alert>{t("valid:not-valid-phone-number")}</Alert>
            )}
            <AddressWrapper>
              <Input
                className="input-postcode"
                name="postcode"
                label="postcode"
                placeholder={t("postcode")}
                readOnly={true}
                autoComplete="off"
                value={postcode === "" ? userInfo.address.postcode : postcode}
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={() => openAddressPopUp()}
                ref={register}
              />
              {errors.postcode && <Alert>{t("valid:not-valid-address")}</Alert>}

              <Input
                className="input-address"
                name="address1"
                label="address1"
                placeholder={t("address01")}
                readOnly={true}
                autoComplete="off"
                value={
                  address1 === ""
                    ? userInfo.address.street_name_address
                    : address1
                }
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={() => openAddressPopUp()}
                ref={register}
              />
              <SearchBtn
                onClick={() => openAddressPopUp()}
              >
                검색
              </SearchBtn>
              <Input
                name="address2"
                label="address2"
                placeholder={t("address02")}
                readOnly={true}
                autoComplete="off"
                value={address2 === null ? extraAddress : address2}
                onFocus={onFocus}
                onBlur={onBlur}
                ref={register}
              />
              <Input
                name="address3"
                label="address3"
                placeholder={t("address03")}
                autoComplete="off"
                onFocus={onFocus}
                onBlur={onBlur}
                defaultValue={detailAddress}
                ref={register}
              />
            </AddressWrapper>
            <Link to="unregister">
              <CancelMembershipBtn>
                <em>{t("unregister")}</em>
              </CancelMembershipBtn>
            </Link>
          </UserInfoWrapper>
          <EventButton
            bgColor="#0065c3"
            disabled={!(isDirty || detailDirty || address.postcode)}
          >
            {t("change-account-info")}
          </EventButton>
        </UserInfoForm>
      </>

      {openAddress && (
        <Modal
          open={openAddress}
          onClose={closeAddress}
          classNames={{
            overlay: "customAddressOverlay",
            modal: "customAddressModal",
            closeButton: "customCloseBtn",
            animationIn: "customEnterAnimation",
            animationOut: "customLeaveAnimation",
          }}
          animationDuration={0}
        >
          <DaumPostcode
            setOpenAddress={setOpenAddress}
            setAddress={setAddress}
            setAddress1={setAddress1}
            setAddress2={setAddress2}
            setPostcode={setPostcode}
          />
        </Modal>
      )}
    </>
  );
};

export default UserInfo;
