import React from "react";
import styled from "styled-components";
import Select from "react-select";

const SelectWrapper = styled.div`
  width: 300px;

  select {
    height: 200px;
  }
  option {
    height: 200px;
  }
`;
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    width: "300px",
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#0052CC"
        : isFocused
        ? "#0052CC"
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? "white"
        : isFocused
        ? "white"
        : "#0052CC",
      cursor: isDisabled ? "pointer" : "pointer",
      padding: "13px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#0052CC" : "#0052CC"),
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({ ...styles }),
};
const options = [
  { value: "10630a43-6dc8-4a12-8f4a-1fccd2ac44bf", label: "XL" },
  { value: "c11b7300-4e90-40e4-a30f-4e18f2f370ff", label: "XXL" },
];

const SelectStyle = () => {
  const handleInputChange = (newValue) => {
    // const inputValue = newValue.replace(/\W/g, "");
    // this.setState({ inputValue });
    // console.log(inputValue);
    // console.log(newValue.value);
    // return inputValue;
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <SelectWrapper>
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={options[0]}
          name="color"
          options={options}
          styles={colourStyles}
          onChange={handleInputChange}
        />
      </SelectWrapper>
      {/* <Select
        defaultValue={colourOptions[2]}
        label="Single select"
        options={colourOptions}
        styles={colourStyles}
      /> */}
    </div>
  );
};

export default SelectStyle;
