import React from "react";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const ItemBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    cursor: pointer;
    display: block;
    width: 100%;
    height: 100%;
  }
  .playButton {
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    right: 0;
    margin 6px;
    ${Media.mobile`
      width: 10px;
      height: 10px;
      margin: 4px;
    `}
  }
`;

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1;
  cursor: pointer;
`;

const SnsGridItem = ({ src, isVideo, ...rest }) => {
  return (
    <ItemBlock>
      <Img src={src} {...rest} />
      {isVideo && (
        <img
          src="/Asset/01FYBC86H6SPZGXDPV7Z29Q8K9/Arrow-a.svg"
          alt="playButton"
          className="playButton"
        />
      )}
    </ItemBlock>
  );
};

export default SnsGridItem;
