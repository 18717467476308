/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  useUsersState,
  useUsersDispatch,
  applyNonMembers,
  statusReset,
} from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { useForm } from "react-hook-form";
import { ValidationInput, Input } from "../../Styles/InputStyle";
import AddPopup from "./AddPopup";
import { EventButton } from "../../Styles/ButtonStyle";
import { goodsData } from "../../Constants/GoodsData";
import GoodsItem from "../Items/GoodsItem";
import ApplyComplete from "./ApplyComplete";
import { useTranslation } from "react-i18next";
import DataLoadingStyle from "../../Styles/DataLoadingStyle";
import { Modal } from "react-responsive-modal";
import DaumPostcode from "../../Utils/DaumPostcode";

const ApplyUserInfoBlock = styled.div`
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    line-height: 1.2;
    margin-bottom: 40px;
  }
`;

const NoUserInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;

  ${Media.tablet`
    
  `}

  ${Media.mobile`
    display: block;
  `}
`;

const AgreementWrap = styled.div`
  width: 49%;
  margin: 5px 0;
  ${Media.mobile`
    width: 100%;
  `}
`;

const AgreementBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px 0;

  ${Media.tablet`
    padding: 0 0 20px;
  `}

  ${Media.mobile`
  `}
`;

const UserInfoBlock = styled.div`
  width: 49%;

  ${Media.mobile`
    width: 100%;
    
  `}
`;

const ApplyUserForm = styled.form`
  width: 100%;

  color: rgb(45, 49, 56);
  text-align: center;

  h3 {
    margin: 0 0 10px 0;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -1.16px;
  }
`;

const GoodsList = styled.ul`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  textarea {
    width: 100%;
    height: 150px;
    border: solid 1px #aaa;
    box-sizing: border-box;
    padding: 20px;
    font-size: 0.875rem;
    color: #777;
    line-height: 1.5rem;
  }
  #all-agreement {
    display: none;
  }
  label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url("./Img/check.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }
  label {
    font-size: 0.875rem;
    a {
      text-decoration: underline;
      display: inline;
    }
  }
  #all-agreement:checked + label::before {
    background-color: #0065c3;
  }
`;

const Alert = styled.div`
  color: rgb(1, 74, 153);
  font-size: 12px;
  line-height: 20px;
`;

const AddressWrapper = styled.div`
  margin-bottom: 50px;
`;

const SearchBtn = styled.div`
  display: inline-block;
  /* width: 20%; */
  padding: 5px;
  /* background-color: #ccc; */
  cursor: pointer;

  width: 80px;
  height: 50px;
  vertical-align: middle;
  background: #0065c3 url("/Img/search-icon.svg") no-repeat center;
  background-size: 20px;
  text-indent: -999px;
  overflow: hidden;
`;

export const onFocus = (e) => {
  e.target.style.border = "2px solid rgb(1, 74, 153)";
};

export const onBlur = (e) => {
  e.target.style.border = "1px solid rgb(217, 219, 224)";
};

const Terms = styled.textarea`
  width: 100%;
  height: 150px;
  border: solid 1px #aaa;
  box-sizing: border-box;
  padding: 20px;
  font-size: 0.875rem;
  color: #777;
  line-height: 1.5rem;
`;

const AgreeCheck = styled.div`
  margin: 20px 0;

  input {
    display: none;
  }
  label {
    font-size: 0.875rem;
  }
  label::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 500px;
    vertical-align: middle;
    background-color: #ddd;
    background-image: url(./Img/check.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    margin-right: 0.4rem;
  }

  ${Media.tablet`

  `}

  ${Media.mobile`
    label {
      font-size: 0.8rem;
    }
  `}

  input:checked + label::before {
    background-color: #0065c3;
  }
`;

const addInitialState = {
  address: {
    province: "",
    street_name_address: "",
    land_lot_number_address: "",
    details: "",
    postcode: "",
  },
};

const ApplyEventUserInfo = ({
  applyEventInfo,
  setApplyEventInfo,
  setState,
  openState,
  match,
}) => {
  const [applyCompletePage, setApplyCompletePage] = useState(false);

  const [openAddress, setOpenAddress] = useState(false);

  const [address, setAddress] = useState(addInitialState);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [postcode, setPostcode] = useState("");

  const openAddressPopUp = () => setOpenAddress(true);
  const closeAddress = () => setOpenAddress(false);
  const openApplyCompletePage = () => setApplyCompletePage(true);

  const [inputs, setInputs] = useState({
    address3: "",
  });

  const { address3 } = inputs;

  const onChange = (e) => {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    errors,
    setValue,
    getValues,
  } = useForm({
    mode: "all",
  });

  const state = useUsersState();
  const dispatch = useUsersDispatch();

  // console.log(state);
  const { loading, success, error } = state.status;
  const { is_login, is_reset, applyEventUser } = state;

  const goScrollTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    goScrollTop();
  }, []);

  if (!match) console.log(match);
  else if (match.url === "/newtropepsipartner")
    localStorage.setItem("partner", true);

  useEffect(() => {
    if (success) {
      reset();
      openApplyCompletePage();
    }
  }, [state]);

  // event 갱신 event_uuid 수정
  const onSubmit = (data) => {
    let fullData = {
      event_uuid: "f8618201-082d-41f1-9ded-073f79301c19",
      first: applyEventInfo.first,
      second: applyEventInfo.second,
      third: applyEventInfo.third,
      exclusive: localStorage.getItem("partner")
        ? localStorage.getItem("partner")
        : false,
      name: data.username,
      email: data.email,
      phonenumber: data.phonenumber,
      address: {
        province: address.province,
        street_name_address: address.street_name_address,
        land_lot_number_address: address.land_lot_number_address,
        details: inputs.address3,
        postcode: address.postcode,
      },
    };

    applyNonMembers(dispatch, fullData);
  };

  const { t } = useTranslation();

  const watchEmail = watch("email");
  const watchName = watch("username");
  const watchPhone = watch("phonenumber");
  const watchPostcode = watch("postcode");
  const watchAgree = watch("agreeevent");
  const watchAgreePolicy = watch("agreeeventPolicy");

  const cancleHypen = (e) => {
    let phoneValue = getValues("phonenumber");
    var replaceNotInt = /[^0-9]/gi;

    if (phoneValue !== replaceNotInt)
      setValue("phonenumber", phoneValue.replace(replaceNotInt, ""));
  };

  if (loading) return <DataLoadingStyle />;

  if (error) {
    let errorMsg = "";
    let first_key = "";
    let first_value = "";

    if (error.response.status === 500)
      errorMsg = "네트워크나 서버에 문제가 있습니다. 다시 시도해주세요.";
    else {
      errorMsg = JSON.parse(error.response.request.responseText);

      first_key = Object.keys(errorMsg)[0];
      first_value = errorMsg[Object.keys(errorMsg)[0]];

      if (first_key === "phonenumber")
        alert("핸드폰번호 형식에 맞지 않습니다.");
      else if (errorMsg[0] === "daily events limit is over")
        alert("하루에 3번까지만 응모가 가능합니다.");
      else alert(errorMsg);
    }
    statusReset(dispatch);
  }

  return (
    <>
      {!applyCompletePage ? (
        <ApplyUserInfoBlock>
          <h3>{t("event:enter-information")}</h3>
          <h4>
            {t("event:result-will-send")
              .split("\n")
              .map((line) => (
                <div>{line}</div>
              ))}
          </h4>
          <NoUserInfoWrap>
            <AgreementWrap>
              {/*Agreement Block복사하여 다국어 함수 키값 및 input id / htmlFor 속성 변경*/}
              <AgreementBlock>
                <Terms defaultValue={t("policy:privacy-policy")} />
                <AgreeCheck>
                  <input
                    type="checkbox"
                    id="agree-event"
                    name="agreeevent"
                    ref={register({
                      required: true,
                    })}
                    // checked={checkedState[1].checked}
                    // onChange={onCheckedBox}
                  />
                  <label htmlFor="agree-event">
                    {t("policy:agree-event-privacy")}
                  </label>
                </AgreeCheck>
                {errors.agreeevent && (
                  <Alert>{t("valid:not-valid-check")}</Alert>
                )}
              </AgreementBlock>
              <AgreementBlock>
                <Terms defaultValue={t("policy:event-policy")} />
                <AgreeCheck>
                  <input
                    type="checkbox"
                    id="agree-event-policy"
                    name="agreeeventPolicy"
                    ref={register({
                      required: true,
                    })}
                    // checked={checkedState[1].checked}
                    // onChange={onCheckedBox}
                  />
                  <label htmlFor="agree-event-policy">
                    {t("policy:agree-event-policy")}
                  </label>
                </AgreeCheck>
                {errors.agreeeventPolicy && (
                  <Alert>{t("valid:not-valid-check")}</Alert>
                )}
              </AgreementBlock>
            </AgreementWrap>
            <UserInfoBlock>
              <ApplyUserForm onSubmit={handleSubmit(onSubmit)}>
                <ValidationInput
                  name="email"
                  label="email"
                  placeholder={t("email")}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  register={register}
                  validation={{
                    required: true,
                    minLength: 5,
                    pattern: /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/,
                  }}
                />
                {errors.email && <Alert>{t("valid:not-valid-email")}</Alert>}
                <ValidationInput
                  name="username"
                  label="username"
                  placeholder={t("name")}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  register={register}
                  validation={{ required: true, minLength: 2 }}
                />
                {errors.username && (
                  <Alert>{t("valid:not-valid-username")}</Alert>
                )}
                <ValidationInput
                  name="phonenumber"
                  label="phonenumber"
                  placeholder={t("number")}
                  autoComplete="off"
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onKeyUp={cancleHypen}
                  register={register}
                  validation={{
                    required: true,
                    minLength: 10,
                    maxLength: 11,
                  }}
                />
                {errors.phonenumber && (
                  <Alert>{t("valid:not-valid-phone-number")}</Alert>
                )}
                <AddressWrapper>
                  <Input
                    className="input-postcode"
                    name="postcode"
                    label="postcode"
                    placeholder={t("postcode")}
                    readOnly={true}
                    autoComplete="off"
                    value={postcode}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onClick={() => openAddressPopUp()}
                    ref={register({
                      required: true,
                      minLength: 3,
                    })}
                  />
                  {errors.postcode && !postcode && (
                    <Alert>{t("valid:not-valid-address")}</Alert>
                  )}
                  <Input
                    className="input-address"
                    name="address1"
                    label="address1"
                    placeholder={t("address01")}
                    readOnly={true}
                    autoComplete="off"
                    value={address1}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onClick={() => openAddressPopUp()}
                  />
                  <SearchBtn
                    // className="btn-address"
                    onClick={() => openAddressPopUp()}
                  >
                    {t("search")}
                  </SearchBtn>
                  <Input
                    name="address2"
                    label="address2"
                    placeholder={t("address02")}
                    readOnly={true}
                    autoComplete="off"
                    value={address2}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                  <Input
                    name="address3"
                    label="address3"
                    placeholder={t("address03")}
                    autoComplete="off"
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={address3}
                  />
                </AddressWrapper>
                {/* {errorMsg && <strong>{errorMsg}</strong>} */}
                <EventButton
                  bgColor="#0065c3"
                  disabled={
                    !(
                      watchEmail &&
                      watchName &&
                      watchPhone &&
                      watchPostcode &&
                      watchAgree &&
                      watchAgreePolicy &&
                      watchPostcode
                    )
                  }
                >
                  {t("event:apply-event")}
                </EventButton>
              </ApplyUserForm>
            </UserInfoBlock>
          </NoUserInfoWrap>
          {openAddress && (
            <Modal
              open={openAddress}
              onClose={closeAddress}
              classNames={{
                overlay: "customAddressOverlay",
                modal: "customAddressModal",
                closeButton: "customCloseBtn",
                animationIn: "customEnterAnimation",
                animationOut: "customLeaveAnimation",
              }}
              animationDuration={0}
            >
              <DaumPostcode
                setOpenAddress={setOpenAddress}
                setAddress={setAddress}
                setAddress1={setAddress1}
                setAddress2={setAddress2}
                setPostcode={setPostcode}
              />
            </Modal>
          )}
        </ApplyUserInfoBlock>
      ) : (
        <ApplyComplete />
      )}
    </>
  );
};

export default ApplyEventUserInfo;
