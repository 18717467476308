/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSocialState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import PopUp from "../../Styles/PopUp";
import AgreementPersonal from "../Layout/AgreementPersonal";
import { useTranslation } from "react-i18next";
import SocialButtons2 from "./SocialButtons2";
import { loginSuccess } from "../../Service/UserApi";
import axios from "axios";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const AgreeBtn = styled.button`
  width: 100%;
  color: #fff;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#0065c3")};
  margin-top: 10px;
  padding: 20px 0;
  border: 0;
`;

const Line = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-size: 0.875rem;

  &::before {
    content: "";
    display: inline-block;
    width: 38%;
    height: 1px;
    background-color: #ddd;
    vertical-align: middle;
    margin-right: 5%;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 38%;
    height: 1px;
    background-color: #ddd;
    vertical-align: middle;
    margin-left: 5%;
  }
`;

const PersonalButtons = ({
  popUpPage,
  setPopUpPage,

  checkSocial,
}) => {
  // const state = useUsersState();
  // const dispatch = useUsersDispatch();

  // const { loading, success, error } = state.status;

  const pageChange = (pageNumber) => setPopUpPage(pageNumber);

  const { t, changeLanguage } = useTranslation();

  // const initializeUserInfo = async () => {
  //   const loggedInfo = localStorage.getItem("refresh");
  //   if (!loggedInfo) return;
  //   await refresh(dispatch);
  // };

  // const checkSocial = async (payload) => {
  //   const response = await axios.post("/api/v1/auth/social/check/", payload);
  //   console.log(response);
  //   // console.log(response.status);
  //   if (response.status === 204) {
  //     setOpenSignUp(true);
  //     setOpenLogIn(false);
  //   } else {
  //     const { refresh } = response.data;
  //     localStorage.setItem("refresh", refresh);
  //     loginSuccess(response);
  //     initializeUserInfo();
  //     // return response;
  //   }
  // };

  const socialState = useSocialState();
  // console.log(socialState);
  return (
    <>
      <Wrapper>
        <AgreeBtn onClick={() => pageChange(2)}>{t("personal-join")}</AgreeBtn>
        {socialState.status !== "success" ? (
          <>
            <Line>{t("or")}</Line>
            <SocialButtons2 checkSocial={checkSocial} />
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </>
  );
};

export default PersonalButtons;
