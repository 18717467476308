/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSocialState } from "../../Context/UserContext";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import PopUp from "../../Styles/PopUp";
import AgreementBusiness from "./AgreementBusiness";
import { useTranslation, initReactI18next } from "react-i18next";
import SocialButtons from "./SocialButtons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const AgreeBtn = styled.button`
  width: 100%;
  color: #fff;
  background-color: ${(props) => (props.disabled ? "#ccc" : "#0065c3")};
  margin-top: 10px;
  padding: 20px 0;
  border: 0;
`;

const Line = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-size: 0.875rem;

  &::before {
    content: "";
    display: inline-block;
    width: 38%;
    height: 1px;
    background-color: #ddd;
    vertical-align: middle;
    margin-right: 5%;
  }
  &::after {
    content: "";
    display: inline-block;
    width: 38%;
    height: 1px;
    background-color: #ddd;
    vertical-align: middle;
    margin-left: 5%;
  }
`;

const BusinessButtons = ({
  checkSocial,

  popUpPage,
  setPopUpPage,
}) => {
  const pageChange = (pageNumber) => setPopUpPage(pageNumber);

  const { t, changeLanguage } = useTranslation();

  const socialState = useSocialState();
  // console.log(socialState);
  return (
    <>
      <Wrapper>
        <AgreeBtn onClick={() => pageChange(5)}>{t("business-join")}</AgreeBtn>
        {socialState.status !== "success" ? (
          <>
            <Line>{t("or")}</Line>
            <SocialButtons checkSocial={checkSocial} />
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </>
  );
};

export default BusinessButtons;
