import React from "react";
// swiper
import { globalStyles } from "../../Utils/stylesConfig";
import { Global } from "@emotion/core";
import Swiper from "react-id-swiper";
import { RelatedProductContainer } from "../../Utils/styledComponents";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import { Link } from "react-router-dom";

const RelatedProductBlock = styled.div`
  width: 100%;
  background-color: #efefef;
  padding-bottom: 40px;

  h2 {
    padding-top: 43px;
    margin-left: 30px;
    margin-bottom: 10px;
    color: #000;
    font-size: 38px;

    ${Media.mobile`
    font-size: 22px;
    margin-left: 17px;
    margin-bottom: 0px;
  `}
  }
`;

const SwiperSlide = styled.li`
  /* width: 50% !important; */
  /* margin-right: 30px !important; */
  /* flex: 0 1 47%; */
  /* transform: translate3d(50%, 0px, 0px); */
`;

const Img = styled.img`
  width: 100%;
  display: block;

  ${Media.mobile`
    display: none;
  `}
`;

const MobileImg = styled.img`
  width: 100%;
  display: none;

  ${Media.mobile`
    display: block;
  `}
`;

const PrevButton = styled.div`
  background-color: #000;
  width: 65px !important;
  height: 48px !important;
  left: -1px !important;
  border-radius: 0px 100px 100px 0px;
  color: #fff;
  font-size: 14px;
  padding: 17px 15px;
`;

const NextButton = styled.div`
  background-color: #000;
  width: 65px !important;
  height: 48px !important;
  right: -1px !important;
  border-radius: 100px 0px 0px 100px;
  color: #fff;
  font-size: 14px;
  padding: 17px 15px;
`;

const RelatedProduct = () => {
  const swiperParams = {
    slidesPerView: 2,
    spaceBetween: 30,
    mousewheel: false,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <PrevButton className="swiper-button-prev eng">PREV</PrevButton>
    ),
    renderNextButton: () => (
      <NextButton className="swiper-button-next eng">NEXT</NextButton>
    ),
    breakpoints: {
      1024: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      320: {
        slidesPerView: 2,
        spaceBetween: 15,
        loop: true,
      },
    },
  };
  return (
    <RelatedProductBlock>
      <h2>RELATED PRODUCT</h2>
      <RelatedProductContainer>
        <Global styles={globalStyles} />
        <Swiper {...swiperParams}>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/brand/1">
              <Img src="/Img/brand-detail/ban-zero-sugar.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-zero-sugar.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/brand/2">
              <Img src="/Img/brand-detail/ban-regular.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-regular.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/history/history40">
              <Img src="/Img/brand-detail/ban-40.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-40.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/history/history50">
              <Img src="/Img/brand-detail/ban-50.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-50.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/history/history60">
              <Img src="/Img/brand-detail/ban-60.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-60.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/history/history80">
              <Img src="/Img/brand-detail/ban-80.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-80.jpg" alt="" />
            </Link>
          </SwiperSlide>
          <SwiperSlide key={Math.random()} id={Math.random()}>
            <Link to="/history/history90">
              <Img src="/Img/brand-detail/ban-90.jpg" alt="" />
              <MobileImg src="/Img/brand-detail-m/ban-90.jpg" alt="" />
            </Link>
          </SwiperSlide>
        </Swiper>
      </RelatedProductContainer>
    </RelatedProductBlock>
  );
};

export default RelatedProduct;
