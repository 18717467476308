import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  logout,
  usePopupSetState,
  usePopupState,
  useUsersDispatch,
  useUsersState,
} from "../../Context/UserContext";
import Media from "../../Styles/MediaQuery";
// import SamsungEventSection from "./SamsungEventSection";
import MainEventSection from "./MainMenuEventSection";

const MainMenuBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100vh;
`;

const MainMenuHeader = styled.div`
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  width: 92%;
  margin: 0 auto;
  height: 52px;
  ${Media.mobile`
    width: 92%;
  `}
  align-content: center;
  div {
    margin: 2px;
    ${Media.mobile`
      margin-top: 14px;
      margin-right: 10px;
    `}
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-image: url("/Asset/01FSC1SW0WNT9AVTM13PQ6S1T2/close.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-indent: -9999px;
    z-index: 99;
  }
`;

const MainMenuSection = styled.div`
  padding: 20px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  ${Media.mobile`
    padding: 13px 40px 30px;
    // height: calc(100vh - 263px);
    flex-direction: column-reverse;
        margin-top: -4vh;
  `}
`;

const MemberMenu = styled.ul`
  li {
    font-size: 1rem;
  }
  h3 {
    font-size: 1rem;
  }
  text-align: center;
  ${Media.mobile`
    text-align: left;
    margin-bottom: 30px;
  `}
`;

const MemberItem = styled.li`
  ${Media.mobile`
    margin-right: 15px;
  `}
  margin-right: 36px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
`;

const LanguageButton = styled.h3`
  ${Media.mobile`
    margin-right: 30px;
  `}
  margin-right: 36px;
  display: inline-block;
  cursor: pointer;
  font-size: 0.875rem;
`;

const MainMenuList = styled.ul`
  margin-bottom: 2rem;
  .parent {
    padding-bottom: 15px;
    ${Media.mobile`
      padding: 0;
    `}
  }
  li a {
    color: #000;
    display: inline-block;
    font-size: 2rem;
    line-height: 4rem;
    transition: all 0.5s;
    ${Media.mobile`
      font-size: 1.5rem;
      line-height: 48px;
    `}
  }
  li a:hover {
    color: #004caf;
  }
  li ul {
    padding-top: 5px;
    ${Media.mobile`
      padding: 0;
    `}
  }
  li ul li a {
    font-size: 1rem;
    line-height: 32px;
    ${Media.mobile`
      font-size: 16px;
      line-height: 32px;
    `}
  }
`;

const ChildMenu = styled.li`
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const MainMenuFooter = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

const menus = [
/*  {
    name: "brand",
    link: "/brand",
    children: [
      {
        name: "pepsi zero sugar lime",
        link: "/brand/1",
      },
      {
        name: "pepsi regular",
        link: "/brand/2",
      },
      {
        name: "retro pepsi",
        link: "/brand/3",
      },
    ],
  }*/
/*  {
    name: "history",
    link: "/history",
    children: [
      {
        name: "1940’",
        link: "/history/history40",
      },
      {
        name: "1950’",
        link: "/history/history50",
      },
      {
        name: "1960’",
        link: "/history/history60",
      },
      {
        name: "1980’",
        link: "/history/history80",
      },
      {
        name: "1990’",
        link: "/history/history90",
      },
    ],
  }*/
  {
    name: "magazine",
    link: "/magazine",
    children: [
      {
        name: "lookbook",
        link: "/magazine/lookbook/2023-pepsi-ive",
      },
      {
        name: "tvc",
        link: "/magazine/tvc/2023-pepsi-ive",
      },
      {
        name: "mv",
        link: "/magazine/mv/2022-pepsi-black-blue",
      },
      {
        name: "challenge",
        link: "/magazine/challenge/pepsi-2022zero",
      },
    ],
  },
  {
    name: "more",
    link: "/gatorade",
    children: [
      {
        name: "GATORADE",
        link: "/gatorade",
      },
    ],
  },
];

const MainMenu2 = ({ openSignUpPopUp, openLogInPopUp }) => {
  const state = useUsersState();
  const dispatch = useUsersDispatch();

  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { is_login } = state;

  const [tabIsOpen, setTabIsOpen] = useState({
    brand: false,
    history: false,
    magazine: false,
  });

  const [langState, setLangState] = useState("ENG");

  const closeMainMenu = () =>
    popupSetState({
      ...popupState,
      mainmenu: false,
    });

  const onLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("acc");
    logout(dispatch);
  };

  const { t, i18n } = useTranslation();

  const onChangeLanguage = () => {
    if (i18n.language === "en") {
      i18n.changeLanguage("ko");
      setLangState("ENG");
    } else if (i18n.language === "ko") {
      i18n.changeLanguage("en");
      setLangState("KOR");
    }
  };

  return (
    <Modal
      open={popupState.mainmenu}
      onClose={closeMainMenu}
      classNames={{
        overlay: "customMainmenuOverlay",
        modal: "customMainmenu customMainmenu2",
        animationIn: "customEnterAnimation",
        animationOut: "customLeaveAnimation",
        closeButton: "customMainCloseBtn",
      }}
      animationDuration={0}
    >
      <MainMenuBlock>
        <MainMenuHeader>
          <div onClick={closeMainMenu} />
        </MainMenuHeader>
        <MainMenuSection>
          <MainMenuList>
            <ul>
              {menus.map((menu) => (
                <li className="parent">
                  <Link
                    className="eng"
                    onClick={() =>
                      setTabIsOpen({
                        brand: false,
                        history: false,
                        magazine: false,
                        [menu.name]: true,
                      })
                    }
                  >
                    {menu.name}
                  </Link>
                  <ul>
                    {menu.children?.map((child) => (
                      <ChildMenu isOpen={tabIsOpen[menu.name]}>
                        <Link
                          className="eng"
                          to={child.link}
                          onClick={closeMainMenu}
                        >
                          -{child.name}
                        </Link>
                      </ChildMenu>
                    ))}
                  </ul>
                </li>
              ))}
              {/*<li className="parent">*/}
              {/*  <Link*/}
              {/*    className="eng" to={"/samsung_galaxy"} onClick={closeMainMenu}> COLLABO*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>
          </MainMenuList>
          <MemberMenu>
            {/*{!is_login ? (
              <>
                <MemberItem onClick={openLogInPopUp}>{t("login")}</MemberItem>
                <MemberItem onClick={openSignUpPopUp}>{t("signup")}</MemberItem>
              </>
            ) : (
              <>
                <MemberItem onClick={onLogout}>{t("logout")}</MemberItem>
                <MemberItem onClick={closeMainMenu}>
                  <Link to="/mypage">{t("mypage")}</Link>
                </MemberItem>
              </>
            )}*/}
            <LanguageButton onClick={onChangeLanguage}>
              {langState}
            </LanguageButton>
          </MemberMenu>
        </MainMenuSection>
        {/*<MainMenuFooter>*/}
        {/*  <MainEventSection />*/}
        {/*</MainMenuFooter>*/}
      </MainMenuBlock>
    </Modal>
  );
};

export default MainMenu2;
