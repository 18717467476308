import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MagazineMenuItemBlock = styled.div`
  a {
    font-size: 14px;
    color: ${(props) => props.color};
    padding-right: 32px;
  }
`

const MagazineMenuItemGatorade = ({ title, detail, path, pathType }) => {
  return (
    <MagazineMenuItemBlock className="eng gatorade" color={path === detail ? '#000000' : '#999999'}>
      <Link to={`/gatorade/magazine/${pathType}/${path}`}>
        {title}
      </Link>
    </MagazineMenuItemBlock>
  );
};

export default MagazineMenuItemGatorade;