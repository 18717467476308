/* eslint-disable no-unused-vars */
import React from "react";
// swiper
import { globalStyles } from "../../Utils/stylesConfig";
import { Global } from "@emotion/core";
import Swiper from "react-id-swiper";
import { PhotoSlideContainer } from "../../Utils/styledComponents";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";

const PhotoSliderBlock = styled.div`
  width: 100%;
`;

const SwiperSlide = styled.li`
  width: 100%;

  img {
    width: 100%;
    cursor: pointer;
  }
`;

const PhotoSlider = ({ data }) => {
  const swiperParams = {
    slidesPerView: 1,
    spaceBetween: 0,
    mousewheel: false,
    loop: false,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  };

  return (
    <PhotoSliderBlock>
      <PhotoSlideContainer>
        <Global styles={globalStyles} />
        <Swiper {...swiperParams}>
          {data.map((item) => (
            <SwiperSlide key={Math.random()} id={Math.random()}>
              <img src={item} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </PhotoSlideContainer>
    </PhotoSliderBlock>
  );
};

export default PhotoSlider;
