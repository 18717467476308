/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { loginSuccess } from "./Service/UserApi";

import {
  useUsersState,
  useUsersDispatch,
  useSocialState,
  useSocialDispatch,
  usePopupState,
  usePopupSetState,
  refresh,
  getUsers,
  getUserEvent,
  getUserEventDetail,
} from "./Context/UserContext";
import MainRouter from "./Components/Router/MainRouter";
import GlobalStyles from "./Styles/GlobalStyles";
import HeaderContainer from "./Components/Containers/HeaderContainer";
import HeaderContainer2 from "./Components/Containers/HeaderContainer2";
import HeaderContainerGatorade2 from "./Components/Containers/HeaderContainerGatorade2";
import Maintenance from "./Components/Pages/Maintenance";
import "./App.css";
import MainMenu from "./Components/Layout/MainMenu";
import MainMenu2 from "./Components/Layout/MainMenu2";
import SignUp from "./Components/Pages/SignUp";
import Login from "./Components/Pages/Login";
import ModalStyle from "./Components/Pages/ModalStyle";
import BrandModal from "./Components/Pages/BrandModal";

function App() {
  const state = useUsersState();
  const dispatch = useUsersDispatch();
  const socialState = useSocialState();
  const socialDispatch = useSocialDispatch();
  const popupState = usePopupState();
  const popupSetState = usePopupSetState();

  const { error } = state.status;
  // console.log("state", state);
  // console.log(popupState);
  // console.log(socialState);

  // console.log(state.is_login);

  const initializeUserInfo = async () => {
    const loggedInfo = localStorage.getItem("refresh");
    if (!loggedInfo) return;
    await refresh(dispatch);
    // if (!state.userInfo) await getUsers(dispatch);
    // if (!state.applyEventUser) await getUserEvent(dispatch);
    // if (!state.applyEventUserDetail) await getUserEventDetail(dispatch);
  };

  useEffect(() => {
    initializeUserInfo();
  }, []);

  // const api = axios.create({
  //   baseURL: "https://khouseofpepsi.com",
  // });

  const openLogInPopUp = () =>
    popupSetState({
      ...popupState,
      login: true,
      mainmenu: false,
    });

  const openSignUpPopUp = () =>
    popupSetState({
      ...popupState,
      signup: true,
    });

  const gatoradeHeader = () =>
      popupSetState({
        ...popupState,
        gatorade: true,
      })

  const checkSocial = async (payload) => {
    // console.log("facebook");
    try {
      const response = await axios.post(
        `https://web.pep1.kr/api/v1/auth/social/check/`,
        payload
      );
      // const data = await response.json();
      // console.log(response);
      if (response.status === 200 && response.data.email) {
        // console.log("가입하지 않은 계정입니다.");
        socialDispatch({
          type: "CHECK_SOCIAL_SUCCESS",
          social_email: response.data.email,
        });
        popupSetState({
          ...popupState,
          signup: true,
          login: false,
        });
      } else if (response.status === 200 && response.data.email === null) {
        console.log("이메일이 없습니다.");
        socialDispatch({
          type: "CHECK_SOCIAL_SUCCESS",
          social_email: response.data.email,
        });
        popupSetState({
          ...popupState,
          signup: true,
          login: false,
        });
      } else {
        // console.log("이미 가입된 계정입니다.");
        const { refresh } = response.data;
        localStorage.setItem("social", true);
        localStorage.setItem("refresh", refresh);
        loginSuccess(response);
        initializeUserInfo();
        popupSetState({
          ...popupState,
          signup: false,
          login: false,
        });
      }
    } catch (err) {
      // console.error(err);
      // console.log(err.response);
      if (err.response.data[0] === "User with this e-mail already exists") {
        // this.props.setError(err.response.data.description)
        alert("일반회원 또는 다른 채널로 가입한 이메일입니다.");
        popupSetState({
          ...popupState,
          signup: false,
          login: true,
        });
      } else {
        alert(err.response.statusText + "고객센터로 문의바랍니다.");
      }
    }
  };

  // useEffect(() => {
  // const acc = localStorage.getItem("acc");
  // console.log(state.acc);
  // console.log(axios.defaults.headers.common["Authorization"]);
  // if (acc) console.log(acc);
  // getUsers(dispatch);

  //   if (state.acc) getUsers(dispatch);
  // }, [state.acc]);

  // console.log(socialState);
  if (error) {
    if (
      error.response.status === 401 &&
      error.response.data.code === "token_not_valid"
    )
      localStorage.removeItem("refresh");
    localStorage.removeItem("acc");
    localStorage.removeItem("social");
  }

  return (
    <>
      {/*<Maintenance />*/}
      {!popupState.mainmenu && !popupState.brand && !popupState.gatorade && <HeaderContainer2 openLogInPopUp={openLogInPopUp} />}
      {!popupState.mainmenu && !popupState.brand && popupState.gatorade && <HeaderContainerGatorade2 openLogInPopUp={openLogInPopUp} />}
      {popupState.login && <Login checkSocial={checkSocial} />}
      {popupState.signup && <SignUp checkSocial={checkSocial} />}
      {popupState.brand && <BrandModal />}
      {/* {popupState.mainmenu && (
        <MainMenu
          openLogInPopUp={openLogInPopUp}
          openSignUpPopUp={openSignUpPopUp}
        />
      )} */}
      {popupState.mainmenu && (
        <MainMenu2
          openLogInPopUp={openLogInPopUp}
          openSignUpPopUp={openSignUpPopUp}
        />
      )}
      {/* {popupState.modal_test && <ModalStyle checkSocial={checkSocial} />} */}
      <MainRouter checkSocial={checkSocial} />

      <GlobalStyles />
    </>
  );
}

export default App;
// export default React.memo(App);
