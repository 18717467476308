import styled from "styled-components";
import Media from "../../../Styles/MediaQuery";

export const ChallengeBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;

  padding: 50px 0;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
  }

  .tiktok-block > div > div > div > div > div {
    margin: 0;
  }
  a {
    display: inline;
  }
`;

export const PhoneImg = styled.div`
  width: 23vw;
  height: 100%;

  img {
    width: 100%;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

export const PhoneImgMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 3vw 0;
    padding: 0 18vw;
    img {
      width: 100%;
      height: 100%;
      max-width: 300px;
    }
  }
`;

export const Heading = styled.div`
  display: flex;
  > div {
    font-size: 3vw;
    font-weight: 900;
    color: #fff;
    line-height: 4vw;
    span {
      color: #c2d501;
      font-family: inherit;
    }
    font-family: "MontrealTS", "noto sans kr", "맑은 고딕", "Malgun Gothic",
      sans-serif !important;
  }
  > img {
    margin-left: 9vw;
    width: 83px;
    height: 115px;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    > div {
      font-size: 2.8vw;
      line-height: 3.2vw;
    }
    > img {
      width: 9%;
      height: 100%;
    }
  }
  @media (max-width: 767px) {
    > div {
      font-size: 5.3vw;
      line-height: 7vw;
    }
    > img {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  justify-content: center;
  width: 60vw;
  margin: 0 0 10vw 3vw;
  font-family: "MontrealTS", "noto sans kr", "맑은 고딕", "Malgun Gothic",
    sans-serif !important;
  @media (max-width: 767px) {
    margin-left: 0;
    width: 80vw;
  }
`;

export const Description = styled.p`
  color: #fff;
  font-size: 1.4vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin-bottom: 0 !important;
  margin-top: 0.5vw !important;
  font-family: "noto sans kr", "맑은 고딕", "Malgun Gothic", sans-serif !important;
  line-height: 0;

  @media (min-width: 768px) and (max-width: 1280px) {
    font-size: 1.6vw;
  }

  @media (max-width: 767px) {
    font-size: 3.2vw;
    line-height: 5.1vw;
    word-break: keep-all;
  }
`;

export const Slogan = styled.p`
  margin: 3.1rem 2rem 1.2rem 0 !important;
  margin-bottom: 1.2rem !important;
  font-size: 1.2vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  color: #c2d501;
  font-family: "MontrealTS", "noto sans kr", "맑은 고딕", "Malgun Gothic",
    sans-serif !important;
  @media (max-width: 767px) {
    font-size: 2.5vw;
    text-align: center;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
`;

export const MobileLogo = styled.div`
  display: none;
  @media (max-width: 767px) {
    display: block;
    margin-top: 3.5rem;
    img {
      width: 7.6rem;
      margin-bottom: 19px;
    }
  }
`;

export const ChannelHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop || 0};
  margin-bottom: 2vw;
  img {
    width: 3.7vw;
    margin-right: 0.2vw;
    @media (max-width: 767px) {
      width: 40px;
      margin-right: 10px;
    }
  }
  span {
    font-family: "MontrealTS", "noto sans kr", "맑은 고딕", "Malgun Gothic",
      sans-serif !important;
    font-style: normal;
    font-weight: 900;
    font-size: 1.6vw;
    line-height: 150%;
    color: white;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
`;

export const TiktokFeedList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 30px;
  column-gap: 30px;
`;

export const HorizontalBetween = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  ${Media.mobile`
    justify-content: center;
`}
`;

export const MobileShowAgreementButton = styled.a`
  font-family: Noto Sans KR;
  font-style: normal;
  font-size: 12px;
  line-height: 25px;

  color: #ffffff;
  svg {
    margin-left: 2px;
    width: 6px;
    height: 10px;
    vertical-align: baseline;
  }
  display: none !important;
  ${Media.mobile`
    display: inline !important;
  `}
  cursor: pointer;
`;

export const ShowAgreementButton = styled.a`
  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 25px;

  color: #ffffff;
  svg {
    margin-left: 9px;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
  cursor: pointer;
`;
