import React, { useEffect, useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import styled from "styled-components";
import Media from "../../Styles/MediaQuery";
import MagazineMenuList from "../Items/MagazineMenuItem";
import { instagram, others } from "../../Constants/SnsData";
import SnsGridItem from "../Items/SnsGridItem";

const NavBlock = styled.div`
  ${Media.mobile`
    padding: 0 0 20px;
  `}
`;

const NavSection = styled.div`
  margin: 32px auto 56px;
  display: flex;
  flex-direction: row;
  ${Media.mobile`
    display: none;
  `}
`;

const NavSectionMobile = styled.div`
  margin: 10px auto;
  display: none;
  select {
    font-size: 12px;
  }
  ${Media.mobile`
    display: block;
  `}
`

const SnsBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 18px;
  row-gap: 20px;
  ${Media.mobile`
    gap: 6px;
    grid-template-columns: repeat(2, 1fr);
  `}
`

const ShowMoreButton = styled.button`
  margin-bottom: 60px;
  padding: 7px 0;
  background-color: #F2F2F2;
  border: 1px solid #DDDDDD;
  color: #868686;
  font-size: 14px;
  display: ${(props) => props.shouldShowMore ? 'none' : 'block'};
`

const snsTabs = [
  { id: 0, title: 'instagram', path: 'instagram' },
  /*{ id: 1, title: 'tiktok', path: 'tiktok' },*/
]

const SNS = ({ wrapper: Wrapper }) => {
  const history = useHistory();
  const { params: { detail }} = useRouteMatch();
  const tabs = ['instagram', 'others'];
  const tabData = { instagram, others };

  const [shouldShowMore, setShouldShowMore] = useState(false)

  const getDetail = () => {
    if (tabs.includes(detail)) {
      return detail;
    };
    return 'instagram';
  };

  const data = tabData[getDetail()];

  useEffect(() => {
    if (!tabs.includes(detail))
      history.push('/magazine/sns/instagram');
  });

  useEffect(() => {
    if (data.length <= 40)
      setShouldShowMore(true);
  }, [data]);

  const getData = () => {
    if (!shouldShowMore) return data.slice(0,40);
    return data;
  }

  return (
    <Wrapper>
      <NavBlock>
        <NavSection>
          {snsTabs?.map((data) => (
            <MagazineMenuList
              key={data.id}
              title={data.title}
              detail={detail}
              path={data.path}
              pathType="sns"
            />
          ))}
        </NavSection>
        <NavSectionMobile>
          <select
            value={detail}
            className="eng"
            name="path"
            onChange={(e) => history.push(`/magazine/sns/${e.target.value}`)}
          >
            {snsTabs?.map((data) => (
              <option key={data.id} value={data.path}>
                {data.title}
              </option>
            ))}
          </select>
        </NavSectionMobile>
      </NavBlock>
      <SnsBlock className="snsBlock">
        {getData().map(item =>
          <SnsGridItem
            key={item.img}
            src={item.img}
            isVideo={item.isVideo}
            onClick={() => window.open(item.externalLink, '_blank')}
          />
        )}
      </SnsBlock>
      <ShowMoreButton shouldShowMore={shouldShowMore} onClick={() => setShouldShowMore(true)}>
        더보기
      </ShowMoreButton>
    </Wrapper>
  )
};

export default SNS;
